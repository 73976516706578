<template>
  <div>
    <!-- 搜索 -->
     <li class="li-table">
          <div>列名</div>
          <div><el-checkbox :value="showAllFields" @change="(val)=>setAllFieldSelect(val)">字段显示</el-checkbox></div>
          <div><el-checkbox :value="showAllEditable" @change="(val)=>setAllFieldEditable(val)">字段可编辑</el-checkbox></div>
          <div><el-checkbox :value="showAllColumn" @change="(val)=>setAllColumnSelect(val)">显示列</el-checkbox></div>
          <div>列排序</div>        
      </li>
    <draggable v-model="widgetList" ghostClass='ghost' group="field" handler=".drag-handler" :animation="500" class="column-item" @end="updateWidgetList">
      <transition-group>
        <template v-for="(item, index) in filteredWidgetList">
          <li :key="'row'+index">
            <div class="li-table">
              <div>
                <span v-if="isChildren(item)" class="el-icon-arrow">
                  <i class="el-icon-arrow-down" v-if="!isExpand(item)" @click="toggleExpand(item)"></i>
                  <i class="el-icon-arrow-up" v-if="isExpand(item)" @click="toggleExpand(item)"></i>
                </span>{{ item.displayName }}
              </div>
              <div style="display:inline-flex; align-items:center"><el-checkbox :value="!checkItemKey(showFields,item,'hide')" @change="(val)=>setCheckItemKey(showFields,item,'hide',!val,'showFields')" /></div>
              <div style="display:inline-flex; align-items:center"><el-checkbox v-if="item.type!='sysField'" :value="checkItemKey(showFields,item,'editable')" :disabled="disableEditable(showFields,item)" @change="(val)=>setCheckItemKey(showFields,item,'editable',val)" /></div>
              <div style="display:inline-flex; align-items:center">

                <el-checkbox :value="!checkItemKey(showColumns,item,'hide')" :disabled="disableShowColumn(item)"  @change="(val)=>setCheckItemKey(showColumns,item,'hide',!val)" />
                <i title="固定在左侧" class="el-fix-column el-icon-top-left" :class="{'active':!!item.left}" @click="setColumnFix(showColumns,item,'left')" style="margin-left:5px"></i>
                <i title="固定在右侧" class="el-fix-column el-icon-top-right" :class="{'active':!!item.right}" @click="setColumnFix(showColumns,item,'right')"></i>
              </div>
              <div class="drag-handler">
                <i class="el-icon-rank"></i>
              </div>
            </div>
            <template v-if="item.widgetList && isExpand(item)">
              <li class="li-table li-table-child" v-for="(child,childIndex) in item.widgetList" :key="index+'-'+childIndex">
                <div>{{ child.displayName }}</div>
                <div><el-checkbox :value="!checkChildItemKey(showFields, item, child, 'hide')"  @change="(val)=>setChildCheckItemKey(showFields, item,child,'hide',!val)" /></div>
                <div><el-checkbox :value="checkChildItemKey(showFields, item, child, 'editable')" :disabled="disableChildEditable(item,child)" @change="(val)=>setChildCheckItemKey(showFields, item,child,'editable',val)" /></div>
                <div></div>
                <div><i class="el-icon-rank"></i></div>
              </li>
            </template>
          </li>
        </template>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { deepClone } from "@/utils";
export default {
  components: {
    draggable,
  },
  computed:{
    filteredWidgetList: function () {
      return this.widgetList.filter((item) => {
        return !this.searchField || item.headerName.toLowerCase().includes(this.searchField.toLowerCase());
      });
    }
  },
  props: {
    formWidgetList: {
      type: Array,
      default:()=>[],
      required: true,
    },
    showFields:{
      type: Array,
      require: true,
    },
    showColumns:{
      type: Array,
      require: true,
    }
  },
  data () {
    return {
      showAllFields: false,
      showAllEditable: false,
      showAllColumn: false,
      widgetList: [],
      searchField: "",
      checkedAll: {},
      expandChild: {}
    };
  },
  watch:{
    showColumns:function(){
      this.refreshWidgetList();
    }
  },
  mounted(){    
    // console.log('formWidgetChildren',this.formWidgetList);
    // console.log('showFields',this.showFields);
    // console.log('showColumns',this.showColumns);
    this.refreshWidgetList();
  },
  methods: {
    disableEditable(list,item){
      let f=list.find(x=>x.field==item.name);
      
      if(f && f['hide']){
        return true
      }else{
        return false;
      }
    },    
    disableShowColumn(item){
      let fieldItem = this.showFields.find(x=>x.field==item.name);
      if(fieldItem && fieldItem['hide']===true){
        return true
      }else{
        return false;
      }
    },
    disableChildEditable(item,child){
      let childItem= this.showFields.find(x=>x.field==item.name).children.find(x=>x.field==child.name);
      if(childItem && childItem.hide==true){
        return true;
      }else{
        return false;
      }
    },
    checkItemKey(list,item,key){
      let f=list.find(x=>x.field==item.name);
      if(!f){
        return false;
      }else{
        if(key=='hide'){
          return f[key]
        }else{
          return f[key]==undefined?false: f[key]
        }
      }
    },
    checkChildItemKey(list,item,child,key){
      // 找到showFields.children中 与formFieldList 子表单 中组件名称相同的字段
      let f=list.find(x=>x.field == item.name);
      if(f){
        let sf = f.children.find(x=>x.field==child.name)
        if(sf){
          return sf[key]
        }
      }
      return false;
    },
    setCheckItemKey(list,item,key,value, listType){
      let checkItem=list.find(x=>x.field==item.name);
      if(!checkItem)return;
      

      // 不管是取消字段显示还是取消列显示，都把冻结和列宽重置
      if(checkItem.hide!==true){        
        let w = this.widgetList.find(f=>f.name===item.name)
        if(w){
          delete w.left;
          delete w.right;
          delete w.columnWidth;
        }
      }
      
      if(key!=='editable' || checkItem.hide !==true){
        this.$set(checkItem,key,value);
      }
      // 如果不显示字段
      if(listType&&listType=='showFields' && key=='hide' && value===true){
        // 把可编辑的勾去掉，同时也不允许显示列
        this.$set(checkItem,'editable', false);
        let colItem = this.showColumns.find(x=>x.field==item.name);
        colItem && this.$set(colItem,'hide', true);

        
        // 把子字段的显示和编辑也清空
        if(checkItem.children){
          checkItem.children.forEach(child=>{
            this.$set(child,'hide', true);
            this.$set(child,'editable', false);
          })
        }
      }
      // 把子字段的显示和编辑也清空
      if(checkItem.children){
        checkItem.children.forEach(child=>{
          if(key !=='editable' || child.hide!==true){
            this.$set(child,key,value);
          }
        })
      }
      
      // 设置全选checkbox
      this.isAllFieldSelect();
      this.isAllFieldEditable();
      this.isAllColumnEditable();
        this.$forceUpdate();
    },
    setChildCheckItemKey(list,item,child,key,value){      
      let checkItem=list.find(x=>x.field == item.name).children.find(x=>x.field==child.name);
      this.$set(checkItem,key,value);

      if(key=='hide' && value===true){
        this.$set(checkItem,'editable', false)
      }
      this.isAllFieldSelect();
      this.isAllFieldEditable();
      this.isAllColumnEditable();
    },
    updateWidgetList (item) {
      let columns = [];
      this.widgetList.forEach(x=>{
        let column= this.showColumns.find(f=>f.field === x.name);
        let newColumn ={
            field: x.name,
            headerName: x.displayName,
            hide: column?column.hide: true,
            type: x.type=='sysField'?'sysField':'formField',
            left: x.left||false,
            right: x.right||false,
            colWidth: x.colWidth||undefined
        }
        if(column && column.children){
          newColumn.children= column.children;
        }        
        columns.push(newColumn)
      })
      this.$emit("update:showColumns", columns);

      
      let fields = [];
      this.widgetList.forEach(x=>{
        let field= this.showFields.find(f=>f.field === x.name);
        if(!field) return;
        let newColumn ={
            field: x.name,
            headerName: x.displayName,
            editable: field.editable,
            hide: field?field.hide: true,
            type: 'formField'
        }
        
        if(field.children){
          newColumn.children= field.children;
        }        
        fields.push(newColumn)
      })
      
      this.$emit("update:showFields", fields);
      // this.$emit("change", item);
    },
    isChildren(item){
      return this.checkItemKey(this.showFields,item,'children')
    },
    isExpand (item) {
      return !!this.expandChild[item.name]
    },
    toggleExpand (item) {
      this.$set(this.expandChild, item.name, !this.isExpand(item))
    },
    refreshWidgetList(){
      let widgetList = deepClone(this.formWidgetList);
      widgetList.push(
        {
            "name": "name",
            "displayName": "填报人",
            "hide": true,
            "type": "sysField"
        },{
            "name": "fillTime",
            "displayName": "填报时间",
            "hide": true,
            "type": "sysField"
        },{
            "name": "updateTime",
            "displayName": "更新时间",
            "hide": true,
            "type": "sysField"
        },{
            "name": "auditStatus",
            "displayName": "审核意见",
            "hide": true,
            "type": "sysField"
        },{
            "name": "status",
            "displayName": "当前状态",
            "hide": true,
            "type": "sysField"
        }
      )    
      let sortNames = this.showColumns.map(x=>x.field);

      sortNames.forEach((sn,i)=>{
        let w =widgetList.find(x=>x.name==sn);
        if(w) w.sort = i;
      })
      widgetList.sort((x,y)=>{
        return x.sort-y.sort
      })
      this.widgetList = widgetList;
      

      // 复制showColumns的 列宽，冻结属性到widgetList对应属性
      this.showColumns.forEach(y=>{
        let w =widgetList.find(x=>x.name==y.field);
        if(w){
          w.columnWidth = y.columnWidth;
          w.left = y.left;
          w.right = y.right;
        }
      })



      this.isAllFieldSelect();
      this.isAllFieldEditable();
      this.isAllColumnEditable();
    },
    isAllFieldSelect(){
      let isAllSelect= true;        
      this.widgetList.forEach(x=>{
        // if(x.type=='sysField') return;

            let field = this.showFields.find(y=>y.field == x.name);
            if(field && field.hide==true){
              isAllSelect=false
            }else if(field && field.children && field.children.some(x=>x.hide==true)){
              isAllSelect=false;
            } else if(!field) {
              isAllSelect = false
            }
      })
      
      this.showAllFields = isAllSelect;
    },
    isAllFieldEditable(){
      let isAllEdit= true;
      let atLeastOneEditable = false;

      this.widgetList.forEach(x=>{
          // if(x.type=='sysField') return;
          let field = this.showFields.find(y=>y.field == x.name);
          // 如果字段不显示，不处理
          if(field && field.hide==true || x.type=='sysField') return;

          // 如果所有字段都不显示，至少一个可编辑的标记为false，否则为true
          // 避免所有字段都不显示，所有字段都不可编辑时 全选checkbox勾选的情况
          atLeastOneEditable = true;

          if(field && !field.editable){
            isAllEdit = false;
          }

          if(field && field.children && field.children.some(c=>c.hide==false && (c.editable==undefined || c.editable===false))){
            isAllEdit=false;
          } else if(!field) {
            isAllEdit = false
          }
      })
      this.showAllEditable = atLeastOneEditable&& isAllEdit;
    },
    setAllFieldSelect(val){
      this.widgetList.forEach(x=>{
        // if(x.type=='sysField') return;

        this.setCheckItemKey(this.showFields, x, 'hide', !val,'showFields')
      })
      this.showAllFields = val;
    },
    setAllFieldEditable(val){
      this.widgetList.forEach(x=>{
        // if(x.type=='sysField') return;

        this.setCheckItemKey(this.showFields, x, 'editable', val,'showFields')
      })
      this.showAllEditable = val;
    },
    isAllColumnEditable(){
      let isAllEdit= true;

      this.widgetList.forEach(x=>{
          let field = this.showFields.find(y=>y.field == x.name);
          let column = this.showColumns.find(y=>y.field == x.name);
          // 如果字段不显示，不处理
          if(field && field.hide==true) return;
          if(column && column.hide== true) isAllEdit = false;
      })
      this.showAllColumn = isAllEdit;
    },
    setAllColumnSelect(val){
      this.widgetList.forEach(x=>{

        let checkItem=this.showFields.find(y=>y.field==x.name);
        if(!checkItem || checkItem.hide!==true){
          this.setCheckItemKey(this.showColumns, x, 'hide', !val,'showColumns')
        }
      })
      this.showAllColumn = val;
    },
    setColumnFix(showColumns, item,fix){
      if(fix=='left' && !item[fix]==true && item['right']===true){
        this.$set(item, 'right', false)
      }
      if(fix=='right' && !item[fix]==true && item['left']===true){
        this.$set(item, 'left', false)
      }
      this.$set(item,fix, !item[fix])
      let colItem = this.showColumns.find(x=>x.field==item.name);
      this.$set(colItem,'left', item['left']);
      this.$set(colItem,'right', item['right']);

      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
li{
  list-style: none;
}
.li-table{
  display:grid;
  grid-template-columns: 250px repeat(4,100px);
  grid-template-rows: 30px;
  user-select: none;
  .el-icon-arrow{
    width:20px;
    display:inline-block;
  }
}
.li-table-child div:first-child {
  padding-left:20px;
  box-sizing: border-box;
}
.dropdown-search {
  padding: 10px;
}
.dropdown-item {
  list-style: none;
  padding: 5px 10px;
}

.dropdown-item.draggable {
  cursor: move;
}

.display-name {
  display: inline-block;
  width: 200px;
  margin-left: 20px;
}

.column-item {
  margin-bottom: 10px;
  .ghost {
    background-color: rgb(52, 118, 240);
  }
  .drag-handler,
  .drag-sub-handler {
    cursor: move;
    margin-right: 10px;
  }
}

.column-table {
  width: 100%;
  display: inline-flex;
  &-title {
    width: 270px;
    display: inline-block;
  }
}
.column-checkbox {
  width: 90px;
  display: inline-block;
}
.el-icon-arrow-down,
.el-icon-arrow-up {
  cursor: pointer;
}

.ghost{
  background-color:#3476f055!important;
  display:inline-block;
  height:30px;
  width:100%;
}
.el-fix-column{
  display: inline-block;
  width:15px;
  height:15px;
  text-align: center;
  line-height: 15px;
  margin: 0px 3px;
  cursor: pointer;
  &.active{
    color:#3476f0;
    font-weight: bold;
  }
  &:hover{
    color:#3476f0;
    font-weight: bold;
  }
}
</style>
