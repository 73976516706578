<template>
  <div class="school-box">
    <div class="school-box-title" :style="`color:${formDesign.themeColor}`">{{ value }}</div>
    <div class="school-box-line-wrapper">
    <div class="school-box-line" :style="`background:${formDesign.themeColor}`"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    formDesign:{
      type:Object,
      default: ()=>{}
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.school-box {
  width: 100%;
  margin: auto;
  display: block;
  box-sizing: border-box;
  padding: 40px 0 20px 0;
  &-title {
    width: 100%;
    text-align: center;
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
  }
  &-line-wrapper{
    width:100%;
    text-align: center;
  }
  &-line {
    background: #f27c35;
    height:6px;
    width:85px;
    display: inline-block;;
    border-radius: 5px;
  }
  .active {
    color: #f27c35 !important;
  }
}
</style>
