var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forms-content view-info" },
    [
      _c("detail", {
        ref: "formDetail",
        attrs: { standalone: false, reloadProjectList: function () {} },
      }),
      false
        ? _c(
            "div",
            { staticClass: "form-detail-top-part" },
            [
              _c(
                "el-row",
                {
                  staticClass: "el-row",
                  staticStyle: { margin: "0px 0px 10px" },
                  attrs: { gutter: 20, type: "flex" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        padding: "0",
                        width: "200px",
                        height: "120px",
                      },
                      attrs: { span: 7, xs: 24 },
                    },
                    [
                      _c("div", { staticClass: "form-img" }, [
                        _vm.formDetail && _vm.formDetail.cover
                          ? _c("img", {
                              attrs: {
                                src:
                                  _vm.formDetail.cover +
                                  "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        padding: "0",
                        "margin-left": "20px",
                        position: "relative",
                        width: "100%",
                      },
                      attrs: { span: 19, xs: 24 },
                    },
                    [
                      _c("div", { staticClass: "form-info" }, [
                        _c("h1", { staticClass: "clearfix form-title" }, [
                          _c("div", { staticClass: "form-title-left" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "white-space": "nowrap",
                                },
                              },
                              [_vm._v(_vm._s(_vm.formDetail.name))]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "60px",
                                  "margin-left": "20px",
                                },
                              },
                              [
                                !_vm.formDetail.isStop
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "form-state-tag stop",
                                        attrs: { size: "mini", type: "info" },
                                      },
                                      [_vm._v("已暂停")]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        staticClass: "form-state-tag",
                                        class: _vm.getTagClass(
                                          _vm.formDetail.formStateTxt
                                        ),
                                        attrs: { size: "mini" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formDetail.formStateTxt)
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "form-share-warp" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn-wrapper",
                                on: { click: _vm.onCollectClick },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "collect-btn btn",
                                    class: { active: _vm.formDetail.favorite },
                                    attrs: { size: "mini" },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "btn-icon",
                                      attrs: {
                                        "icon-class": _vm.formDetail.favorite
                                          ? "formListCollect"
                                          : "formListUnCollect",
                                      },
                                    }),
                                    _c("span", [_vm._v("收藏")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btn-wrapper",
                                on: { click: _vm.onShareClick },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    attrs: { size: "mini" },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "btn-icon",
                                      attrs: { "icon-class": "formListShare" },
                                    }),
                                    _c("span", [_vm._v("分享")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-detail-top-bottom" }, [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix form-date",
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("p", { staticClass: "fill-date" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "75px",
                                      display: "inline-block",
                                    },
                                  },
                                  [_vm._v("开始时间：")]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formDetail.fillStartTime
                                        ? _vm.formDetail.fillStartTime
                                        : "——"
                                    )
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "0px",
                                    top: "10px",
                                  },
                                },
                                [
                                  _c("formTagEditor", {
                                    attrs: { readonly: true },
                                    model: {
                                      value: _vm.formDetail.tagList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formDetail, "tagList", $$v)
                                      },
                                      expression: "formDetail.tagList",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "clearfix form-date" }, [
                            _c("p", { staticClass: "fill-date" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "75px",
                                    display: "inline-block",
                                  },
                                },
                                [_vm._v("截止时间：")]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formDetail.fillEndTime
                                      ? _vm.formDetail.fillEndTime
                                      : "——"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "clearfix form-date" }, [
                            _c("div", { staticClass: "fill-date" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "75px",
                                    display: "inline-block",
                                  },
                                },
                                [_vm._v("填报人数：")]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formDetail.fillNum != null
                                      ? _vm.formDetail.fillNum + "人可填"
                                      : "填报人数无限制"
                                  )
                                ),
                              ]),
                              _vm.formDetail.fillNumPublic
                                ? _c("span", [_vm._v(",")])
                                : _vm._e(),
                              _vm.formDetail.fillNumPublic
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formDetail.completedFillNum !=
                                            null
                                            ? _vm.formDetail.completedFillNum +
                                                "人已填"
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "clearfix form-date" }, [
                            _c("div", { staticClass: "fill-date" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "75px",
                                    display: "inline-block",
                                  },
                                },
                                [_vm._v("创建机构：")]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formDetail.founder
                                      ? _vm.formDetail.founder
                                      : _vm.formDetail.createName
                                  )
                                ),
                              ]),
                              _vm.formDetail.agencyId
                                ? _c("img", {
                                    staticClass: "is-agency-icon",
                                    attrs: {
                                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-fill-btn" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    type: "primary",
                                    size: "medium",
                                    disabled: _vm.disableFill,
                                  },
                                  on: { click: _vm.onFillClickCheck },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formDetail.startFillText || "开始填报"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "el-row",
                  staticStyle: {
                    "margin-left": "0",
                    "margin-right": "0",
                    height: "30px",
                    padding: "0",
                    display: "-webkit-flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                  attrs: { gutter: 20, type: "flex" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "form-num-wrap",
                      staticStyle: { padding: "0", width: "200px" },
                      attrs: { span: 8, xs: 24, type: "flex" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-total-num" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-right": "5px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "formDetailSeeNum" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formDetail.viewNum &&
                                  _vm.formDetail.viewNum > 10000
                                  ? _vm.formDetail.viewNum / 10000 + "W+"
                                  : _vm.formDetail.viewNum == 10000
                                  ? _vm.formDetail.viewNum / 10000
                                  : _vm.formDetail.viewNum
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-total-num" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-right": "5px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "formDetailShareNum" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formDetail.shareNum &&
                                  _vm.formDetail.shareNum > 10000
                                  ? _vm.formDetail.shareNum / 10000 + "W+"
                                  : _vm.formDetail.shareNum == 10000
                                  ? _vm.formDetail.shareNum / 10000
                                  : _vm.formDetail.shareNum
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-total-num" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-right": "5px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "formDetailCollectNum" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formDetail.favoriteNum &&
                                  _vm.formDetail.favoriteNum > 10000
                                  ? _vm.formDetail.favoriteNum / 10000 + "W+"
                                  : _vm.formDetail.favoriteNum == 10000
                                  ? _vm.formDetail.favoriteNum / 10000
                                  : _vm.formDetail.favoriteNum
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("el-col", {
                    staticStyle: {
                      padding: "0px 0px 0px 20px",
                      position: "relative",
                      "box-sizing": "border-box",
                      "flex-grow": "1",
                      width: "auto",
                    },
                    attrs: { span: 8, xs: 14 },
                  }),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        padding: "0px 0px 0px 20px",
                        position: "relative",
                        "box-sizing": "border-box",
                        "flex-grow": "0",
                        "flex-shrink": "0",
                        "text-align": "right",
                      },
                      attrs: { span: 10, xs: 10 },
                    },
                    [
                      _c("editLinks", {
                        attrs: {
                          formDetail: _vm.formDetail,
                          loginUserId: _vm.loginUserId,
                          loginAgencyUserId: _vm.loginAgencyUserId,
                          formType: "form",
                          returnType: _vm.standalone ? "back" : "refresh",
                        },
                        on: {
                          "update:formDetail": function ($event) {
                            _vm.formDetail = $event
                          },
                          "update:form-detail": function ($event) {
                            _vm.formDetail = $event
                          },
                          refreshList: _vm.refreshList,
                          formStopUpdate: _vm.handleFormStopUpdate,
                          home: _vm.returnProjectHome,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "form-info-tab-bottom" }, [
        _c(
          "div",
          {
            staticClass: "tab-content-nav",
            style: [
              {
                display:
                  _vm.formDetail.details == "" &&
                  _vm.formCustomFileList.length == 0
                    ? "none"
                    : "block",
              },
            ],
          },
          [
            _vm.formDetail && _vm.formDetail.details != ""
              ? _c(
                  "div",
                  {
                    staticClass: "content-nav-title",
                    class: { active: _vm.formInfoCur === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.onFormInfoTabClick(1)
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "formDetailInfo" },
                    }),
                    _vm._v(_vm._s(_vm.formCustomDetails) + " "),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formCustomFileList && _vm.formCustomFileList.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "content-nav-title",
                    class: { active: _vm.formInfoCur === 2 },
                    on: {
                      click: function ($event) {
                        return _vm.onFormInfoTabClick(2)
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "formDetailFile" },
                    }),
                    _vm._v(_vm._s(_vm.formCustomFile) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm.formInfoCur === 1
          ? _c("div", { staticClass: "info-tab-content" }, [
              _c("div", {
                staticClass: "ql-editor details-intro",
                domProps: { innerHTML: _vm._s(_vm.formDetail.details) },
              }),
            ])
          : _vm._e(),
        _vm.formInfoCur === 2
          ? _c(
              "div",
              { staticClass: "info-tab-content" },
              [_c("Download", { attrs: { fileList: _vm.formCustomFileList } })],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填报地址",
            visible: _vm.shareOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareOpen = $event
            },
            close: _vm.handleFillDiaClose,
          },
        },
        [
          _c("div", { staticClass: "share-container" }, [
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v(" 链接分享 ")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v(" 将填报地址粘贴到浏览器访问，或者直接发给填报者 ")]
            ),
            _c(
              "div",
              { staticStyle: { width: "100%", height: "auto" } },
              [
                _c("el-input", {
                  staticClass: "fl",
                  staticStyle: { width: "60%", "margin-right": "40px" },
                  attrs: {
                    type: "text",
                    value: _vm.shareFillUrl,
                    readonly: "",
                  },
                }),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onCopyFillClick },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.onToClick },
                      },
                      [_vm._v("直接打开")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v(" 小程序码分享 ")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v(" 选择小程序码样式，保存小程序码直接发给填报者 ")]
            ),
            _c("img", {
              staticClass: "list-share-image",
              attrs: { src: _vm.shareImageUrl },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  width: "398px",
                  margin: "auto",
                  "text-align": "center",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "20px auto 0" },
                    attrs: { type: "primary" },
                    on: { click: _vm.onSaveClick },
                  },
                  [_vm._v("保存小程序码")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }