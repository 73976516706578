var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "draggable",
        {
          attrs: {
            ghostClass: "ghost",
            animation: "300",
            handle: ".drag-handler",
          },
          model: {
            value: _vm.sortList,
            callback: function ($$v) {
              _vm.sortList = $$v
            },
            expression: "sortList",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.sortList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "sortable-item" },
                [
                  _c("i", { staticClass: "el-icon-rank drag-handler" }),
                  _c(
                    "el-select",
                    {
                      staticClass: "sort-field",
                      attrs: { size: "mini", placeholder: "选择排序字段" },
                      on: {
                        change: function (val) {
                          return _vm.selectChanged(item, val)
                        },
                      },
                      model: {
                        value: item.field,
                        callback: function ($$v) {
                          _vm.$set(item, "field", $$v)
                        },
                        expression: "item.field",
                      },
                    },
                    _vm._l(_vm.sortableFields, function (field) {
                      return _c("el-option", {
                        key: field.name,
                        attrs: { label: field.displayName, value: field.field },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "sort-type",
                      attrs: { size: "mini", placeholder: "选择排序顺序" },
                      model: {
                        value: item.direction,
                        callback: function ($$v) {
                          _vm.$set(item, "direction", $$v)
                        },
                        expression: "item.direction",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "降序", value: -1 } }),
                      _c("el-option", { attrs: { label: "升序", value: 1 } }),
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticClass: "sort-delete",
                    attrs: { size: "mini", icon: "el-icon-delete", circle: "" },
                    on: {
                      click: function ($event) {
                        return _vm.removeSort(index)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", size: "mini", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.addSort()
                },
              },
            },
            [_vm._v("添加排序字段")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }