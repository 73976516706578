var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "dropdown-search" },
        [
          _c("el-input", {
            staticClass: "input-with-select",
            attrs: {
              placeholder: "筛选名称",
              size: "mini",
              "prefix-icon": "el-icon-search",
              clearable: true,
            },
            model: {
              value: _vm.searchField,
              callback: function ($$v) {
                _vm.searchField = $$v
              },
              expression: "searchField",
            },
          }),
        ],
        1
      ),
      _vm.checkKeys.length > 0
        ? _c(
            "div",
            { staticClass: "column-table" },
            [
              _c("div", { staticClass: "column-table-title" }),
              _vm._l(_vm.checkKeys, function (ckb, ckbIdx) {
                return _c(
                  "div",
                  {
                    key: ckbIdx,
                    staticClass: "column-checkbox",
                    staticStyle: { "margin-left": "-15px" },
                  },
                  [_vm._v(" 可编辑 ")]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "draggable",
        {
          staticClass: "column-item",
          attrs: {
            ghostClass: "ghost",
            group: "field",
            handler: ".drag-handler",
            animation: 500,
            disabled: !_vm.sortable,
          },
          on: { end: _vm.updateWidgetList },
          model: {
            value: _vm.widgetList,
            callback: function ($$v) {
              _vm.widgetList = $$v
            },
            expression: "widgetList",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.filteredWidgetList, function (item, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.disabled != true,
                      expression: "item.disabled!=true",
                    },
                  ],
                  key: index,
                  staticClass: "dropdown-item",
                  attrs: { class1: { draggable: _vm.sortable } },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _vm.sortable
                        ? _c("i", { staticClass: "el-icon-rank drag-handler" })
                        : _vm._e(),
                      _c("el-switch", {
                        attrs: {
                          value: !item.hide,
                          disabled: item.disabled,
                          "active-value": true,
                          "inactive-value": false,
                          "active-color": "#3476F0",
                          "inactive-color": "#DCDFE6",
                        },
                        on: {
                          change: function (value) {
                            return _vm.onSwitchChange(item, value)
                          },
                        },
                      }),
                      item.type === "sysField"
                        ? _c(
                            "div",
                            { staticClass: "display-name" },
                            [
                              _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  staticStyle: { "font-size": "10px" },
                                  attrs: { value: "系", type: "primary" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "13px" } },
                                    [_vm._v(_vm._s(item.headerName))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "display-name",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleExpand(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.headerName))]
                          ),
                      item.field.indexOf("statictext") == -1 &&
                      item.field.indexOf("htmltext") == -1 &&
                      item.field.indexOf("divider") == -1
                        ? _vm._l(_vm.checkKeys, function (ckb, ckbIdx) {
                            return _c(
                              "div",
                              { key: ckbIdx, staticClass: "column-checkbox" },
                              [
                                _c("el-checkbox", {
                                  attrs: { value: item[ckb] },
                                  on: {
                                    change: function (val) {
                                      return _vm.onCheckboxChange(
                                        ckb,
                                        item,
                                        val
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      item.children
                        ? _c("div", [
                            !_vm.isExpand(item)
                              ? _c("i", {
                                  staticClass: "el-icon-arrow-down",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleExpand(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.isExpand(item)
                              ? _c("i", {
                                  staticClass: "el-icon-arrow-up",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleExpand(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  item.children
                    ? [
                        _vm.isExpand(item)
                          ? _c(
                              "div",
                              { staticStyle: { margin: "10px 0px" } },
                              _vm._l(
                                item.children,
                                function (child, childIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: index + "-" + childIndex,
                                      staticClass: "dropdown-item",
                                      staticStyle: { "padding-left": "80px" },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          value: !child.hide,
                                          disabled:
                                            !!item.hide || child.disabled,
                                          "active-value": true,
                                          "inactive-value": false,
                                          "active-color": "#3476F0",
                                          "inactive-color": "#DCDFE6",
                                        },
                                        on: {
                                          change: function (value) {
                                            return _vm.onSwitchChange(
                                              child,
                                              value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "display-name" },
                                        [_vm._v(_vm._s(child.headerName))]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }