var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("content-title", {
        attrs: { formDesign: _vm.formDesign },
        model: {
          value: _vm.value.title,
          callback: function ($$v) {
            _vm.$set(_vm.value, "title", $$v)
          },
          expression: "value.title",
        },
      }),
      _c("div", {
        staticClass: "content-wrapper-text",
        domProps: { innerHTML: _vm._s(_vm.value.config) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }