import request from '@/utils/request'

// 获取字段列表
export function largeScreenList(formId) {
  return request({
    url: `/large-screen/list/${formId}`,
    method: 'get'
  })
}

export function wordCloud(formId = '1658663573594832896'){
  return request({
    url: `/fill/Jieba/1658663573594832896`,
    method: 'get'
  })
}