<template>
  <div class="views-page">
    <div v-cloak class="views-tabs block-container">
      <!-- viewName控制显示样式，表单填报的样式，还是web页面样式 -->
      <template v-if="viewName==='baseView'">
        <el-tabs v-model="viewTabName" @tab-click="handleViewClick" v-if="formDetail.enableView &&formIdentity && formAuth" :class="{fullWidth: !isFormAdmin}">
          <el-tab-pane :name="'viewInfo'">
            <span slot="label">
              概览
            </span>
          </el-tab-pane>
          <!-- <el-tab-pane label="全部数据" :name="'viewData'"></el-tab-pane> -->
          <!-- 已填列表需一直显示着，否则用户如果一条数据都没填写，是看不到草稿箱按钮的 -->
          <el-tab-pane :disabled="!complete" label="已填列表" v-if="(true && loginUserId)||formAuth.currentQueryFillListAuth" :name="'myFillList'"></el-tab-pane>
          <el-tab-pane :disabled="!complete" label="审核列表" v-if="formAuth.currentQueryReviewListAuth" :name="'auditList'"></el-tab-pane>
          <el-tab-pane :disabled="!complete" label="管理列表" v-if="!formDetail.enableView && formAuth.currentQueryDataListAuth" :name="'manageList'"></el-tab-pane>
          <el-tab-pane :disabled="!complete" :name="'viewId:'+index" v-for="(item, index) in viewList" :key="index">
            <span slot="label">
              <span>{{item.name}}</span>
              <el-dropdown trigger="click" @click.native.stop="()=>{}" v-if="isFormAdmin">
                <i class="el-icon-arrow-down el-icon--right"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.stop="editView(item)">编辑视图</el-dropdown-item>
                  <el-dropdown-item @click.native.stop="deleteView(item)">删除视图</el-dropdown-item>
                  <el-dropdown-item @click.native.stop="saveView(item)" v-if="viewTabName=='viewId:'+index">保存视图</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </el-tab-pane>
          <el-tab-pane :disabled="!complete" label="统计报表" v-if="formAuth.currentStatisticsAuth" :name="'staticTab'"></el-tab-pane>
        </el-tabs>
        <el-button type="default" size="mini" @click="handleAddDataClick" v-if="isAgencyUser && isFormAdmin && formDetail.enableView" class="view-add">添加视图</el-button>
      </template>
      <template v-else-if="viewName==='webView'">
        <div style="margin-bottom:20px;border-bottom: 1px solid #eee;">
          <viewFormMenu :viewList="viewList" :formAuth="formAuth" :formDesign="formDesign" @change="handleViewClick"></viewFormMenu>
        </div>
      </template>
    </div>
    <div v-show="(formDetail && Object.keys(formDetail).length>0)&&(viewTabName === 'viewInfo' || showDetailType>0)" class="view-info block-container" :class="[viewName]">

      <detail ref="formDetail" :formDetail="formDetail" style="display:inline-block;text-align:left;" :style="{height:viewName==='webView'?'auto':'100%',width:viewName==='webView'?'1280px':'100%',}" :standalone="false" :formDesign="formDesign" @formDetail="onFormDetail" :reloadProjectList="()=>{}"
              @enable-view="enableView" @tab-change="onDetailChange"></detail>
    </div>
    <div v-if="viewTabName!='viewInfo' && showDetailType==-1" class="view-table block-container" :class="[viewName]">     
      <!-- 表格相关代码都写在viewsMixin.js里 -->
      <MyVxeTable ref="fillGridTable" :tableName="formId+'_'+currentViewId" style="text-align:left" :style="{height:viewName==='webView'?'200px':'100%',width:viewName==='webView'?'1280px':'100%',}" v-if="fillGridOptions.columns.length>0" :columns="fillGridOptions.columns" :loading="fillGridOptions.loading" :data="fillGridOptions.data"
                  :params="fillGridOptions.params" :pageConfig="fillGridOptions.pagerConfig" :selectAllRowsHandler_="GetAllDataRows" @page-change="handleDataPageChange" @view-picture="onHandlePreviewImg" @filter-change="fillFilterChanged" @sort-change="fillSortChanged"
                  :agencyId="formDetail.agencyId" :formJson="showAllFieldFormJson" :formDetail="formDetail" @clear-global-search="clearGlobalSearch">
        <template #toolbar_search>
          <el-input v-model="fillQueryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." @clear="onHandleResetFillClick" clearable @keyup.enter.native="onHandleQueryFillClick"></el-input>
        </template>
        <template #toolbar_function>
          <div style="display:flex;">
            <template v-if="btnShowF3DataStatisticsUpdate()">
              <el-button :type="item.backGround" :icon="item.icon" @click="doDataRefreshHandler(item)" v-for="(item,index) in dataRefreshActions" :key="index">{{item.name}}</el-button>
              <el-button v-if="showStatisticRefreshButton(formDetail)" @click="refreshStatisticHandler(formDetail)">数据刷新</el-button>
              <el-button v-if="showStatusButtonShangHaiShiJue(formDetail)" @click="shangHaiShiJueChouJian(formDetail)">论文抽取</el-button>
              <el-button v-if="showStatusButtonShangHaiShiJue(formDetail)" @click="shangHaiShiJuePiPei(formDetail)">专家自动匹配</el-button>
              <el-button v-if="showShuJuShuaXinShangHaiShiJue(formDetail)" @click="shangHaiShiJueShuaXin(formDetail)">数据刷新</el-button>
            </template>
            <!-- formOptionData vform 表单使用的optionData -->
            <ViewBatch :fillGridOptions="fillGridOptions" :formOptionData="optionData" :fillQueryParams="fillQueryParams" :formDetail="formDetail" :formJson="formJson" :formId="formId" :formViewId="currentViewId" :noticeClickNum="noticeClickNum" @resetTable="resetFillGridTable"
                       @reloadTable="getFormViewListData" :viewConfig="{ viewFields: viewFields, viewHideFields: viewHideFields, viewReadonlyFields: viewReadonlyFields }" :showBtn="{
              btnA2Detail,btnA4BatchEdit,btnA6BatchUpdate,btnB1Audit,btnB2AuditBatch,btnC1ExportExcel,btnC3ExportExcelCombine,
              btnC4ExportAttr, btnC5ExportWord,btnD1Import,btnE2PayBatch,btnE3EditPay,btnE4EditPayStatus,btnF1SendNotice,
              dropdownShow,dataChooseDeleteDisabled
            }" style="margin-left:10px"></ViewBatch>
            <!-- 草稿箱入口按钮 -->
            <el-tooltip class="item" effect="dark" content="草稿箱" placement="top-start">
              <el-button type="default" v-if="btnA5Draft()" icon="el-icon-box" circle size="mini" @click="openDraftBox"></el-button>
            </el-tooltip>
            <!-- 草稿箱组件 -->
            <draft ref="draft" v-if="btnA5Draft()" :formAuth="formAuth" :formId="formId" :formJson="formJson" :formDetail="formDetail" @on-draft-submit="onHandleQueryFillClick"></draft>
          </div>
        </template>
        <template #toolbar_share>
          <el-tooltip class="item" effect="dark" content="分享" placement="top" v-if="btnF2Share()">
            <el-link icon="el-icon-share" :underline="false" @click="onHandleShareClick"></el-link>
          </el-tooltip>
        </template>
        <template #toolbar_button>
          <el-button type="primary" size="mini" @click="onBatchReviewClick(
              fillGridOptions.params.selectedRowKeys,
              fillGridOptions.params.isAllDataSelected,
              fillGridOptions.params.excludeIds)" v-if="btnB2AuditBatch()" :disabled="!!btnB2AuditBatchDisabled()" :title="btnB2AuditBatchDisabled()">批量审核</el-button>
          <el-button type="success" size="mini" v-if="btnE2PayBatch()" :disabled="!!btnE2PayBatchDisabled()" @click="onBatchPayHandler(fillGridOptions.params.selectedRowKeys)" style="height:29px;">批量支付</el-button>
          <el-button type="primary" size="mini" @click="onFillClickCheck" style="height:29px;" v-if="btnA1Add()" :disabled="disableFill">继续填报</el-button>
        </template>
        <template #status_text="{column}">
          <vxe-column title="当前状态" width="90" field="status" :fixed="column.fixed" header-class-name="sysfield" :filters="[{ data: {} }]" :filter-render="{ name: 'filter-status' }" :params="filters.status">
            <template #default="{ row }">
              <StatusCode :statusCode="row.status" :row="row" :fillStatusCode="row.fillStatus"></StatusCode>
            </template>
          </vxe-column>
        </template>
        <template #operate>
          <vxe-column type="operate" min-width="100" width="140" slots:default="operate" fixed="right" header-class-name="sysfield" align="center" :show-overflow="true">
            <template v-slot:header>
              <span>操作</span>
            </template>
            <template #default="{ row, rowIndex }">
              <el-button type="text" size="mini" status="primary" content="详情" v-if="btnA2Detail(row)" @click="onViewFillClick(row['fillId'], 'FILL', row)">详情</el-button>

              <el-tooltip effect="dark" :disabled="btnE1Pay(row)===true" :content="btnE1Pay(row).toString()" placement="top-start">
                <span style="margin-left:4px;">
                  <el-button type="text" size="mini" status="primary" content="支付" v-if="btnE1ShowPay()" :disabled="btnE1Pay(row)!==true" @click="onPayFillClick(row['fillId'])">支付</el-button>
                </span>
              </el-tooltip>

              <!-- 审核相关 -->
              <el-button type="text" size="mini" status="primary" content="审核" v-if="btnB1Audit(row) && isAuditLevel(row)" @click="onAuditFillClick( row['fillId'], row )">审核</el-button>
              <!-- 审核相关 -->
              <el-button type="text" size="mini" status="primary" content="修改审核" v-if="btnB1AuditEdit(row) && isAuditEditLevel(row)" @click="onEditReviewClick(row)">修改审核</el-button>

              <el-tooltip :disabled="!dataChooseDeleteDisabled" placement="top-start" content="当前表单关联了子表单数据，请从子表单中删除数据">
                <span style="margin-left:4px;" v-if="btnA3Delete(row)">
                  <el-button type="text" size="mini" status="primary" content="删除" :disabled="!!btnA3DeleteDisabled(row)||dataChooseDeleteDisabled" :title="btnA3DeleteDisabled(row)" @click="onDeleteFillClick(row['fillId'])">删除</el-button>
                </span>
              </el-tooltip>
              <el-tooltip :disabled="!dataChooseDeleteDisabled" placement="top-start" content="当前表单关联了子表单数据，请从子表单中删除数据">
                <span style="margin-left:4px;" v-if="btnA8ManageDelete(row)">
                  <el-button type="text" size="mini" status="primary" content="删除" :disabled="dataChooseDeleteDisabled" @click="onDeleteFillClick(row['fillId'])">删除</el-button>
                </span>
              </el-tooltip>
              <template v-if="btnC5ExportWord() && (!formDetail.exportAfterAudit || (formDetail.exportAfterAudit && row['status'] == 14))">
                <el-button title="WORD导出" style="margin-left:4px;" type="text" size="mini" icon1="el-icon-download" status="primary" content="WORD导出" v-show="!formDetail.exportIsPdf" @click="onExportMyFill(row['fillId'], 'Word')">导出</el-button>
                <el-button title="PDF导出" style="margin-left:4px;" type="text" size="mini" icon1="el-icon-download" status="primary" content="PDF导出" v-show="formDetail.exportIsPdf" @click="onExportMyFill(row['fillId'], 'Pdf')">导出</el-button>
              </template>
            </template>
          </vxe-column>
        </template>
      </MyVxeTable>
    </div>

    <!--审核弹框-->
    <el-dialog title="审核详情" :visible.sync="reviewDialog.open" width="800px" destroy-on-close :append-to-body="true" custom-class="audit-process-dialog" @opened="onShowReviewDialog" @close="onHandleReviewClose">
      <div class="review-contain" v-loading="isAuditLoading">
         <AuditForm ref="reviewFormDialog" 
          :loginUserId="loginUserId" 
          :reviewForm="reviewForm" 
          :fillId="reviewForm.fillId" 
          :formDetail="formDetail"
          :isAllDataSelected="fillGridOptions.params.isAllDataSelected"
          :fillCacheKey="fillGridOptions.fillCacheKey"
          :selectedRowKeys="selectedRowKeys" 
          :excludeIds="fillGridOptions.params.excludeIds"
        ></AuditForm>
        <!-- <el-form ref="reviewFormDialog" :model="reviewForm" :rules="reviewRules" class="audit-form">
          <el-form-item label="审核状态" prop="auditStatus">
            <el-radio-group v-model="reviewForm.auditStatus" @change="onRadioReviewChange">
              <el-radio label="1" style="margin-bottom: 0">通过</el-radio>
              <el-radio label="2" style="margin-bottom: 0">不通过</el-radio>
              <el-radio label="3" style="margin-bottom: 0">返回修改</el-radio>
            </el-radio-group>
            <el-alert v-if="reviewForm.auditStatus == '2'" title="请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。" style="line-height:20px;" type="warning" show-icon></el-alert>
          </el-form-item>
          <el-form-item label="审核意见" prop="reviewRemark" style="line-height:0px;">
            <el-input type="textarea" v-model="reviewForm.reviewRemark" placeholder="请输入审核意见" style="margin-bottom:2px;"></el-input>
          </el-form-item>
          <el-form-item label="审核签字" prop="reviewSign" v-if="formDetail.auditAuthSign">
            <! -- 审核签名 -- >
            <sign ref="signRef" :userId="loginUserId" :agencyId="formDetail.agencyId" :fillId="reviewForm.fillId" :formId="formDetail.formId" :status="reviewForm.status" v-model="reviewForm.reviewSign"></sign>
          </el-form-item>
          <div style="height:20px"></div>
        </el-form> -->
      </div>
      <div slot=footer>
        <div style="width:100%;text-align:right">
          <el-button type="primary" size="small" @click="onSubmitMultipleReviewClick" v-if="isMultipleSelectionFill">提交</el-button>
          <el-button type="primary" size="small" @click="onSubmitReviewClick" v-else>提交</el-button>
          <el-button size="small" @click="onCancelReviewClick">取消</el-button>
        </div>
      </div>
    </el-dialog>



    <!--审核弹框-->
    <!-- 数据分享页面 -->
    <dataShare v-model="dataShareShow" :formId="formId" :formJson="this.formJson" ref="dataShare" v-if="dataShareShow && btnF2Share && loginUserId"></dataShare>
    <!-- 数据分享页面 -->
    <fillAgreement ref="fillAgreement" :formAgreement="agreement" @agree="onFillClick"></fillAgreement>
    <!--支付成功，提示文字-->
    <el-dialog :visible.sync="paySuccessShow" width="430px" custom-class="el-dialog-hide-header" append-to-body @close="onClosePaySuccessPop">
      <!-- <el-dialog :visible="true" width="430px" append-to-body @close="onClosePaySuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;">
          <i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>支付成功！
        </h1>
        <!-- 默认提交提交文字 -->
        <template v-if="customTip.payTipShow == 0">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">
            若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”
          </p>
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px">
            例如可实时接收审核状态变更提醒、管理员定向提醒通知等。
          </p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px;" />
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div v-html="customTip.payTipContent" style="max-height:450px;overflow-y:auto;"></div>
        </template>
      </div>
    </el-dialog>
    <!--支付成功，提示文字-->
    <!--支付填报-->
    <el-dialog title="数据填报支付" :visible.sync="formPayOpen" custom-class="el-dialog-hide-header" width="400px" append-to-body @close="onClosePay">
      <h3 style="width: 100%; text-align: center">
        本次填报支付{{ orderPrice / 100 }}元
      </h3>
      <div class="qrCodeCon" ref="qrCodeUrl" style="text-align: center; margin: auto; width: 200px;" id="qrCode"></div>
      <h3 style="width: 100%; text-align: center">微信扫一扫付款</h3>
    </el-dialog>
    <fillSelector ref="fillSelector" :optionData="optionData"></fillSelector>
    <fillUserViewer ref="fillUserViewer" :formId="formId"></fillUserViewer>
    <fillAgreement ref="fillAgreement" :formAgreement="agreement" @agree="onFillClick"></fillAgreement>
    <userSelect ref="userSelect" :callback="userSelectCallback" :formDetail="formDetail"></userSelect>
    <VFormViewer ref="dlgvFormViewer" @on-save="onHandleEditRefreshData" :onHandleImportClick="onHandleImportClick"></VFormViewer>
    <ViewEdit ref="viewEdit" :formId="formId" :formDetail="formDetail" :formJson='formJson' :visible.sync="showViewEdit" @refreshViewList="reloadView"></ViewEdit>
  </div>
</template>
<script>
import formInfo from '../info/form.vue'
import MyVxeTable from "../components/tables/table.vue";
import gridConfig from "@/utils/grid.js";
import { favorite, userBrowseRecord } from "@/api/system/user";
import { getSessionStorageObj } from "@/utils/db";
import { getToken } from "@/utils/auth";
import { getDateDiff, parseTime } from "@/utils/whale";
import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
import { aliUploader } from "@/utils/oss";
import VFormViewer from "../components/vFormViewer/index.vue";
import * as formApi from "@/api/system/form";

import { formIdentity } from '@/api/system/views'
import ViewEdit from './viewEdit.vue'
import ViewInfo from './viewInfo.vue'
import ViewBatch from './viewBatch.vue'


import sign from "../components/sign/auditSignPC.vue";
import dataShare from "../components/dataShare/index.vue";
import StatusCode from "../components/statusCode.vue";

import fillSelector from "../components/fillSelector";
import fillUserViewer from "../components/fillUserViewer";
import fillAgreement from "../components/fillAgreement/index.vue";
import userSelect from "@/views/system/mechan/components/user-select.vue";


import { listViews, viewDetail, deleteView, editView } from '@/api/system/views.js'
import AuditForm from '@/views/system/form/components/audit/audit-form.vue'

import Draft from '../components/draft'
import {
  beforeBackConfirm,
  showPicturePreview,
  changedValue,
} from "@/utils/formUtil";

import AgGrid from "../agGrid";
import { deepClone } from "@/utils/deepClone.js";
import detail from '../detail.vue'

import viewsMixin from './viewMixin'
import viewAuditMixin from './viewAuditMixin.js'
import viewPayMixin from './viewPayMixin.js'
import viewFillMixin from './viewFillMixin'

import fjnuStatisticsMixin from "@/views/system/form/mixins/fjnuStatisticsMixin";

import { mapState } from 'vuex'
import viewFormMenu from '@/views/system/webView/form/menu'

import { loadDataRefreshConfig } from '@/api/system/statistics'

export default {
  name: "views",
  componentName: "views",
  components:
  {
    formInfo,
    MyVxeTable,
    VFormViewer,
    AgGrid,
    ViewInfo,
    ViewEdit,
    ViewBatch,
    detail,
    sign,
    dataShare,
    StatusCode,
    fillSelector,
    fillAgreement,
    fillUserViewer,
    Draft,
    AuditForm,

    viewFormMenu,
    userSelect
  },
  mixins: [viewsMixin, viewFillMixin, viewAuditMixin, viewPayMixin, fjnuStatisticsMixin],
  props: {
    standalone: {
      type: Boolean,
      default: true,
    },
    formDesign: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      viewTabName: 'viewInfo',
      viewList: [],
      dataEmptyText: '',
      //视图data
      viewTable: {
        loading: false,
        params: {},
        columns: [],
        data: [],
        page: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100, 200],
        },
        rowEditable: () => false,
        fillCacheKey: ''
      },
      showViewEdit: false,
      formId: '',
      loginAgencyUserId: [],
      formJson: {},
      formDetail: {},
      formDetailEmpty: true,
      tableViewJson: {}, // 表格显示的json
      // 查询配置
      formAuth: {},
      fillQueryParams: {
        fillStatus: "",
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        updateStartTime: "",
        updateEndTime: "",
        formFieldId: "",
        fillValue: "",
      },
      formIdentity: {},
      agreement: null,
      optionData: {}, // 给填报表单所需的信息
      excludeIds: [], // 全选后排除的id,
      fillCacheKey: '', // 查询完成后，后端保存结果的cacheKey
      dataShareShow: false,
      notSelfFillOpen: false,
      dataNoticeShow: false,
      currentViewId: '', // 当前选中的视图id
      viewColumns: [],  // 所选视图的表格列
      viewFields: [],  // 所选视图的表单字段
      viewHideFields: [],
      viewReadonlyFields: [],
      viewActions: [], // 视图功能按钮
      viewStyle: {}, // 视图样式
      viewRange: 0, // 视图范围
      showDetailType: -1,  // 显示列表的类型-1 不显示，0视图，3个人填报，4审核列表，5管理列表
      loginUserId: null,
      userInfo: {},
      complete: false,
      dataRefreshActions: [],
      currentViewInfo:{}
    };
  },
  inject: ["getFormDetailObj"],
  async created () {
    const userToken = this.cookie.get("User-Token");
    const sessionStorageUser = getSessionStorageObj("users");
    this.userInfo = sessionStorageUser;

    this.loginUserId = getSessionStorageObj("users")?.userId;
    this.Authorization = getToken();
    try {
      this.optionData = {
        userToken: userToken,
        apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
        formId: this.decode(this.$route.params.formId),
        formPayInfo: this.getFormPayInfo,
      };
    } catch {
      this.optionData = {
        userToken: userToken,
        apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
        formPayInfo: this.getFormPayInfo,
      };
    }

    //图片选项、关联选择中使用的渲染器等数据
    this.optionData.formDataSelectHandler = this.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler = this.showDataAutoSelect;
    this.optionData.formDataSelectMultiFillHandler = this.showDataMultiSelect;
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formPicturePreviewHandler = showPicturePreview;
    this.optionData.formDataCardRender = this.dataCardRender();
    this.optionData.formAgencyId = this.formAgencyId();
    this.optionData.formData = {};
    this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
    this.optionData.loadAgencyUserListHandler = agencyUserList;
    this.optionData.loadAgencyUserHandler = getAgencyUser;
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;

    // this.optionData.aliOSSUpload =aliUploader(true,
    //  {  //OK
    //   formId:'formId',
    //   agencyId:'agencyId',
    //   userId:sessionStorageUser.userId||'userId',
    //   uploadPath:'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
    // })
    this.optionData.aliOSSUpload = aliUploader;
    this.optionData.aliOSSUploadConfig = {
      //OK
      formId: "formId",
      agencyId: "agencyId",
      userId: sessionStorageUser?.userId || "userId",
      uploadPath: "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/",
    };

    this.optionData.userSelectHandler = this.showUserSelect;
    this.optionData.formCreateUser = sessionStorageUser;
    this.optionData.isUserAdmin = getSessionStorageObj("users")?.type > 1;
    this.optionData.fileDelete = this.fileDelete;

    if(sessionStorageUser){
      // this.$store.dispatch("dept/GetDeptList").then((res) => { });
    }

    // 判断是独立运行模式，还是嵌入到页面的模式
    if (this.standalone) {
      if (this.myIsNaN(this.$route.params.id)) {
        this.init(this.$route.query.type, this.$route.params.id);
      } else {
        this.init(this.$route.query.type, this.decode(this.$route.params.id));
      }
    }
  },
  mounted () { },
  computed: {
    ...mapState({
      viewName: state => state.designView.viewName
    }),
    // 表单管理员
    isFormAdmin: function () {
      const loginUser = getSessionStorageObj("users");
      if (!loginUser) return false;
      const agencyUserId = (loginUser.agencyUserId || "").split(",");
      const userId = loginUser.userId;
      if (this.formDetail.userId == userId) {
        return true;
      }
      for (let i = 0; i < agencyUserId.length; i++) {
        if (
          this.formDetail.managerUserId &&
          this.formDetail.managerUserId.indexOf(agencyUserId[i]) != -1
        ) {
          return true;
        }
      }
      return false;
    },
    listType: function () {
      var listType = '';
      if (this.viewRange == 3) {
        //填报人可见
        listType = 'FILL'
      } else if (this.viewRange == 4) {
        // 审核人可见
        listType = 'AUDIT'
      } else if (this.viewRange == 5 || this.viewRange == 6) {
        // 数据管理员可见, 表单管理员可见
        listType = 'MANAGE'
      } else {
        // 不能确定，所有人可见或机构用户可见，根据用户身份判断

        // 默认只能看
        listType = 'VIEW';
        // 如果是填报人，填报弹窗
        if (this.formIdentity.isFillUser) listType = 'FILL';

        // 如果是审核人，审核弹窗
        if (this.formIdentity.isAuditUser) listType = 'AUDIT';

        // 如果是管理员，管理弹窗
        var isAdmin = this.formIdentity.isDataAdmin || this.formIdentity.isFormAdmin;
        if (isAdmin) listType = 'MANAGE';

        // 如果既是管理员，又是审核人 都显示审核列表
        if (isAdmin && this.formIdentity.isAuditUser) listType = 'AUDIT';
      }
      return listType;

    },
    showAllFieldFormJson: function () {
      return {
        formConfig: this.formJson.formConfig,
        widgetList: this.formJson.widgetList,
        fieldList: this.formJson.fieldList.map(x => { return { ...x, hidden: false } })
      }
    },
    dataChooseDeleteDisabled: function () {
      // 禁用子表单关联的 填报删除
      return !!this.formJson.fieldList.find(x => x.type === 'data-choose' && x.isBoth && x.bothRelativeField.indexOf('subformchoose') > -1)
    },
    isFormCreator () {
      const sessionStorageUser = getSessionStorageObj("users");
      // if(this.formDetail)
      return this.formDetail.userId === sessionStorageUser.userId;
    },
    isAgencyUser: function () {
      return !!this.userInfo?.adminAgencyId
    }
  },
  watch: {
    viewTabName: {
      handler: function (val) {
        if (val === 'viewInfo') {
          this.$nextTick(() => {
            this.$refs.formDetail.init("project", this.formId);
          })
        }
      }
    },
    currentViewId:{
      handler:function(val){
        this.$nextTick(() => {
          this.fillQueryParams.fillValue= '';
        })
      }
    }
  },
  methods: {
    async init (type, id) {
      this.formId = id;
      this.viewTabName = "viewInfo";
      this.complete = false;
      if (!this.isBlank(type)) {
        this.formType = type; // 应用模式
      } else {
        this.formType = "form"; // 表单模式
      }
      // const userToken = this.cookie.get('User-Token')
      // this.optionData = { userToken: userToken, apiUrl: `${process.env.VUE_APP_BASE_API_URL}`, formId: this.formId }
      
      Promise.all([
        this.getFormDetail(),
        this.getFields(),
        this.getCustomTip(),
        this.getFormAuth(),
      ]).then(()=>{
      this.clearSearch();

      if(this.userInfo){
        this.getFormIdentity();
        this.getViewList();
        if(this.formType == "form"){
          userBrowseRecord({
            type: "form",
            typeId: this.formId,
          })
        }
      }

      this.$nextTick(() => {
        // this.$refs.formDetail.init("project", this.formId);
        this.$refs.formDetail.initByView({
          type,
          formId: this.formId,
          formDetail: this.formDetail,
          formAuth: this.formAuth,
          customTip: this.customTip
        })
      });
      })
    },
    getCustomTip () {
      const _this = this;
      return new Promise((resolve)=>{
      // 获取表单配置(表单设计器)数据
      formApi.getCustomTip(_this.formId).then((response) => {
        this.customTip = response.data;
        resolve();
      });
      })
    },
    async getFormDetail () {
      if (this.getFormDetailObj) {
        this.formDetail = this.getFormDetailObj();
        this.optionData.formAgencyId = this.formAgencyId();
      } else {
        const formDetailRes = await formApi.formDetail(this.formId);
        this.formDetail = formDetailRes.data;
        this.$store.dispatch(
          "EnableDesignView",
          this.formDetail.enableDesign ? "webView" : "baseView"
        );
      }
    },
    handleAddDataClick () {
      this.showViewEdit = true;
    },
    // 在webVIew视图中的menu调用的
    switchViewClick (tabName) {
      this.viewTabName = tabName
      this.handleViewClick();
    },
    handleViewClick () {
      this.showDetailType = -1;
      this.clearSearch();

      // 点击视图的时候更新一下表单配置，避免管理员的修改未能同步过来
      // this.getFormDetail();
      if (this.viewTabName.indexOf('viewId:') >= 0) {
        let viewIndex = Number(this.viewTabName.split(':')[1]);

        let viewInfo = this.viewList[viewIndex];
        // this.$emit('tab-change',100,viewInfo.name)
        this.currentViewId = viewInfo.id;

        Promise.all([
          viewDetail(this.currentViewId),
          loadDataRefreshConfig(this.formId)
        ]).then(resArray => {
          this.getFormView(resArray[0])
          this.getFormViewListData();

          this.dataRefreshActions = resArray[1].data.filter(x=>resArray[0].data.actions.includes(x.btnId));
          this.$nextTick(()=>{
            this.$refs.fillGridTable.clearFiltersAndSearch({quite: true, runQuery:false})
          })
        })
        // 获取视图配置
        // this.getFormView()
        // 读取视图数据
        // viewsMinin.js里


        // 加载视图
      } else if (this.viewTabName === 'myFillList') {
        this.showDetailType = 3;
        this.$nextTick(() => {
          this.$refs.formDetail.onTabClick(3);
        })
      } else if (this.viewTabName === 'auditList') {
        this.showDetailType = 4;
        this.$nextTick(() => {
          this.$refs.formDetail.onTabClick(4);
        })
      } else if (this.viewTabName === 'manageList') {
        this.showDetailType = 5;
        this.$nextTick(() => {
          this.$refs.formDetail.onTabClick(5);
        })
      } else if (this.viewTabName === 'staticTab') {
        this.showDetailType = 8;
        this.$nextTick(() => {
          this.$refs.formDetail.onTabClick(8);
        })
      } else {
        this.currentViewId = '';
      }

    },
    onFillClickCheck () {
      // 开始填报、继续填报，跳转填报页面
      //开始填报的检查：表单中是是否配置了字段，表单是否需要先同意协议
      if (this.isBlank(this.formJson)) {
        this.$message({
          type: "warning",
          message: "该表单暂无设置表单字段无法填报～",
        });
        return;
      }

      const startFill = () => {
        // 打开填报弹窗
        this.$refs.dlgvFormViewer.showAdd({
          title: this.formDetail.startFillText,
          formId: this.formId,
          formDetail: this.formDetail,
          formData: {},
          formJson: deepClone(this.formJson),
          formMode: 'new',
        });
      }

      if (this.formAgreementId != null) {
        this.$refs.fillAgreement.show(this.formAgreementId, startFill);
      } else {
        startFill();
      }
    },
    onFillClick () {
      this.fillId = null;
      this.viewTable.columns = [];

      formApi.checkFill(this.formDetail.formId).then((response) => {
        if (response.code == 200) {
          // 如果强制启用只读字段
          this.$refs.vformRender.enableForm();

          this.$refs.vformRender.enableForm(this.optionData.isUserAdmin);
          this.$refs.vformRender.hideHiddenWidgets();
        }
      });
      this.breadcrumbName = "";
    },
    clearGlobalSearch () {
      this.fillQueryParams.fillValue = "";
    },
    clearSearch () {
      // clearFilter  true参数：静默清除 不触发事件
      if (this.$refs.fillGridTable) {
        this.fillQueryParams.fillValue = '';
        this.fillQueryParams.filterData = undefined;
        this.$refs.fillGridTable.clearFiltersAndSearch({ runQuery: false });
      }
    },
    getDataList (callback) {
      this.viewTable.loading = true;
      this.dataEmptyText = "正在加载中...";


      const queryParams = {
        pageNum: this.viewTable.page.currentPage
          ? this.viewTable.page.currentPage
          : 1,
        pageSize: this.viewTable.page.pageSize
          ? this.viewTable.page.pageSize
          : 10,
        criteria: this.fillQueryParams.filterData,
        orders: this.fillQueryParams.sortData,
        formFieldId: this.fillQueryParams.formFieldId,
        fillValue: this.fillQueryParams.fillValue,
        queryStatus: this.fillQueryParams.queryStatus,
        payStatus: this.fillQueryParams.payStatus,
        fillStartTime: this.fillQueryParams.fillStartTime,
        fillEndTime: this.fillQueryParams.fillEndTime,
        searchText: this.fillQueryParams.fillValue, //全局搜索
        fillStatus: this.fillQueryParams.fillStatus,
      };
      formApi.fillDataDetail(this.formId, queryParams).then((response) => {
        this.viewTable.fillCacheKey = response.fillCacheKey;
        for (let i = 0; i < response.rows.length; i++) {
          const item = response.rows[i];
          const obj = { ...item.fillJson };

          // 表单开通支付
          obj["payStatus"] = item.payStatus;
          switch (item.payStatus) {
            case 1:
              item.payStatusTxt = "无需支付";
              break;
            case 2:
              item.payStatusTxt = "待支付";
              break;
            case 3:
              item.payStatusTxt = "已支付";
              break;
            case 4:
              item.payStatusTxt = "确认支付";
              break;
          }
          obj["payStatusTxt"] = item.payStatusTxt;
          obj["orderPriceTxt"] = item.orderPrice / 100 + "元";
          obj["payTime"] = !this.isBlank(item.payTime) ? item.payTime : "--";
          obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
          obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
          obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
          obj["auditAuth1Sign"] = item.auditAuth1Sign;
          obj["auditAuth2Sign"] = item.auditAuth2Sign;
          obj["auditAuth3Sign"] = item.auditAuth3Sign;
          
          obj["auditAuth1Time"] = item.auditAuth1Time;
          obj["auditAuth2Time"] = item.auditAuth2Time;
          obj["auditAuth3Time"] = item.auditAuth3Time;
          obj["auditAuthSign"] = item.auditAuthSign;
          obj["auditAuthRemarks"] = item.auditAuthRemarks;
          obj["auditAuthTime"] = item.auditAuthTime;

          obj["status"] = item.status;
          obj["reviewRemark"] =
            item.auditAuthRemarks ||
            item.auditAuth3Remarks ||
            item.auditAuth2Remarks ||
            item.auditAuth1Remarks ||
            "";

          obj["userId"] = item.userId;
          obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
            ? item.fillUserNumber
            : "-";
          obj["fillUserName"] = item.fillUserName;
          obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
          obj["createTime"] = item.createTime;
          obj["agencyDeptList"] = item.agencyDeptList;

          obj["reviewSign"] = item.reviewSign;
          if (this.dataFillStatus) {
            switch (item.fillStatus) {
              case 1:
                item.fillStatusTxt = "个人填报";
                break;
              case 2:
                item.fillStatusTxt = "导入待确认";
                break;
              case 3:
                item.fillStatusTxt = "导入已确认";
                break;
              case 4:
                item.fillStatusTxt = "导入无需确认";
                break;
            }
            obj["fillStatusTxt"] = item.fillStatusTxt;
            obj["fillStatus"] = item.fillStatus;
          }

          obj["fillId"] = item.fillId;

          obj["fillHeaders"] = Object.keys(item.fillJson);
          this.viewTable.data.push(obj);
          this.viewTable.page.total = response.total;
          this.viewTable.params.allDataTotal = response.total;
        }
        if (this.isBlank(this.viewTable.data)) {
          this.dataEmptyText = "暂无数据";
        }

        this.viewTable.loading = false;

        this.$nextTick(() => {
          if (callback) callback();
        });
      });
    },
    async getFields () {
      return new Promise(resolve=>{
      // 获取表单配置(表单设计器)数据
      formApi.httpGetFormFieldV2(this.formId).then((response) => {
        if (response.data) {
          this.formJson = {
            formConfig: response.data.formConfig,
            widgetList: response.data.widgetList,
            fieldList: response.data.fieldList,
          };
          resolve();
        } else {
          this.formJson = {
            fieldList: [],
          };
        }
      });
      })
      
    },

    myIsNaN (value) {
      return typeof value === "number" && !isNaN(value);
    },
    getFormFiles () {
      formApi.formFilesList(this.formId).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            const suffix = this.getSuffix(response.data[i].name);
            let suffixType = null;
            if (
              suffix == "jpeg" ||
              suffix == "JPEG" ||
              suffix == "jpg" ||
              suffix == "JPG" ||
              suffix == "png" ||
              suffix == "PNG"
            ) {
              suffixType = "image";
            } else if (
              suffix == "doc" ||
              suffix == "DOC" ||
              suffix == "docx" ||
              suffix == "DOCX"
            ) {
              suffixType = "word";
            } else if (
              suffix == "exl" ||
              suffix == "EXL" ||
              suffix == "xlsx" ||
              suffix == "XLSX"
            ) {
              suffixType = "excel";
            } else if (suffix == "pdf" || suffix == "PDF") {
              suffixType = "pdf";
            } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
              suffixType = "audio";
            } else if (
              suffix == "mp4" ||
              suffix == "MP4" ||
              suffix == "mov" ||
              suffix == "m4v" ||
              suffix == "mpg" ||
              suffix == "wma" ||
              suffix == "avi" ||
              suffix == "flv" ||
              suffix == "mkv" ||
              suffix == "rm" ||
              suffix == "rmvb"
            ) {
              suffixType = "video";
            } else if (
              suffix == "zip" ||
              suffix == "ZIP" ||
              suffix == "rar" ||
              suffix == "RAR"
            ) {
              suffixType = "zip";
            } else if (
              suffix == "ppt" ||
              suffix == "PPT" ||
              suffix == "pptx" ||
              suffix == "PPTX"
            ) {
              suffixType = "ppt";
            } else {
              suffixType = "other";
            }
            response.data[i].suffixType = suffixType;
          }
          this.formCustomFileList = response.data;
          if (this.formDetail && this.formDetail.details != "") {
            this.formInfoCur = 1;
          } else {
            if (
              this.formCustomFileList &&
              this.formCustomFileList.length > 0
            ) {
              this.formInfoCur = 2;
            }
          }
        }
      });
    },
    async getFormPayInfo (callback) {
      if (!this.formDetail.pay) {
        return {};
      }
      return formApi.formPayInfo(this.formId);
    },
    getSuffix (name) {
      return name.split(".").pop().toLowerCase();
    },
    getFormAuth () {
      return new Promise((resolve)=>{
      formApi.formAuth(this.formId).then((response) => {
        if (response.code == 200) {
          this.formAuth = response.data;
          resolve();
        }
      });
      })
    },
    getFormIdentity () {
      formIdentity(this.formId).then((response) => {
        if (response.code == 200) {
          this.formIdentity = response.data;


        }
      });
    },
    // 获取年月日
    getNowFormatDate () {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentDate = year + seperator1 + month + seperator1 + strDate;
      return currentDate;
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    formAgencyId () {
      return this.formDetail.agencyId;
    },
    onDetailChange (index) {
      if (index == 3) {
        this.viewTabName = 'myFillList';
        this.showDetailType = 3;
      }
      this.$emit('tab-change', index)
    },
    onHandleShareClick () {
      this.dataShareShow = true;
    },
    /**
     * 点击查看按钮
     * @param {string} fillId - 填充ID
     * @param {string} listType - 列表类型, FILL：我的填报, MANAGE：管理列表, AUDIT: 审核列表
     * @param {object} row - 行数据
     */
    onViewFillClick (fillId, listType, row) {
      if (!fillId) {
        fillId = this.selectedDataRowKeys[0].fillId;
      }

      // let isAdmin = this.formIdentity.isDataAdmin || this.formIdentity.isFormAdmin;
      let formMode = 'view';
      // 如果是填报人/数据管理员/表单创建人/表单管理员/当前审核人其中的一个，都可以修改
      // 如果是填报人，表单状态在无需审核、待一级审核，一级二级三级，并且表单未停止 未到期 或 到期可修改 时可以修改
      if (this.formIdentity.isDataAdmin || this.formIdentity.isFormAdmin || this.isFormCreator) {
        formMode = 'edit';
      } else if (row.userId == this.loginUserId && [1, 2, 5, 9, 13].indexOf(row['status']) > -1 && !this.disableEditFill) {
        formMode = 'edit';
      } else if (this.formIdentity.isAuditUser && this.isShowAuthReUpdate(row)) {
        formMode = 'edit';
      }

      formApi.fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;

          let fillJson = deepClone(response.data.fillJson);
          let dataChoose = deepClone(response.data.dataChoose);
          delete response.data.fillJson;

          const data = { ...fillJson, ...response.data };

          // const dataChooseFields = this.formJson.fieldList.filter(x => x.type === 'data-choose');
          // dataChooseFields.forEach(fi => {
          //   if (fi.subtype) {
          //     data[fi.name+'__data']= data.dataChoose[fi.name] || [];
          //   }else{
          //     data[fi.name]=data.dataChoose[fi.name] || [];
          //   }
          // })

          // this.$refs.dlgvFormViewer[this.listType == 'AUDIT' ? 'showView' : 'show']({

          this.$refs.dlgvFormViewer['show']({
            fillId,
            formId: this.formId,
            formJson: deepClone(this.formJson),
            formData: data,
            showHiddenWidgets: false, // this.listType != 'FILL' && this.listType != 'VIEW', /* 非个人填报列表显示隐藏字段 */
            hideFields: this.viewHideFields,
            readonlyFields: this.viewReadonlyFields,
            enableReadonly: false,// isAdmin, /* 管理列表启用只读字段 */
            agencyId: this.formDetail.agencyId,
            formDetail: this.formDetail,
            formMode,
            listType: this.listType,
            viewActions: this.viewActions,
            fillInfo: {
              fillUserName: this.viewShowFields.find(x=>x.field==='name')?.hide==false? row.fillUserName:'',
              fillUserAvatarUrl: row.fillUserAvatarUrl,
              fillUserId: response.data.userId,
              createTime: this.viewShowFields.find(x=>x.field==='fillTime')?.hide==false? row.createTime:'',
              updateTime: this.viewShowFields.find(x=>x.field==='updateTime')?.hide==false? row.createTime:'',

              status: this.viewShowFields.find(x=>x.field==='status')?.hide==false? row.status:'',
              auditStatus: this.viewShowFields.find(x=>x.field==='auditStatus')?.hide==false? '1':'',
              row: row
            },
            callback: () => {
              // if (this.listType === 'FILL') {
              //   // 如果是个人填报列表，并且状态是不允许编辑状态就把所有的字段禁用
              //   // 同时个人填报列表页不要按照视图配置禁用字段
              //   if ([1, 2, 5, 9, 13].indexOf(row['status']) == -1) {
              //     this.$refs.dlgvFormViewer.disableForm();
              //   }

              //   return;
              // }
              if (formMode == 'view') {
                this.$refs.dlgvFormViewer.disableForm();
                return;
              }
              let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
              let allWidgetnames = allWidgetList.map(x => x.options.name);

              // 设置所有字段都可编辑
              this.$refs.dlgvFormViewer.setNotReadonlyWidgets(allWidgetnames, false);
              // 设置所有字段不可见
              this.$refs.dlgvFormViewer.setHiddenWidgets(allWidgetnames);

              if (this.viewShowFields && Array.isArray(this.viewShowFields)) {

                let showNames = [];
                allWidgetList.forEach(x => {
                  let findWgt = this.viewShowFields.find(y => y.name === x.options.name);
                  if (findWgt) {
                    showNames.push(x.options.name)
                    if (x.widgetList) {
                      showNames = showNames.concat(x.widgetList.map(t => t.options.name))
                    }
                  }
                })
                this.$refs.dlgvFormViewer.setNotHiddenWidgets(showNames);
              }

              if (this.viewReadonlyFields && Array.isArray(this.viewReadonlyFields)) {
                const disableFields = this.viewReadonlyFields.map((x) => x.name);

                this.$refs.dlgvFormViewer.setReadonlyWidgets(disableFields, true);
              }
              this.$refs.dlgvFormViewer.showAuditButton(false);

              this.$nextTick(() => {
                // 重新触发逻辑显示
                // 这个代码之前有注释的状态，打开以后才能再详情、编辑时按照逻辑进行显示，但是会覆盖视图的配置 2024-08-27
                this.$refs.dlgvFormViewer.updateLogicalWidgets();
              })
              // if (this.afterShowCallback)
              // this.afterShowCallback({ listType: this.listType, formMode: formMode, fillId, fillDetailData: response.data, row })
            }
          });

        }
      })
    },
    getFormFieldList (widgetList) {
      let allWidgetList = [];
      widgetList.forEach(wgt => {
        allWidgetList.push(wgt);
        if (wgt.widgetList) {
          allWidgetList = allWidgetList.concat(this.getFormFieldList(wgt.widgetList));
        }
      })
      return allWidgetList;
    },
    setNotHiddenWidgets (widgets) {
      this.$refs.dlgvFormViewer.showWidgets(widgets);
    },
    setNotReadonlyWidgets (widgets, forSubWidgets = true) {
      this.$refs.dlgvFormViewer.enableWidgets(widgets, forSubWidgets);
    },

    showFillView (formId, fillId, showFields) {

      // 同时加载，提升加载速度
      const loadField = new Promise(async (resolve, reject) => {
        const res = await formApi.httpGetFormFieldV2(formId);
        resolve(res.data);
      });
      const loadData = new Promise(async (resolve, reject) => {
        const res = await formApi.fillDetail(fillId, formId);
        resolve(res.data.fillJson);
      });

      let self = this;
      Promise.all([loadField, loadData]).then((values) => {
        this.$refs.dlgvFormViewer.showView({
          fillId: fillId,
          formId: formId,
          formJson: values[0],
          formData: values[1],
          showHiddenWidgets: this.$parent.cur != 3,
          showFields: showFields,
          enableReadonly: true,
          agencyId: this.agencyId,
          formDetail: this.formJson,
          callback: () => {
            self.$nextTick(() => {
              self.$refs.dlgvFormViewer.disableForm();
            })
          }
        });
      });
    },
    // 数据查看列表----批量导入他人数据
    onHandleImportNotSelfClick () {
      this.notSelfFillOpen = true;
    },

    // 表单开通支付，管理员修改支付金额
    onHandleEditPayPriceClick () {
      const _this = this;
      if (_this.isBlank(this.gridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      // console.log('选择的数据', _this.selectedDataRowKeys, _this.selectionDataRows)
      _this.editPayPriceOpen = true;
    },
    // 表单开通支付，管理员点击确认到款
    onHandleConfirmPayClick () {
      const _this = this;
      if (_this.isBlank(_this.gridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      _this.confirmPayStatusOpen = true;
    },
    // 数据查看列表数据，设置批量通知
    onHandleNoticeClick () {
      this.dataNoticeShow = true;
    },
    // 点击列表的修改表单后，刷新列表
    onHandleEditRefreshData () {
      this.getFormViewListData();
    },
    // 批量填报导入
    onHandleImportClick () {
      this.fillOpen = true;
    },
    async editView (item) {
      await this.$refs.viewEdit.showEdit(item)
      this.showViewEdit = true;
    },
    // 删除视图
    deleteView (item) {
      // 先提示是否不保存，然后关闭el-draw
      this.$confirm('确实要删除视图吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteView(item.id).then(res => {
          if (res.code = 200) {
            this.$message.success("删除成功");
            this.getViewList();
          }
        })
      })
    },
    saveView(item){
      this.$confirm('确定要保存视图的列宽吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
          let columns = this.$refs.fillGridTable.getNativeTable().getColumns();
          columns.forEach(col=>{
            let cfgColItem= this.currentViewInfo.showColumns.find(x=> x.field ===col.field);
            if(cfgColItem){
              cfgColItem.columnWidth = col.renderWidth;
            }
          })
          editView(this.currentViewInfo).then((result) => {
            if (result.code === 200) {
              this.$message.success("保存成功")
            }
          })

      })
    },
    // 读取视图列表
    getViewList () {
      return new Promise((resolve, reject) => {
        listViews(this.formId).then(res => {
          if (res.code === 200) {
            this.viewList = res.data
          }
          resolve();
        }).catch((e) => {
          console.error("error", e)
          reject();
        })
      })

    },
    async reloadView (id) {

      await this.getViewList();
      let index = this.viewList.findIndex(x => x.id == id);
      if (index > -1) {
        this.viewTabName = "viewId:" + index
        this.handleViewClick();
      }
    },

    getTableViewJson () {
      let formJson = deepClone(this.formJson);
      let showFields = this.viewColumns.map(x => { return { ...x, name: x.field } });
      if (showFields) {
        const showNames = showFields.map((x) => x.name);

        const findShowWidget = (widget) => {
          const newFormWidgetList = [];
          if(!widget||widget.widgetList){
            widget.widgetList = []
          }
          for (let i = 0; i < widget.widgetList.length; i++) {
            if (showNames.indexOf(widget.widgetList[i].options.name) >= 0) {
              newFormWidgetList.push(widget.widgetList[i]);
            }
            if (widget.widgetList[i].widgetList) {
              findShowWidget(widget.widgetList[i]);
            }
          }
          widget.widgetList = newFormWidgetList;
        }
        findShowWidget(formJson)
      }
      this.tableViewJson = formJson;
    },
    // 读取视图详情
    getFormView (viewDetailRes) {
      // 视图信息
      // let viewDetailRes = await viewDetail(this.currentViewId);
      let viewColumns = viewDetailRes.data.showColumns;
      let viewFields = viewDetailRes.data.showFields;
      this.currentViewInfo= viewDetailRes.data

      this.viewActions = (viewDetailRes.data.actions || '').split(',');
      this.viewColumns = viewColumns.filter(x => !x.hide)
      this.viewRange = viewDetailRes.data.viewRange;
      this.viewStyle = viewDetailRes.data.style;
      this.viewFields = viewFields.filter(x => !x.hide).map(x => {
        return {
          ...x,
          name: x.field
        }
      })

      this.viewHideFields = viewFields.filter(x => x.hide || x.hide == undefined).map(x => {
        return {
          ...x,
          name: x.field
        }
      })    
      this.viewShowFields = viewFields.filter(x => !x.hide || x.hide == undefined).map(x => {
        return {
          ...x,
          name: x.field
        }
      })
      this.viewReadonlyFields = viewFields.filter(x => (x.editable == false || x.editable == undefined)).map(x => {
        return {
          ...x,
          name: x.field
        }
      })
      this.$nextTick(() => {
        if (this.$refs.fillGridTable)
          this.$refs.fillGridTable.setRowStyle({
            height:
              this.viewStyle.lineHeight == 'high' ? 50 :
                this.viewStyle.lineHeight == 'mediumn' ? 40 :
                  this.viewStyle.lineHeight == 'tight' ? 30 : 40
          })
      })

      this.getTableViewJson();
    },
    addNewView (type) {
      if (!type) {
        this.handleAddDataClick();
        return;
      }
      this.$refs.viewEdit.saveTypedView(type);
    },
    onFormDetail (data) {
      // this.formDetail = data;
      // this.formDetailEmpty = false
      // 向上层组件传递表单信息
      this.$emit("formDetail", data)
    },
    openDraftBox () {
      this.$refs.draft.openDraftBox();

    },
    enableView () {
      this.$nextTick(()=>{
        this.complete = true;
      })
    }
  },
};
</script>

<style scoped lang="scss">
.view-table {
  &.baseView {
    background-color: #fff;
  }
}
// 引入detail.vue并覆盖其默认样式
.view-info {
  flex: 1 auto;
  height: 100%;
  overflow: hidden;
  ::v-deep {
    .form-detail-container {
      position: initial;
      margin: initial;
      .forms-content {
        // .form-detail-top-part,
        // .form-info-tab-bottom {
        //   padding: 0px;
        //   box-shadow: none;
        // }
        // & > div {
        //   height: 100%;
        // }
        // .form-info-tab-bottom {
        //   display: flex;
        //   flex-direction: column;
        //   height: 100%;
        //   .info-tab-content {
        //     overflow-y: auto;
        //   }
        // }
      }
    }
  }
  & > div {
    height: 100%;
    overflow: hidden;
  }

  &.webView {
    text-align: center;
    background-color: #fff;
  }
}
// --------------------------

.views-page {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  ::v-deep .form-detail-top-part {
    padding-top: 5px;
  }
  ::v-deep .forms-content {
    padding-left: 0px;
    padding-right: 0px;
  }
  ::v-deep .form-detail-container .forms-content {
    max-width: 100%;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
}
.block-container {
  border-radius: 4px;
  display: inline-block;
  width: 100%;
}

.views-tabs {
  padding: 8px 10px;
  padding-bottom: 0px;
  background-color: #fff;
  .el-tabs {
    margin-right: 120px;
    &.fullWidth {
      margin-right: 0px;
    }
  }
  .el-dropdown {
    margin-left: 0px;
    margin-right: 5px;
    float: initial;
  }
  .el-icon-arrow-down {
    padding: 15px 0px;
  }

  .view-add {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}
.view-table {
  height: 100%;
  flex: 1;
  flex-shrink: 0;
  &.webView {
    display: inline-block;
    height: 500px;
    text-align: center;
    background-color: white;
  }
}

.form-view-table {
  width: 100%;
  .el-table td.el-table__cell {
    border-bottom: 0px none;
    padding: 4px 0px;
  }
}
</style>

<style>
.msgShowZIndexTop {
  z-index: 9999 !important;
}
.audit-process-dialog .el-loading-mask {
  bottom: -80px;
  left: -20px;
}
</style>
