var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "views-page" },
    [
      _c(
        "div",
        { staticClass: "views-tabs block-container" },
        [
          _vm.viewName === "baseView"
            ? [
                _vm.formDetail.enableView && _vm.formIdentity && _vm.formAuth
                  ? _c(
                      "el-tabs",
                      {
                        class: { fullWidth: !_vm.isFormAdmin },
                        on: { "tab-click": _vm.handleViewClick },
                        model: {
                          value: _vm.viewTabName,
                          callback: function ($$v) {
                            _vm.viewTabName = $$v
                          },
                          expression: "viewTabName",
                        },
                      },
                      [
                        _c("el-tab-pane", { attrs: { name: "viewInfo" } }, [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [_vm._v(" 概览 ")]
                          ),
                        ]),
                        (true && _vm.loginUserId) ||
                        _vm.formAuth.currentQueryFillListAuth
                          ? _c("el-tab-pane", {
                              attrs: {
                                disabled: !_vm.complete,
                                label: "已填列表",
                                name: "myFillList",
                              },
                            })
                          : _vm._e(),
                        _vm.formAuth.currentQueryReviewListAuth
                          ? _c("el-tab-pane", {
                              attrs: {
                                disabled: !_vm.complete,
                                label: "审核列表",
                                name: "auditList",
                              },
                            })
                          : _vm._e(),
                        !_vm.formDetail.enableView &&
                        _vm.formAuth.currentQueryDataListAuth
                          ? _c("el-tab-pane", {
                              attrs: {
                                disabled: !_vm.complete,
                                label: "管理列表",
                                name: "manageList",
                              },
                            })
                          : _vm._e(),
                        _vm._l(_vm.viewList, function (item, index) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: index,
                              attrs: {
                                disabled: !_vm.complete,
                                name: "viewId:" + index,
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _vm.isFormAdmin
                                    ? _c(
                                        "el-dropdown",
                                        {
                                          attrs: { trigger: "click" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (function () {})($event)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editView(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑视图")]
                                              ),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteView(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除视图")]
                                              ),
                                              _vm.viewTabName ==
                                              "viewId:" + index
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.saveView(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("保存视图")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm.formAuth.currentStatisticsAuth
                          ? _c("el-tab-pane", {
                              attrs: {
                                disabled: !_vm.complete,
                                label: "统计报表",
                                name: "staticTab",
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.isAgencyUser && _vm.isFormAdmin && _vm.formDetail.enableView
                  ? _c(
                      "el-button",
                      {
                        staticClass: "view-add",
                        attrs: { type: "default", size: "mini" },
                        on: { click: _vm.handleAddDataClick },
                      },
                      [_vm._v("添加视图")]
                    )
                  : _vm._e(),
              ]
            : _vm.viewName === "webView"
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-bottom": "20px",
                      "border-bottom": "1px solid #eee",
                    },
                  },
                  [
                    _c("viewFormMenu", {
                      attrs: {
                        viewList: _vm.viewList,
                        formAuth: _vm.formAuth,
                        formDesign: _vm.formDesign,
                      },
                      on: { change: _vm.handleViewClick },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.formDetail &&
                Object.keys(_vm.formDetail).length > 0 &&
                (_vm.viewTabName === "viewInfo" || _vm.showDetailType > 0),
              expression:
                "(formDetail && Object.keys(formDetail).length>0)&&(viewTabName === 'viewInfo' || showDetailType>0)",
            },
          ],
          staticClass: "view-info block-container",
          class: [_vm.viewName],
        },
        [
          _c("detail", {
            ref: "formDetail",
            staticStyle: { display: "inline-block", "text-align": "left" },
            style: {
              height: _vm.viewName === "webView" ? "auto" : "100%",
              width: _vm.viewName === "webView" ? "1280px" : "100%",
            },
            attrs: {
              formDetail: _vm.formDetail,
              standalone: false,
              formDesign: _vm.formDesign,
              reloadProjectList: function () {},
            },
            on: {
              formDetail: _vm.onFormDetail,
              "enable-view": _vm.enableView,
              "tab-change": _vm.onDetailChange,
            },
          }),
        ],
        1
      ),
      _vm.viewTabName != "viewInfo" && _vm.showDetailType == -1
        ? _c(
            "div",
            {
              staticClass: "view-table block-container",
              class: [_vm.viewName],
            },
            [
              _vm.fillGridOptions.columns.length > 0
                ? _c("MyVxeTable", {
                    ref: "fillGridTable",
                    staticStyle: { "text-align": "left" },
                    style: {
                      height: _vm.viewName === "webView" ? "200px" : "100%",
                      width: _vm.viewName === "webView" ? "1280px" : "100%",
                    },
                    attrs: {
                      tableName: _vm.formId + "_" + _vm.currentViewId,
                      columns: _vm.fillGridOptions.columns,
                      loading: _vm.fillGridOptions.loading,
                      data: _vm.fillGridOptions.data,
                      params: _vm.fillGridOptions.params,
                      pageConfig: _vm.fillGridOptions.pagerConfig,
                      selectAllRowsHandler_: _vm.GetAllDataRows,
                      agencyId: _vm.formDetail.agencyId,
                      formJson: _vm.showAllFieldFormJson,
                      formDetail: _vm.formDetail,
                    },
                    on: {
                      "page-change": _vm.handleDataPageChange,
                      "view-picture": _vm.onHandlePreviewImg,
                      "filter-change": _vm.fillFilterChanged,
                      "sort-change": _vm.fillSortChanged,
                      "clear-global-search": _vm.clearGlobalSearch,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toolbar_search",
                          fn: function () {
                            return [
                              _c("el-input", {
                                attrs: {
                                  "prefix-icon": "el-icon-search",
                                  placeholder: "全局搜索...",
                                  clearable: "",
                                },
                                on: { clear: _vm.onHandleResetFillClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onHandleQueryFillClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.fillQueryParams.fillValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fillQueryParams,
                                      "fillValue",
                                      $$v
                                    )
                                  },
                                  expression: "fillQueryParams.fillValue",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "toolbar_function",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _vm.btnShowF3DataStatisticsUpdate()
                                    ? [
                                        _vm._l(
                                          _vm.dataRefreshActions,
                                          function (item, index) {
                                            return _c(
                                              "el-button",
                                              {
                                                key: index,
                                                attrs: {
                                                  type: item.backGround,
                                                  icon: item.icon,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.doDataRefreshHandler(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        _vm.showStatisticRefreshButton(
                                          _vm.formDetail
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.refreshStatisticHandler(
                                                      _vm.formDetail
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("数据刷新")]
                                            )
                                          : _vm._e(),
                                        _vm.showStatusButtonShangHaiShiJue(
                                          _vm.formDetail
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shangHaiShiJueChouJian(
                                                      _vm.formDetail
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("论文抽取")]
                                            )
                                          : _vm._e(),
                                        _vm.showStatusButtonShangHaiShiJue(
                                          _vm.formDetail
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shangHaiShiJuePiPei(
                                                      _vm.formDetail
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("专家自动匹配")]
                                            )
                                          : _vm._e(),
                                        _vm.showShuJuShuaXinShangHaiShiJue(
                                          _vm.formDetail
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shangHaiShiJueShuaXin(
                                                      _vm.formDetail
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("数据刷新")]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                  _c("ViewBatch", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      fillGridOptions: _vm.fillGridOptions,
                                      formOptionData: _vm.optionData,
                                      fillQueryParams: _vm.fillQueryParams,
                                      formDetail: _vm.formDetail,
                                      formJson: _vm.formJson,
                                      formId: _vm.formId,
                                      formViewId: _vm.currentViewId,
                                      noticeClickNum: _vm.noticeClickNum,
                                      viewConfig: {
                                        viewFields: _vm.viewFields,
                                        viewHideFields: _vm.viewHideFields,
                                        viewReadonlyFields:
                                          _vm.viewReadonlyFields,
                                      },
                                      showBtn: {
                                        btnA2Detail: _vm.btnA2Detail,
                                        btnA4BatchEdit: _vm.btnA4BatchEdit,
                                        btnA6BatchUpdate: _vm.btnA6BatchUpdate,
                                        btnB1Audit: _vm.btnB1Audit,
                                        btnB2AuditBatch: _vm.btnB2AuditBatch,
                                        btnC1ExportExcel: _vm.btnC1ExportExcel,
                                        btnC3ExportExcelCombine:
                                          _vm.btnC3ExportExcelCombine,
                                        btnC4ExportAttr: _vm.btnC4ExportAttr,
                                        btnC5ExportWord: _vm.btnC5ExportWord,
                                        btnD1Import: _vm.btnD1Import,
                                        btnE2PayBatch: _vm.btnE2PayBatch,
                                        btnE3EditPay: _vm.btnE3EditPay,
                                        btnE4EditPayStatus:
                                          _vm.btnE4EditPayStatus,
                                        btnF1SendNotice: _vm.btnF1SendNotice,
                                        dropdownShow: _vm.dropdownShow,
                                        dataChooseDeleteDisabled:
                                          _vm.dataChooseDeleteDisabled,
                                      },
                                    },
                                    on: {
                                      resetTable: _vm.resetFillGridTable,
                                      reloadTable: _vm.getFormViewListData,
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "草稿箱",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _vm.btnA5Draft()
                                        ? _c("el-button", {
                                            attrs: {
                                              type: "default",
                                              icon: "el-icon-box",
                                              circle: "",
                                              size: "mini",
                                            },
                                            on: { click: _vm.openDraftBox },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.btnA5Draft()
                                    ? _c("draft", {
                                        ref: "draft",
                                        attrs: {
                                          formAuth: _vm.formAuth,
                                          formId: _vm.formId,
                                          formJson: _vm.formJson,
                                          formDetail: _vm.formDetail,
                                        },
                                        on: {
                                          "on-draft-submit":
                                            _vm.onHandleQueryFillClick,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "toolbar_share",
                          fn: function () {
                            return [
                              _vm.btnF2Share()
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "分享",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-link", {
                                        attrs: {
                                          icon: "el-icon-share",
                                          underline: false,
                                        },
                                        on: { click: _vm.onHandleShareClick },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "toolbar_button",
                          fn: function () {
                            return [
                              _vm.btnB2AuditBatch()
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        disabled:
                                          !!_vm.btnB2AuditBatchDisabled(),
                                        title: _vm.btnB2AuditBatchDisabled(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onBatchReviewClick(
                                            _vm.fillGridOptions.params
                                              .selectedRowKeys,
                                            _vm.fillGridOptions.params
                                              .isAllDataSelected,
                                            _vm.fillGridOptions.params
                                              .excludeIds
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("批量审核")]
                                  )
                                : _vm._e(),
                              _vm.btnE2PayBatch()
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { height: "29px" },
                                      attrs: {
                                        type: "success",
                                        size: "mini",
                                        disabled: !!_vm.btnE2PayBatchDisabled(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onBatchPayHandler(
                                            _vm.fillGridOptions.params
                                              .selectedRowKeys
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("批量支付")]
                                  )
                                : _vm._e(),
                              _vm.btnA1Add()
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { height: "29px" },
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        disabled: _vm.disableFill,
                                      },
                                      on: { click: _vm.onFillClickCheck },
                                    },
                                    [_vm._v("继续填报")]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "status_text",
                          fn: function (ref) {
                            var column = ref.column
                            return [
                              _c("vxe-column", {
                                attrs: {
                                  title: "当前状态",
                                  width: "90",
                                  field: "status",
                                  fixed: column.fixed,
                                  "header-class-name": "sysfield",
                                  filters: [{ data: {} }],
                                  "filter-render": { name: "filter-status" },
                                  params: _vm.filters.status,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("StatusCode", {
                                            attrs: {
                                              statusCode: row.status,
                                              row: row,
                                              fillStatusCode: row.fillStatus,
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                        {
                          key: "operate",
                          fn: function () {
                            return [
                              _c("vxe-column", {
                                attrs: {
                                  type: "operate",
                                  "min-width": "100",
                                  width: "140",
                                  "slots:default": "operate",
                                  fixed: "right",
                                  "header-class-name": "sysfield",
                                  align: "center",
                                  "show-overflow": true,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [_c("span", [_vm._v("操作")])]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        var rowIndex = ref.rowIndex
                                        return [
                                          _vm.btnA2Detail(row)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                    status: "primary",
                                                    content: "详情",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onViewFillClick(
                                                        row["fillId"],
                                                        "FILL",
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("详情")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                disabled:
                                                  _vm.btnE1Pay(row) === true,
                                                content: _vm
                                                  .btnE1Pay(row)
                                                  .toString(),
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "4px",
                                                  },
                                                },
                                                [
                                                  _vm.btnE1ShowPay()
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "mini",
                                                            status: "primary",
                                                            content: "支付",
                                                            disabled:
                                                              _vm.btnE1Pay(
                                                                row
                                                              ) !== true,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onPayFillClick(
                                                                row["fillId"]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("支付")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.btnB1Audit(row) &&
                                          _vm.isAuditLevel(row)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                    status: "primary",
                                                    content: "审核",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAuditFillClick(
                                                        row["fillId"],
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("审核")]
                                              )
                                            : _vm._e(),
                                          _vm.btnB1AuditEdit(row) &&
                                          _vm.isAuditEditLevel(row)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                    status: "primary",
                                                    content: "修改审核",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onEditReviewClick(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("修改审核")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.dataChooseDeleteDisabled,
                                                placement: "top-start",
                                                content:
                                                  "当前表单关联了子表单数据，请从子表单中删除数据",
                                              },
                                            },
                                            [
                                              _vm.btnA3Delete(row)
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "4px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "mini",
                                                            status: "primary",
                                                            content: "删除",
                                                            disabled:
                                                              !!_vm.btnA3DeleteDisabled(
                                                                row
                                                              ) ||
                                                              _vm.dataChooseDeleteDisabled,
                                                            title:
                                                              _vm.btnA3DeleteDisabled(
                                                                row
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDeleteFillClick(
                                                                row["fillId"]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.dataChooseDeleteDisabled,
                                                placement: "top-start",
                                                content:
                                                  "当前表单关联了子表单数据，请从子表单中删除数据",
                                              },
                                            },
                                            [
                                              _vm.btnA8ManageDelete(row)
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "4px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "mini",
                                                            status: "primary",
                                                            content: "删除",
                                                            disabled:
                                                              _vm.dataChooseDeleteDisabled,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDeleteFillClick(
                                                                row["fillId"]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm.btnC5ExportWord() &&
                                          (!_vm.formDetail.exportAfterAudit ||
                                            (_vm.formDetail.exportAfterAudit &&
                                              row["status"] == 14))
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_vm.formDetail
                                                            .exportIsPdf,
                                                        expression:
                                                          "!formDetail.exportIsPdf",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "4px",
                                                    },
                                                    attrs: {
                                                      title: "WORD导出",
                                                      type: "text",
                                                      size: "mini",
                                                      icon1: "el-icon-download",
                                                      status: "primary",
                                                      content: "WORD导出",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onExportMyFill(
                                                          row["fillId"],
                                                          "Word"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("导出")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.formDetail
                                                            .exportIsPdf,
                                                        expression:
                                                          "formDetail.exportIsPdf",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "4px",
                                                    },
                                                    attrs: {
                                                      title: "PDF导出",
                                                      type: "text",
                                                      size: "mini",
                                                      icon1: "el-icon-download",
                                                      status: "primary",
                                                      content: "PDF导出",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onExportMyFill(
                                                          row["fillId"],
                                                          "Pdf"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("导出")]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1407506846
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4045126260
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核详情",
            visible: _vm.reviewDialog.open,
            width: "800px",
            "destroy-on-close": "",
            "append-to-body": true,
            "custom-class": "audit-process-dialog",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.reviewDialog, "open", $event)
            },
            opened: _vm.onShowReviewDialog,
            close: _vm.onHandleReviewClose,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isAuditLoading,
                  expression: "isAuditLoading",
                },
              ],
              staticClass: "review-contain",
            },
            [
              _c("AuditForm", {
                ref: "reviewFormDialog",
                attrs: {
                  loginUserId: _vm.loginUserId,
                  reviewForm: _vm.reviewForm,
                  fillId: _vm.reviewForm.fillId,
                  formDetail: _vm.formDetail,
                  isAllDataSelected:
                    _vm.fillGridOptions.params.isAllDataSelected,
                  fillCacheKey: _vm.fillGridOptions.fillCacheKey,
                  selectedRowKeys: _vm.selectedRowKeys,
                  excludeIds: _vm.fillGridOptions.params.excludeIds,
                },
              }),
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { width: "100%", "text-align": "right" } },
              [
                _vm.isMultipleSelectionFill
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSubmitMultipleReviewClick },
                      },
                      [_vm._v("提交")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSubmitReviewClick },
                      },
                      [_vm._v("提交")]
                    ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.onCancelReviewClick },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.dataShareShow && _vm.btnF2Share && _vm.loginUserId
        ? _c("dataShare", {
            ref: "dataShare",
            attrs: { formId: _vm.formId, formJson: this.formJson },
            model: {
              value: _vm.dataShareShow,
              callback: function ($$v) {
                _vm.dataShareShow = $$v
              },
              expression: "dataShareShow",
            },
          })
        : _vm._e(),
      _c("fillAgreement", {
        ref: "fillAgreement",
        attrs: { formAgreement: _vm.agreement },
        on: { agree: _vm.onFillClick },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.paySuccessShow,
            width: "430px",
            "custom-class": "el-dialog-hide-header",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.paySuccessShow = $event
            },
            close: _vm.onClosePaySuccessPop,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("支付成功！ "),
                ]
              ),
              _vm.customTip.payTipShow == 0
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报” "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 例如可实时接收审核状态变更提醒、管理员定向提醒通知等。 "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: {
                        "max-height": "450px",
                        "overflow-y": "auto",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.payTipContent),
                      },
                    }),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据填报支付",
            visible: _vm.formPayOpen,
            "custom-class": "el-dialog-hide-header",
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formPayOpen = $event
            },
            close: _vm.onClosePay,
          },
        },
        [
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v(" 本次填报支付" + _vm._s(_vm.orderPrice / 100) + "元 "),
          ]),
          _c("div", {
            ref: "qrCodeUrl",
            staticClass: "qrCodeCon",
            staticStyle: {
              "text-align": "center",
              margin: "auto",
              width: "200px",
            },
            attrs: { id: "qrCode" },
          }),
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v("微信扫一扫付款"),
          ]),
        ]
      ),
      _c("fillSelector", {
        ref: "fillSelector",
        attrs: { optionData: _vm.optionData },
      }),
      _c("fillUserViewer", {
        ref: "fillUserViewer",
        attrs: { formId: _vm.formId },
      }),
      _c("fillAgreement", {
        ref: "fillAgreement",
        attrs: { formAgreement: _vm.agreement },
        on: { agree: _vm.onFillClick },
      }),
      _c("userSelect", {
        ref: "userSelect",
        attrs: { callback: _vm.userSelectCallback, formDetail: _vm.formDetail },
      }),
      _c("VFormViewer", {
        ref: "dlgvFormViewer",
        attrs: { onHandleImportClick: _vm.onHandleImportClick },
        on: { "on-save": _vm.onHandleEditRefreshData },
      }),
      _c("ViewEdit", {
        ref: "viewEdit",
        attrs: {
          formId: _vm.formId,
          formDetail: _vm.formDetail,
          formJson: _vm.formJson,
          visible: _vm.showViewEdit,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showViewEdit = $event
          },
          refreshViewList: _vm.reloadView,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }