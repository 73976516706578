<template>
  <div class="content-wrapper">
    <content-title :formDesign="formDesign" v-model="value.title"></content-title>
    <div class="vidoe-wrapper">
      <div style="width:80%;display:inline-block;max-width:1280px">
      <video width="100%" controls>
        <!-- 提供多种格式的视频源，以确保更好的浏览器兼容性 -->
        <source :src="videoUrl" type="video/mp4" />
        <!-- 如果浏览器不支持video标签，显示以下内容 -->
        <p>您的浏览器不支持视频标签。</p>
      </video>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "./content-title";
export default {
  components: {
    contentTitle,
  },
  computed:{
    videoUrl: function(){      
      if(!this.value){
        return '';
      }
      if(Array.isArray(this.value.config.urls)){
        return this.value.config.urls[0].url
      }
    }
  },
  props: {
    formDesign: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      title: "标题",
    };
  },
};
</script>
<style scoped>
.content-wrapper {
  width:100%;
  display: inline-block;
  background-color:white;
}
.vidoe-wrapper{
  text-align: center;
}
</style>
