<template>
  <el-dialog :title="agreement.agreementTitle" top="5vh" :visible.sync="showFormAgreementDialog" custom-class="agreement-dialog" width="800px" append-to-body @close="onCloseEditPayStatusClick">
    <div v-html="agreement.agreementContent" class="agreement-context"></div>
    <div slot="footer" class="dialog-footer" style="margin-top: -30px;text-align:center">
      <el-button @click="onDisableAgreementClick">不同意</el-button>
      <el-button type="primary" :disabled="isAgreementButtonDisabled" @click="onAgreementClick">同意并继续<span v-if="agreementCountdown>0">({{agreementCountdown}})</span></el-button>
    </div>
  </el-dialog>
</template>
<script>
import { formAgreementInfo } from '@/api/system/form'
export default {
  data () {
    return {
      isAgreementButtonDisabled: true,
      showFormAgreementDialog: false,
      agreementCountdown: 0,
      agreement: {},
      fillHandler: null
    }
  },
  methods: {
    onAgreementClick () {
      // 从show传入的填报方法，如果有执行，如果没有则使用agree事件调用外部的填报方法。
      // 原来从表单详情采用跳转的方式到填报界面，使用agree事件，
      // 后来改为了表单填报页面弹出显示填报界面，使用fillHandler

      this.showFormAgreementDialog = false;
      if (!this.fillHandler) {
        this.$emit("agree")
      } else {
        this.fillHandler();
      }
    },
    onDisableAgreementClick () {
      this.showFormAgreementDialog = false;
    },
    show (formAgreementId, fillHandler) {
      this.fillHandler = fillHandler;
      const contDown = (seconds) => {
        let handler =
          setInterval(() => {
            this.agreementCountdown--;
            if (this.agreementCountdown <= 0) {
              this.isAgreementButtonDisabled = false;
              clearInterval(handler);
            }
          }, 1000)
      }
      formAgreementInfo(formAgreementId).then((agreeResp) => {
        if (agreeResp.code == 200) {
          this.agreement = agreeResp.data
          this.showFormAgreementDialog = true;
          this.isAgreementButtonDisabled = true;

          if (this.agreement.agreementConfirmationMethod == 1) {
            this.isAgreementButtonDisabled = false
          } else if (this.agreement.agreementConfirmationMethod == 2) {
            this.agreementCountdown = 5;
            contDown(this.agreementCountdown)
          } else if (this.agreement.agreementConfirmationMethod == 21) {
            this.agreementCountdown = 15;
            contDown(this.agreementCountdown)
          }
        }
      });
    },
    onCloseEditPayStatusClick () { },
    close () { }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .agreement-dialog {
  .el-dialog__header {
    text-align: center;
  }
}
.agreement-context {
  min-height: 200px;
  max-height: calc(90vh - 55px - 33px - 60px);
  overflow-y: auto;
}
</style> 