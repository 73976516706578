const importFn = require.context('./', false, /\.vue$/)
let comp={}
  
    importFn.keys().forEach((path) => {
      // 导入组件
      const component = importFn(path).default
      // 注册组件
      comp[component.name]= component;
    })

export default comp;