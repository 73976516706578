<template>
  <div>
    <!--数据分享列表-->
    <el-dialog title="数据分享" :visible.sync="isShow" width="1300px" append-to-body @close="cancel">
      <el-button type="success" size="mini" style="height:29px;" @click="showNewForm">新建数据分享</el-button>
      <el-table :data="shareList" style="width: 100%">
        <el-table-column prop="shareUrl" label="分享链接" width="320">
          <template slot-scope="scope">
            <a :href="getUrl(scope.row)" target="_blank" class="alink">{{getUrl(scope.row)}}</a> <i class="el-icon-copy-document" @click="copyUrl(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="名称" width="220">
        </el-table-column>
        <el-table-column prop="expireTime" label="链接有效期" width="100">
          <template slot-scope="scope">
            {{moment(scope.row.expireTime).format('YYYY-MM-DD')}}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="字段">
          <template slot-scope="scope">
             <span :title="renderHeader(scope.row).toString()">{{renderHeader(scope.row).toString()}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="password" label="密码" width="220">
        </el-table-column>
        <!-- <el-table-column prop="filterCriteria" label="筛选条件" width="220">
          <template slot-scope="scope">
            <span :title="renderFilter(scope.row).toString()">{{renderFilter(scope.row).toString()}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderBy" label="排序" width="220">
          <template slot-scope="scope">
            <span :title="renderOrderBy(scope.row).toString()">{{renderOrderBy(scope.row).toString()}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="option" label="操作" width="150">
           <template slot-scope="scope">
            <el-button type="default" size="mini" @click="showViewForm(scope.row)">查看</el-button>
            <el-button type="warning" size="mini" @click="deleteDataShare(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>

      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!--数据分享列表-->

    <!--新建数据分享-->
    <el-dialog title="数据分享" :visible.sync="isShowForm" width="540px" append-to-body>
      
      <el-form ref="newShareForm" :model="shareModel" :rules="shareModelRule" :disabled="shareModelReadonly" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="分享名称">
          <el-input type="text" v-model="shareModel.remark" :rows="{min:1,max:5}" ></el-input>
        </el-form-item>
        <el-form-item prop="expireTime" label="分享有效期">
          <el-date-picker v-model="shareModel.expireTime" value-format="yyyy-MM-dd 00:00:00" :editable="false" align="right" style="width:400px;" type="date" placeholder="选择日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="password" label="查看密码">
          <!-- <el-input type="password" v-if="!shareModelReadonly" v-model="shareModel.password" style="width:400px;" placeholder="设置查看密码"></el-input> -->
          <el-input type="input" velse v-model="shareModel.password" style="width:400px;" placeholder="设置查看密码"></el-input>
        </el-form-item>
        <el-form-item prop="headers" label="显示字段">
          <div style="display:flex;">
          <el-select v-model="shareModel.headers" style="width:400px;" multiple placeholder="选择列表的显示字段">
            <el-option v-for="(item,index) in fieldList" :key="index" :label="item.displayName" :value="item.name"></el-option>
          </el-select>
          <el-button type="default" plain size="mini" style="height:30px;" @click="selectAllHeaders">全选</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="filterCriteria" label="筛选条件" style="width:400px;">
          <el-button type="default" size="mini" style="height:29px;" @click="addFilter">添加</el-button>
          <div class="express-value-selector" v-for="(listItem,listIndex) in express.list" :key="listIndex">
            <el-select v-model="listItem.name" placeholder="请选择">
              <el-option v-for="(item,index) in filterFieldList(listItem.name)" :key="index" :label="item.displayName" :value="item.name"></el-option>
            </el-select>
            <div class="express-operator">=</div>
            <el-input v-model="listItem.value" placeholder=""></el-input>
          </div>

        </el-form-item>
        <el-form-item prop="orderby" label="排序条件" style="width:400px;">
          <el-button type="default" size="mini" style="height:29px;" @click="addOrderBy">添加</el-button>
          <div class="express-value-selector" v-for="(listItem,listIndex) in express.orderBy" :key="listIndex">
            <el-select v-model="listItem.name" placeholder="请选择">
              <el-option v-for="(item,index) in orderFieldList(listItem.name)" :key="index" :label="item.displayName" :value="item.name"></el-option>
            </el-select>
            <el-select v-model="listItem.value" placeholder="请选择">
              <el-option label="升序" value="1"></el-option>
              <el-option label="降序" value="-1"></el-option>
            </el-select>
          </div>

        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="confirmForm">确 定</el-button>
        <el-button @click="cancelForm">取 消</el-button>
      </div>
    </el-dialog>
    <!--新建数据分享-->

  </div>
</template>
<script>
import { saveDataShare,getDataShareList,deleteDataShare,getDataShare } from '@/api/system/form.js'
import moment from 'moment'
import {copyText} from '@/utils/index'
export default {
  props: {
    value: Boolean,
    formId: String,
    formJson: Object
  },
  data() {
    return {
      moment:moment,
      isShow: false,
      isShowForm: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [{
          text: '一天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', new Date());
          }
        }, {
          text: '三天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
            picker.$emit('pick', date);
          }
        }, {
          text: '一星期',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一个月',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 31);
            picker.$emit('pick', date);
          }
        }]
      },
      shareList:[],
      express: {
        list: [],
        orderBy: []
      },
      shareModel: {
        type:1,
        formId:'',
        expireTime: '',
        password: '',
        headers: [],
        remark: '',
        shareUrl:'/dataShare/${id}',
        filterCriteria: {},
        orders: []
      },
      shareModelReadonly:false,
      shareModelRule:{
        expireTime: [{ required: true, message: '请设置有效期', trigger: 'blur' }],
        headers: [{ required: true, message: '请选择标题', trigger: 'blur' }]
      }
    }
  },
  computed: {
    fieldList: function () {
      if(!this.formJson|| !this.formJson.fieldList){
        return [];
      }
      return this.formJson.fieldList.map(x => { return { name: x.name, displayName: x.displayName } })
    }
  },
  mounted() {
    this.isShow = this.value;
    this.loadList();
  },
  watch: {
    value: function (val) {
      this.isShow = val;
      this.loadList();
    }
  },
  methods: {
    loadList(){
      getDataShareList().then(res=>{
        if(res && res.code===200){
          this.shareList=res.rows;
        }
      })
    },
    showNewForm() {
      this.isShowForm = true;
      this.shareModel.expireTime = '';
      this.shareModel.password = '';
      this.shareModel.formId = '';
      this.shareModel.remark = '';
      this.shareModel.headers.splice(0);
      this.shareModel.filterCriteria = {};
      this.shareModel.orders.splice(0);
      
      this.express.list.splice(0);
      this.express.orderBy.splice(0);
      this.$refs.newShareForm.resetFields();
      this.shareModelReadonly=false;
    },
    showViewForm(row){
      this.isShowForm = true;
      this.shareModel=row;
      this.$refs.newShareForm.resetFields();

      this.express.list=[];
      Object.keys(this.shareModel.filterCriteria).forEach(x=>{
        this.express.list.push({
          name:x,
          value:this.shareModel.filterCriteria[x]
        })
      })

      this.express.orderBy=[];
      this.shareModel.orders.forEach(x=>{
        this.express.orderBy.push({
          name:x.field,
          value:x.direction
        })
      })



      this.shareModelReadonly=true;
    },
    addFilter() {
      if (this.express.list.length < this.filterFieldList().length) {
        this.express.list.push({ name: '', value: '' })
      } else {
        this.$message({
          message: '已添加全部字段'
        })
      }
    },
    addOrderBy() {
      if (this.express.orderBy.length < this.orderFieldList().length) {
        this.express.orderBy.push({ name: '', value: '' })
      } else {
        this.$message({
          message: '已添加全部字段'
        })
      }
    },
    filterFieldList: function (selectedName) {
      let filterType = ['input', 'textarea', 'radio', 'checkbox']
      let selectedFields = this.express.list.map(x => x.name)
      return this.formJson.fieldList
        .filter(x => filterType.indexOf(x.type) >= 0)
        .filter(x => selectedName == null || x.name == selectedName || selectedFields.indexOf(x.name) == -1)
        .map(x => { return { name: x.name, displayName: x.displayName } })
    },
    orderFieldList: function (selectedName) {
      let selectedFields = this.express.orderBy.map(x => x.name)
      return this.formJson.fieldList
        .filter(x => x.sortable)
        .filter(x => selectedName == null || x.name == selectedName || selectedFields.indexOf(x.name) == -1)
        .map(x => { return { name: x.name, displayName: x.displayName } })
    },
    confirmForm() {
      this.shareModel.filterCriteria = {};
      this.shareModel.orders = [];
      this.express.list.forEach(x =>
        {
          if(!x.name) return;
          this.shareModel.filterCriteria[x.name] = x.value
        }
      );
      this.express.orderBy.forEach(x =>{
          if(!x.name) return;
          let f=this.formJson.fieldList.find(x=>x.name==x.name);
          this.shareModel.orders.push({field:x.name,direction: x.value||'-1',label:f.displayName})
        }
      );
      this.shareModel.formId=this.formId;

      saveDataShare(this.shareModel).then(data=>{
        if(data.code===200){
          this.loadList();
          this.msgSuccess("保存成功")
          this.isShowForm = false;
        }else{
          this.msgError("保存失败")
        }
      })
    },
    selectAllHeaders(){
      this.shareModel.headers=this.fieldList.map(x=>x.name)
    },
    cancelForm() {
      this.isShowForm = false;
      this.loadList();
    },
    confirm() {

    },
    cancel() {
      this.isShow = false;
      this.$emit("input", false);
    },
    getUrl(row){
      return `${window.location.origin}${row.shareUrl.replace(/\$\{id\}/g,row.id)}`
    },
    renderHeader(row){
      let headers=[];
      if(row.headers)
      row.headers.forEach(r=>{
        let f=this.formJson.fieldList.find(x=>x.name==r);
        if(f) headers.push(f.displayName);
      })
      return headers;
    },
    renderFilter(row){
      let filter=[];
      if(row.filterCriteria)
      Object.keys(row.filterCriteria).forEach(key=>{
        let f=this.formJson.fieldList.find(x=>x.name==key);
        if(f) filter.push(`${f.displayName}=${row.filterCriteria[key]}`);
      })
      return filter;
    },
    renderOrderBy(row){
      let orderby=[];
      if(row.orders)
      row.orders.forEach(item=>{
        let f=this.formJson.fieldList.find(x=>x.name==item.field);
        if(f) orderby.push(`${f.displayName}:${item.direction==1?'升序':'降序'}`);
      })
      return orderby;
    },
    copyUrl(row){
      copyText(this.getUrl(row))
    },
    deleteDataShare(row){
      this.$confirm('确实要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDataShare(row.id).then((data)=>{
          if(data.code===200){
            this.loadList();
            this.msgSuccess("删除成功")

          }else{
            this.msgError("删除失败")
          }
        })
      })
      
    }

  }
}
</script>
<style scoped lang="scss">
.alink{
  color:#1890FF;
  &:hover{
    text-decoration: underline;
  }
}
.el-icon-copy-document{
  cursor: pointer;
}
.express-value-selector {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 10px;
  .express-operator {
    display: inline-block;
    width: 30px;
    text-align: center;
  }
  .el-select,
  .el-input {
    display: inline-block;
    width: 48%;
  }
}
</style>