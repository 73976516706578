var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "school-box" }, [
    _c(
      "div",
      {
        staticClass: "school-box-title",
        style: "color:" + _vm.formDesign.themeColor,
      },
      [_vm._v(_vm._s(_vm.value))]
    ),
    _c("div", { staticClass: "school-box-line-wrapper" }, [
      _c("div", {
        staticClass: "school-box-line",
        style: "background:" + _vm.formDesign.themeColor,
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }