var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-dialog",
        {
          attrs: { visible: _vm.visible, title: _vm.title },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "div",
            [
              _c("wordcloud", {
                ref: "wordcloudRef",
                attrs: { height: "280px", sizeRange: [12, 30] },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeDialog } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }