var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "horizontal-checkbox-group" },
    [
      _c(
        "el-checkbox-group",
        {
          attrs: { size: "medium" },
          on: { change: _vm.handleButtonChange },
          model: {
            value: _vm.selectedButtons,
            callback: function ($$v) {
              _vm.selectedButtons = $$v
            },
            expression: "selectedButtons",
          },
        },
        [
          _vm._l(_vm.buttonOptions, function (option, index) {
            return _c(
              "el-checkbox",
              { key: index, attrs: { label: option.value } },
              [_vm._v(" " + _vm._s(option.label) + " ")]
            )
          }),
          _vm._l(_vm.dataRefreshActions, function (option, index) {
            return _c(
              "el-checkbox",
              { key: "btn_" + index, attrs: { label: option.btnId } },
              [_vm._v(" " + _vm._s(option.name) + " ")]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }