<template>
  <el-drawer :visible.sync="showdrawer" :destroy-on-close="true" :append-to-body="true" direction="rtl" size="700px">
    <div slot="title">
      <span style="font-size: 18px;font-weight: bold;">{{title}}</span>
    </div>
    <div class="view-designer-drawer">
      <div class="view-designer-body">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="名称" name="8">
            <div slot="title">
              <span style="color: #f56c6c;margin-right: 4px;">*</span>名称
            </div>
            <el-input v-model="viewConfig.name" placeholder="设置视图名称"></el-input>
          </el-collapse-item>
          <el-collapse-item title="可见范围" name="6">
            <div class="view-item">
              <div class="view-item-subtitle">
                设置哪些用户可以看到此视图
              </div>
            </div>
            <ViewScope :formJson="formJson" :scope.sync="viewConfig.viewRange"></ViewScope>
          </el-collapse-item>
           <el-collapse-item title="筛选内容" name="4">
            <ViewFilter :formJson="formJson" :filters.sync="viewConfig.filters"></ViewFilter>
          </el-collapse-item>
          <el-collapse-item title="显示字段" name="2">
            <div class="view-item">
              <div class="view-item-title">
                字段
              </div>
            </div>
            <ColumnTable :formWidgetList="formJson.fieldList" :showFields.sync="viewConfig.showFields" :showColumns.sync="viewConfig.showColumns" @change="onShowFieldChange"></ColumnTable>
          </el-collapse-item>
          <!-- <el-collapse-item title="显示字段" name="2">
            <div class="view-item">
              <div class="view-item-title">
                字段
              </div>
              <div class="view-item-subtitle">
                设置此视图下的表单中需要对用户显示的字段
              </div>
            </div>
            <ColumnList :formWidgetList.sync="viewConfig.showFields" :checkKeys="['editable']" :checkLabels="['可编辑']" :checkKeyDefault="[true]" @change="onShowFieldChange"></ColumnList>
          </el-collapse-item> 
          <el-collapse-item title="显示列" name="3">
            <div class="view-item-title">
              列名
            </div>
            <div class="view-item-subtitle">
              设置此视图下的列表中需要对用户显示的字段
            </div>
            <ColumnList :formWidgetList.sync="viewConfig.showColumns" :sortable="true"></ColumnList>
          </el-collapse-item>-->
         
          <el-collapse-item title="默认排序" name="5">
            <ViewSort :formJson="formJson" :sort.sync="viewConfig.sortList"></ViewSort>
          </el-collapse-item>
          <el-collapse-item title="操作按钮" name="7">
            <ViewActions :formJson="formJson" :formId="formId" :actions.sync="viewConfig.actions"></ViewActions>
          </el-collapse-item>
          <el-collapse-item title="其他设置" name="1">
            <div class="view-item">
              <div class="view-item-title">
                行高
              </div>
              <div class="view-item-subtitle"></div>
            </div>
            <el-radio-group v-model="viewConfig.style.lineHeight" size="mini">
              <el-radio-button label="tight">紧凑</el-radio-button>
              <el-radio-button label="medium">中等</el-radio-button>
              <el-radio-button label="high">高</el-radio-button>
            </el-radio-group>
            <div class="view-item">
              <div class="view-item-title">
                排序号
              </div>
              <div class="view-item-subtitle">越小排序越靠前</div>
            </div>
            <el-input-number v-model="viewConfig.sort" size="mini"/>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="view-designer-footer">
        <el-button type="default" @click="cancelSaveView">取消</el-button>
        <el-button type="primary" @click="saveView">保存</el-button>
      </div>
    </div>

  </el-drawer>

</template>

<script>
import ViewFilter from './viewFilter.vue'
import ViewSort from './viewSort.vue'
import ViewScope from './viewScope.vue'
import ViewActions from './viewActions.vue'
import ColumnList from './columnList.vue'
import ColumnTable from './columnTable.vue'
import { deepClone } from '@/utils/index.js'

import { dataFillStatus } from "@/api/system/form.js";
import { addView, editView, viewDetail, formViewFields } from '@/api/system/views.js'
export default {
  components: {
    ViewFilter,
    ViewSort,
    ViewScope,
    ViewActions,
    ColumnList,
    ColumnTable
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "视图设置",
    },
    formJson: {
      type: Object,
      default: () => { }
    },
    formDetail: {
      type: Object,
      default: () => { }
    },
    formId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      showdrawer: false,
      activeNames: ["6","4","2","5","7","8"],
      viewConfig: {
        formId: '',
        name: "",
        style: {
          lineHeight: 'medium'
        },
        showFields: [],
        showColumns: [],
        sortList: [],
        filters: [],
        viewRange: 3,
        actions: [],
        sort:0,
        viewType: 'normal'
      },
      filterFields: [],
      dataFillStatus: false,
      conditions: [
        // 在这里定义条件选项
        { value: 'option1', label: '条件1' },
        { value: 'option2', label: '条件2' },
        // ...更多选项
      ],
    };
  },
  methods: {
    cancelSaveView () {
      // 先提示是否不保存，然后关闭el-draw
      this.$confirm('是否确认直接关闭，而不保存视图的配置？', '提示', {
        confirmButtonText: '关闭',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 关闭视图
        this.showdrawer = false;
      }).catch(() => {
        // 保存视图
      });
    },
    saveView () {
      this.viewConfig.formId = this.formId
      let saveHandler;
      if (this.viewConfig.id) {
        saveHandler = editView;
      } else {
        saveHandler = addView;
      }

      let config = deepClone(this.viewConfig);
      config.actions = config.actions.join(",")


      // config.showColumns // 按照field去重
      let tmpColumns=[],tmpFields=[];
      config.showColumns.forEach(x=>{
        if(tmpFields.indexOf(x.field)==-1){
          tmpColumns.push(x)
        }
      })
      config.showColumns = tmpColumns

      config.showFields.forEach(x=>{
        if(tmpFields.indexOf(x.field)==-1){
          tmpFields.push(x)
        }
      })
      config.showFields = tmpFields


      saveHandler(config)
        .then((result) => {
          if (result.code === 200) {
            this.$message.success("保存成功")
            this.$emit("refreshViewList", this.viewConfig.id)
            this.showdrawer = false;
          }
        })
    },
    // 保存特殊的视图
    saveTypedView (type) {
      const newView = {
        formId: this.formId,
        style: {}
      }
      newView.style.lineHeight = 'medium';
      newView.showColumns = this.formJson.fieldList.map(x => {
        return {
          field: x.name,
          headerName: x.displayName,
          hide: false
        }
      });
      newView.showFields = this.formJson.fieldList.map(x => {
        return {
          field: x.name,
          headerName: x.displayName,
          hide: false,
          editable:false
        }
      });
      newView.sortList = [{
        "field": "fillTime",
        "direction": "desc"
      }];

      if (type === 'personal') {
        newView.name = '个人填报'
        newView.filters = [
          {
            "valueType": "sysField",
            "type": "sysField",
            "value": "userId",
            "key": "name",
            "operator": "is",
            "widgetType": "name"
          }
        ]
      } else if (type === 'audit') {
        newView.name = '审核列表'
      } else if (type === 'manage') {
        newView.name = '管理列表'
      }

      addView(newView)
        .then((result) => {
          if (result.code === 200) {
            this.$message.success("保存成功")
            this.$emit("refreshViewList")
            this.showdrawer = false;
          }
        })
    },
    async showEdit (item) {
      this.$emit("update:visible", true)

      await this.getDataFillStatus();
      await this.loadFieldList();

      this.viewConfig.id = item.id;
      var showColumns = deepClone(this.viewConfig.showColumns).filter(x =>
        x.field.indexOf('divider')==-1 && x.field.indexOf('statictext')==-1 && x.field.indexOf('htmltext')==-1
      );
      var showFields = deepClone(this.viewConfig.showFields);

      let res = await viewDetail(item.id);
        // 记录表单所有的字段
        // 保存的字段
        // 先按照保存的字段显示(因为有排序)，根据现有表单记录，去删除或者添加字段
        // var viewColumns = res.data.showColumns.filter(x =>
        //   x.field.indexOf('divider')==-1 && x.field.indexOf('statictext')==-1 && x.field.indexOf('htmltext')==-1
        // );
        var viewColumns = res.data.showColumns;
        var viewFields = res.data.showFields;

        // let fieldList = this.formJson.fieldList;
        // // 这个无法显示子表单

        this.$set(this.viewConfig, 'showColumns',[])
        this.$set(this.viewConfig, 'showFields',[])

        viewColumns.forEach(x=>{
          if(!this.viewConfig.showColumns.find(y=>y.field==x.field)){
            this.viewConfig.showColumns.push(x)
          }
        })
        viewFields.forEach(x=>{
          if(!this.viewConfig.showFields.find(y=>y.field==x.field)){
            this.viewConfig.showFields.push(x)
          }
        })
        // 处理改名的字段，名称如果更改，跟formJson.fieldList中的名称对齐
        this.formJson.fieldList.forEach(x => {
          let col = this.viewConfig.showColumns.find(y => { return (y.field == x.name) && (y.headerName != x.displayName) });
          if (col) {
            col.headerName = x.displayName;
          }

          col = this.viewConfig.showFields.find(y => { return (y.field == x.name) && (y.headerName != x.displayName) });
          if (col) {
            col.headerName = x.displayName;
          }


        showColumns.forEach(x => {
          // 如果表单中的字段，在数据库中不存在，说明书后来添加的，默认隐藏
          if (!viewColumns?.find(y => y.field === x.field)) {
            x.hide = true;
            // x.children = this.getChildFieldList(x);
            if(!this.viewConfig.showColumns.find(y=>y.field==x.field))
              this.viewConfig.showColumns.push(x);
          }
        })

        showFields.forEach(x => {
          // 如果表单中的字段，在数据库中不存在，说明书后来添加的，默认隐藏
          if (!viewFields?.find(y => y.field === x.field)) {
            x.hide = true;
            x.editable = false;
            // x.children = this.getChildFieldList(x);
            if(!this.viewConfig.showFields.find(y=>y.field==x.field))
              this.viewConfig.showFields.push(x);
          }
        })

        this.viewConfig.formId = res.data.formId;
        this.viewConfig.name = res.data.name;
        if (res.data.style) {
          this.viewConfig.style.lineHeight = res.data.style.lineHeight || 'medium';
        }
        this.viewConfig.sortList = res.data.sortList || [];
        this.viewConfig.filters = res.data.filters || [];
        this.viewConfig.viewRange = res.data.viewRange;
        this.viewConfig.actions = (res.data.actions || '').split(",")
        this.viewConfig.sort = res.data.sort||0

        // 显示列禁用显示字段没选择的
        this.disableShowColumnFields(this.viewConfig.showFields)
      })

    },
    getChildFieldList (field) {
      if (field.type === 'sub-form') {
        return field.widgetList.map(x => {
          return {
            field: x.name,
            headerName: x.displayName,
            hide: true,
            editable: false
          }
        })
      } else {
        return undefined;
      }
    },
    loadFieldList () {
      if (this.formJson && this.formJson.fieldList) {
        this.viewConfig.showFields.splice(0);

        
        // 添加系统字段
        const sysFields = [{
          field: 'name',
          headerName: '填报人',
          hide: true,
          type: 'sysField'
        }, {
          field: 'fillTime',
          headerName: '填报时间',
          hide: true,
          type: 'sysField'
        }, {
          field: 'updateTime',
          headerName: '更新时间',
          hide: true,
          type: 'sysField'
        }, {
          field: 'status',
          headerName: '当前状态',
          hide: true,
          type: 'sysField'
        }, {
          field: 'payStatus',
          headerName: '支付状态',
          hide: true,
          type: 'sysField'
        }, {
          field: 'auditStatus',
          headerName: '审核意见',
          hide: true,
          type: 'sysField'
        }, {
          field: 'confirmStatus',
          headerName: '导入确认',
          hide: true,
          type: 'sysField'
        }
        ];



        this.formJson.fieldList.map(x => {
          return {
            field: x.name,
            headerName: x.displayName,
            hide: true,
            editable: false,
            children: this.getChildFieldList(x),
            type: sysFields.find(y=>y.field == x.name)?'sysField': 'formField',
          }
        }).filter(x => {
          this.viewConfig.showFields.push(x);
        });


        this.viewConfig.showColumns.splice(0);
        this.formJson.fieldList.map(x => {
          return {
            field: x.name,
            headerName: x.displayName,
            hide: true,
            type: sysFields.find(y=>y.field == x.name)?'sysField': 'formField',
          }
        }).filter(x => {
          this.viewConfig.showColumns.push(x);
        });


        // 检查系统字段是否出现
        const checkSysFields = () => {
          const copySysFields = deepClone(sysFields);
          const removeSysField = (name) => {
            const index = copySysFields.findIndex(x => x.field === name);
            if (index > -1) {
              copySysFields.splice(index, 1);
            }
          }

          // 如果表单没有支付，隐藏支付字段
          if (!this.formDetail.pay) {
            removeSysField('payStatus')
          }
          if (!this.formDetail.auditAuth) {
            removeSysField('auditStatus');
          }
          if (!this.formDetail.isBatchFill) {
            removeSysField('confirmStatus');
          }

          return copySysFields;
        }

        checkSysFields().forEach(x => {
          // 表单没有系统字段，只设置列表的系统字段
          this.viewConfig.showColumns.push(deepClone(x));
          this.viewConfig.showFields.push(deepClone(x));
        })
      }
    },
    getDataFillStatus () {
      return new Promise((resolve, reject) => {
        dataFillStatus(this.formId).then((response) => {
          if (response.code == 200) {
            this.dataFillStatus = response.data;
            resolve(response.data);
          } else {
            reject(response);
          }
        }).catch((err) => {
          reject(err);
        });
      })
    },
    disableShowColumnFields (fields) {
      let hideFields = fields.filter(x => x.hide == true)

      this.viewConfig.showColumns.forEach(x => {
        // this.$set(x, 'hide',  hideFields.some(y => y.field == x.field)) // 设置默认为显示状态
        this.$set(x, 'disabled', hideFields.some(y => y.field == x.field))
      })
    },
    onShowFieldChange(field){

      let columnField = this.viewConfig.showColumns.find(x=>x.field == field.field)
      // 如果字段隐藏了，同时在列表中也要隐藏 hide=true
      if(field.hide){
        // 如果给字段开启了隐藏，在columnField也隐藏并且禁用
        this.$set(columnField,'hide', true);
        this.$set(columnField,'disabled', true);  // 在显示列中不可选，看不到
      }else{
        // 如果给字段开启了启用，在columnField显示(disabled = false)，但不启用(hide = true)
        this.$set(columnField,'disabled', false);  // 在显示列中可选，能看不到
      }
    }
  },
  mounted () {
  },
  watch: {
    visible (newValue) {
      this.showdrawer = newValue;

    },
    showdrawer (newValue) {
      if (!newValue) {

        this.viewConfig.id = '';
        this.viewConfig.formId = '';
        this.viewConfig.name = '';
        this.viewConfig.style.lineHeight = 'medium';
        this.viewConfig.showFields = [];
        this.viewConfig.showColumns = [];
        this.viewConfig.sortList = [];
        this.viewConfig.filters = [];
        this.viewConfig.actions = [];
        this.viewConfig.sort = 0;
        this.viewConfig.viewRange = 3;
        this.$emit("update:visible", false); // 当抽屉关闭时，通知父组件更新visible状态
      } else {
        this.$emit("update:visible", true);
        this.loadFieldList();
      }
    },
    formJson: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.loadFieldList();
      }
    }
  },
};
</script>

<style scoped lang="scss">
.view-designer-drawer {
  padding: 20px;
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  ::v-deep .el-collapse-item__header {
    font-size: 14px;
    font-weight: bold;
  }
  .view-designer-body {
    flex: 1;
  }
  .view-designer-footer {
    height: 50px;
    line-height: 50px;
    flex: 0 auto;
    text-align: right;
  }
}
.view-item {
  &-title {
    font-size: 14px;
    line-height: 30px;
  }
  &-subtitle {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    color: rgb(52, 118, 240);
  }
}
.sortable-item {
  margin-bottom: 10px;
  .ghost {
    background-color: rgb(52, 118, 240);
  }
  .drag-handler {
    cursor: move;
  }
  .el-icon-rank {
    margin-right: 10px;
  }
  .sort-field,
  .sort-type {
    margin-right: 10px;
    width: 150px;
  }
  .sort-delete {
    margin-right: 10px;
  }
}
</style>
