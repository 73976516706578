var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        size: "mini",
        "default-first-option": "",
        placeholder: "请选择可见范围",
      },
      on: { change: _vm.changeVisibility },
      model: {
        value: _vm.selectedVisibility,
        callback: function ($$v) {
          _vm.selectedVisibility = $$v
        },
        expression: "selectedVisibility",
      },
    },
    _vm._l(_vm.visibilityOptions, function (option, index) {
      return _c("el-option", {
        key: index,
        attrs: { label: option.label, value: option.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }