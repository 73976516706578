var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-file" },
    [
      _vm._l(_vm.fileList, function (item, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "file-wrapper",
            attrs: { href: item.url, target: "_blank" },
          },
          [
            item.suffixType == "image"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_image" },
                })
              : _vm._e(),
            item.suffixType == "word"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_word" },
                })
              : _vm._e(),
            item.suffixType == "excel"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_excel" },
                })
              : _vm._e(),
            item.suffixType == "pdf"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_pdf" },
                })
              : _vm._e(),
            item.suffixType == "audio"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_audio" },
                })
              : _vm._e(),
            item.suffixType == "video"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_video" },
                })
              : _vm._e(),
            item.suffixType == "zip"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_zip" },
                })
              : _vm._e(),
            item.suffixType == "ppt"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_ppt" },
                })
              : _vm._e(),
            item.suffixType == "other"
              ? _c("svg-icon", {
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    "vertical-align": "middle",
                  },
                  attrs: { "icon-class": "annex_other" },
                })
              : _vm._e(),
            _c("svg-icon", {
              staticClass: "down-icon",
              attrs: { "icon-class": "download2" },
            }),
            _c("span", [_vm._v(_vm._s(item.name))]),
          ],
          1
        )
      }),
      _vm.fileList.length == 0
        ? _c("div", { staticClass: "empty" }, [_vm._v(" 暂无内容 ")])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }