import {
  auditFillUpdate,
  batchReviewFill,
  fillDetail,
  getFormFieldAuth,
  review,
  auditBack
} from "@/api/system/form";
import { deepClone } from "@/utils/deepClone.js";
import { getAuditLevelByStatus } from "@/utils/formUtil"
export default {
  data() {
    return {
      multipleSelectionFill: [], // 一键审核所有数据
      isMultipleSelectionFill: false, // 是否是一键审核
      isAuditLoading: false,

      reviewForm: {
        reviewRemark: null,
        auditStatus: null,
        reviewSign: null,
        version: null,
      },
      reviewFormError: {
        reviewRemark: "",
        reviewSign: "",
      },
      reviewRules: {
        auditStatus: [
          {
            required: true,
            message: "审核状态不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      // 审核弹框
      reviewDialog: {
        type: "audit",
        open: false,
      },
      selectedRowKeys:null
    };
  },
  computed: {
    isShowAdminListMoreButton: function () {},
  },
  methods: {
    isAuditLevel: function (row) {
      if (
        ["一级审核视图", "二级审核视图", "三级审核视图"].includes(
          this.currentViewInfo.name
        )
      ) {
        let status = getAuditLevelByStatus(row.status);
        if(status==1 && this.currentViewInfo.name=="一级审核视图"){
          return true;
        }else if(status==2 && this.currentViewInfo.name=="二级审核视图"){
          return true;
        }else if(status ==3 && this.currentViewInfo.name=="三级审核视图"){
          return true;
        }else{
          return false;
        }
      }
      return true;
    },
    isAuditEditLevel: function (row) {
      if (
        ["一级审核视图", "二级审核视图", "三级审核视图"].includes(
          this.currentViewInfo.name
        )
      ) {
        if(this.currentViewInfo.name=="一级审核视图"){
          return row['auditAuth1Operator']?.agencyUserId == this.userInfo.agencyUserId
        }else if(this.currentViewInfo.name=="二级审核视图"){
          return row['auditAuth2Operator']?.agencyUserId == this.userInfo.agencyUserId
        }else if(this.currentViewInfo.name=="三级审核视图"){
          return row['auditAuth3Operator']?.agencyUserId == this.userInfo.agencyUserId
        }else{
          return false;
        }
      }
      return true;
    },
    /**
     * 点击审核按钮
     * @param {string} fillId - 填充ID
     * @param {object} row - 行数据
     */
    // !! view/sign/sign-form.vue
    // !! view/system/form/detail.vue
    onAuditFillClick(fillId, row) {
      if (!fillId) {
        fillId = this.selectedDataRowKeys[0].fillId;
      }
      fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;
          const data = response.data.fillJson;
          const formFieldList = response.data.formFieldList;

          this.$refs.dlgvFormViewer.show({
            fillId: fillId,
            formId: this.formId,
            formJson: deepClone(this.formJson),
            formData: data,
            agencyId: this.formDetail.agencyId,
            formDetail: this.formDetail,
            formMode: "audit",
            listType: "AUDIT",
            showHiddenWidgets: true, // 审核列表显示隐藏字段
            enableReadonly: true, // 启用只读字段可编辑,
            fillInfo: {
              fillUserName:
                this.viewShowFields.find((x) => x.field === "name")?.hide ==
                false
                  ? row.fillUserName
                  : "",
              fillUserAvatarUrl: row.fillUserAvatarUrl,
              fillUserId: response.data.userId,
              createTime:
                this.viewShowFields.find((x) => x.field === "fillTime")?.hide ==
                false
                  ? row.createTime
                  : "",
              updateTime:
                this.viewShowFields.find((x) => x.field === "updateTime")
                  ?.hide == false
                  ? row.createTime
                  : "",

              status:
                this.viewShowFields.find((x) => x.field === "status")?.hide ==
                false
                  ? row.status
                  : "",
              auditStatus:
                this.viewShowFields.find((x) => x.field === "auditStatus")
                  ?.hide == false
                  ? "1"
                  : "",
              row: row,
            },
            callback: () => {
              this.afterShowCallback({
                listType: "AUDIT",
                formMode: "audit",
                fillId,
                fillDetailData: response.data,
                row,
              });
            },
          });

          // if (row && parseInt(row["status"]) > 2) {
          // this.$refs.dlgvFormViewer.disableForm();
          // this.$refs.dlgvFormViewer.setAllowSave(false);
          // } else {
          // this.$refs.dlgvFormViewer.enableForm();
          // this.$refs.dlgvFormViewer.setAllowSave(true);
          // }
          // 第一个参数，是否显示审核按钮
        }
      });
    },
    setReviewRules() {
      // 根据配置判断，审核意见是否必填？
      if (this.formDetail.auditAuthReviewRemark) {
        this.reviewRules.reviewRemark = [
          {
            required: true,
            message: "请填写审核意见",
            trigger: "blur",
          },
        ];
      }
      // 根据配置判断是否手写签名必填。
      if (
        this.formDetail.auditAuthSign &&
        this.formDetail.auditAuthReviewSign
      ) {
        this.reviewRules.reviewSign = [
          {
            required: true,
            message: "请填写签名",
            trigger: "blur",
          },
        ];
      }
    },
    // 点击查看/修改 界面弹出后，根据处于所在的列表，判断显示哪些字段
    // !! view/sign/sign-form.vue
    // !! view/system/form/detail.vue
    afterShowCallback({ listType, formMode, fillId, fillDetailData, row }) {
      //如果是个人填报列表，已支付状态的支付字段不能修改
      if (listType == "FILL") {
        setTimeout(() => {
          //判断表单是否开启了支付，支付状态为已支付，表单是根据字段支付，三个条件为true，隐藏支付字段。
          if (
            this.formDetail.pay &&
            this.formDetail.payStatus > 2 &&
            this.formPayInfo
          ) {
            this.$refs.dlgvFormViewer.hideWidgets([
              this.formPayInfo.formFieldId,
            ]);
          }
        }, 200);
        if (formMode == "view") {
          this.$refs.dlgvFormViewer.disableForm();
        } else {
        }
        this.$refs.dlgvFormViewer.setAllowSave(true);
        this.$refs.dlgvFormViewer.showAuditButton(false);
      } else if (listType == "AUDIT") {
        this.$refs.dlgvFormViewer.showAuditButton(true, () => {
          fillDetail(fillId, this.formId).then((auditItem) => {
            this.onReviewClick(this.fillId, auditItem.data.version, fillDetailData);
          });
        });

        // 获取表单按钮状态
        // 计算审核层级
        let level = 1;
        //Status 审核状态：1无需审核、2待一级审核、3一级审核通过、4、一级审核不通过、5一级审核返回修改、6待二级审核、7二级审核通过、8二级审核不通过、9二级审核返回修改
        const state = row["status"];
        switch (state) {
          case 1:
            level = 0;
            break;
          case 2:
          case 3:
          case 4:
          case 5:
            level = 1;
            break;
          case 6:
          case 7:
          case 8:
          case 9:
            level = 2;
            break;
          default:
            level = 3;
        }
        if (level > 0) {
          getFormFieldAuth({
            formId: this.formId,
            level: level,
            type: 1,
          }).then((res) => {
            let fieldAuth = [];
            let readonlyWidgets = [];
            let hiddenWidgets = [];

            let notReadonlyWidgets = [];
            let notHiddenWidgets = [];
            if (res.data && res.data.fieldAuth) {
              fieldAuth = res.data.fieldAuth;
            } else {
              let allWidgetList = this.getFormFieldList(
                this.formJson.widgetList
              );
              let allDisabledWidgetNames = allWidgetList
                .filter((x) => x.options.disabled)
                .map((x) => x.options.name);
              let allHiddenWidgetNames = allWidgetList
                .filter((x) => x.options.hidden)
                .map((x) => x.options.name);

              allDisabledWidgetNames.forEach((name) => {
                readonlyWidgets.push(name);
              });
              allHiddenWidgetNames.forEach((name) => {
                hiddenWidgets.push(name);
              });
            }

            Object.keys(fieldAuth).forEach((key) => {
              let val = fieldAuth[key];
              if (val.find((x) => x == 2)) {
                readonlyWidgets.push(key);
              } else {
                notReadonlyWidgets.push(key);
              }
              if (val.find((x) => x == 3)) {
                hiddenWidgets.push(key);
              } else {
                notHiddenWidgets.push(key);
              }
            });

            if (formMode == "view") {
              this.$refs.dlgvFormViewer.setHiddenWidgets(hiddenWidgets);
              this.$refs.dlgvFormViewer.setNotHiddenWidgets(notHiddenWidgets);
              this.$refs.dlgvFormViewer.disableForm();
            } else {
              this.$refs.dlgvFormViewer.setReadonlyWidgets(
                readonlyWidgets,
                false
              );
              this.$refs.dlgvFormViewer.setNotReadonlyWidgets(
                notReadonlyWidgets,
                false
              );

              this.$refs.dlgvFormViewer.setHiddenWidgets(hiddenWidgets);
              this.$refs.dlgvFormViewer.setNotHiddenWidgets(notHiddenWidgets);
            }
          });
        }
      } else if (listType == "MANAGE") {
        if (formMode == "view") {
          this.$nextTick(() => {
            let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
            let allWidgetnames = allWidgetList.map((x) => x.options.name);

            this.$refs.dlgvFormViewer.setNotHiddenWidgets(allWidgetnames);

            this.$refs.dlgvFormViewer.disableForm();
          });
        } else {
          let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
          let allWidgetnames = allWidgetList.map((x) => x.options.name);
          this.$nextTick(() => {
            this.$refs.dlgvFormViewer.setNotReadonlyWidgets(
              allWidgetnames,
              false
            );
            this.$refs.dlgvFormViewer.setNotHiddenWidgets(allWidgetnames);

            this.$refs.dlgvFormViewer.enableForm();
            this.$refs.dlgvFormViewer.setAllowSave(true);
            this.$refs.dlgvFormViewer.showAuditButton(false);
          });
        }
      }
    },
    getFormFieldList(widgetList) {
      let allWidgetList = [];

      widgetList.forEach((wgt) => {
        allWidgetList.push(wgt);

        if (wgt.widgetList) {
          allWidgetList = allWidgetList.concat(
            this.getFormFieldList(wgt.widgetList)
          );
        }
      });
      return allWidgetList;
    },
    // 3.0.0.1---审核
    onReviewClick(fillId, version, row) {
      this.selectedRowKeys = { fillId: fillId, version: version };

      this.isMultipleSelectionFill = false;

      this.setReviewRules();
      this.reviewDialog.type = "audit";
      this.reviewDialog.open = true;
      this.$emit("showAuditDialog", "audit", true);
      
      //三个待审核状态不回显审核数据
      if (!["2", "6", "10"].find((x) => x === row.status.toString())) {
        this.reviewForm.auditStatus = row.auditStatus;
        this.reviewForm.reviewRemark = row.reviewRemark;
        this.reviewForm.reviewSign = row.reviewSign;
      }
      this.reviewForm.fillId = row.fillId;
      this.reviewForm.status = row.status;

      this.$nextTick(() => {
        // this.$refs['reviewFormDialog'].resetFields()
        this.$refs.reviewFormDialog.clearValidate();
      });
    },
    // 3.0.0.1---修改审核
    onEditReviewClick(row) {
      const _this = this;
      this.selectedRowKeys = { fillId: row.fillId, version: row.version };
      if (row.status == 6 || row.status == 10) {
        _this.reviewForm.auditStatus = "1";
      } else if (row.status == 4 || row.status == 8 || row.status == 12) {
        _this.reviewForm.auditStatus = "2";
      } else if (row.status == 5 || row.status == 9 || row.status == 13) {
        _this.reviewForm.auditStatus = "3";
      }

      _this.reviewForm.reviewRemark =
        row.reviewRemark == "--" ? "" : row.reviewRemark;
      _this.reviewForm.reviewSign = row.reviewSign;

      if (row.status >= 3 && row.status <= 6) {
        _this.reviewForm.reviewRemark = row.auditAuth1Remarks;
        _this.reviewForm.reviewSign = row.auditAuth1Sign;
      } else if (row.status > 6 && row.status <= 10) {
        _this.reviewForm.reviewRemark = row.auditAuth2Remarks;
        _this.reviewForm.reviewSign = row.auditAuth2Sign;
      } else if (row.status > 10 && row.status < 15) {
        _this.reviewForm.reviewRemark = row.auditAuth3Remarks;
        _this.reviewForm.reviewSign = row.auditAuth3Sign;
      }

      _this.reviewForm.version = row.version;
      _this.reviewForm.fillId = row.fillId;
      _this.reviewForm.status = row.status;

      this.setReviewRules();
      this.isMultipleSelectionFill = false;
      this.reviewDialog.type = "editAudit";
      this.reviewDialog.open = true;
      this.$emit("showAuditDialog", "editAudit", true);
      this.isAuditLoading = false;

      this.$nextTick(() => {
        this.$refs.reviewFormDialog.clearValidate();
      });
    },
    onRadioReviewChange(value) {
      if (value == "1") {
        // this.reviewForm.reviewRemark = '审核通过'
      } else if (value == "2" || value == "3") {
        // this.reviewForm.reviewRemark = null
      }
    },
    // 新版---审核弹框 确认点击
    async onSubmitReviewClick() {
      const _this = this;
      this.isAuditLoading = true;
      if (this.formDetail.auditAuthSign) {
        await this.$refs.reviewFormDialog.uploadSign();
      }

      _this.$refs["reviewFormDialog"].validate((valid, reviewFormData) => {
        if (valid) {
          const param = {
            auditStatus: parseInt(_this.reviewForm.auditStatus),
          };
          if (!_this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          if (!_this.isBlank(this.formDetail.auditAuthSign)) {
            //保存审核签名
            param.reviewSign = _this.reviewForm.reviewSign;
          }
          param.fillId = this.selectedRowKeys.fillId;
          param.formId = _this.formId;
          param.version = this.selectedRowKeys.version;

          // 如果是退回，并且退回填报人返回修改，还是调用这个接口
          // 如果是退回前几步的审核，不再调用
          let reviewApi= null;
          if(this.reviewForm.auditStatus!='3' || this.reviewForm.backStatus==-1){
            // 判断是调用审核接口还是修改审核接口
            reviewApi = this.reviewDialog.type == 'audit' ? review : auditFillUpdate;
          }else{
            reviewApi = auditBack
            param.auditBackStatus = this.reviewForm.backStatus;
            param.fillList= [{
                  "fillId": param.fillId,
                  "version": param.version
            }]
            param.type=0
          }
          
          reviewApi(param)
            .then((response) => {
              if (response.code == 200) {
                _this.msgSuccess("已审核");
                _this.resetReviewForm(); // 审核成功后，清空审核弹框中数据
                _this.getFormViewListData();

                _this.reviewDialog.open = false;
                _this.isAuditLoading = false;
                if (_this.$refs.fillGridTable) {
                  _this.$refs.fillGridTable.clearFilter();
                  _this.$refs.fillGridTable.clearSelected();
                  _this.$refs.fillGridTable.clearCheckboxRow();
                }
              } else {
                _this.isAuditLoading = false;
                _this.$message(response.msg);
              }
            })
            .catch(() => {
              _this.isAuditLoading = false;
            });
        } else {
          let errs = [];
          Object.keys(reviewFormData).forEach((x) => {
            errs = [...errs, ...reviewFormData[x].map((x) => x.message)];
          });
          // _this.$message(errs.join(','))
          _this.isAuditLoading = false;
        }
      });
    },
    // 一键审核确认点击
    async onSubmitMultipleReviewClick() {
      const _this = this;
      this.isAuditLoading = true;

      if (this.formDetail.auditAuthSign) {
        await this.$refs.signRef.promiseUpload();
      }

      _this.$refs["reviewFormDialog"].validate((valid) => {
        if (valid) {
          const param = {
            auditStatus: parseInt(_this.reviewForm.auditStatus),
            formId: _this.formId,
            fillList: this.fillGridOptions.params.isAllDataSelected
              ? undefined
              : this.fillGridOptions.params.selectedRowKeys.map((x) => {
                  return { fillId: x.fillId, version: x.version };
                }),
            fillIdCacheKey: this.fillGridOptions.params.isAllDataSelected
              ? this.fillGridOptions.fillCacheKey
              : undefined,
            excludeFillIds: this.fillGridOptions.params.isAllDataSelected
              ? this.fillGridOptions.params.excludeIds.toString()
              : undefined,
            type: this.fillGridOptions.params.isAllDataSelected ? 1 : 0,
          };
          if (!_this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          if (!_this.isBlank(this.formDetail.auditAuthSign)) {
            //保存审核签名
            param.reviewSign = _this.reviewForm.reviewSign;
          }
          batchReviewFill(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("已审核");
              _this.resetReviewForm(); // 审核成功后，清空审核弹框中数据

              _this.getFormViewListData();
              _this.$emit("closeAuditDialog");
              _this.reviewDialog.open = false;

              _this.isMultipleSelectionFill = false;
              _this.isAuditLoading = false;
              _this.selectionRows = [];
              _this.selectedRowKeys = [];
              if (_this.$refs.fillGridTable) {
                _this.$refs.fillGridTable.clearFilter();
                _this.$refs.fillGridTable.clearSelected();
                _this.$refs.fillGridTable.clearCheckboxRow();
              }
            } else {
              _this.$message(response.msg);
            }
          });
        } else {
          _this.isAuditLoading = false;
        }
      });
    },
    // 审核弹框取消
    onCancelReviewClick() {
      this.resetReviewForm();
      this.$emit("closeAuditDialog");
      this.reviewDialog.open = false;
    },
    // 监听审核弹框打开
    onShowReviewDialog() {
      this.$refs.reviewFormDialog.clearValidate();
    },
    // 监听审核弹框关闭
    onHandleReviewClose() {
      this.resetReviewForm();
      this.$refs.dlgvFormViewer.closeQuite();
    },
    // 审核弹框清空数据
    resetReviewForm() {
      this.reviewForm.status = "";
      this.reviewForm.auditStatus = "";
      this.reviewForm.reviewRemark = "";
      this.reviewForm.fillId = "";
      this.reviewForm.reviewSign = "";
    },
    // version 2.4 确认审核
    onSubmitReviewTableClick() {
      const _this = this;
      _this.$refs["reviewForm"].validate((valid) => {
        if (valid) {
          const param = {
            fillId: _this.fillId,
            formId: _this.formId,
            auditStatus: parseInt(_this.reviewForm.auditStatus),
          };

          param.version = _this.reviewForm.version;
          if (!_this.isBlank(_this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          review(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("已审核");
              _this.resetReviewTableForm();
              _this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                queryStatus: "",
                // payStatus:'',
                fillStartTime: "",
                fillEndTime: "",
              };
              _this.getFormDetail();
              _this.fillGridOptions.pagerConfig.currentPage = 1;
              // _this.fillGridOptions.columns = []
              _this.fillGridOptions.data = [];
              _this.getReviewList();
              _this.cur = 4;
            } else {
              _this.$message(response.msg);
            }
          });
        }
      });
    },
    // version 2.4 取消审核
    onCancelReviewTableClick() {
      this.cur = 4;
      this.resetReviewTableForm();
      this.multipleSelectionFill = [];
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        queryStatus: "",
        // payStatus:'',
        fillStartTime: "",
        fillEndTime: "",
      };
      this.fillGridOptions.pagerConfig.currentPage = 1;
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getReviewList();
    },
    // version 2.4 重置审核相关
    resetReviewTableForm() {
      this.reviewForm.status = "";
      this.reviewForm.reviewRemark = "";
      this.reviewForm.fillId = "";
      if (this.$refs["reviewForm"]) {
        this.$refs["reviewForm"].resetFields();
        this.$refs.reviewForm.clearValidate();
      }
      this.formData = {};
      // this.formFieldList = []
    },
    // 一键审核
    onBatchReviewClick(selectedRowKeys, isAllDataSelected, excludeIds) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.isBlank(this.selectedRowKeys) && !isAllDataSelected) {
        this.$message({
          message: "请先选择审核数据",
          type: "warning",
        });
        return false;
      }

      this.isMultipleSelectionFill = true;

      this.setReviewRules();
      this.reviewDialog.type = "audit";
      this.reviewDialog.open = true;

      this.$emit("showAuditDialog", "audit", true);
      this.isAuditLoading = false;

      this.reviewForm.reviewSign = "";

      this.$nextTick(() => {
        // this.$refs['reviewFormDialog'].resetFields()
        this.$refs.reviewFormDialog.clearValidate();
      });
    },
  },
};
