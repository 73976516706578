var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-nav" },
    [
      _c(
        "div",
        { staticClass: "detail-container app-inner-container" },
        [
          _c("div", { staticClass: "detail-left" }, [
            _c(
              "div",
              { staticClass: "detail-left-logo", on: { click: _vm.naviHome } },
              [_c("svg-icon", { attrs: { "icon-class": "home" } })],
              1
            ),
            _c("div", { staticClass: "separator" }),
            _c(
              "span",
              {
                staticClass: "headerNow",
                on: {
                  click: function ($event) {
                    return _vm.naviTo(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.layerName(1)))]
            ),
            _vm.layer(2) ? _c("div", { staticClass: "separator" }) : _vm._e(),
            _vm.layer(2)
              ? _c(
                  "span",
                  {
                    staticClass: "headerNow",
                    on: {
                      click: function ($event) {
                        return _vm.naviTo(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.layerName(2)))]
                )
              : _vm._e(),
            _vm.layer(3) ? _c("div", { staticClass: "separator" }) : _vm._e(),
            _vm.layer(3)
              ? _c(
                  "span",
                  {
                    staticClass: "headerNow",
                    staticStyle: { cursor: "inherit" },
                    on: {
                      click: function ($event) {
                        return _vm.naviTo(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.layerName(3)))]
                )
              : _vm._e(),
          ]),
          _vm.needLogin
            ? _c("title-user-menu", {
                attrs: { "name-color": "#2B344199", "icon-color": "#2B344199" },
              })
            : [
                _c(
                  "div",
                  {
                    staticClass: "detail-right-login",
                    on: { click: _vm.toLogin },
                  },
                  [
                    _c("span", { staticClass: "right-login-text" }, [
                      _vm._v("您暂未登录，请微信扫码或账号登录"),
                    ]),
                    _c(
                      "el-button",
                      { attrs: { type: "primary", size: "mini" } },
                      [_vm._v("登录")]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.loginDialog.open,
            width: "340px",
            "append-to-body": "",
            "custom-class": "el-dialog-hide-header login-dialog",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.loginDialog, "open", $event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }),
          _c("LoginDialog", {
            on: { "login-success": _vm.handleLoginSuccess },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }