<template>
  <el-dialog :title="title" :visible.sync="showDialog" width="750px" append-to-body @close="closeDialog">
     <div class="user-item header">
        <div class="user-item-name">编号</div>
        <div class="user-item-name">姓名</div>
        <div class="user-item-agency">部门</div>
        <div class="user-item-agency">角色</div>
      </div>
    <div class="user-list">
      <div class="user-item" v-for="(item,index) in userList" :key="index">
        <div class="user-item-name">{{item.number}}</div>
        <div class="user-item-name">{{item.name}}</div>
        <div class="user-item-agency">{{item.deptName}}</div>
        <div class="user-item-agency">{{item.roleName}}</div>
      </div>
     <div class="user-list" v-if="userList.length==0">
      <div v-if="!isLoading" style="width:100%;height:60px;line-height:60px;text-align:center;color:#888888">暂无数据</div>
      <div v-if="isLoading"  style="width:100%;height:60px;line-height:60px;text-align:center;color:#888888">数据加载中，请稍候</div>
    </div>
    
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
      <div style="height:30px;text-aign:right">
        <el-button type="text" @click="copy(userList)">复制数据</el-button>
      <el-button type="primary" @click="closeDialog">关 闭</el-button>
        </div>
    </div>
  </el-dialog>
</template>
<script>
import { fillUserList, unfillUserList } from "@/api/system/form"
import { agencyDeptList } from "@/api/system/agency"
import { agencyUserList } from '@/api/system/agency'
import {copyText} from "@/utils/index"
export default {
  props: {
    formId: {
      type: String,
      defatul: ""
    }
  },
  data() {
    return {
      title: "",
      userList: [],
      agencyList: [],
      agencyUserListData: [],
      showDialog: false,
      isLoading:false,
      queryUserListParam: {
        pageNum: 1,
        pageSize: 500,
        bindStatus: '',
        name: '',
        number: ''
      }
    }
  },
  created() {

    // agencyDeptList().then(res => {
    //   this.agencyList = res.data;
    // })

    // agencyUserList(this.queryUserListParam).then(res => {
    //   this.agencyUserListData = res.rows
    //   console.log("role",res.rows)
    // })
  },
  methods: {
    proxyAgent(reqs){
      Promise.all(reqs.map(x=>x.promise)).then(reps=>{
        for(let i=0;i<reps.length;i++){
          reqs[i].resolve(reps[i]);
        }
      })
    },
    buildProxyPrams(proxyReq){
      //请求api的数组
      let req=[];

      //机构列表数据，从中获取部门名称
      if(this.agencyList.length==0){
        req.push(
          { 
            promise:agencyDeptList(),
            resolve:(res)=>{
              this.agencyList = res.data;
            }
          }
        )
      }
      //机构用户列表，从中找到用户的角色
      if(this.agencyUserListData.length==0){
        req.push(
          { 
            promise:agencyUserList(this.queryUserListParam),
            resolve:(res)=>{
              this.agencyUserListData = res.rows
            }
          }
        )
      }
      req.push(proxyReq);
      
      this.proxyAgent(req);
    },
    showFilledUserView() {
      this.title = "已填报用户列表";
      this.isLoading=true;
      this.userList.splice(0,this.userList.length);

      this.buildProxyPrams({ 
          promise:fillUserList(this.formId,1,500),
          resolve:(res)=>{
            this.isLoading=false;
            res.rows.forEach(x=>{
              x.deptName=this.userAgency(x.agencyId).deptName||'';              
              x.roleName=this.userRole(x.agencyUserId)||'';
            })


            this.userList =[...res.rows];
          }
      })
      this.showDialog = true;
    },
    showUnfilledUserView() {
      this.title = "未填报用户列表";
      this.userList.splice(0,this.userList.length);

      this.isLoading=true;
      this.buildProxyPrams({ 
          promise:unfillUserList(this.formId,1,500),
          resolve:(res)=>{
            this.isLoading=false;
            res.rows.forEach(x=>{
              x.deptName=this.userAgency(x.agencyId).deptName||'';              
              x.roleName=this.userRole(x.agencyUserId)||'';
            })

            this.userList = res.rows;
          }
      })
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    userAgency(agencyId) {
      let agency = this.agencyList.find(x => x.agencyId == agencyId) || {}
      return agency;
    },
    userRole(agencyUserId){
      let role=this.agencyUserListData.find(x=>x.agencyUserId==agencyUserId)||{};
      if(role.agencyUserRoleNames&&role.agencyUserRoleNames.toString)
      return role.agencyUserRoleNames.toString();
      else
      return "";
    },
    copy(userList){
      let expdata=[];
      expdata.push(`编号\t姓名\t部门\t角色\r`);

      userList.forEach(item=>{
        expdata.push(`${item.number}\t${item.name}\t${item.deptName}\t${item.roleName}\r`);
      })
      copyText(expdata.join('\n'));
    }
  }
}
</script>
<style lang="scss" scoped>
.user-list {
  width: 100%;
  height: 400px;
  overflow-y: auto;
}
.user-item {
  width: 100%;
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0px 10px;
  cursor: default;

  &.header {
    background-color: #eaeaea;
  }

  &-name {
    width: 22%;
    flex-shrink: 0;
    flex-grow:0;
    display: inline-block;
  }
  &-agency {
    width: 28%;
    flex-shrink: 0;
    flex-grow:0;
    display: inline-block;
    text-align: left;
  }

  &:not(.header):nth-child(odd) {
    background-color: #f5f5f5;
  }
}
</style>