<template>
  <el-dialog
    title="草稿箱"
    :visible.sync="draftBoxVisible"
    width="85%"
    :before-close="handleClose"
    append-to-body
    custom-class="el-dialog-hide-header"
    ><div style="height: 500px">
      <MyVxeTable
        ref="fillGridTable"
        :columns="fillGridOptions.columns"
        :loading="fillGridOptions.loading"
        :data="fillGridOptions.data"
        :params="fillGridOptions.params"
        :agencyId="formDetail.agencyId"
        :formJson="formJson"
        :formDetail="formDetail"
        :extendTableConfig="customTableConfig"
      >
        <template #operate>
          <vxe-column
            width="140px"
            slots:default="operate"
            fixed="right"
            header-class-name="sysfield"
            align="center"
            type="operate"
          >
            <template v-slot:header>
              <span>操作</span>
            </template>
            <template #default="{ row, rowIndex }">
              <el-button
                type="text"
                size="mini"
                status="primary"
                :disabled="disableFill"
                @click="handleEdit(row['fillId'], row)"
                >继续填报</el-button
              >
              <el-button
                type="text"
                size="mini"
                status="primary"
                @click="handleDelete(row['fillId'], row)"
                >删除</el-button
              >
            </template>
          </vxe-column>
        </template>
      </MyVxeTable>
    </div>
    <VFormViewer
      ref="dlgvFormViewer"
      :formAuth="formAuth"
      @on-save="handleDraftSubmit"
    ></VFormViewer>
  </el-dialog>
</template>
<script>
import { deepClone } from "@/utils/deepClone";
import gridConfig from "@/utils/grid.js";
import headers from "@/utils/gridHeader";
import MyVxeTable from "../tables/table.vue";
import { getDraft, getDraftList, deleteDraft } from "@/api/system/draft";
export default {
  props: {
    formId: {
      type: String,
      default: "",
    },
    formDetail: {
      type: Object,
      default: () => ({}),
    },
    formJson: {
      type: Object,
      default: () => ({}),
    },
    disableFill: {
      type: Boolean,
      default: false,
    },
    formAuth: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MyVxeTable,
  },
  data() {
    return {
      editDraftFillId: "",
      draftBoxVisible: false,
      drafts: [],

      fillGridOptions: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        // showOverflow: true,
        loading: false,
        columns: [],
        data: [],
        params: {
          selectedRowKeys: [],
          excludeIds: [],
        },
        tableToolbar: {
          custom: true,
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100],
        },
      },
      fillJson: {},
      fillQueryParams: {
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        updateStartTime: "",
        updateEndTime: "",
        formFieldId: "",
        fillValue: "",
      },
      customTableConfig:{
        sortConfig:{
          showIcon:false
        },
        filterConfig: {
          remote:false,
          showIcon:false
        },
      }
    };
  },
  created() {},
  methods: {
    openDraftBox() {
      this.fillQueryParams = {
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        formFieldId: "",
        fillValue: "",
      };
      this.fillGridOptions.pagerConfig.currentPage = 1;

      this.draftBoxVisible = true;
      this.renderTableColumns(this.fillGridOptions, true);
      this.getDrafts();
    },
    handleClose() {
      // 在这里编写关闭草稿箱的功能逻辑
      this.draftBoxVisible = false;
    },
    // 编辑草稿
    handleEdit(fillId, row) {
      // 在这里编写编辑草稿的功能逻辑
      this.editDraftFillId = fillId;
      getDraft(this.formId,fillId).then(rowData=>{
        this.$refs.dlgvFormViewer.showAdd({
          title: this.formDetail.startFillText,
          formId: this.formId,
          draftFillId: this.editDraftFillId,
          formDetail: this.formDetail,
          formData: rowData.data.fillJson,
          formJson: deepClone(this.formJson),
          formMode: "new",
          listType: "FILL",
          fromDraft: true, //从草稿箱填报的
          callback:()=>{
            this.getDrafts();
          }
        });
      })
    },
    handleDelete(fillId, row) {
      //弹出删除提示
      this.$confirm("确定删除该草稿吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 用户点击确定删除逻辑
          deleteDraft(this.formId, fillId).then(()=>{
            this.getDrafts();
          });
        })
        .catch(() => {
          // 用户点击取消删除逻辑
          console.log("取消删除");
          
        });
    },
    getDrafts() {
      getDraftList(this.formId, {
        draft: true,
        pageNum: 1,
        pageSize: 9999,
      }).then((data) => {
        this.fillGridOptions.data.splice(0);
        data.rows.forEach((item) => {
          item= Object.assign(item, item.fillJson);
          this.fillGridOptions.data.push(item)
        });
      });
    },
    handleDraftSubmit(formId, newFillId) {
      if (newFillId) {
        deleteDraft(this.formId, this.editDraftFillId).then(() => {
          this.getDrafts();
          this.$emit("on-draft-submit")
          this.handleClose();
        });
      }else{        
        this.getDrafts();
      }
    },
    // 渲染表头
    renderTableColumns(refGrid, hideHiddenField, removeFields = []) {
      const _this = this;
      refGrid.columns = [];
      let fieldListTmp = deepClone(_this.formJson.fieldList);
      let fieldList = [];
      // let notFilterManager = this.formDetail.managerUserId== true && this.formDetail.managerUserId.indexOf(this.loginUserId)>-1
      // let notManagerFormCreater = this.formDetail.userId=== this.loginUserId;

      fieldListTmp.forEach((field) => {
        if (removeFields.indexOf(field.name) == -1) {
          fieldList.push(field);
        }
      });

      gridConfig
        .renderHeaderColumns(
          // fieldList.filter(x=>!x.hidden || notFilterManager || notManagerFormCreater),
          fieldList,
          {
            formDetail: _this.formDetail,
            dataFillStatus: _this.dataFillStatus,
          },
          { hideHiddenField: hideHiddenField }
        )
        .forEach((c) => {
          refGrid.columns.push(c);
        });

      refGrid.columns.add(headers.operate);
      return refGrid.columns;
    },
  },
};
</script>
<style lang="scss">
.draft-dialog {
  height: 580px;
  margin-top: 7vh !important;
  .el-dialog__header {
    text-align: left;
  }
  .el-dialog__body {
    padding-top: 0px;
  }
}
</style>
