<template>
  <div>
    <div class="backBox" >
      <div class="backBox-btn" :style="`background-color:${formDesign.themeColor};`" @click="goBack">返回<br />首页</div>
    </div>
    <div class="tabs">
      <div
        class="tabs-item"
        :style="`${activeTab == 'viewInfo'?'color:'+formDesign.themeColor:''}`"
        :class="{ active: activeTab == 'viewInfo' }"
        @click="switchTab('viewInfo')"
      >
        概览
        <div class="line" v-if="activeTab == 'viewInfo'" :style="`background-color:${formDesign.themeColor};`"></div>
      </div>
      <div
        class="tabs-item"
         :style="`${activeTab == 'myFillList'?'color:'+formDesign.themeColor:''}`"
        :class="{ active: activeTab == 'myFillList' }"
        @click="switchTab('myFillList')"
      >
        已填列表
        <!---->
        <div class="line" v-if="activeTab == 'myFillList'" :style="`background-color:${formDesign.themeColor};`"></div>
      </div>
      <div
        class="tabs-item"
         :style="`${activeTab == 'auditList'?'color:'+formDesign.themeColor:''}`"
        :class="{ active: activeTab == 'auditList' }"
        v-if="formAuth.currentQueryReviewListAuth"
        @click="switchTab('auditList')"
      >
        审核列表
        <!---->
        <div class="line" v-if="activeTab == 'auditList'" :style="`background-color:${formDesign.themeColor};`"></div>
      </div>
      <div
        class="tabs-item"
         :style="`${activeTab == 'viewId:' + index?'color:'+formDesign.themeColor:''}`"
        :class="{ active: activeTab == 'viewId:' + index }"
        v-for="(item, index) in viewList"
        :key="index"
        @click="switchTab('viewId:' + index)"
      >
        {{ item.name }}
        <div class="line" v-if="activeTab == 'viewId:' + index" :style="`background-color:${formDesign.themeColor};`"></div>
      </div>
      <div
        class="tabs-item"
         :style="`${activeTab == 'staticTab'?'color:'+formDesign.themeColor:''}`"
        :class="{ active: activeTab == 'staticTab' }"
        @click="switchTab('staticTab')"
      >
        统计报表
        <!---->
        <div class="line" v-if="activeTab == 'staticTab'" :style="`background-color:${formDesign.themeColor};`"></div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from "@/utils/emitter";
export default {
  mixins: [emitter],
  props: {
    viewList: {
      type: Array,
      default: () => [],
    },
    formAuth: {
      type: Object,
      default: () => {},
    },
    
    formDesign:{
      type:Object,
      default: ()=>{}
    }

  },
  data() {
    return {
      activeTab: "viewInfo",
    };
  },
  methods: {
    switchTab(name) {
      this.activeTab = name;
      this.invoke("views", "switchViewClick", name);
    },
    
      goBack(){
       this.$router.back();
      },
  },
};
</script>
<style lang="scss" scoped>

      .backBox{
        display: flex;
        font-size: 24px;
        width: 1280px;
        height:30px;
        margin: auto;
        justify-content: flex-end;
        position: relative;
        .backBox-btn{
            color: #fff;
            padding: 15px;
            font-size: 16px;
            cursor: pointer;
            position: absolute;
            right:0px;
            top: 0px;
            &:hover{
                opacity: 0.8;
            }
        }
      }
.tabs {
  width: 1280px;
  margin: auto;
  display: flex;
  box-sizing: border-box;
  padding: 40px 0 20px 0;

  .tabs-item {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    .line {
      position: absolute;
      width: 70px;
      height: 6px;
      bottom: -20px;
      border-radius: 5px;
    }
  }
  .active {
  }
}
</style>
