<template>
  <div class="horizontal-checkbox-group">
    <el-checkbox-group v-model="selectedButtons" @change="handleButtonChange" size="medium">
      <el-checkbox v-for="(option, index) in buttonOptions" :key="index" :label="option.value">
        {{ option.label }}
      </el-checkbox>
      <el-checkbox v-for="(option, index) in dataRefreshActions" :key="'btn_'+index" :label="option.btnId">
        {{ option.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { deepClone } from '../../../../utils';
import { viewActions } from "./viewActions";
import { loadDataRefreshConfig } from '@/api/system/statistics'
export default {
  name: "availableButtons",
  props: {
    formId: {
      type: String,
      default: ''
    },
    // (可选)默认已选按钮，用于初始化时设置
    actions: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      selectedButtons: this.actions || [],
      buttonOptions: viewActions,
      dataRefreshActions: []
    };
  },
  methods: {
    handleButtonChange (selectedButtons) {
      this.$emit('update:actions', deepClone( selectedButtons));
    },
    getDataRefreshConfig(){
      loadDataRefreshConfig(this.formId).then((res)=>{
        this.dataRefreshActions = res.data;
      })
    }
  },
  created(){
    this.getDataRefreshConfig()
  },
  watch:{
    actions: function (val) {
      this.selectedButtons.splice(0);
      val.forEach((item) => {
        this.selectedButtons.push(item);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.horizontal-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  .el-checkbox-group {
    font-size: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.horizontal-checkbox-group .el-checkbox {
  margin-right: 10px; /* 自定义间距，根据需要调整 */
  margin-bottom:5px;
  font-size:14px;
}
</style>