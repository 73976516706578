import request from '@/utils/request'

// 从数据库配置中读取按钮
export function loadDataRefreshConfig(formId){
  return request({
    url: "/button/list/" + formId,
    method: "get",
  });
}

// 获取全国普通高校统计分布
export function getColleges(formId) {
  return request({
    url: "/statistic/colleges/" + formId,
    method: "get",
  });
}

// 获取应用配置的报表
export function getReportListByFormId(formId) {
  return request({
    url: "/report/list/" + formId,
    method: "get",
  });
}

// 获取应用配置的报表
export function getReportListByProjectId(projectId) {
  return request({
    url: "/report/list/project/" + projectId,
    method: "get",
  });
}

/**
 * 获取要展示的报表数据
 * @param {*} formId
 * @returns
 */
export function getReportData(url) {
  return request({
    url: url,
    method: "get",
  });
}
/**
 * 获取表单文本字段的词云
 * @param {*} formId
 * @param {*} fieldId
 * @returns
 */
export function getFormInputFieldWorldCloud(formId,fieldId) {
  return request({
    url: `/form/field-participle/${formId}/${fieldId}`,
    method: "get",
  });
}


// 福建师范大学统计更新。
// GET: fill/fjnu/refresh/{type}
export function refreshFjnus(type) {
  return request({
    url: `/fill/fjnu/refresh/${type}`,
    method: "get",
  });
}


// 北方工业大学大创项目管理系统--项目综合管理--统计更新。
// GET: fill/fjnu/refresh/{type}
export function refreshFjnusDaChuang() {
  return request({
    url: `/fill/ncut/project/management/`,
    method: "get",
  });
}

// 北方工业大学大学生创新创业训练计划项目研究成果汇总表
export function getRowDataByGbd(formId) {
  return request({
    url: `/statistic/rowData/bgd/${formId}`,
    method: "get",
  });
}

// 北方工业大学大创项目预算及绩效申报统计
export function getRowDataByGbdYsjx(formId) {
  return request({
    url: `/statistic/rowData/bgd/ysjx/${formId}`,
    method: "get",
  });
}

// 山东英才大创项目管理系统---统计更新。
// GET: fill/fjnu/refresh/{type}
export function refreshYingCaiDaChuang() {
  return request({
    url: `/fill/sdyc/project/management`,
    method: "get",
  });
}

// 通用的数据刷新
export function doStatisticsRefresh(url){
  return request({
    method: "get",
    url,
  });
}
