var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer",
      style:
        "background: linear-gradient(to bottom, " +
        _vm.formDesign.themeColor +
        "60, " +
        _vm.formDesign.themeColor +
        ");",
    },
    [_vm._v(" " + _vm._s(_vm.formDesign.pageFoot) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }