var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("li", { staticClass: "li-table" }, [
        _c("div", [_vm._v("列名")]),
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                attrs: { value: _vm.showAllFields },
                on: {
                  change: function (val) {
                    return _vm.setAllFieldSelect(val)
                  },
                },
              },
              [_vm._v("字段显示")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                attrs: { value: _vm.showAllEditable },
                on: {
                  change: function (val) {
                    return _vm.setAllFieldEditable(val)
                  },
                },
              },
              [_vm._v("字段可编辑")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                attrs: { value: _vm.showAllColumn },
                on: {
                  change: function (val) {
                    return _vm.setAllColumnSelect(val)
                  },
                },
              },
              [_vm._v("显示列")]
            ),
          ],
          1
        ),
        _c("div", [_vm._v("列排序")]),
      ]),
      _c(
        "draggable",
        {
          staticClass: "column-item",
          attrs: {
            ghostClass: "ghost",
            group: "field",
            handler: ".drag-handler",
            animation: 500,
          },
          on: { end: _vm.updateWidgetList },
          model: {
            value: _vm.widgetList,
            callback: function ($$v) {
              _vm.widgetList = $$v
            },
            expression: "widgetList",
          },
        },
        [
          _c(
            "transition-group",
            [
              _vm._l(_vm.filteredWidgetList, function (item, index) {
                return [
                  _c(
                    "li",
                    { key: "row" + index },
                    [
                      _c("div", { staticClass: "li-table" }, [
                        _c("div", [
                          _vm.isChildren(item)
                            ? _c("span", { staticClass: "el-icon-arrow" }, [
                                !_vm.isExpand(item)
                                  ? _c("i", {
                                      staticClass: "el-icon-arrow-down",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleExpand(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isExpand(item)
                                  ? _c("i", {
                                      staticClass: "el-icon-arrow-up",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleExpand(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(_vm._s(item.displayName) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: {
                                value: !_vm.checkItemKey(
                                  _vm.showFields,
                                  item,
                                  "hide"
                                ),
                              },
                              on: {
                                change: function (val) {
                                  return _vm.setCheckItemKey(
                                    _vm.showFields,
                                    item,
                                    "hide",
                                    !val,
                                    "showFields"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              "align-items": "center",
                            },
                          },
                          [
                            item.type != "sysField"
                              ? _c("el-checkbox", {
                                  attrs: {
                                    value: _vm.checkItemKey(
                                      _vm.showFields,
                                      item,
                                      "editable"
                                    ),
                                    disabled: _vm.disableEditable(
                                      _vm.showFields,
                                      item
                                    ),
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.setCheckItemKey(
                                        _vm.showFields,
                                        item,
                                        "editable",
                                        val
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: {
                                value: !_vm.checkItemKey(
                                  _vm.showColumns,
                                  item,
                                  "hide"
                                ),
                                disabled: _vm.disableShowColumn(item),
                              },
                              on: {
                                change: function (val) {
                                  return _vm.setCheckItemKey(
                                    _vm.showColumns,
                                    item,
                                    "hide",
                                    !val
                                  )
                                },
                              },
                            }),
                            _c("i", {
                              staticClass: "el-fix-column el-icon-top-left",
                              class: { active: !!item.left },
                              staticStyle: { "margin-left": "5px" },
                              attrs: { title: "固定在左侧" },
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnFix(
                                    _vm.showColumns,
                                    item,
                                    "left"
                                  )
                                },
                              },
                            }),
                            _c("i", {
                              staticClass: "el-fix-column el-icon-top-right",
                              class: { active: !!item.right },
                              attrs: { title: "固定在右侧" },
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnFix(
                                    _vm.showColumns,
                                    item,
                                    "right"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "drag-handler" }, [
                          _c("i", { staticClass: "el-icon-rank" }),
                        ]),
                      ]),
                      item.widgetList && _vm.isExpand(item)
                        ? _vm._l(item.widgetList, function (child, childIndex) {
                            return _c(
                              "li",
                              {
                                key: index + "-" + childIndex,
                                staticClass: "li-table li-table-child",
                              },
                              [
                                _c("div", [_vm._v(_vm._s(child.displayName))]),
                                _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        value: !_vm.checkChildItemKey(
                                          _vm.showFields,
                                          item,
                                          child,
                                          "hide"
                                        ),
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.setChildCheckItemKey(
                                            _vm.showFields,
                                            item,
                                            child,
                                            "hide",
                                            !val
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        value: _vm.checkChildItemKey(
                                          _vm.showFields,
                                          item,
                                          child,
                                          "editable"
                                        ),
                                        disabled: _vm.disableChildEditable(
                                          item,
                                          child
                                        ),
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.setChildCheckItemKey(
                                            _vm.showFields,
                                            item,
                                            child,
                                            "editable",
                                            val
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div"),
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-rank" }),
                                ]),
                              ]
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }