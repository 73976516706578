import {
  refreshStatisticUrl,
  showStatusButtonShangHaiShiJue,
  showStatisticRefreshButton,
  showStatisticRefreshButton_dachuang,
  showStatisticRefreshButton_dachuang1,
  showShuJuShuaXinShangHaiShiJue,
} from "@/utils/isButtonShow.js";
import {
  doStatisticsRefresh,
  refreshFjnus,
  refreshFjnusDaChuang,
  refreshYingCaiDaChuang,
} from "@/api/system/statistics";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      showStatusButtonShangHaiShiJue,
      showStatisticRefreshButton,
      showStatisticRefreshButton_dachuang,
      showStatisticRefreshButton_dachuang1,
      showShuJuShuaXinShangHaiShiJue,
      refreshButtonLoadingInstance: null,
      Loading: Loading,
    };
  },
  methods: {
    refreshFjnus() {
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "数据更新中请稍候...",
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
      });
      const refreshNum = showStatisticRefreshButton(this.formDetail);

      refreshFjnus(refreshNum).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "刷新成功",
            type: "success",
          });

          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.refreshButtonLoadingInstance.close();
        }
      });
    },
    refreshFjnusDaChuang() {
      console.log(this.Loading);
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "数据更新中请稍候...",
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
      });

      refreshFjnusDaChuang().then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "刷新成功",
            type: "success",
          });
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.refreshButtonLoadingInstance.close();
        }
      });
    },
    // 山东英才大创项目管理
    refreshYingCaiDaChuang() {
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "数据更新中请稍候...",
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
      });

      refreshYingCaiDaChuang().then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "刷新成功",
            type: "success",
          });
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.refreshButtonLoadingInstance.close();
        }
      });
    },
    doDataRefreshHandler(detail) {
      
      const handleRequestSuccess = (res) => {
        if (res.code == 200) {
          this.$message({
            message: "数据处理成功",
            type: "success",
          });
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        }
      };

      const handleRequestError = ()=>{
        this.onHandleQueryDataClick();
        this.refreshButtonLoadingInstance.close();
      }

      if (detail.type == 0) {
        this.refreshButtonLoadingInstance = this.Loading.service({
          fullscreen: true,
          text: "数据处理中请稍候...",
        });
        doStatisticsRefresh(detail.linkUrl).then(handleRequestSuccess).catch(handleRequestError);
      } else {
        this.$confirm(detail.content, detail.title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.refreshButtonLoadingInstance = this.Loading.service({
            fullscreen: true,
            text: "数据处理中请稍候...",
          });
          doStatisticsRefresh(detail.linkUrl).then(handleRequestSuccess).catch(handleRequestError);
        });
      }
    },
    refreshStatisticHandler(detail) {
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "数据更新中请稍候...",
      });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
      });

      let url = refreshStatisticUrl(detail);

      doStatisticsRefresh(url)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "刷新成功",
              type: "success",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          }
        })
        .catch(() => {
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        });
    },
    shangHaiShiJueChouJian(detail) {
      this.$confirm("是否确认重新抽取?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.refreshButtonLoadingInstance = this.Loading.service({
            fullscreen: true,
            text: "论文抽取中请稍候...",
          });
          doStatisticsRefresh(`/fill/random/selection/${detail.formId}`)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "抽取成功",
                  type: "success",
                });
                this.onHandleQueryDataClick();
                this.refreshButtonLoadingInstance.close();
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.onHandleQueryDataClick();
                this.refreshButtonLoadingInstance.close();
              }
            })
            .catch(() => {
              this.onHandleQueryDataClick();
              this.refreshButtonLoadingInstance.close();
            });
        })
        .catch(() => {
          // 用户点击了取消按钮或关闭对话框后的处理逻辑
          console.log("用户点击了取消按钮");
          // 处理取消操作的情况
        });
    },
    shangHaiShiJuePiPei(detail) {
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "专家匹配中请稍候...",
      });
      doStatisticsRefresh(`/fill/auto/match/${detail.formId}`)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "专家匹配完成",
              type: "success",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          }
        })
        .catch(() => {
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        });
    },
    shangHaiShiJueShuaXin(detail) {
      this.refreshButtonLoadingInstance = this.Loading.service({
        fullscreen: true,
        text: "数据刷新中，请稍候...",
      });
      doStatisticsRefresh(`/fill/siva/refresh`)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "数据刷新完成",
              type: "success",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
            this.onHandleQueryDataClick();
            this.refreshButtonLoadingInstance.close();
          }
        })
        .catch(() => {
          this.onHandleQueryDataClick();
          this.refreshButtonLoadingInstance.close();
        });
    },
  },
};
