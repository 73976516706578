var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.tabList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: [_vm.idx == index ? "tabs-item active" : "tabs-item"],
            style: { color: _vm.formDesign.themeColor },
            on: {
              click: function ($event) {
                return _vm.toggleNav(index)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(item.title) + " "),
            _vm.idx == index
              ? _c("div", {
                  staticClass: "line",
                  style: { background: _vm.formDesign.themeColor },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-main" }, [
        _c("div", { staticClass: "line2" }, [
          _c("div", {
            staticStyle: {
              width: "18px",
              height: "79px",
              "border-radius": "4px",
            },
            style: { background: _vm.formDesign.themeColor },
          }),
        ]),
        _vm.idx == 0
          ? _c("div", {
              staticClass: "content-main-con",
              domProps: { innerHTML: _vm._s(_vm.projectDetail.details) },
            })
          : _vm._e(),
        _vm.idx == 1
          ? _c(
              "div",
              { staticClass: "content-main-con" },
              [_c("Download", { attrs: { fileList: _vm.fileList } })],
              1
            )
          : _vm._e(),
        _vm.idx == 2
          ? _c("div", {
              staticClass: "content-main-con",
              domProps: { innerHTML: _vm._s(_vm.other) },
            })
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }