<template>
    <el-dropdown size="mini" split-button type="" @click="command['import']" style="height:30px;margin-right:10px">
        <i class="el-icon-upload2"></i>导入
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="command['import']">批量导入</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
  name:"Import",
  props: {
    command: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.iconfont-jyd {
    color: #1890ff;
    font-size: 12px;
}
</style>