<template>
  <div class="content-wrapper">
    <content-title :formDesign="formDesign" v-model="value.title"></content-title>
    <div class="content-wrapper-text" v-html="value.config"></div>
  </div>
</template>
<script>
import contentTitle from "./content-title";
export default {
  components: {
    contentTitle,
  },
  props: {
    formDesign: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      title: "标题",
    };
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  display: inline-block;
  text-align: center;

  background-color:white;

  /* 超出自动换行 */
  word-wrap: break-word;
  word-break: break-all;
  white-space: wrap;
}
.content-wrapper-text {
  width: 1280px;
  display: inline-block;
  text-align: left;

  /* 超出自动换行 */
  word-wrap: break-word;
  word-break: break-all;
  white-space: wrap;
}

.content-wrapper-text{
  ::v-deep .ql-align-left {
    text-align: left;
  }
  ::v-deep .ql-align-right {
    text-align: right;
  }
  ::v-deep .ql-align-center {
    text-align: center;
  }
}
</style>
