<template>
  <div>
    <el-dropdown class="view-batch-dropdown" v-if="showBtn.dropdownShow()">
      <el-button style="background: #f2f5f7;border: none;" :style="fillGridOptions.params.selectedRowKeys.length > 0
                  ? 'border: 1px solid #3476f0; '
                  : ''
                  ">
        批量操作 {{fillGridOptions.params.selectedRowKeys.length > 0 ? selectedCount + " 条"
                    : (fillGridOptions.params.isAllDataSelected && fillGridOptions.params.allDataTotal ? selectedCount + " 条" : '' )
                  }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="showBtn.btnC1ExportExcel()" @click.native="onExportForGridClick('excel', null)" data-tag="excel">导出Excel</el-dropdown-item>
        <!-- <el-dropdown-item v-if="showBtn.btnC2ExportPicExcel()" @click.native="onExportForGridClick('picexcel',null)" data-tag="picexcel">导出为带图片的Excel</el-dropdown-item> -->
        <!-- <el-dropdown-item v-if="showBtn.btnC3ExportExcelCombine()" @click.native="onExportForGridClick('excel2',null)" data-tag="excel2">Excel的合并导出</el-dropdown-item> -->
        <el-dropdown-item v-if="showBtn.btnC4ExportAttr()" @click.native="onExportForGridClick('file',null)" data-tag="file">导出附件</el-dropdown-item>
        <el-dropdown-item v-if="showBtn.btnC5ExportWord()" @click.native="onExportForGridClick('word',null)" data-tag="word">导出word/pdf文档</el-dropdown-item>
        <el-divider class="view-batch-divider"></el-divider>
        <el-dropdown-item v-if="showBtn.btnD1Import()" @click.native="onHandleImportNotSelfClick()">批量导入</el-dropdown-item>
        <el-dropdown-item v-if="showBtn.btnA4BatchEdit()" @click.native="showMultiEdit= true" :disabled="fillGridOptions.params.selectedRowKeys.length == 0 && !fillGridOptions.params.isAllDataSelected">
          批量编辑
        </el-dropdown-item>
         <el-dropdown-item v-if="showBtn.btnA6BatchUpdate()" @click.native="onShowBatchUpdate">
          批量更新数据
        </el-dropdown-item>
        <el-dropdown-item v-if="showBtn.btnA4BatchEdit() " @click.native="showMultiDelete = true" :disabled="showBtn.dataChooseDeleteDisabled || (fillGridOptions.params.selectedRowKeys.length == 0 && !fillGridOptions.params.isAllDataSelected) ">
          批量删除
        </el-dropdown-item>
        <el-divider class="view-batch-divider"></el-divider>
        <el-dropdown-item v-if="showBtn.btnF1SendNotice()" @click.native="onHandleNoticeClick(formDetail)" :disabled="fillGridOptions.params.selectedRowKeys.length == 0 && !fillGridOptions.params.isAllDataSelected">发布通知</el-dropdown-item>
        <el-divider v-if="showBtn.btnE3EditPay()||showBtn.btnE4EditPayStatus()" class="view-batch-divider"></el-divider>
        <!-- <el-dropdown-item command="batch-pay">支付</el-dropdown-item> -->
        <el-dropdown-item v-if="showBtn.btnE3EditPay()" @click.native="onHandleEditPayPriceClick()" :disabled="fillGridOptions.params.selectedRowKeys.length == 0">修改订单金额</el-dropdown-item>
        <el-dropdown-item v-if="showBtn.btnE4EditPayStatus()" @click.native="onHandleConfirmPayClick()" :disabled="fillGridOptions.params.selectedRowKeys.length == 0">调整支付状态</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog title="导出详情" :visible.sync="dataDownloadAllOpen" width="1000px" :append-to-body="true">
      <el-button type="primary" size="mini" style="margin-bottom: 20px;" @click="onCreateDownloadClick">数据下载</el-button>
      <el-table :data="fillDataZipDownList" border style="width: 100%">
        <el-table-column prop="createTime" label="下载创建时间" align="center"></el-table-column>
        <el-table-column prop="downStartTime" label="数据打包开始时间" align="center"></el-table-column>
        <el-table-column prop="downEndTime" label="链接生成时间" align="center"></el-table-column>
        <el-table-column prop="expireTime" label="链接过期时间" align="center"></el-table-column>
        <el-table-column label="任务状态" align="center">
          <template slot-scope="scope">
            <span slot="label" v-if="scope.row.status == '1'">排队下载中</span>
            <span slot="label" v-else-if="scope.row.status == '2'">数据打包中</span>
            <span slot="label" v-else-if="scope.row.status == '3'">数据打包完成</span>
            <span slot="label" v-else-if="scope.row.status == '4'">
              <span v-if="scope.row.errorMsg">{{ scope.row.errorMsg }}</span>
              <span v-else>数据打包失败</span>
            </span>
            <span slot="label" v-else-if="scope.row.status == '5'">下载链接过期</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" :disabled="scope.row.status == '3' ? false : true">
              <a :href="apiPathUrl +
                  '/dataDownload/downloadZip/' +
                  scope.row.zipDownId +
                  '?token=' +
                  Authorization
                  " target="_blank" v-if="scope.row.status == '3'">下载</a>
              <a href="javascript:void(0);" v-else style="cursor: not-allowed; color: #C0C4CC">下载</a>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--批量填报-->
    <el-dialog title="导入填报" :visible.sync="fillOpen" width="400px" :append-to-body="true" @close="onCloseUploadFillClick">
      <el-upload ref="formFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleFillFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" @click="onDownloadExcelTemplate" style="font-size: 12px;">下载模板</el-button>
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
            提示：仅允许导入“xls”或“xlsx”格式文件！
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadFillClick">确 定</el-button>
        <el-button @click="onCancelUploadFillClick">取 消</el-button>
      </div>
    </el-dialog>
    <!--批量填报-->
    <!--批量设置通知-->
    <el-dialog title="通知" :visible.sync="dataNoticeShow" width="640px" :append-to-body="true" @opened="onDataNoticeOpen" @close="onDataNoticeClose">
      <h5 class="noticeHTitle">
        <span style="font-size: 14px; color: #999; font-weight: normal;">注：关注微信公众“鲸鱼填报”的用户才能收到此通知</span>
        <img :src="qrImg" style="width: 25px; height: 25px; cursor: pointer;" class="officialQrImg" />
        <img :src="chatImg" class="officialChatImg" />
      </h5>
      <el-form ref="noticeForm" :model="noticeModel" :rules="noticeRules" label-width="90px" label-position="right" @submit.native.prevent>
        <el-form-item prop="noticeOverview" label="通知概览">
          <el-col :span="24">
            <el-input type="textarea" v-model="noticeModel.noticeOverview" placeholder="请输入通知概览"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item prop="noticeDetails" label="通知详情">
          <!-- <div id="wangEditor"></div> -->
          <richEditor :formId="formId" :agencyId="formDetail.agencyId" uploadPath="fillFile/{agencyId}/{formId}/formNotice/" v-model="noticeModel.noticeDetails" :Settings="{ placeholder: '请输入通知详情' }"></richEditor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmNoticeClick">确 定</el-button>
        <el-button @click="onDataNoticeClose">取 消</el-button>
      </div>
    </el-dialog>
    <!--批量设置通知-->

    <!-- 管理员批量更新数据 -->
      <el-dialog title="批量更新数据" :visible.sync="batchUpdateDataForExcel" width="400px" append-to-body @close="onCloseNotSelfUploadFillClick">
        <el-upload ref="notSelfFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleNotSelfFillFileRequest" drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
            <!-- <el-button type="text" @click="onExcelDataImportTemplate" style="font-size: 12px;">下载模板</el-button> -->
            <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
              提示：仅允许导入“xls”或“xlsx”格式文件！
            </div>
          </div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onBatchUpdateDataForExcel">确 定</el-button>
          <el-button @click="onCancelNotSelfUploadFillClick">取 消</el-button>
        </div>
      </el-dialog>

    <!--数据查看，管理员批量导入他人填报-->
    <el-dialog title="导入他人填报" :visible.sync="notSelfFillOpen" width="400px" :append-to-body="true" @close="onCloseNotSelfUploadFillClick">
      <el-upload ref="notSelfFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleNotSelfFillFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" @click="onDownloadNotSelfExcelTemplate" style="font-size: 12px;">下载模板</el-button>
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
            提示：仅允许导入“xls”或“xlsx”格式文件！
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmNotSelfUploadFillClick">确 定</el-button>
        <el-button @click="onCancelNotSelfUploadFillClick">取 消</el-button>
      </div>
    </el-dialog>
    <!--数据查看，管理员批量导入他人填报-->
    <!--数据查看，管理员批量导入他人填报-->
    <el-dialog title="提示" :visible.sync="notSelfFillFailOpen" width="400px" :append-to-body="true" @close="onCloseNotSelfUploadFillClick">
      <div style="line-height:30px;">
        系统检查出您导入的数据有部分存在问题，已将问题数据整理成为Excel，请点击下载修改
      </div>
      <div style="height:30px;line-height:30px;">
        <a :href="notSelfFillFailUrl" style="color:#1890FF">点击此处下载导入失败的Excel</a>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="notSelfFillFailOpen = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--数据查看，管理员批量导入他人填报-->

    <!--数据查看列表，修改订单金额-->
    <el-dialog title="修改订单金额" :visible.sync="editPayPriceOpen" width="400px" append-to-body @close="onCloseEditPayPriceClick">
      <el-form ref="editPayPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
        <el-form-item label="订单金额" prop="pay">
          <el-input type="text" placeholder="请输入支付金额" v-model="editPayPrice" style="width:200px; display: inline-block;"></el-input>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmEditPayPriceClick">确 定</el-button>
        <el-button @click="onCloseEditPayPriceClick">取 消</el-button>
      </div>
    </el-dialog>

      <!--数据查看列表，修改订单金额-->
      <!--数据查看列表，确认到款-->
      <el-dialog title="修改支付状态" :visible.sync="confirmPayStatusOpen" width="400px" append-to-body @close="onCloseEditPayStatusClick">
        <el-form ref="confirmPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
          <el-form-item label="支付状态" prop="payStatus">
            <el-select v-model="confirmPayStatus" placeholder="请选择支付状态">
              <el-option label="未支付" value="2"></el-option>
              <el-option label="确认到款" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button type="primary" @click="onConfirmPayStatusClick">确 定</el-button>
          <el-button @click="onCloseEditPayStatusClick">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 导出excel选择需要导出的列 -->
      <el-dialog title="选择需要导出的列信息" :visible.sync="exportFieldSelect" width="40%" append-to-body @close="()=>exportFieldList.splice(0)">
        <div style="height: 500px;overflow-y: auto;" v-loading="!exportFieldList||exportFieldList.length==0">
          <el-tree ref="channelTree" :default-checked-keys="defaultSelectAll" :props="defaultFieldProps" :check-on-click-node="true" :data="exportFieldList" show-checkbox node-key="field">
            <span slot-scope="{ node, data }">
              <el-badge v-if="data.type === 'sysField'" value="系" class="item" type="primary" style="font-size: 10px;">
                <span style="font-size: 14px;">{{ node.label }}</span>
              </el-badge>
              <span v-else>{{ node.label }}</span>
            </span>
          </el-tree>
        </div>

        <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button type="primary" @click="onExportExcelClick(0)">确 定</el-button>
          <el-button @click="exportFieldSelect = false">取 消</el-button>
        </div>
      </el-dialog>

    <!--数据查看列表，修改订单金额-->
    <multiEdit :showMultiEdit.sync="showMultiEdit" 
               :formJson="viewFieldFormJson" 
               :formId="formId" 
               :refresh="onHandleQueryDataClick" 
               :formOptionData="formOptionData" 
               :fillIds="fillGridOptions.params.selectedRowKeys" 
               :fillCacheKey="fillGridOptions.fillCacheKey" 
               :excludeIds="fillGridOptions.params.excludeIds"
               :selectAll="fillGridOptions.params.isAllDataSelected"
               :viewConfig="viewConfig">
    </multiEdit>
    <multiDelete :showMultiDelete.sync="showMultiDelete" :formId="formId" :refresh="onHandleQueryDataClick" :formOptionData="formOptionData" :fillIds="fillGridOptions.params.selectedRowKeys" :fillCacheKey="fillGridOptions.fillCacheKey"
                 :excludeIds="fillGridOptions.params.excludeIds" :formName="formDetail.name" :selectAll="fillGridOptions.params.isAllDataSelected"></multiDelete>
  </div>
</template>
<script>
import richEditor from "@/components/RichEditor";
import viewExportMixin from './viewExportMixin'
import multiEdit from "@/views/system/form/components/multiEdit/index.vue";
import multiDelete from "@/views/system/form/components/multiDelete/index.vue";

import { deepClone } from "@/utils/deepClone.js";
import { VFormUtils } from 'VFormDesigner'

export default {
  props: {
    fillGridOptions: Object,
    formDetail: Object,
    formJson: Object,
    formId: String,
    showBtn: Object,
    formViewId: String,
    formOptionData: Object,
    viewConfig: Object
  },
  components: {
    richEditor,
    multiEdit,
    multiDelete
  },
  mixins: [viewExportMixin],
  computed:{
    selectedCount:function(){
        return this.fillGridOptions.params.isAllDataSelected?
          this.fillGridOptions.params.allDataTotal- this.fillGridOptions.params.excludeIds.length: this.fillGridOptions.params.selectedRowKeys.length
    },
    viewFieldFormJson:function(){
      let formJson = deepClone(this.formJson);
      let viewShowFields = this.viewConfig.viewFields;
      
      if(viewShowFields==null || viewShowFields.length==0){
        formJson.widgetList.splice(0);
        formJson.fieldList.splice(0);
      }else{
        // 处理formJson widgetList
        let showFields = viewShowFields.map(x=>x.name)
        VFormUtils.trimShownWidgets(formJson.widgetList,showFields)
        
        // 处理formJson fieldList
        let notShowFields = formJson.fieldList.map(x=>x.name).filter(x=>showFields.indexOf(x)==-1)
        notShowFields.forEach(x=>{
          let fieldIndex = formJson.fieldList.findIndex(y=>y.name==x);
          if(fieldIndex>-1){
            formJson.fieldList.splice(fieldIndex,1);
          }
        })
      }
      return formJson;
    }
  }
}
</script>
<style lang="scss" scoped>
  .view-batch-divider + .view-batch-divider{
    display:none;
  }
</style>
<style lang="scss">

  .el-dropdown-menu .view-batch-divider:first-child {
    display:none;
  }
  .el-dropdown-menu .view-batch-divider:first-child {
    display:none;
  }
</style>
