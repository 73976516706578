var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "backBox" }, [
      _c(
        "div",
        {
          staticClass: "backBox-btn",
          style: "background-color:" + _vm.formDesign.themeColor + ";",
          on: { click: _vm.goBack },
        },
        [_vm._v("返回"), _c("br"), _vm._v("首页")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "tabs" },
      [
        _c(
          "div",
          {
            staticClass: "tabs-item",
            class: { active: _vm.activeTab == "viewInfo" },
            style:
              "" +
              (_vm.activeTab == "viewInfo"
                ? "color:" + _vm.formDesign.themeColor
                : ""),
            on: {
              click: function ($event) {
                return _vm.switchTab("viewInfo")
              },
            },
          },
          [
            _vm._v(" 概览 "),
            _vm.activeTab == "viewInfo"
              ? _c("div", {
                  staticClass: "line",
                  style: "background-color:" + _vm.formDesign.themeColor + ";",
                })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "tabs-item",
            class: { active: _vm.activeTab == "myFillList" },
            style:
              "" +
              (_vm.activeTab == "myFillList"
                ? "color:" + _vm.formDesign.themeColor
                : ""),
            on: {
              click: function ($event) {
                return _vm.switchTab("myFillList")
              },
            },
          },
          [
            _vm._v(" 已填列表 "),
            _vm.activeTab == "myFillList"
              ? _c("div", {
                  staticClass: "line",
                  style: "background-color:" + _vm.formDesign.themeColor + ";",
                })
              : _vm._e(),
          ]
        ),
        _vm.formAuth.currentQueryReviewListAuth
          ? _c(
              "div",
              {
                staticClass: "tabs-item",
                class: { active: _vm.activeTab == "auditList" },
                style:
                  "" +
                  (_vm.activeTab == "auditList"
                    ? "color:" + _vm.formDesign.themeColor
                    : ""),
                on: {
                  click: function ($event) {
                    return _vm.switchTab("auditList")
                  },
                },
              },
              [
                _vm._v(" 审核列表 "),
                _vm.activeTab == "auditList"
                  ? _c("div", {
                      staticClass: "line",
                      style:
                        "background-color:" + _vm.formDesign.themeColor + ";",
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._l(_vm.viewList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "tabs-item",
              class: { active: _vm.activeTab == "viewId:" + index },
              style:
                "" +
                (_vm.activeTab == "viewId:" + index
                  ? "color:" + _vm.formDesign.themeColor
                  : ""),
              on: {
                click: function ($event) {
                  return _vm.switchTab("viewId:" + index)
                },
              },
            },
            [
              _vm._v(" " + _vm._s(item.name) + " "),
              _vm.activeTab == "viewId:" + index
                ? _c("div", {
                    staticClass: "line",
                    style:
                      "background-color:" + _vm.formDesign.themeColor + ";",
                  })
                : _vm._e(),
            ]
          )
        }),
        _c(
          "div",
          {
            staticClass: "tabs-item",
            class: { active: _vm.activeTab == "staticTab" },
            style:
              "" +
              (_vm.activeTab == "staticTab"
                ? "color:" + _vm.formDesign.themeColor
                : ""),
            on: {
              click: function ($event) {
                return _vm.switchTab("staticTab")
              },
            },
          },
          [
            _vm._v(" 统计报表 "),
            _vm.activeTab == "staticTab"
              ? _c("div", {
                  staticClass: "line",
                  style: "background-color:" + _vm.formDesign.themeColor + ";",
                })
              : _vm._e(),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }