import {
  fillDataDownload,
  fillDataDownloadFormPicDownList,
  fillDataDownloadFormPicDownload,
  fillDataDownloadFormWordDownList,
  fillDataDownloadFormWordDownload,
  othersExcelImportUpdate,
  fillDataZipDownHttp,
  fillDataZipDownList,
  fillDownloadExcel,
  fillDownloadOthersExcel,
  formFillExcelImport,
  formFillExcelOtherImport,
  noticeSend,
  orderPriceEdit,
  payStatusEdit,
} from "@/api/system/form";
import { formViewColumnDefs } from "@/api/system/views";

import { getToken } from "@/utils/auth";

import ELEMENT from "element-ui";
export default {
  data() {
    return {
      Authorization: "",
      noticeModel: {
        noticeOverview: "",
        noticeDetails: "",
      },
      noticeRules: {
        noticeOverview: [
          {
            required: true,
            message: "通知概览不能为空",
            trigger: ["change", "blur"],
          },
        ],
        noticeDetails: [
          {
            required: true,
            message: "通知详情不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },

      dataQueryParams: {
        formFieldId: "",
        fillValue: "",
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        fillStatus: "",
      },
      // noticeEditor: {},
      noticeClickNum: 0, // 数据查看列表点击查询按钮次数
      fillOpen: false,
      qrImg: require("@/assets/images/qr-icon.png"),
      chatImg: require("@/assets/images/code.jpeg"),
      dataNoticeShow: false, // 批量设置通知
      exportDownloadType: "",
      batchUpdateDataForExcel: false,
      dataDownloadAllOpen: false,
      otherFillFile: "",
      fillDataZipDownList: [],
      apiPathUrl: process.env.VUE_APP_BASE_API,
      // 数据查看列表---管理员批量导入他人填报
      notSelfFillOpen: false,
      // 数据查看列表---管理员批量导入他人数据失败，下载失败的excel
      notSelfFillFailOpen: false,
      notSelfFillFailUrl: "",

      showMultiEdit: false, // 显示批量编辑框
      showMultiDelete: false, // 显示批量删除框

      editPayPriceOpen: false, // 批量设置支付金额
      editPayPrice: 0, // 批量设置支付金额
      confirmPayStatusOpen: false, // 表单开通支付功能，管理员确认到款
      confirmPayStatus: null, // 表单开通支付功能，管理员确认到款类型，确认到款，未支付
      exportFieldSelect: false,
      exportFieldList: [],
      defaultSelectAll: [],
      defaultFieldProps: {
        label: "headerName",
        children: "children",
      },
    };
  },
  created() {
    this.Authorization = getToken();
  },
  methods: {
    // 管理列表的导出
    onExportForGridClick(type, fillIds) {
      const tag = type;
      let isAllDataSelected = false;
      if (fillIds && fillIds.length > 0) {
        this.downloadParams = {
          formId: this.formId,
          type: 1,
          fillIds: fillIds,
        };
      } else {
        const selectKeys = this.fillGridOptions.params.selectedRowKeys;
        isAllDataSelected =
          this.fillGridOptions.params.isAllDataSelected || false;
        if (!isAllDataSelected && selectKeys.length == 0) {
          this.$message.error("请先勾选下载记录");
          return;
        }
        if (isAllDataSelected) selectKeys.splice(0);

        fillIds = selectKeys.map((x) => x.fillId);
        if (isAllDataSelected) {
          this.downloadParams = {
            formId: this.formId,
            searchText: this.dataQueryParams.fillValue,
            criteria: this.dataQueryParams.filterData,
            fillIdCacheKey: this.fillGridOptions.fillCacheKey,
            excludeFillIds: this.fillGridOptions.params.excludeIds.toString(),
            type: 2,
            fillStartTime: this.dataQueryParams.fillStartTime,
            fillEndTime: this.dataQueryParams.fillEndTime,
            queryStatus: this.dataQueryParams.queryStatus,
            payStatus: this.dataQueryParams.payStatus,
          };
        } else {
          this.downloadParams = {
            formId: this.formId,
            type: 1,
            fillIds: fillIds,
          };
        }
      }

      if (
        isAllDataSelected === false &&
        this.formDetail.exportAfterAudit &&
        type === "word"
      ) {
        let fillJsons = this.fillGridOptions.data.filter((x) => {
          return fillIds.findIndex((y) => y == x.fillId) > -1;
        });
        // 检查每一条formJson的数据，status是否为14

        if (!fillJsons.every((x) => x.status == 14)) {
          //如果不是所有的都是14
          this.$message.error(
            "导出数据中存在未完成审核的数据，请先审核通过后再进行导出"
          );
          return;
        }
      }
      this.onExportProxy(tag);
    },
    onExportProxy(tag) {
      if (tag == "excel") {
        //是否有关联字段 0没有 1有-合并导出
        // this.onExportExcelClick(0);
        // 打开弹出框，让用户选择要导出的字段
        this.exportFieldSelect = true;
        // 加载字段
        formViewColumnDefs(this.formViewId).then((response) => {
          if (response.code == 200) {
            this.exportFieldList =[{
                headerName: "填报Id",
                field: "fillId",
                type: "sysField",
                children: null
            },...response.data];

            this.exportFieldList.forEach((e) => {
              this.defaultSelectAll.push(e.field);
            });
          }
        });
      } else if (tag == "excel2") {
        this.onExportExcelClick(1);
      } else if (tag == "picexcel") {
        this.exportDownloadType = "picexcel";
        this.onExportExcelThumbnailClick();
      } else if (tag == "file") {
        this.exportDownloadType = "file";
        this.onExportExcelFileClick();
      } else if (tag == "word") {
        this.exportDownloadType = "word";
        this.onExportWordClick();
      } else {
        // this.$message.error('未知的下载类型')
        this.onExportExcelClick(0);
      }
    },
    // 下载数据详情表格
    onDownloadClick() {
      this.dataDownloadOpen = true;
    },
    // 仅导出Excel
    onDownLoadExcel(showImg = "show", isFillSelect = 0, exportFields = []) {
      const formId = this.formId;
      const loading = ELEMENT.Loading.service({
        fullscreen: true,
        lock: true,
        text: "导出中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.5)",
      });
      // const showImg = 'show'
      fillDataDownload({
        showImg: showImg,
        isFillSelect: isFillSelect,
        exportFields: exportFields,
        ...this.downloadParams,
      }).then(
        (data) => {
          if (!data) {
            return false;
          }
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          loading.close();
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
          loading.close();
        }
      );
    },
    // 导出Excel和附件（图片）
    onDownLoadAll(fillId) {
      this.dataDownloadAllOpen = true;
      this.getZipDownList(this.formId);
    },
    // EXCEL带附件生成下载请求
    onCreateDownloadClick() {
      const formId = this.formId;
      if (this.exportDownloadType === "file") {
        fillDataZipDownHttp(this.downloadParams).then((response) => {
          if (response.code == 200) {
            this.$message({
              type: "success",
              message: "数据正在打包中，请稍后进行下载～",
            });

            //获取下载数据列表完成的
            let getZipDownListFinishHandler = (data) => {
              if (
                data.filter((x) => x.status <= 1).length == 0 ||
                timeoutTimes > 60
              ) {
                clearInterval(checkDownload);
              }
            };
            let timeoutTimes = 0;
            let checkDownload = setInterval(() => {
              timeoutTimes++;
              this.getZipDownList(formId, getZipDownListFinishHandler);
            }, 1000);
          }
        });
      } else if (this.exportDownloadType === "word") {
        fillDataDownloadFormWordDownload(this.downloadParams).then(
          (response) => {
            if (response.code == 200) {
              this.$message({
                type: "success",
                message: "数据正在打包中，请稍后进行下载～",
              });

              //获取下载数据列表完成的
              let getZipDownListFinishHandler = (data) => {
                if (
                  data.filter((x) => x.status <= 1).length == 0 ||
                  timeoutTimes > 60
                ) {
                  clearInterval(checkDownload);
                }
              };
              let timeoutTimes = 0;
              let checkDownload = setInterval(() => {
                timeoutTimes++;
                this.getExportWordDownList(formId, getZipDownListFinishHandler);
              }, 1000);
            }
          }
        );
      } else {
        fillDataDownloadFormPicDownload(this.downloadParams).then(
          (response) => {
            let getZipDownListFinishHandler = (data) => {
              if (
                data.filter((x) => x.status <= 1).length == 0 ||
                timeoutTimes > 60
              ) {
                clearInterval(checkDownload);
              }
            };
            let timeoutTimes = 0;
            let checkDownload = setInterval(() => {
              timeoutTimes++;
              this.getExportExcelPicList(formId, getZipDownListFinishHandler);
            }, 1000);
          }
        );
      }
    },
    getZipDownList(formId, callback) {
      fillDataZipDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    getExportExcelPicList(formId, callback) {
      fillDataDownloadFormPicDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    getExportWordDownList(formId, callback) {
      fillDataDownloadFormWordDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    onDownloadFormFileClick(item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onExportExcelClick(isFillSelect) {
      if (isFillSelect === 0) {
        let checkedKeys = this.$refs.channelTree.getCheckedKeys();
        let halfCheckedKeys = this.$refs.channelTree.getHalfCheckedKeys();
        const mergedArray = [...checkedKeys, ...halfCheckedKeys];
        this.onDownLoadExcel("hide", isFillSelect, mergedArray);
      } else {
        this.onDownLoadExcel("hide", isFillSelect);
      }
    },
    onExportExcelThumbnailClick() {
      this.dataDownloadAllOpen = true;
      this.getExportExcelPicList(this.formId);
    },
    onExportExcelFileClick() {
      this.onDownLoadAll();
    },
    onExportWordClick() {
      // this.$message({
      //   type: 'warning',
      //   message: '正在开发中，请敬请期待！'
      // })
      this.dataDownloadAllOpen = true;
      this.getExportWordDownList(this.formId);
    },
    getExportWordDownList(formId, callback) {
      fillDataDownloadFormWordDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },

    // 下载模版
    onDownloadExcelTemplate() {
      fillDownloadExcel(this.formId).then(
        (data) => {
          if (!data) {
            return false;
          }
          // 获得时间戳
          const date = moment().format("YYYYMMDDHHmmss");
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `填报数据导入-${date}-${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },
    handleFillFileRequest({ file }) {
      this.fillFile = file;
    },
    // 确认导入填报数据
    onConfirmUploadFillClick() {
      const _this = this;
      if (_this.isBlank(_this.fillFile)) {
        _this.$message({
          message: "请上传文件",
          type: "warning",
        });
        return;
      }
      formFillExcelImport(_this.fillFile, _this.formId).then((response) => {
        if (response.code == 200) {
          // loading.close()
          if (
            response.data &&
            response.data.data !== "" &&
            response.data.data !== null
          ) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = response.data;
            return;
          }

          _this.msgSuccess("导入成功");
          _this.fillOpen = false;
          _this.fillFile = null;
          if (_this.$refs.formFillUpload) {
            _this.$refs.formFillUpload.clearFiles(); // 上传完成后执行清空
          }
          // _this.fillGridOptions.columns = []
          _this.fillGridOptions.data = [];
          _this.onTabClick(3);
          _this.$refs.dlgvFormViewer.close();
          // _this.cur = 3;
          // _this.$nextTick(()=>{
          // _this.getMyFillList();
          // })
        }
      });
    },
    onCloseUploadFillClick() {
      this.fillOpen = false;
      this.fillFile = null;
      if (this.$refs.formFillUpload) {
        this.$refs.formFillUpload.clearFiles();
      }
    },
    onCancelUploadFillClick() {
      this.fillOpen = false;
      this.fillFile = null;
      if (this.$refs.formFillUpload) {
        this.$refs.formFillUpload.clearFiles();
      }
    },

    // 数据查看列表----批量导入他人数据
    onHandleImportNotSelfClick() {
      this.notSelfFillOpen = true;
    },
    onHandleNoticeClick() {
      this.dataNoticeShow = true;
    },
    // 批量通知设置弹框，解决dialog弹出层初始化失败的问题
    onDataNoticeOpen() {
      this.$refs.noticeForm.resetFields();
      if (this.$refs["noticeForm"]) {
        this.$refs["noticeForm"].clearValidate();
      }
      this.noticeModel.noticeOverview = "";
      this.noticeModel.noticeDetails = "";
    },
    // 批量通知设置弹框，确定事件
    onConfirmNoticeClick() {
      const _this = this;
      // console.log(this.fillGridOptions.params);
      const selectKeys = this.fillGridOptions.params.selectedRowKeys;
      const isAllDataSelected = this.fillGridOptions.params.isAllDataSelected;

      if (selectKeys.length == 0) {
        _this.$message({
          message: "请选择要通知的数据",
          type: "warning",
        });
        return;
      }
      // return;
      _this.$refs["noticeForm"].validate((valid) => {
        if (valid) {
          const sendMpNoticeDTO = {
            // formId: _this.formId,
            listCheckParam: {
              fillEndTime: this.dataQueryParams.fillEndTime,
              fillIds: selectKeys.map((x) => x.fillId),
              fillStartTime: this.dataQueryParams.fillStartTime,
              formId: this.formId,
              queryStatus: this.dataQueryParams.queryStatus,
              type: isAllDataSelected ? 2 : 1,
              fillIdCacheKey: this.fillGridOptions.params.isAllDataSelected
                ? this.fillGridOptions.fillCacheKey
                : undefined,
              excludeFillIds: this.fillGridOptions.params.isAllDataSelected
                ? this.fillGridOptions.params.excludeIds.toString()
                : undefined,
            },
            noticeOverview: _this.noticeModel.noticeOverview,
            noticeDetails: _this.noticeModel.noticeDetails,
          };

          const sendMpNoticeScreenDTO = {};
          if (!_this.isBlank(_this.dataQueryParams.queryStatus)) {
            sendMpNoticeScreenDTO.queryStatus =
              _this.dataQueryParams.queryStatus;
          } else if (!_this.isBlank(_this.dataQueryParams.fillStartTime)) {
            sendMpNoticeScreenDTO.fillStartTime =
              _this.dataQueryParams.fillStartTime;
          } else if (!_this.isBlank(_this.dataQueryParams.fillEndTime)) {
            sendMpNoticeScreenDTO.fillEndTime =
              _this.dataQueryParams.fillEndTime;
          } else if (!_this.isBlank(_this.dataQueryParams.formFieldId)) {
            sendMpNoticeScreenDTO.formFieldId =
              _this.dataQueryParams.formFieldId;
          } else if (!_this.isBlank(_this.dataQueryParams.fillValue)) {
            sendMpNoticeScreenDTO.fillValue = _this.dataQueryParams.fillValue;
          }

          const fillIdArr = [];
          for (
            let i = 0;
            i < _this.fillGridOptions.params.selectedRowKeys.length;
            i++
          ) {
            fillIdArr.push(
              _this.fillGridOptions.params.selectedRowKeys[i].fillId
            );
          }

          let sendType = null;

          if (
            fillIdArr.length == 0 &&
            JSON.stringify(sendMpNoticeScreenDTO) == "{}"
          ) {
            sendType = 3;
          } else if (fillIdArr.length > 0) {
            sendType = 1;
            sendMpNoticeDTO.fillIds = fillIdArr;
          } else {
            sendType = 2;
            sendMpNoticeDTO.sendMpNoticeScreenDTO = sendMpNoticeScreenDTO;
          }

          sendMpNoticeDTO.sendType = sendType;

          noticeSend(sendMpNoticeDTO).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("通知发送成功");

              _this.dataNoticeShow = false;

              _this.$refs.noticeForm.resetFields();
              if (_this.$refs["noticeForm"]) {
                _this.$refs["noticeForm"].clearValidate();
              }
              _this.noticeModel.noticeOverview = "";
              _this.noticeModel.noticeDetails = "";
            } else {
            }
          });
        }
      });
    },
    // 批量通知设置弹框，关闭和取消事件
    onDataNoticeClose() {
      this.dataNoticeShow = false;
      this.$refs.noticeForm.resetFields();
      if (this.$refs["noticeForm"]) {
        this.$refs["noticeForm"].clearValidate();
      }
    },
    onCancelNotSelfUploadFillClick() {
      this.notSelfFillOpen = false;
      this.batchUpdateDataForExcel = false;
      this.otherFillFile = null;
      if (this.$refs.notSelfFillUpload) {
        this.$refs.notSelfFillUpload.clearFiles();
      }
    },

    // 数据查看列表----模版下载帮助他人批量填报
    onDownloadNotSelfExcelTemplate() {
      fillDownloadOthersExcel(this.formId).then(
        (data) => {
          if (!data) {
            return false;
          }
          // 获得时间戳
          const date = moment().format("YYYYMMDDHHmmss");
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `他人填报数据导入-${date}-${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },
    // 数据查看列表----确认批量导入他人数据
    onConfirmNotSelfUploadFillClick() {
      const _this = this;
      if (_this.isBlank(_this.otherFillFile)) {
        _this.$message({
          message: "请上传文件！",
          type: "warning",
        });
        return;
      }
      _this
        .$confirm(
          "批量导入的数据是否需要填报人确认？选择需要，则经本人确认后才算提交完成。选择不需要，数据直接导入并完成提交。",
          "警告",
          {
            confirmButtonText: "需要",
            cancelButtonText: "不需要",
            type: "warning",
          }
        )
        .then(function () {
          // console.log('点击需要操作')
          return formFillExcelOtherImport(_this.otherFillFile, 1, _this.formId);
        })
        .then((res) => {
          // console.log('成功操作')
          // _this.msgSuccess("导入成功");
          // _this.notSelfFillOpen = false;
          // _this.otherFillFile = null;
          // if (_this.$refs.notSelfFillUpload) {
          //   _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
          // }
          // _this.cur = 5;
          // if (res && res.data) {
          //   _this.notSelfFillFailOpen = true;
          //   _this.notSelfFillFailUrl = res.data;
          // }
          // _this.fillGridOptions.columns = []
          // _this.fillGridOptions.data = []
          // _this.getDataList()
        })
        .catch(function (res) {
          //点击为他人导入
          return formFillExcelOtherImport(_this.otherFillFile, 2, _this.formId);
        })
        .then((res) => {
          // console.log('成功操作')
          _this.msgSuccess("导入成功");
          _this.notSelfFillOpen = false;
          _this.otherFillFile = null;
          if (_this.$refs.notSelfFillUpload) {
            _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
          }

          if (res.data) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = res.data;
          }
          this.$emit("reloadTable");
          this.getFormViewListData();
        })
        .catch((res) => {
          if (res.data) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = res.data;
          }
        });
    },
    onCloseNotSelfUploadFillClick() {
      this.notSelfFillOpen = false;
      this.otherFillFile = null;
      if (this.$refs.notSelfFillUpload) {
        this.$refs.notSelfFillUpload.clearFiles();
      }
    },
    handleNotSelfFillFileRequest({ file }) {
      this.otherFillFile = file;
    },
    onHandleQueryDataClick() {
      this.fillGridOptions.data = [];
      this.fillGridOptions.pagerConfig.currentPage = 1;

      this.noticeClickNum++;
      this.$emit("reloadTable");
    },

    // 表单开通支付，管理员取消修改支付金额
    onCloseEditPayPriceClick() {
      this.editPayPriceOpen = false;
      this.editPayPrice = null;
      this.editPayPriceOpen = false;
      if (this.$refs.xGrid) {
        this.$refs.xGrid.clearFilter();
        this.$refs.xGrid.clearSelected();
        this.$refs.xGrid.clearCheckboxRow();
      }
      this.selectedDataRowKeys = [];
      this.selectionDataRows = [];
    },
    // 表单开通支付，管理员点击确认到款
    onHandleConfirmPayClick() {
      const _this = this;
      if (_this.isBlank(_this.fillGridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      _this.confirmPayStatusOpen = true;
    },
    // 表单开通支付，管理员确定修改支付金额
    onConfirmEditPayPriceClick() {
      const _this = this;

      const inconformityArr = [];
      for (
        let i = 0;
        i < _this.fillGridOptions.params.selectedRowKeys.length;
        i++
      ) {
        // const dataRow = this.fillGridOptions.data.find((x) => x.fillId == _this.fillGridOptions.params.selectedRowKeys[i].fillId)
        const dataRow = _this.fillGridOptions.params.selectedRowKeys[i];
        if (dataRow.payStatus == 3 || dataRow.payStatus == 4) {
          inconformityArr.push(dataRow);
        }
      }
      if (inconformityArr.length > 0) {
        this.$message({
          type: "warning",
          message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
        });
        return;
      }
      if (_this.isBlank(_this.editPayPrice)) {
        this.$message({
          type: "warning",
          message: "支付金额不能为空～",
        });
        return;
      }
      const n = Number(_this.editPayPrice);
      if (isNaN(n)) {
        _this.$message({
          type: "warning",
          message: "支付金额格式有误～",
        });
        return;
      } else if (n < 0.01) {
        _this.$message({
          type: "warning",
          message: "设置支付金额最小为0.01元～",
        });
        return;
        // } else if (n > 3000) {
        //   _this.$message({
        //     type: 'warning',
        //     message: '设置支付金额不能超过3000元～'
        //   })
        //   return
      } else {
        _this.isRegPrice(n);
      }
      const fillIds = [];
      for (
        let j = 0;
        j < _this.fillGridOptions.params.selectedRowKeys.length;
        j++
      ) {
        fillIds.push(_this.fillGridOptions.params.selectedRowKeys[j].fillId);
      }
      const dto = {
        fillIds: fillIds,
        formId: _this.formId,
        orderPrice: _this.editPayPrice * 100,
      };
      orderPriceEdit(dto).then((response) => {
        if (response.code == 200) {
          _this.msgSuccess("修改成功");
          _this.editPayPrice = null;
          _this.editPayPriceOpen = false;
          if (_this.$refs.xGrid) {
            _this.$refs.xGrid.clearFilter();
            _this.$refs.xGrid.clearSelected();
            _this.$refs.xGrid.clearCheckboxRow();
          }
          // _this.selectionDataRows = []
          // _this.fillGridOptions.columns = []
          _this.fillGridOptions.data = [];
          this.$emit("reloadTable");
          // _this.fillGridOptions.params.selectedRowKeys.splice(0);
        } else {
        }
      });
    },
    // 表单开通支付，确认修改支付状态
    onConfirmPayStatusClick() {
      const _this = this;
      if (_this.isBlank(_this.confirmPayStatus)) {
        this.$message({
          type: "warning",
          message: "请选择支付状态～",
        });
        return;
      }

      const inconformityArr = [];
      // console.log('支付状态', _this.confirmPayStatus)
      if (_this.confirmPayStatus == 2) {
        // 选择的未支付的状态
        for (
          let i = 0;
          i < _this.fillGridOptions.params.selectedRowKeys.length;
          i++
        ) {
          if (
            _this.fillGridOptions.params.selectedRowKeys[i].payStatus == 2 ||
            _this.fillGridOptions.params.selectedRowKeys[i].payStatus == 3
          ) {
            inconformityArr.push(
              _this.fillGridOptions.params.selectedRowKeys[i]
            );
          }
        }
        if (inconformityArr.length > 0) {
          _this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“未支付”，修改失败～",
          });
          return;
        }
      }
      if (_this.confirmPayStatus == 4) {
        // 选择的确认到款的状态
        for (
          let i = 0;
          i < _this.fillGridOptions.params.selectedRowKeys.length;
          i++
        ) {
          if (
            _this.fillGridOptions.params.selectedRowKeys[i].payStatus == 3 ||
            _this.fillGridOptions.params.selectedRowKeys[i].payStatus == 4
          ) {
            inconformityArr.push(
              _this.fillGridOptions.params.selectedRowKeys[i]
            );
          }
        }
        if (inconformityArr.length > 0) {
          _this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
          });
          return;
        }
      }
      const fillIds = [];
      for (
        let j = 0;
        j < _this.fillGridOptions.params.selectedRowKeys.length;
        j++
      ) {
        fillIds.push(_this.fillGridOptions.params.selectedRowKeys[j].fillId);
      }
      const dto = {
        fillIds: fillIds,
        formId: _this.formId,
        payStatus: _this.confirmPayStatus,
        type: 0,
      };
      // console.log('最终修改支付状态的参数', dto)
      payStatusEdit(dto).then((response) => {
        if (response.code == 200) {
          _this.msgSuccess("修改成功");
          _this.confirmPayStatus = null;
          _this.confirmPayStatusOpen = false;
          this.$emit("reloadTable");
          this.$emit("resetTable");

          _this.fillGridOptions.data = [];
        }
      });
    },
    isRegPrice(total) {
      const dot = String(total).indexOf(".");
      if (dot != -1) {
        const dotCnt = String(total).substring(dot + 1, total.length);
        if (dotCnt.length > 2) {
          total = total.toFixed(2);
          this.$message({
            type: "warning",
            message: "支付金额只保留两位小数哦～",
          });
          this.editPayPrice = total;
        }
      }
    },
    // 表单开通支付，管理员修改支付金额
    onHandleEditPayPriceClick() {
      const _this = this;
      if (_this.isBlank(this.fillGridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      // console.log('选择的数据', _this.selectedDataRowKeys, _this.selectionDataRows)
      _this.editPayPriceOpen = true;
    },
    // 表单开通支付，管理员关闭修改支付状态
    onCloseEditPayStatusClick() {
      this.confirmPayStatus = null;
      this.confirmPayStatusOpen = false;
      if (this.$refs.xGrid) {
        this.$refs.xGrid.clearFilter();
        this.$refs.xGrid.clearSelected();
        this.$refs.xGrid.clearCheckboxRow();
      }
      this.selectedDataRowKeys = [];
      this.selectionDataRows = [];
    },
    onShowBatchUpdate() {
      this.batchUpdateDataForExcel = true;
    },

    onBatchUpdateDataForExcel() {
      const _this = this;
      if (_this.isBlank(_this.otherFillFile)) {
        _this.$message({
          message: "请上传文件！",
          type: "warning",
        });
        return;
      }
      othersExcelImportUpdate(_this.otherFillFile, _this.formId).then((res) => {
        // console.log('成功操作')
        _this.msgSuccess("导入成功");
        _this.batchUpdateDataForExcel = false;
        _this.otherFillFile = null;
        if (_this.$refs.notSelfFillUpload) {
          _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
        }
        _this.cur = 5;

        if (res.data) {
          _this.notSelfFillFailOpen = true;
          _this.notSelfFillFailUrl = res.data;
        }
        // _this.gridOptions.columns = []
        _this.gridOptions.data = [];
        _this.getDataList();
      });
    },
  },
};
