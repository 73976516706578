import { getSessionStorageObj } from "@/utils/db";

import { doStatisticsRefresh } from "@/api/system/statistics";

const statisticApi = {
  福建师范大学统计更新: {
    "/fill/fjnu/refresh/1": ["1742002607108390948", "1741407256143986688"],
    "/fill/fjnu/refresh/2": ["1742002607108390953", "1741411242196598784"],
    "/fill/fjnu/refresh/3": ["1742002607108390958", "1741416163151183872"],
    "/fill/fjnu/refresh/4": ["1742002607108390957", "1741416014618296320"],
    "/fill/fjnu/refresh/5": ["1742002607108390963", "1741665510455508992"],
    "/fill/fjnu/refresh/0": ["1742804348674506752", "1742727962282688512"],
  },
  北方工业大学的大创项目管理: {
    "/fill/ncut/project/management/": [
      "1764522988599771158", // 测试
      "1732300847196405760", // 正式
    ],
    "/fill/ncut/jxjs/management/":[
      "1767112052305821696", // 正式
      "1767182892858867734" //测试
    ]
  },
  山东英才大创项目管理: {
    "/fill/sdyc/project/management": [
      "1765351198572937224", // 测试
      "1760664588493258762", // 正式
    ],
  },
  塔里木大学:{
    "/fill/taru/review/statistics": [
      "1769887099860811776", // 正式
      "1769893761153761280" //测试
    ]
  },
  西华大学:{
    "/fill/xhu/lwps/1": [
      "1798614417177313280"
    ],
    "/fill/xhu/lwps/2": [
      "1801136180847181824"
    ]
  }
};

export function refreshStatisticUrl(detail) {

  var isExist = false;
  var url = "";
  Object.keys(statisticApi).forEach((x) => {
    if(isExist) return;
    Object.keys(statisticApi[x]).forEach((y) => {
      let tmp = statisticApi[x][y].find((z) => z == detail.formId);
      if(!!tmp){
        url = y;
      }
      isExist = isExist || !!url;
    });
  });
  return url;
}

export function showStatisticRefreshButton(detail) {
  const runtime = process.env.NODE_ENV;

  var isExist = false;
  var url = "";
  Object.keys(statisticApi).forEach((x) => {
    if(isExist) return;
    Object.keys(statisticApi[x]).forEach((y) => {
      url = statisticApi[x][y].find((z) => z == detail.formId);
      isExist = isExist || !!url;
    });
  });
  return isExist;

  // const obj = {
  //   "1742002607108390948": 1,
  //   "1742002607108390953": 2,
  //   "1742002607108390958": 3,
  //   "1742002607108390957": 4,
  //   "1742002607108390963": 5,
  //   "1742804348674506752": 0,

  //   "1741407256143986688": 1,
  //   "1741411242196598784": 2,
  //   "1741416163151183872": 3,
  //   "1741416014618296320": 4,
  //   "1741665510455508992": 5,
  //   "1742727962282688512": 0,
  // };

  // const userInfo = getSessionStorageObj('users');
  // // if(userInfo.userId !== 53283 && userInfo.userId !== 315){
  // //   return -1;
  // // }

  // if(obj[detail.formId]==undefined||obj[detail.formId]==null){
  //   return -1;
  // }
  // return obj[detail.formId];
}

// 显示北方工业大学的大创项目管理--项目综合管理数据刷新
export function showStatisticRefreshButton_dachuang(detail) {
  return (
    detail.formId == "1764522988599771158" ||
    detail.formId === "1732300847196405760"
  );

  // 测试：1764522988599771158
  // 正式：1732300847196405760
}

// 显示山东英才大创项目管理
export function showStatisticRefreshButton_dachuang1(detail) {
  return (
    detail.formId == "1765351198572937224" ||
    detail.formId === "1760664588493258762"
  );

  // 测试：1765351198572937224
  // 正式：1760664588493258762
}


// 上海视觉临时增加的按钮
export function showStatusButtonShangHaiShiJue(detail){
  console.log('detail', detail)
  return detail.formId === '1790185975100473344'
}
export function showShuJuShuaXinShangHaiShiJue(detail){
  return detail.formId === '1790185975100473344'
}