<template>
  <div>
    <!-- 搜索 -->
    <div class="dropdown-search">
      <el-input placeholder="筛选名称" size="mini" prefix-icon="el-icon-search" v-model="searchField" :clearable="true" class="input-with-select"></el-input>
    </div>
    <div class="column-table" v-if="checkKeys.length>0">
      <div class="column-table-title"></div>
      <div class="column-checkbox" style="margin-left: -15px" v-for="(ckb,ckbIdx) in checkKeys" :key="ckbIdx">
        <!-- <el-checkbox v-model="checkedAll[ckb]" @change="checkkAll(ckb)"> -->
        可编辑
        <!-- </el-checkbox> -->
      </div>
    </div>
    <draggable v-model="widgetList" ghostClass='ghost' group="field" handler=".drag-handler" :animation="500" class="column-item" :disabled="!sortable" @end="updateWidgetList">
      <transition-group>
        <li v-for="(item, index) in filteredWidgetList" :key="index" v-show="item.disabled!=true" class="dropdown-item" :class1="{'draggable': sortable}">
          <div style="display: flex; align-items: center;">
            <i v-if="sortable" class="el-icon-rank drag-handler"></i>
            <el-switch :value="!item.hide" :disabled="item.disabled" :active-value="true" :inactive-value="false" active-color="#3476F0" inactive-color="#DCDFE6" @change="(value)=>onSwitchChange(item,value)"></el-switch>
            <div class="display-name" v-if="item.type === 'sysField'">
              <el-badge value="系" class="item" type="primary" style="font-size: 10px;">
                <div style="font-size: 13px;">{{ item.headerName }}</div>
              </el-badge>
            </div>
            <div v-else class="display-name" @click="toggleExpand(item)">{{ item.headerName }}</div>
            <template v-if="item.field.indexOf('statictext')==-1 && item.field.indexOf('htmltext')==-1 &&item.field.indexOf('divider')==-1">
              <div class="column-checkbox" v-for="(ckb,ckbIdx) in checkKeys" :key="ckbIdx">
                <el-checkbox :value="item[ckb]" @change="(val)=>onCheckboxChange(ckb,item,val)"></el-checkbox>
              </div>
            </template>
            <div v-if="item.children">
              <i class="el-icon-arrow-down" v-if="!isExpand(item)" @click="toggleExpand(item)"></i>
              <i class="el-icon-arrow-up" v-if="isExpand(item)" @click="toggleExpand(item)"></i>
            </div>
          </div>
          <template v-if="item.children">
            <!-- <draggable v-model="item.children" group="children" ghostClass='ghost' handler=".drag-sub-handler" :animation="500" :disabled="!sortable" @end="updateWidgetList"> -->
              <!-- <el-collapse-transition> -->
                <div style="margin:10px 0px;" v-if="isExpand(item)">
                  <div v-for="(child,childIndex) in item.children" :key="index+'-'+childIndex" class="dropdown-item" style="padding-left:80px;">
                    <!-- <i class="el-icon-rank drag-sub-handler"></i> -->
                    <el-switch :value="!child.hide" :disabled="!!item.hide || child.disabled" :active-value="true" :inactive-value="false" active-color="#3476F0" inactive-color="#DCDFE6" @change="(value)=>onSwitchChange(child,value)"></el-switch>
                    <div class="display-name">{{ child.headerName }}</div>
                  </div>
                </div>
              <!-- </el-collapse-transition> -->
            <!-- </draggable> -->
          </template>
        </li>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { deepClone } from "@/utils";
export default {
  components: {
    draggable,
  },
  props: {
    formWidgetList: {
      type: Array,
      required: true,
    },
    sortable: {
      type: true,
      default: false
    },
    checkKeys: {
      type: Array,
      default: () => []
    },
    checkLabels: {
      type: Array,
      default: () => []
    },
    checkKeyDefault: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredWidgetList: function () {
      return this.widgetList.filter((item) => {
        return !this.searchField || item.headerName.toLowerCase().includes(this.searchField.toLowerCase());
      });
    }
  },
  data () {
    return {
      widgetList: [],
      searchField: "",
      checkedAll: {},
      expandChild: {

      }
    };
  },
  methods: {
    onSwitchChange (item, value) {
      item.hide = !value
      if (!value) {
        this.checkKeys.forEach(key => {
          this.$set(item, key, false)
        })
      }
      if(item.hide && item.children){
        item.children.forEach(x=>{
          x.hide= true;
          x.disabled= true;
        })
      }else if(!item.hide && item.children){
          if(item.children.every(x=>x.hide==true)){
            item.children.forEach(x=>{
              x.hide= false;
              x.disabled = false;
            })
          }
      }
      this.updateWidgetList(item);
    },
    onCheckboxChange (checkKey, item, value) {
      this.$set(item, checkKey, value)
      this.updateWidgetList(item);
    },
    selectFieldCount () {
      return this.formWidgetList.filter((item) => item.selected).length;
    },
    dragWidgetItemEnd (event) {
    },
    updateWidgetList (item) {
      this.$emit("update:formWidgetList", this.widgetList);
      this.$emit("change", item);
    },
    checkkAll (ckb) {
      this.filteredWidgetList.forEach(item => {
        this.$set(item, ckb, this.checkedAll[ckb]);
      });
    },
    isExpand (item) {
      return !!this.expandChild[item.field]
    },
    toggleExpand (item) {
      this.$set(this.expandChild, item.field, !this.isExpand(item))
    }
  },
  watch: {
    formWidgetList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.widgetList = deepClone(val);
        //按照checkKeys，自动给widgetList 赋默认值，需保持checkKeys 和checkKeyDefault 相对应
        if (this.checkKeyDefault.length != this.checkKeys.length) {
          console.warn("columnList：checkKeys 和checkKeyDefault不一致，未能自动赋默认值");
          return;
        }
        // for (let i = 0; i < this.checkKeyDefault.length; i++) {
        //   if (this.checkKeyDefault[i]) {
        //     let checkAll = true;
        //     this.widgetList.forEach(item => {
        //       if(item[this.checkKeys[i]]===undefined){
        //         this.$set(item, this.checkKeys[i], this.checkKeyDefault[i]);
        //       } else if (item[this.checkKeys[i]]===false){
        //         checkAll = checkAll && false;
        //       }
        //     })            
        //     if(checkAll){
        //       // this.checkedAll[this.checkKeys[i]] = true;
        //     }
        //   }
        // }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-search {
  padding: 10px;
}
.dropdown-item {
  list-style: none;
  padding: 5px 10px;
}

.dropdown-item.draggable {
  cursor: move;
}

.display-name {
  display: inline-block;
  width: 200px;
  margin-left: 20px;
}

.column-item {
  margin-bottom: 10px;
  .ghost {
    background-color: rgb(52, 118, 240);
  }
  .drag-handler,
  .drag-sub-handler {
    cursor: move;
    margin-right: 10px;
  }
}

.column-table {
  width: 100%;
  display: inline-flex;
  &-title {
    width: 270px;
    display: inline-block;
  }
}
.column-checkbox {
  width: 90px;
  display: inline-block;
}
.el-icon-arrow-down,
.el-icon-arrow-up {
  cursor: pointer;
}
</style>
