<template>
  <div class="forms-content view-info">
    <detail ref="formDetail" :standalone="false" :reloadProjectList="()=>{}"></detail>
    <div class="form-detail-top-part" v-if="false">
      <el-row :gutter="20" class="el-row" type="flex" style="margin: 0px 0px 10px">
        <el-col :span="7" :xs="24" style="padding: 0; width: 200px; height: 120px;">
          <div class="form-img">
            <img :src="formDetail.cover +
                    '?x-oss-process=image/resize,m_fixed,h_150,w_300'
                    " v-if="formDetail && formDetail.cover" />
          </div>
        </el-col>
        <el-col :span="19" :xs="24" style="padding: 0; margin-left: 20px; position: relative; width:100%;">
          <div class="form-info">
            <h1 class="clearfix form-title">
              <div class="form-title-left">
                <span style="overflow: hidden; text-overflow: ellipsis;white-space: nowrap;">{{ formDetail.name
                      }}</span>
                <span style="width: 60px;  margin-left: 20px">
                  <el-tag v-if="!formDetail.isStop" size="mini" class="form-state-tag stop" type="info">已暂停</el-tag>
                  <el-tag v-else size="mini" class="form-state-tag" :class="getTagClass(formDetail.formStateTxt)">{{
                          formDetail.formStateTxt }}</el-tag>
                </span>
              </div>
              <div class="form-share-warp">
                <div class="btn-wrapper" @click="onCollectClick">
                  <el-button size="mini" class="collect-btn btn" :class="{ active: formDetail.favorite }">
                    <svg-icon class="btn-icon" :icon-class="formDetail.favorite
                            ? 'formListCollect'
                            : 'formListUnCollect'
                            " />
                    <span>收藏</span>
                  </el-button>
                </div>
                <div class="btn-wrapper" @click="onShareClick">
                  <el-button size="mini" class="btn">
                    <svg-icon class="btn-icon" icon-class="formListShare" />
                    <span>分享</span>
                  </el-button>
                </div>
              </div>
            </h1>
            <div class="form-detail-top-bottom">
              <div class="clearfix form-date" style="position:relative">
                <p class="fill-date">
                  <span style="width: 75px; display: inline-block;">开始时间：</span>
                  <span>{{
                          formDetail.fillStartTime
                          ? formDetail.fillStartTime
                          : "——"
                        }}</span>
                </p>
                <div style="position:absolute;right:0px;top:10px;">
                  <formTagEditor v-model="formDetail.tagList" :readonly="true"></formTagEditor>
                </div>
              </div>
              <div class="clearfix form-date">
                <p class="fill-date">
                  <span style="width: 75px; display: inline-block;">截止时间：</span>
                  <span>{{
                          formDetail.fillEndTime ? formDetail.fillEndTime : "——"
                        }}</span>
                </p>
              </div>
              <div class="clearfix form-date">
                <div class="fill-date">
                  <span style="width: 75px; display: inline-block;">填报人数：</span>
                  <span>{{
                          formDetail.fillNum != null
                          ? formDetail.fillNum + "人可填"
                          : "填报人数无限制"
                        }}</span>
                  <span v-if="formDetail.fillNumPublic">,</span>
                  <span v-if="formDetail.fillNumPublic">
                    {{
                            formDetail.completedFillNum != null
                            ? formDetail.completedFillNum + "人已填"
                            : ""
                          }}
                  </span>
                </div>
              </div>
              <div class="clearfix form-date">
                <div class="fill-date">
                  <span style=" width: 75px; display: inline-block;">创建机构：</span>
                  <span>{{
                          formDetail.founder
                          ? formDetail.founder
                          : formDetail.createName
                        }}</span>
                  <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="formDetail.agencyId" />
                  <!--                          <span v-else>（个人）</span>-->
                </div>
              </div>
              <div class="form-fill-btn">
                <el-button type="primary" size="medium" @click="onFillClickCheck" :disabled="disableFill" class="btn">{{ formDetail.startFillText || "开始填报" }}</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="el-row" type="flex" style="margin-left: 0; margin-right: 0;height: 30px; padding: 0; display: flex; display: -webkit-flex; align-items: center;justify-content:space-between;">
        <el-col :span="8" :xs="24" type="flex" style="padding: 0; width: 200px;" class="form-num-wrap">
          <div class="form-total-num">
            <svg-icon icon-class="formDetailSeeNum" style="margin-right: 5px;font-size: 14px;" />
            <span>{{
                    formDetail.viewNum && formDetail.viewNum > 10000
                    ? formDetail.viewNum / 10000 + "W+"
                    : formDetail.viewNum == 10000
                      ? formDetail.viewNum / 10000
                      : formDetail.viewNum
                  }}</span>
          </div>
          <div class="form-total-num">
            <svg-icon icon-class="formDetailShareNum" style="margin-right: 5px;font-size: 14px;" />
            <span>{{
                    formDetail.shareNum && formDetail.shareNum > 10000
                    ? formDetail.shareNum / 10000 + "W+"
                    : formDetail.shareNum == 10000
                      ? formDetail.shareNum / 10000
                      : formDetail.shareNum
                  }}</span>
          </div>
          <div class="form-total-num">
            <svg-icon icon-class="formDetailCollectNum" style="margin-right: 5px;font-size: 14px;" />
            <span>{{
                    formDetail.favoriteNum && formDetail.favoriteNum > 10000
                    ? formDetail.favoriteNum / 10000 + "W+"
                    : formDetail.favoriteNum == 10000
                      ? formDetail.favoriteNum / 10000
                      : formDetail.favoriteNum
                  }}</span>
          </div>
        </el-col>
        <el-col :span="8" :xs="14" style="padding: 0px 0px 0px 20px; position: relative; box-sizing: border-box;flex-grow:1;width:auto;">
        </el-col>
        <el-col :span="10" :xs="10" style="padding: 0px 0px 0px 20px; position: relative; box-sizing: border-box;flex-grow:0;flex-shrink:0;text-align:right;">
          <editLinks :formDetail.sync="formDetail" :loginUserId="loginUserId" :loginAgencyUserId="loginAgencyUserId" formType="form" @refreshList="refreshList" @formStopUpdate="handleFormStopUpdate" @home="returnProjectHome" :returnType="standalone ? 'back' : 'refresh'"></editLinks>
        </el-col>
      </el-row>
    </div>
    <div class="form-info-tab-bottom">
      <div class="tab-content-nav" :style="[
              {
                display:
                  formDetail.details == '' && formCustomFileList.length == 0
                    ? 'none'
                    : 'block'
              }
            ]">
        <div class="content-nav-title" @click="onFormInfoTabClick(1)" :class="{ active: formInfoCur === 1 }" v-if="formDetail && formDetail.details != ''">
          <svg-icon icon-class="formDetailInfo" />{{ formCustomDetails }}
        </div>
        <div class="content-nav-title" @click="onFormInfoTabClick(2)" :class="{ active: formInfoCur === 2 }" v-if="formCustomFileList && formCustomFileList.length > 0">
          <svg-icon icon-class="formDetailFile" />{{ formCustomFile }}
        </div>
      </div>
      <div class="info-tab-content" v-if="formInfoCur === 1">

        <div class="ql-editor details-intro" v-html="formDetail.details"></div>
      </div>
      <div class="info-tab-content" v-if="formInfoCur === 2">
        <Download :fileList="formCustomFileList"></Download>
        <!-- <div class="details-file">
          <div class="file-wrapper" v-for="item in formCustomFileList" @click="onDownloadFormFileClick(item)" :key="item">
            <svg-icon v-if="item.suffixType === 'image'" icon-class="annex_image" style="width: 22px; height: 22px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'word'" icon-class="annex_word" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'excel'" icon-class="annex_excel" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'pdf'" icon-class="annex_pdf" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'audio'" icon-class="annex_audio" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'video'" icon-class="annex_video" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'zip'" icon-class="annex_zip" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'ppt'" icon-class="annex_ppt" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon v-if="item.suffixType === 'other'" icon-class="annex_other" style="width: 18px; height: 18px;vertical-align: middle;" />
            <svg-icon class="down-icon" icon-class="download2" />
            <span>{{ item.name }}</span>
          </div>
        </div> -->
      </div>
    </div>

    <!--页面详情分享-->
    <el-dialog title="填报地址" :visible.sync="shareOpen" width="1000px" append-to-body @close="handleFillDiaClose">
      <div class="share-container">
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">
          链接分享
        </h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">
          将填报地址粘贴到浏览器访问，或者直接发给填报者
        </p>
        <div class="" style="width: 100%;height: auto; ">
          <el-input type="text" :value="shareFillUrl" readonly class="fl" style="width: 60%; margin-right: 40px"></el-input>
          <p>
            <el-button type="primary" @click="onCopyFillClick">复制链接</el-button>
            <el-button type="primary" style="margin-left: 20px;" @click="onToClick">直接打开</el-button>
          </p>
        </div>
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">
          小程序码分享
        </h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">
          选择小程序码样式，保存小程序码直接发给填报者
        </p>
        <img :src="shareImageUrl" class="list-share-image" />
        <div style=" width: 398px; margin: auto; text-align: center;">
          <el-button type="primary" @click="onSaveClick" style="margin: 20px auto 0;">保存小程序码</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import detail from '../detail.vue'

import { favorite, userBrowseRecord } from "@/api/system/user";

import Download from '@/components/FileDownload/download.vue'
import {
  batchReviewFill,
  checkFill,
  editFill,
  confirmFill,
  fill,
  fillReviewList,
  fillDownloadExcel,
  fillDownloadOthersExcel,
  formFillExcelImport,
  formFillExcelOtherImport,
  fillDataDetail,
  fillDataDownload,
  fillDataZipDownHttp,
  fillDataZipDownList,
  fillDetail,
  fillList,
  historyFillList,
  formAuth,
  formDetail, //表单配置数据接口
  formPayInfo, //表单配置的支付信息接口
  formFieldList,
  customFormFieldList,
  formFilesList,
  dataFillStatus,
  formReserves,
  fillReserveTimes,
  review,
  reviewList,
  fillDel,
  fieldList,
  orderCreat,
  orderStatus,
  aliDelete,
  formStatics,
  formFieldStatics,
  formPicSelFieldStatics,
  httpShareNum,
  exportFillWord,
  exportFillPdf,
  fillDataDownloadFormPicDownload,
  fillDataDownloadFormPicDownList,
  fillDataDownloadFormWordDownList,
  fillDataDownloadFormWordDownload,
  shareImg,
  noticeSend,
  aliUpload,
  deleleteFile,
  orderPriceEdit,
  payStatusEdit,
  getCustomTip,
  httpGetFormFieldV2,
  shortLink,
  httpViewNum,
  getFormFieldAuth
} from "@/api/system/form";

import editLinks from "../components/editLinks.vue";
import formTagEditor from "../components/formTag/editor.vue";
import { debounce } from 'xe-utils';
import { getSessionStorageObj } from "@/utils/db";
export default {
  props: {
    formDetail: {
      type: Object,
      default: () => { }
    },
    formJson: {
      type: Object,
      default: () => { }
    },
    //是独立页面还是嵌入页面（表单查看是独立的，应用内表单是嵌入的）
    standalone: {
      type: Boolean,
      default: true,
    },
    formAuth: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    formId: function () {
      return this.formDetail.formId
    },

    disableFill: function () {
      return (
        !this.formDetail.isStop ||
        (this.formAuth.currentInsertFillAuth ? false : true)
      );
    },
    // 有支付的表单
    formPay: function () {
      return this.formDetail.pay;
    },
  },
  components: {
    editLinks,
    detail,
    formTagEditor,
    Download
  },
  data () {
    return {
      optionData: {},
      fillId: '',
      cur: 1,
      formInfoCur: 1,
      formCustomFileList: [],
      formCustomDetails: "",
      shareFillUrl: '',
      shareImageUrl: '',
      shareOpen: false,
      shareItemInfo: {},
      shortLink: '',
      favorite: '',
      loginUserId: null,
      formCustomFileList: [],
      loginAgencyUserId: [],
      formCustomDetails: {}
    };
  },
  mounted () {
    this.loginUserId = getSessionStorageObj("users").userId;
    this.loginAgencyUserId = (
      getSessionStorageObj("users").agencyUserId || ""
    ).split(",");


    this.$nextTick(() => {
    });
  },
  methods: {
    getCustomFormFieldList () {
      const _this = this;
      if (this.formId) {
        customFormFieldList(_this.formId).then((response) => {
          if (response.code == 200) {
            _this.formCustomDetails = !_this.isBlank(response.data.details)
              ? response.data.details
              : "表单介绍";
            _this.formCustomFile = !_this.isBlank(response.data.file)
              ? response.data.file
              : "表单附件";
          }
        });
      }
    },
    getFormFiles () {
      const _this = this;
      formFilesList(_this.formId).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            const suffix = _this.getSuffix(response.data[i].name);
            let suffixType = null;
            if (
              suffix == "jpeg" ||
              suffix == "JPEG" ||
              suffix == "jpg" ||
              suffix == "JPG" ||
              suffix == "png" ||
              suffix == "PNG"
            ) {
              suffixType = "image";
            } else if (
              suffix == "doc" ||
              suffix == "DOC" ||
              suffix == "docx" ||
              suffix == "DOCX"
            ) {
              suffixType = "word";
            } else if (
              suffix == "exl" ||
              suffix == "EXL" ||
              suffix == "xlsx" ||
              suffix == "XLSX"
            ) {
              suffixType = "excel";
            } else if (suffix == "pdf" || suffix == "PDF") {
              suffixType = "pdf";
            } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
              suffixType = "audio";
            } else if (
              suffix == "mp4" ||
              suffix == "MP4" ||
              suffix == "mov" ||
              suffix == "m4v" ||
              suffix == "mpg" ||
              suffix == "wma" ||
              suffix == "avi" ||
              suffix == "flv" ||
              suffix == "mkv" ||
              suffix == "rm" ||
              suffix == "rmvb"
            ) {
              suffixType = "video";
            } else if (
              suffix == "zip" ||
              suffix == "ZIP" ||
              suffix == "rar" ||
              suffix == "RAR"
            ) {
              suffixType = "zip";
            } else if (
              suffix == "ppt" ||
              suffix == "PPT" ||
              suffix == "pptx" ||
              suffix == "PPTX"
            ) {
              suffixType = "ppt";
            } else {
              suffixType = "other";
            }
            response.data[i].suffixType = suffixType;
          }
          _this.formCustomFileList = response.data;
          if (_this.formDetail && _this.formDetail.details != "") {
            _this.formInfoCur = 1;
          } else {
            if (
              _this.formCustomFileList &&
              _this.formCustomFileList.length > 0
            ) {
              _this.formInfoCur = 2;
            }
          }
        }
      });
    },
    getTagClass (text) {
      switch (text) {
        case "进行中":
          return "ing";
        case "已结束":
          return "stop";
        default:
          return "un-start";
      }
    },
    async getFormPayInfo (callback) {
      if (!this.formDetail.pay) {
        return {};
      }
      return formPayInfo(this.formId);
    },
    getSuffix (name) {
      return name.split(".").pop().toLowerCase();
    },
    // 收藏or取消收藏
    onCollectClick () {
      const _this = this;
      let operation = "";
      const param = {};
      if (
        !_this.isBlank(this.formDetail.favorite) &&
        _this.formDetail.favorite
      ) {
        // 如果是已收藏
        operation = "cancel";
      } else {
        operation = "favorite";
      }
      param.operation = operation;
      param.type = "form";
      param.typeId = _this.formId;
      favorite(param).then((response) => {
        if (response.code == 200) {
          const msg =
            !_this.isBlank(this.formDetail.favorite) &&
              _this.formDetail.favorite
              ? "取消收藏"
              : "收藏成功";
          _this.msgSuccess(msg);
          _this.formDetail.favorite = !_this.formDetail.favorite;
        }
      });
    },
    // 点击分享弹框
    async onShareClick () {
      const self = this;
      if (self.formDetail.status == "1") {
        this.$message({
          message: "未发布的表单暂不能分享～",
          type: "warning",
        });
        return;
      }
      self.getShareNum();
      const locationUrl = window.location.origin;
      const item = this.formDetail;
      const id = this.formId;
      const shareType = "form";
      const type = "form";
      self.shareFillUrl =
        locationUrl +
        "/share/" +
        self.encode(item.formId) +
        "?type=" +
        self.encode(type);

      shortLink(self.shareFillUrl).then((res) => {
        this.shareFillUrl = res.data;
      });
      self.shareItemInfo = item;
      self.getShareImage(id, shareType);
    },
    // 新版---生成分享图片
    getShareImage (id, type) {
      const _this = this;
      shareImg(id, type).then((res) => {
        if (res.code == 200) {
          _this.shareImageUrl = "data:image/jpg;base64," + res.data;
          _this.shareOpen = true;
        }
      });
    },
    // 新版---添加分享量
    async getShareNum () {
      const _this = this;
      const formId = _this.formId;
      httpShareNum(formId).then((response) => { });
    },

    // 详细介绍、附件列表 tab切换
    onFormInfoTabClick (index) {
      this.formInfoCur = index;
    },
    // 获取年月日
    getNowFormatDate () {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentDate = year + seperator1 + month + seperator1 + strDate;
      return currentDate;
    },
    // 页面详情 填报上传图片限制
    onHandleImgExceed () {
      this.$message.warning(`当前限制添加1张图片！`);
    },
    returnProjectHome () {
      this.$emit("multipleFormDelete");
    },
    // 开始填报、继续填报，跳转填报页面
    onFillClickCheck () {
      //开始填报的检查：表单中是是否配置了字段，表单是否需要先同意协议
      if (this.isBlank(this.formJson)) {
        this.$message({
          type: "warning",
          message: "该表单暂无设置表单字段无法填报～",
        });
        return;
      }

      const startFill = () => {
        // FEAT#表单填报 开始新的填报
        // 打开填报弹窗
        this.$refs.dlgvFormViewer.showAdd({
          title: this.formDetail.startFillText,
          formId: this.formId,
          formDetail: this.formDetail,
          formData: {},
          formJson: deepClone(this.formJson),
          formMode: 'new',
        });
      }

      if (this.formAgreementId != null) {
        this.$refs.fillAgreement.show(this.formAgreementId, startFill);
      } else {
        startFill();
      }
      // if (this.formAgreementId != null) {
      //   this.$refs.fillAgreement.show(this.formAgreementId);
      // } else {
      //   this.onFillClick();
      // }
    },
    onFillClick () {
      const _this = this;
      _this.clearFormDetail();
      _this.fillId = null;
      _this.isEditFill = false;

      _this.fillGridOptions.columns = [];
      _this.renderTableColumns(_this.fillGridOptions, true);
      _this.renderPersonalTableHeaderColumns(_this.fillGridOptions);

      checkFill(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.cur = 2;
          // 如果强制启用只读字段
          _this.$refs.vformRender.enableForm();

          _this.$refs.vformRender.enableForm(_this.optionData.isUserAdmin);
          _this.$refs.vformRender.hideHiddenWidgets();
        }
      });
      _this.breadcrumbName = "";
    },
    // 详细介绍、附件列表 tab切换
    onFormInfoTabClick (index) {
      this.formInfoCur = index;
    },
    // 获取年月日
    getNowFormatDate () {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentDate = year + seperator1 + month + seperator1 + strDate;
      return currentDate;
    },
    // 详细介绍、附件列表 tab切换
    onFormInfoTabClick (index) {
      this.formInfoCur = index;
    },
    refreshList () {
      if (this.reloadProjectList) this.reloadProjectList();
    },
    handleFormStopUpdate (isStop) {
      this.formDetail.isStop = isStop;
      this.$forceUpdate();
    },
    // 监听分享弹框关闭
    handleFillDiaClose () {
      this.shareFillUrl = "";
    },// 复制填报地址
    async onCopyFillClick () {
      const oInput = document.createElement("input"); // 创建input节点
      oInput.value = this.shareFillUrl; // 给input的value赋值
      document.body.appendChild(oInput); // 向页面插入input节点
      oInput.select(); // 选中input
      try {
        await document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          type: "success",
          message: "复制成功",
        });
      } catch {
        this.$message({
          type: "success",
          message: "复制失败",
        });
      }
      document.body.removeChild(oInput);
    },
    // 直接打开填报地址页面
    onToClick () {
      const tempWindow = window.open("_blank");
      tempWindow.location.href = this.shareFillUrl;
    },
    // 保存分享小程序码
    onSaveClick () {
      const self = this;
      if (this.isBlank(this.shareItemInfo.shareQr)) {
        this.$message({
          message: "小程序码缺失，暂无法保存",
          type: "warning",
        });
        return;
      }
      this.downloadFile(this.shareItemInfo.name + ".jpg", self.shareImageUrl);
    },
    downloadFile (fileName, content) {
      const aLink = document.createElement("a");
      const blob = this.base64ToBlob(content); // new Blob([content]);

      const evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      ); // 兼容火狐
    },
    // base64转blob
    base64ToBlob (code) {
      const parts = code.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  },
  watch: {
    formDetail: function (val) {
      if (val.formId) {
        this.getCustomFormFieldList()
        this.getFormFiles()
        this.getFormPayInfo()

        this.$nextTick(() => {
          this.$refs.formDetail.init("project", val.formId);
        });

      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 引入detail.vue并覆盖其默认样式
.forms-content.view-info {
  ::v-deep {
    .app-container {
      position: initial;
      margin: initial;
    }
    .form-detail-container .forms-content .form-detail-top-part,
    .form-detail-container .forms-content .form-info-tab-bottom {
      padding: 0px;
      box-shadow: none;
    }
  }
}

.forms-content.view-info {
  max-width: 100%;
  // height: auto;
  margin: 0px auto;
  background: #ffffff;
  padding: 0 10px;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 1588px;

  .form-detail-top-part {
    padding: 20px 20px 15px;
    background: #fff;
    // -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
    // box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
    border-radius: 4px;

    .form-img {
      width: 200px;
      height: 120px;

      img {
        width: 100%;
        max-width: 100%;
        height: 120px;
        border-radius: 6px;
      }
    }

    .form-info {
      width: 100%;
      position: relative;
      padding: 0 0 0 0;
      box-sizing: border-box;
      height: 120px;

      .form-title {
        width: 100%;
        font-size: 16px;
        color: #333333;
        margin: 0px;
        line-height: 22px;
        font-weight: 400;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;

        .form-title-left {
          flex: 1;
          -webkit-flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          display: -webkit-flex;

          .form-state-tag {
            height: 18px;
            line-height: 18px;

            &.un-start {
              color: #3476f0;
              background-color: #ebf1fe;
              border-color: #d6e4fc;
            }

            &.ing {
              background-color: #effaf5;
              border-color: #dff5eb;
              color: #61cd9d;
            }

            &.stop {
              color: #a5abb1;
              background: #888d931a;
              border-color: #888d9380;
            }
          }
        }

        button {
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          align-items: center;
        }

        .form-share-warp {
          width: 170px;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: flex-end;

          .btn-wrapper {
            cursor: pointer;
            display: inline-block;
            margin-left: 8px;

            .btn {
              width: 58px;
              height: 26px;
              font-size: 12px;
              font-weight: 400;
              color: #646d7a;
              line-height: 22px;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }

            .collect-btn {
              color: #646d7a;

              // border-color: #dbdde7;
              &.active {
                color: #ffa500;
                border-color: #ffa500;
              }

              &:hover {
                border-color: rgba(255, 165, 0, 0.8);
                background-color: rgba(255, 165, 0, 0.1);
              }

              .collect-icon {
                margin-right: 8px;
                font-size: 14px;
              }
            }

            .btn-icon {
              margin-right: 4px;
              font-size: 14px;
            }
          }
        }
      }

      .form-detail-top-bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;

        .form-date {
          width: 100%;
          font-size: 12px;
          height: 22px;
          line-height: 22px;
          color: #a5abb1;

          .fill-date {
            margin: 0;
            padding: 0;

            .is-agency-icon {
              display: inline-block;
              width: 40px;
              height: 16px;
              margin-left: 5px;
              vertical-align: -4px;
            }
          }
        }

        .form-fill-btn {
          position: absolute;
          bottom: 8px;
          right: 0;

          .btn {
            width: 150px;
            height: 40px;
            font-size: 16px;
            background: #3476f0;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .formStatus {
          position: absolute;
          right: 0;
          bottom: 60px;
        }

        .form-fill {
          width: 100%;
          font-size: 12px;
          line-height: 22px;
          color: #909090;
          margin: 0 0;
        }

        .form-do {
          width: 100%;
          font-size: 14px;
          margin: 15px 0 0;
          text-align: right;
          display: block;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .form-num-wrap {
      width: 200px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      line-height: 30px;

      .form-total-num {
        font-size: 12px;
        line-height: 16px;
        color: #a5abb1;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex: 1;
        -webkit-flex: 1;
        text-align: center;
        justify-content: center;
      }
    }

    .operate-btn {
      margin-left: 0;
      padding: 7px 15px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #3476f0;
      border-color: #3476f0;
    }
  }

  .form-info-tab-bottom {
    width: 100%;
    background: #fff;
    border-radius: 4px;

    .info-tab-content {
      padding: 20px 0px;
    }

    .tab-content-nav {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #f2f3f4;
      // padding: 0 20px;
      box-sizing: border-box;
      cursor: pointer;

      .content-nav-title {
        color: #646d7a;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 20px;
        display: inline-block;
        position: relative;

        &.active {
          line-height: 48px;
          color: #3476f0;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0;
            left: 0;
            background: #3476f0;
            border-radius: 3px 3px 0 0;
          }
        }

        svg {
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }
  }
}

.details-intro {
  width: 100%;
  font-size: 14px;
  color: #394349;
  word-break: break-all;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.details-file {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .file-wrapper {
    position: relative;
    width: 752px;
    height: 40px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    padding-left: 8px;
    color: #646d7a;
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }

    .down-icon {
      font-size: 16px;
      color: #3476f0;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      display: none;
    }

    &:hover {
      background: rgba(0, 92, 238, 0.07);
    }

    &:hover .down-icon {
      display: block;
    }
  }
}
</style>