
<template>
  <div>
    <div class="express-wrapper" v-for="(item, index) in express.list" :key="index">
      <div class="connector-item" v-if="false && index > 0">
        <div class="express-key"><!-- 占位 --></div>
        <div class="express-operator"></div>
        <div class="express-value"><!-- 占位 --></div>
        <div class="express-button"><!-- 占位 --></div>
      </div>
      <div class="connector-item" v-if="index > 0">
        <el-divider>
          <!-- <el-select  size="mini" v-model="express.connector" placeholder="请选择" style="width: 65px; border: none 0px" v-if="index == 1">
            <el-option v-for="item in connectorOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"> </el-option>
          </el-select>
          <span v-else>{{ connectorOptions.find(x => x.value == express.connector).label }}</span> -->
          且
        </el-divider>
      </div>
      <div class="express-item">
        <div class="express-key">
          <el-select  size="mini" v-model="item.key" placeholder="请选择" @change="bindKeyType(item)">
            <el-option-group v-for="group in expressKeyOptions" :key="group.label" :label="group.label">
              <el-option v-for="(item,kIndex) in group.options" :key="kIndex" :label="item.label" :value="item.value"> </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div class="express-operator">
          <el-select  size="mini" v-model="item.operator" placeholder="请选择" @change="operatorChanged(item)">
            <el-option v-for="item in expressOperatorOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"> </el-option>
          </el-select>
        </div>
        <div class="express-value">
          <!-- 类型选择: 字段/固定值 -->
          <el-select  size="mini" v-model="item.valueType" placeholder="请选择" style="width:100px;flex:0 auto" @change="valueTypeChange(item)">
            <el-option v-for="(item, sIndex) in expressValueTypeOptions" :disabled="item.disabled" :key="sIndex" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- </div>
          <div class="express-value"> -->
          <el-input  size="mini" v-model="item.value" placeholder="" v-if="showFilterType(item) === 'input'"></el-input>
          <el-select  size="mini" v-model="item.value" placeholder="请选择" v-if="showFilterType(item) === 'select(sysfields)'">
            <el-option v-for="(item, sIndex) in expressValueOptions" :disabled="item.disabled" :key="sIndex" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select  size="mini" :value="getArrayValue(item)" placeholder="请选择" :multiple="true" @change="(val)=>setArrayValue(item,val)" v-if="showFilterType(item) === 'select(key-values)'">
            <el-option v-for="(sItem, sIndex) in item.filterOptions" :key="sIndex" :label="sItem.label" :value="sItem.value"> </el-option>
          </el-select>
          <el-select size="mini" :value="getArrayValue(item)" placeholder="请选择" :multiple="true" @change="(val)=>setArrayValue(item,val)" v-if="showFilterType(item) === 'select(status)'">
            <el-option v-for="x in expressKeyStatusOptionItems" :key="x.value" :label="x.label" :value="x.value"> </el-option>
          </el-select>

          
          <!-- 时间范围选择 -->
          <el-date-picker  size="mini" :value="getArrayValue(item)" style="width:240px" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @input="(val)=>setArrayValue(item,val)" v-if="showFilterType(item) === 'datetime'"></el-date-picker>
          <!-- {{showFilterType(item)}} -->
        </div>
        <div class="express-button">
          <el-button icon="el-icon-delete" type="text" style="font-size: 16px" @click="deleteExpress(index)"></el-button>
        </div>
      </div>
    </div>

    <div>
      <el-button type="text" size="mini" icon="el-icon-plus" @click="addExpression">添加筛选条件</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewFilter',
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    formJson: {
      type: Object,
      default: () => { },
    }
  },
  data () {
    return {
      express: {
        list: [],
        connector: ''
      },
      connectorOptions: [
        { label: '且', value: 'AND' },
        { label: '或', value: 'OR' },
      ],
      expressKeyOptions: [
        {
          label: '系统字段',
          options: [
            {
              value: 'name',
              label: '填报人',
            }, {
              value: 'audit',
              label: '待审核人',
            }, {
              value: 'auditOperator',
              label: '实际审核人',
            }, {
              value: 'auditors_1',
              label: '一级审核人(待审核或已审核)',
            }, {
              value: 'auditors_2',
              label: '二级审核人(待审核或已审核)',
            }, {
              value: 'auditors_3',
              label: '三级审核人(待审核或已审核)',
            }, {
              value: 'viewAuthAgencyUserId',
              label: '数据管理员',
            },
            {
              value: 'createTime',
              label: '填报时间',
            },
            {
              value: 'status',
              label: '审核状态',
            },
          ],
        },
        {
          label: '表单字段',
          options: [],
        },
      ],
      expressOperatorOptions: [
        { label: '等于', value: 'is' },
        { label: '不等于', value: 'ne' },
        { label: '包含', value: 'in' }
      ],
      expressKeyStatusOptionItems: [
        {
          "value": "-1",
          "label": "待当前用户审核"
        },
        {
          "value": "1",
          "label": "无需审核"
        },
        {
          "value": "2",
          "label": "待一级审核"
        },
        // {
        //   "value": "3",
        //   "label": "审核通过"
        // },
        {
          "value": "4",
          "label": "一级审核不通过"
        },
        {
          "value": "5",
          "label": "一级审核返回修改"
        },


        {
          "value": "6",
          "label": "待二级审核"
        },
        // {
        //   "value": "7",
        //   "label": "二级审核通过"
        // },
        {
          "value": "8",
          "label": "二级审核不通过"
        },
        {
          "value": "9",
          "label": "二级审核返回修改"
        },



        {
          "value": "10",
          "label": "待三级审核"
        },
        {
          "value": "12",
          "label": "三级审核不通过"
        },
        {
          "value": "13",
          "label": "三级审核返回修改"
        },
        {
          "value": "14",
          "label": "审核完成"
        }
      ],
      expressValueOptions: [
        {
          value: 'userId',
          label: '当前用户(用户Id)',
          disabled: false,
        },
        {
          value: 'agencyUserId',
          label: '当前用户(机构用户Id)',
          disabled: false,
        },
        {
          value: 'userDeptId1',
          label: '当前用户一级部门',
          disabled: false,
        },
        {
          value: 'userDeptId2',
          label: '当前用户二级部门',
          disabled: false,
        },
        {
          value: 'userDeptId3',
          label: '当前用户三级部门',
          disabled: false,
        },
        {
          value: 'userDeptId4',
          label: '当前用户四级部门',
          disabled: false,
        },
        {
          value: 'userDeptId5',
          label: '当前用户五级部门',
          disabled: false,
        },
        // {
        //   value: 'userRoleId',
        //   label: '当前用户角色',
        // },
      ],
      expressValueTypeOptions: [
        {
          value: 'sysField',
          label: '系统值'
        },
        {
          value: 'fixedValue',
          label: '固定值'
        },
      ],
      expressKeyWidggetOptionItems: []
    }
  },
  methods: {
    addExpression () {
      if (this.express.list.length == 1) this.express.connector = 'AND';
      this.express.list.push({
        key: '',
        operator: '',
        type: '',
        value: '',
      });
    },
    deleteExpress (i) {
      this.express.list.splice(i, 1);
    },
    showFilterType (item) {
      // 关联表的字段
      if (item.key === 'name') {
        if (item.valueType === 'sysField') {
          return 'select(sysfields)'
        } else {
          return 'input'
        }
      } else if (item.key === 'status') {
        return 'select(status)'
      } else if (item.key === 'createTime') {
        return 'datetime'
      } else {
        if (item.valueType === 'sysField') {
          return 'select(sysfields)';
        } else {
          if (item.widgetType && ["radio", 'select', 'checkbox'].find(x => x === item.widgetType)) {
            return 'select(key-values)';
          } else {
            return 'input'
          }
        }
      }
    },
    showFilterSelect () {
      let exclude = ['rich-editor', 'picture-select', 'cascader', 'sub-form', 'sign', 'fill-select', 'picture-upload', 'file-upload', 'matrix-form'];

      //设置列表的字段
      let opt = this.expressKeyOptions.find(x => x.label == '表单字段');
      opt.options = [];
      this.formJson.fieldList
        .filter(x => !exclude.find(y => y == x.type))
        .forEach(x => {
          opt.options.push({
            label: x.displayName,
            // value: (x.type == 'select' || x.type == 'radio' || x.type == 'checkbox' || x.type == 'cascader') ? x.name + '__label' : x.name,
            value: x.name,
            type: x.type,
          });
        });

      this.isShowFilterSelect = true;
    },
    bindKeyType (item) {
      let keyTypes = this.expressKeyOptions.find(x => x.label == '系统字段');
      if (keyTypes && keyTypes.options.find(x => x.value == item.key)) {
        item.type = 'sysField';
        item.widgetType = item.key;   // 名称设置为表单的类型
      } else {
        keyTypes = this.expressKeyOptions.find(x => x.label == '表单字段');
        let fieldItem = keyTypes.options.find(x => x.value === item.key);
        // 表单设计的字段类型
        item.widgetType = fieldItem.type;
        item.type = 'formField';
      }

      this.expressValueTypeOptions.forEach(x => {
        x.disabled = false;
      })
      item.value = '';
      if (item.widgetType === 'createTime') {
        item.valueType = 'fixedValue'
        this.expressValueTypeOptions.find(x => x.value === 'sysField').disabled = true;
      } else if (item.widgetType === 'status') {
        item.valueType = 'sysField'
        this.expressValueTypeOptions.find(x => x.value === 'fixedValue').disabled = true;
      }

      this.$set(item,'value', null);
      this.$set(item,'filterOptions', null);
      this.loadExpressKeyWidgetOptionItems(item);
      this.checkValueDisabled(item)
    },
    valueTypeChange (item) {
      this.$set(item,'value', null);
      this.$set(item,'filterOptions', null);
      this.loadExpressKeyWidgetOptionItems(item);
      this.checkValueDisabled(item)
    },
    loadExpressKeyWidgetOptionItems (item) {
      let expressKeyWidggetOptionItems=[];
      if (item.type === 'formField') {
        let widget = this.formJson.fieldList.find(x => x.name === item.key);
        if (widget && widget.filterOptions) {
          expressKeyWidggetOptionItems.splice(0);

          item.filterOptions = widget.filterOptions.map(x=>{return {
            label: x.label,
            value: x.value
          }});
        }
      }
    },

    checkValueDisabled (item) {
      // 如果选择的是填报人，系统值仅能选择当前用户(用户id)
      // 如果选择的是表单中的用户字段，系统值仅能选择当前用户(用户机构id)
      try{
            this.expressValueOptions.find(x => x.value === 'userId').disabled = false;
            this.expressValueOptions.find(x => x.value === 'agencyUserId').disabled = false;
            this.expressValueOptions.filter(x => x.value.indexOf('userDeptId') >= 0).forEach(item => {
              item.disabled = false;
            })

            if (item.key === 'name' && item.valueType === 'sysField') {
              this.expressValueOptions.find(x => x.value === 'agencyUserId').disabled = true;
            }
            if (item.key.indexOf('audit')>=0 && item.valueType === 'sysField') {
              this.expressValueOptions.find(x => x.value === 'userId').disabled = true;
              this.expressValueOptions.filter(x => x.value.indexOf('userDeptId') >= 0).forEach(item => {
                item.disabled = true;
              })
            }
            if (item.type === 'formField' && item.widgetType === 'user-select' && item.valueType === 'sysField') {
              this.expressValueOptions.find(x => x.value === 'userId').disabled = true;
            }
      }catch{
        item.value = [];
        this.$forceUpdate();
      }
    },
    operatorChanged (item) {
      item.value = '';
      // this.loadExpressKeyWidgetOptionItems(item);
    },
    getArrayValue (item) {

      if (!item.value) {
        return [];
      }
      let values = item.value.split(",");
      return values;
    },
    setArrayValue (item, val) {
      console.log(item, val)
      item.value = val.join(",");
    }
  },
  watch: {
    filters: {
      // deep: true,
      immediate: true,
      handler (val) {
        this.express.list = val
        this.express.list.forEach(item=>{
          this.loadExpressKeyWidgetOptionItems(item);
        })
      }
    },
    formJson: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.widgetList) {
          this.express.list.splice(0)
          this.showFilterSelect();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.express-wrapper {
  &.empty {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #a8a8a8;
    border: solid 1px #e3e3e3;
    border-radius: 3px;
  }
}

.express-item,
.connector-item {
  display: flex;
  justify-content: flex-start;
  .express-key {
    width: 130px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .express-operator {
    width: 100px;
    flex: 0 auto;
    margin-right: 10px;
    display: inline-block;
  }
  .express-value {
    flex: 1;
    display: inline-flex;
    justify-content: flex-start;
    .el-select,
    .el-input {
      display: inline-block;
      margin-right: 10px;
      flex: 1;
    }
  }
  .express-button {
    width: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-block;
  }
}

.connector-item {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  .connector {
    display: inline-block;
    width: 20%;
  }
}

::v-deep .connector-item .el-input__inner {
  border: none 0px;
}
</style>
