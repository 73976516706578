var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("content-title", {
        attrs: { formDesign: _vm.formDesign },
        model: {
          value: _vm.value.title,
          callback: function ($$v) {
            _vm.$set(_vm.value, "title", $$v)
          },
          expression: "value.title",
        },
      }),
      _c("div", { staticClass: "vidoe-wrapper" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "80%",
              display: "inline-block",
              "max-width": "1280px",
            },
          },
          [
            _c("video", { attrs: { width: "100%", controls: "" } }, [
              _c("source", { attrs: { src: _vm.videoUrl, type: "video/mp4" } }),
              _c("p", [_vm._v("您的浏览器不支持视频标签。")]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }