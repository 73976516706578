<template>
  <div>
    <draggable v-model="sortList" ghostClass='ghost' animation=300 handle=".drag-handler">
      <transition-group>
        <div v-for="(item, index) in sortList" :key="index" class="sortable-item">
          <i class="el-icon-rank drag-handler"></i>
          <el-select size="mini" v-model="item.field" placeholder="选择排序字段" class="sort-field" @change="(val)=>selectChanged(item,val)">
            <el-option v-for="field in sortableFields" :key="field.name" :label="field.displayName" :value="field.field">
            </el-option>
          </el-select>
          <el-select size="mini" v-model="item.direction" placeholder="选择排序顺序" class="sort-type">
            <el-option label="降序" :value="-1"></el-option>
            <el-option label="升序" :value="1"></el-option>
          </el-select>
          <el-button size="mini" icon="el-icon-delete" @click="removeSort(index)" circle class="sort-delete"></el-button>
          <!-- <el-button icon="el-icon-plus" @click="addSort(index)" circle class="sort-add"></el-button> -->
        </div>
      </transition-group>
    </draggable>
    <div>
      <el-button type="text"  size="mini" icon="el-icon-plus" @click="addSort()">添加排序字段</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewSort',
  props: {
    sort: {
      type: Array,
      default: () => []
    },
    formJson: {
      type: Object,
      default: () => { },
    }
  },
  data () {
    return {
      sortList: [],
      sortableFields: [],
    }
  },
  methods: {
    addSort: function (index) {
      if (index === undefined) {
        this.sortList.push({
          field: "",
          fieldType: '',
          direction: 1
        });
      } else {
        this.sortList.splice(index + 1, 0, {
          field: "",
          fieldType: '',
          direction: 1
        });
      }
    },
    removeSort (index) {
      this.sortList.splice(index, 1);
    },

    selectChanged (item, val) {
      let field = this.sortableFields.find(x => x.field == val);
      if (field) {
        item.fieldType = field.fieldType;
      }
    },
  },
  watch: {
    sort: {
      deep: true,
      immediate: true,
      handler (val) {
        this.sortList = val
      }
    },
    sortList: {
      deep: true,
      handler (val) {
        this.$emit('update:sort', val)
      }
    },
    formJson: {
      deep: true,
      immediate: true,
      handler (val) {
        this.sortableFields = (this.formJson.fieldList||[])
          .filter(x => {
            return !['fill-select', 'sub-form', 'divider', 'static-text', 'html-text', 'sign', 'file-upload', 'picture-upload'].includes(x.type)
          }).map(x => {
            return {
              field: x.name,
              displayName: x.displayName,
              fieldType: 'formField',
              direction: 'asc'
            }
          });

        // 添加系统字段
        const sysFields = [{
          field: 'name',
          headerName: '填报人',
          fieldType: 'sysField',
          hide: false
        }, {
          field: 'createTime',
          headerName: '填报时间',
          fieldType: 'sysField',
          hide: false
        },
        {
          headerName: '修改时间',
          field: 'updateTime',
          fieldType: 'sysField',
          hide: false
        },
        {
          field: 'status',
          headerName: '当前状态',
          fieldType: 'sysField',
          hide: false
        }, {
          field: 'payStatus',
          headerName: '支付状态',
          fieldType: 'sysField',
          hide: false
        }
        ];
        sysFields.forEach(x => {
          this.sortableFields.push({
            field: x.field,
            fieldType: x.fieldType,
            displayName: x.headerName
          });
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sortable-item {
  margin-bottom: 10px;
  .drag-handler {
    margin-right: 10px;
  }
  .sort-type {
    margin: 0px 10px;
  }
}
</style>