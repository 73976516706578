<template>
  <el-dialog v-if="visible" :visible="visible" :title="title" @close="closeDialog">
    <!-- 添加需要显示的内容 -->
    <div>
      <wordcloud ref="wordcloudRef" :height="'280px'" :sizeRange="[12, 30]"></wordcloud>
    </div>
    <!-- 添加底部按钮 -->
    <template slot="footer">
      <el-button type="primary" @click="closeDialog">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import wordcloud from './commonWordCloud.vue'
import { getFormInputFieldWorldCloud } from '@/api/system/statistics'
export default {
  components: {
    wordcloud
  },
  data () {
    return {
      visible: false, // 默认显示弹窗
      title: '',
    }
  },
  methods: {
    closeDialog () {
      this.visible = false // 关闭弹窗
    },
    submitForm () {
      // 提交表单
    },
    showDialog (field, formId) {
      console.log(field)
      this.visible = true;
      var fieldId = field.name;
      this.title = field.displayName + ' 的词云统计'

      getFormInputFieldWorldCloud(formId, fieldId).then(data => {
        let wordCloudData = data.data.find(x => x.fieldId == fieldId)?.fieldResult
        this.$refs.wordcloudRef.initChart(wordCloudData)
      })

    }
  }
}
</script>
