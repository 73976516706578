import { formViewData,
  unLoginListViews,
  unlLoginFillDetail,
  unLoginViewData, } from "@/api/system/views.js";
import {
  fillDataDetail, //表单配置数据接口
  formPayInfo,
  exportFillWord,
  fillDel,
  exportFillPdf,
  getCustomTip,
} from "@/api/system/form";
import gridConfig from "@/utils/grid.js";
import { deepClone } from "@/utils";
export default {
  data() {
    return {
      fillFile: null,
      dataEmptyText: "",
      noticeClickNum: 0,
      fillGridOptions: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        loading: false,
        columns: [],
        data: [],
        fillCacheKey: "",
        params: {
          selectedRowKeys: [],
          excludeIds: [],
        },
        tableToolbar: {
          custom: true,
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100,200],
        },
      },
      filters: {
        status: {
          column: "status",
          field: "status",
          fieldType: "sysStatus",
          isSysField: true,
          optionsItem: [
            { value: "-1", label: "待您审核" },
            { value: "1", label: "待审核" },
            { value: "2", label: "已通过" },
            { value: "3", label: "未通过" },
            { value: "4", label: "返回修改" },
            { value: "5", label: "待一级审核" },
            { value: "6", label: "待二级审核" },
            { value: "7", label: "待三级审核" },
            { value: "8", label: "进行中" },
            { value: "9", label: "已结束" },
          ],
          options: [
            { value: "1", label: "待审核" },
            { value: "2", label: "已通过" },
            { value: "3", label: "未通过" },
            { value: "4", label: "返回修改" },
          ],
        },
        fillStatus: {
          column: "fillStatus",
          field: "fillStatus",
          fieldType: "fillStatus",
          isSysField: true,
          optionsItem: [
            { value: "1", label: "个人填报" },
            { value: "2", label: "导入待确认" },
            { value: "3", label: "导入已确认" },
            { value: "4", label: "导入无需确认" },
          ],
          options: [
            { value: "1", label: "个人填报" },
            { value: "2", label: "导入待确认" },
            { value: "3", label: "导入已确认" },
            { value: "4", label: "导入无需确认" },
          ],
        },
      },
      formAuth: {},
      // 审核弹框
      reviewDialog: {
        type: "audit",
        open: false,
      },
      dataShareShow: false,
      multipleSelectionFill: [], // 一键审核所有数据
      isMultipleSelectionFill: false, // 是否是一键审核
      userSelectCallback: null
    };
  },
  mounted() {},
  computed: {
    // 判断表单停止填报和填报人有没有权限
    disableFill: function () {
      // isStop 是反的，true的时候没有stop，false的时候是stop
      // disableFill 返回true时表示禁止修改
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentInsertFillAuth ? false : true)
      );
    },
    // 判断表单停止填报和填报人有没有权限
    disableEditFill: function () {
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentEditFillAuth == true ? false : true)
      );
    },
    displayViewColumns: function () {
      if (
        !this.formJson ||
        !this.formJson.widgetList ||
        this.viewColumns.length == 0
      ) {
        return [];
      }
      let widgetList = [];
      
      this.viewColumns
        .filter((x) => !x.hide)
        .forEach((col) => {
          let x = deepClone(col);
          if (x.field && x.type == "formField") {
            let widget = deepClone(
              this.formJson.fieldList.find((y) => y.name == x.field)
            );
            if(!widget){
              console.log(`字段${x.field}不存在`)
              return;
            }
            widget.hidden = false;
            widget.fieldType = "formField";
            widget.left = x.left;
            widget.right = x.right;
            widget.columnWidth = x.columnWidth;
            widget && widgetList.push(widget);
          } else {
            x.fieldType = x.type;
            delete x.type;
            widgetList.push(x);
          }

          if(x.fieldType==undefined){
            // 正则表达式判断x.name是否是字母+5位以上的数字
            if (/^[a-zA-Z]\d{5,}$/.test(x.name)) {
              x.fieldType = "formField";
            }else{
              x.fieldType ='sysField'
            }
          }
        });
      return widgetList;
    },
    selectedCount:function(){
      return this.fillGridOptions.params.isAllDataSelected?
        this.fillGridOptions.params.allDataTotal- this.fillGridOptions.params.excludeIds.length: this.fillGridOptions.params.selectedRowKeys.length
    }
  },
  methods: {
    // 获取视图数据
    getFormViewListData() {
      this.fillGridOptions.loading = true
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = []
      const queryParams = {
        queryStatus: this.fillQueryParams.queryStatus || undefined,
        payStatus: this.fillQueryParams.payStatus || undefined,
        criteria: this.fillQueryParams.filterData || undefined,
        orders: this.fillQueryParams.sortData || undefined,
        fillStartTime: this.fillQueryParams.fillStartTime || undefined,
        fillEndTime: this.fillQueryParams.fillEndTime || undefined,
        updateStartTime: this.fillQueryParams.updateStartTime || undefined,
        updateEndTime: this.fillQueryParams.updateEndTime || undefined,
        // formFieldId: this.fillQueryParams.formFieldId,
        fillValue: this.fillQueryParams.fillValue || undefined,
        searchText: this.fillQueryParams.fillValue || undefined,

        pageNum: this.fillGridOptions.pagerConfig.currentPage,
        pageSize: this.fillGridOptions.pagerConfig.pageSize,
      };

      let loadViewDataHanlder = this.loginUserId? formViewData: unLoginViewData;
      if(!this.currentViewId) return;
      loadViewDataHanlder(this.currentViewId, queryParams).then((data) => {
        this.fillGridOptions.fillCacheKey = data.fillCacheKey;

        const listData = [];
        for (let i = 0; i < data.rows.length; i++) {
          const item = data.rows[i];
          const obj = { ...item.fillJson,...item.dataChoose };

          // 表单开通支付
          obj["payStatus"] = item.payStatus;
          switch (item.payStatus) {
            case 1:
              item.payStatusTxt = "无需支付";
              break;
            case 2:
              item.payStatusTxt = "待支付";
              break;
            case 3:
              item.payStatusTxt = "已支付";
              break;
            case 4:
              item.payStatusTxt = "确认支付";
              break;
          }
          obj["payStatusTxt"] = item.payStatusTxt;
          obj["orderPriceTxt"] = item.orderPrice / 100 + "元";
          obj["payTime"] = !this.isBlank(item.payTime) ? item.payTime : "--";
          obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
          obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
          obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
          obj["auditAuth1Sign"] = item.auditAuth1Sign;
          obj["auditAuth2Sign"] = item.auditAuth2Sign;
          obj["auditAuth3Sign"] = item.auditAuth3Sign;
          
          obj["auditAuth1Time"] = item.auditAuth1Time;
          obj["auditAuth2Time"] = item.auditAuth2Time;
          obj["auditAuth3Time"] = item.auditAuth3Time;
          obj["auditAuthSign"] = item.auditAuthSign;
          obj["auditAuthRemarks"] = item.auditAuthRemarks;
          obj["auditAuthTime"] = item.auditAuthTime;
          
          obj["status"] = item.status;
          obj["reviewRemark"] =
            item.auditAuthRemarks ||
            item.auditAuth3Remarks ||
            item.auditAuth2Remarks ||
            item.auditAuth1Remarks ||
            "";

          obj["userId"] = item.userId;
          obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
            ? item.fillUserNumber
            : "-";
          obj["fillUserName"] = item.fillUserName;
          obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
          obj["createTime"] = item.createTime;
          obj["updateTime"] = item.updateTime;
          obj["agencyDeptList"] = item.agencyDeptList;

          obj["reviewSign"] = item.reviewSign;

            switch (item.fillStatus) {
              case 1:
                item.fillStatusTxt = "个人填报";
                break;
              case 2:
                item.fillStatusTxt = "导入待确认";
                break;
              case 3:
                item.fillStatusTxt = "导入已确认";
                break;
              case 4:
                item.fillStatusTxt = "导入无需确认";
                break;
            }
            obj["fillStatusTxt"] = item.fillStatusTxt;
            obj["fillStatus"] = item.fillStatus;


          obj["fillId"] = item.fillId;
          obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
          obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
          obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
          obj["auditAuth1Sign"] = item.auditAuth1Sign;
          obj["auditAuth2Sign"] = item.auditAuth2Sign;
          obj["auditAuth3Sign"] = item.auditAuth3Sign;
          obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
          obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
          obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
          obj["auditAuth1Operator"] = item.auditAuth1Operator;
          obj["auditAuth2Operator"] = item.auditAuth2Operator;
          obj["auditAuth3Operator"] = item.auditAuth3Operator;
          obj["status"] = item.status;
          obj["version"] = item.version;
          obj["auditButton"] = item.auditButton;

          obj["fillHeaders"] = Object.keys(item.fillJson||[]);
          listData.push(obj);
        }

        this.fillGridOptions.data.splice(
          0,
          this.fillGridOptions.data.length,
          ...listData
        );

        this.fillGridOptions.pagerConfig.total = data.total;
        this.fillGridOptions.params.allDataTotal = data.total;

        if (this.isBlank(this.fillGridOptions.data)) {
          this.dataEmptyText = "暂无数据";
        }

        this.fillGridOptions.loading = false;
      });
    },
    // 排序
    fillSortChanged(sortObjects) {
      this.fillGridOptions.data = [];

      this.fillQueryParams.sortData = sortObjects;
      this.getFormViewListData(() => {
        let sortData = this.fillQueryParams.sortData;
        this.restoreFillter(
          this.$refs.fillGridTable,
          this.fillQueryParams.filterObjects,
          sortData
        );
      });
    },
    // 筛选变化
    fillFilterChanged(filterObjects,runQuery = true) {
      this.fillGridOptions.data = [];
      let filterData = {};
      if (filterObjects.length > 0) {
        this.clearGlobalSearch();
      }
      if(!runQuery){
        this.restoreFillter(this.$refs.fillGridTable, {}, []);
        return;
      }
      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "updateTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.updateStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.updateEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.fillQueryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.fillQueryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.fillQueryParams.fillStatus = x.value;
          }
        } else {
          filterData[x.field] = x.value;
        }
      });
      if (filterObjects.length > 0) {
        this.fillQueryParams.filterData = filterData;
        this.fillQueryParams.filterObjects = filterObjects;
      } else {
        this.fillQueryParams.filterData = undefined;
        this.fillQueryParams.fillStartTime = undefined;
        this.fillQueryParams.fillEndTime = undefined;
        this.fillQueryParams.updateStartTime = undefined;
        this.fillQueryParams.updateEndTime = undefined;
        this.fillQueryParams.queryStatus = undefined;
        this.fillQueryParams.payStatus = undefined;
      }

      this.getFormViewListData(() => {
        let sortData = this.fillQueryParams.sortData;
        this.restoreFillter(this.$refs.fillGridTable, filterData, sortData);
      });
    },
    restoreFillter(el, filterDataArray, sortData) {
      if (filterDataArray && Array.isArray(filterDataArray)) {
        filterDataArray.forEach((f) => {
          el.setFilter(f.field, f.value);
        });
      } else if (filterDataArray) {
        Object.keys(filterDataArray).forEach((f) => {
          el.setFilter(f, filterDataArray[f]);
        });
      }

      if (sortData) {
        sortData.forEach((field) => {
          el.setSort(field);
        });
      }
    },

    // 清空数据
    clearGlobalSearch() {
      this.fillQueryParams.fillValue = "";
    },
    handleDataPageChange({ currentPage, pageSize }) {
      this.fillGridOptions.pagerConfig.currentPage = currentPage;
      this.fillGridOptions.pagerConfig.pageSize = pageSize;
      // this.fillGridOptions.columns = []=
      this.getFormViewListData(() => {
        this.restoreFillter(
          this.$refs.fillGridTable,
          this.fillQueryParams.filterData,
          this.fillQueryParams.sortData
        );
      });
    },

    onHandlePreviewImg() {},
    // 清空全局搜索
    onHandleResetFillClick() {},

    onHandleQueryDataClick(){
      this.onHandleQueryFillClick();
    },
    onHandleQueryFillClick() {
      this.fillGridOptions.data.splice(0);
      this.fillGridOptions.pagerConfig.currentPage=1;
      if (this.$refs.fillGridTable) {
        // clearFilter 会触发getFormViewListData

        // this.$refs.fillGridTable.clearFilters(false);
        this.$refs.fillGridTable.clearSelected();
      // } else {
        this.getFormViewListData();
      }
    },

    // 删除某一条填报
    onDeleteFillClick(fillId) {
      const self = this;
      this.$confirm("是否确认删除该填报？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return fillDel(fillId, self.formId);
        })
        .then(() => {
          self.msgSuccess("删除成功");
          self.getFormViewListData();
        })
        .catch(function () {});
    },

    // 读取管理列表所有数据（不分页）
    GetAllDataRows(totalNum, callback) {
      let _this = this;
      const queryParams = {
        pageNum: 1,
        pageSize: totalNum,
        orders: _this.dataQueryParams.sortData,
        formFieldId: _this.dataQueryParams.formFieldId,
        fillValue: _this.dataQueryParams.fillValue,
        queryStatus: _this.dataQueryParams.queryStatus,
        payStatus: _this.dataQueryParams.payStatus,
        fillStartTime: _this.dataQueryParams.fillStartTime,
        fillEndTime: _this.dataQueryParams.fillEndTime,
        searchText: _this.dataQueryParams.fillValue, //全局搜索
      };
      fillDataDetail(this.formId, queryParams).then((response) => {
        const rows = [];
        response.rows.forEach((item) => {
          const obj = {
            fillId: item.fillId,
            payStatus: item.payStatus,
            status: item.status,
            fillStatus: item.fillStatus,
            version: item.version,
          };
          rows.push(obj);
        });

        callback(rows);
      });
    },

    // 获取支付信息
    async getFormPayInfo(callback) {
      if (!this.formDetail.pay) {
        return {};
      }
      return formPayInfo(this.formDetail.formId);
    },

    // 渲染表头
    renderTableColumns(refGrid, fieldList, hideHiddenField = false) {
      const _this = this;
      refGrid.columns = [];

      gridConfig
        .renderViewHeaderColumns(
          fieldList,
          {
            formDetail: _this.formDetail,
            dataFillStatus: _this.dataFillStatus,
          },
          { hideHiddenField: hideHiddenField }
        )
        .forEach((c) => {
          if((!c.type || (c.type != "checkbox" && c.type != "operator")) &&  c.width ) {
            // c.minWidth = 50;
            // delete c.width;
          }
          if(c.fieldType=='updateTime'){
            c.minWidth = 100;
            delete c.width;
          }
          refGrid.columns.push(c);
        });
      return refGrid.columns;
    },
    actionExists(action) {
      return !!(this.viewActions || []).find((x) => x == action);
    },
    resetFillGridTable() {
      this.$refs.fillGridTable.clearSelected();

      if (this.$refs.fillGridTable) this.$refs.fillGridTable.clearFilters();
    },

    // 数据查看列表数据，设置批量通知
    onHandleShareClick() {
      this.dataShareShow = true;
    },

    showAuditDialog(type) {
      this.reviewDialog.open = true;
    },
    closeAuditDialog() {
      this.reviewDialog.open = false;
    },
    isShowAuthReUpdate(row) {
      // 获取数组是否为空
      const isArrayNotEmpty = (arr) => {
        return arr && arr.length !== 0;
      };
      const isAssign = (assign) => {
        if (!assign) return false;

        return assign.userId === this.loginUserId;
      };

      // 通过判断待审核人数组是否为空，判断一共有几级审核
      const totalLevel = isArrayNotEmpty(row.auditAuth3AgencyUserList)
        ? 3
        : isArrayNotEmpty(row.auditAuth2AgencyUserList)
        ? 2
        : isArrayNotEmpty(row.auditAuth1AgencyUserList)
        ? 1
        : 0;
      // 判断当前用户的审核级别
      const auditLevel3 = isAssign(row.auditAuth3Operator); // 当前用户是三级审核
      const auditLevel2 = isAssign(row.auditAuth2Operator); // 当前用户是二级审核
      const auditLevel1 = isAssign(row.auditAuth1Operator); // 当前用户是一级审核

      // 如果没有审核，或者当前用户未参与任何审核，不显示审核修改按钮
      if (
        totalLevel === 0 ||
        (auditLevel1 === 0 && auditLevel2 === 0 && auditLevel3 === 0)
      ) {
        return false;
      }

      // 当前审核区间
      var currentLevel = 0;
      // 通过判断当前状态是否为审核通过，如果是，则显示修改按钮
      var agencyUserList;

      // 有多级审核，一级审核(通过获不通过)，或仅有一级审核，判断当前用户是否是审核人
      if (
        (row.status > 3 && row.status <= 7) ||
        (row.status === 14 && totalLevel == 1)
      ) {
        // 一级审核区间
        agencyUserList = row.auditAuth1AgencyUserList;
        currentLevel = 1;

        // 如果是一级审核人
        if (auditLevel1) {
          return true;
        }
      }
      // 有多级审核，二级级审核完成，或仅有一级审核，判断当前用户是否是审核人
      else if (
        (row.status > 7 && row.status <= 10) ||
        (row.status === 14 && totalLevel == 2)
      ) {
        // 二级审核区间
        agencyUserList = row.auditAuth2AgencyUserList;
        currentLevel = 2;

        // 如果是二级审核人
        if (auditLevel2) {
          return true;
        }
      } else if (
        (row.status > 10 && row.status <= 13) ||
        (row.status === 14 && totalLevel == 3)
      ) {
        // 三级审核区间
        agencyUserList = row.auditAuth3AgencyUserList;
        currentLevel = 3;

        // 如果是三级审核人
        if (auditLevel3) {
          return true;
        }
      }
      return false;
    },
    clearFormDetail() {
      this.$refs.vformRender.resetForm();
    },

    // 新版 3.0.0.1 导出设置 允许个人导出
    onExportMyFill(fillId, exportType = "Word") {
      const _this = this;
      // window.location.href = _this.apiPathUrl + '/export/word/' + _this.formId + '/' + fillId + '?token=' + _this.Authorization
      const exportHandler =
        exportType == "Word" ? exportFillWord : exportFillPdf;
      exportHandler(_this.formId, fillId).then(
        (res) => {
          const { data, headers } = res;
          if (!data) {
            return false;
          }
          if (data.msg) {
            _this.$message.error(data.msg);
            return false;
          }

          const fileName = headers["content-disposition"].split("filename=")[1];
          const blob = new Blob([data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = `${decodeURIComponent(fileName)}`;
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },

    // 新增
    btnA1Add: function () {
      return this.actionExists("A1");
    },
    // 详情/编辑
    btnA2Detail: function (row) {
      // 如果是填报人，审核人，管理员其中一个才显示

      if (
        this.formIdentity.isFillUser ||
        this.formIdentity.isAuditUser ||
        this.formIdentity.isDataAdmin
      ) {
        return this.actionExists("A2");
      } else {
        return false;
      }
    },
    // 删除
    btnA3Delete: function (row) {
      if (!this.actionExists("A3")) {
        return false;
      }

      // 审核列表没有删除
      if (this.listType === "AUDIT") {
        return false;
      }

      return true;
    },
    btnA3DeleteDisabled: function (row) {
      // 如果表单停止填报了，个人不能删除
      if (this.listType === "FILL" && this.disableEditFill) {
        return "表单停止填报后不可修改";
      }

      if (this.listType === "FILL" && row.payStatus > 2) {
        return "表单已支付，请联系管理员删除";
      }

      // 如果是填报人，表单中审核流程中，不允许删除
      if (this.listType === "FILL" && row.status > 2) {
        return "表单在审核中或已审核，请联系表单管理员删除";
      }

      // 如果是数据管理员的管理列表，表单中审核流程中也不允许删除
      if (
        this.listType === "MANAGE" &&
        this.formIdentity.isDataAdmin &&
        row.status > 2
      ) {
        return "表单在审核中或已审核，请联系表单管理员删除";
      }

      return "";
    },
    // 批量编辑
    btnA4BatchEdit: function () {
      return this.actionExists("A4");
    },
    // 草稿箱
    btnA5Draft: function () {
      return this.actionExists("A5");
    },
    // 批量更新数据
    btnA6BatchUpdate: function () {
      return this.actionExists("A6");
    },

    // 管理员删除
    btnA8ManageDelete: function (row) {
      if (!this.actionExists("A8")) {
        return false;
      }
      return true;
    },
    // 审核
    btnB1Audit: function (row) {
      if (!this.actionExists("B1")) {
        return false;
      }

      if (!this.formIdentity.isAuditUser) {
        return false;
      }
      if (
        // !row["auditButton"] ||
        (row["status"] != "2" && row["status"] != "10" && row["status"] != "6")
      ) {
        return false;
      }
      return true;
    },
    btnB1AuditEdit: function (row) {
      if (!this.actionExists("B1")) {
        return false;
      }
      // 如果当前不是审核人
      if (!this.formIdentity.isAuditUser) {
        return false;
      }

      // 如果当前表单不允许修改
      if (!this.formDetail.auditAuthReUpdate) {
        return false;
      }

      if (!this.isShowAuthReUpdate(row)) {
        return false;
      }

      return true;
    },
    // 批量审核
    btnB2AuditBatch: function () {
      if (!this.actionExists("B2")) {
        return false;
      }
      return true;
    },
    btnB2AuditBatchDisabled: function () {
      if (this.selectedCount===0) {
        return "请选择批量审核的数据";
      }
      return "";
    },
    btnShowF3DataStatisticsUpdate: function () {
      return this.actionExists("F3");
    },
    btnE2PayBatchDisabled: function () {
      if (this.selectedCount===0) {
        return "请选择批量支付的数据";
      }
      if(!this.formDetail.pay){
        return '表单未开启支付'
      }
      if(this.formDetail.isStop==false){ //isStop = false 是暂停
        return '表单已暂停'
      }
      if(this.disableEditFill){
        return '表单已到期'
      }

      return "";
    },
    // 导出Excel
    btnC1ExportExcel: function () {
      return this.actionExists("C1");
    },
    // 导出带图片Excel
    // btnC2ExportPicExcel: function () {
    //   return this.actionExists("C2");
    // },
    // EXCEL的合并导出
    btnC3ExportExcelCombine: function () {
      return this.actionExists("C3");
    },
    // 附件导出
    btnC4ExportAttr: function () {
      return this.actionExists("C4");
    },
    // 导出Word/Pdf
    btnC5ExportWord: function () {
      if (
        !this.formDetail.exportTemplate ||
        this.formDetail.exportTemplate == null
      ) {
        return false;
      }
      return this.actionExists("C5");
    },
    // 导入Import
    btnD1Import: function () {
      return this.actionExists("D1");
    },
    // 支付
    btnE1Pay: function (row) {
      if(!this.formDetail.pay){
        return '表单未开启支付'
      }
      if(row.payStatus != 2){
        return '表单无需支付'
      }
      if(this.formDetail.isStop==false){ //isStop = false 是暂停
        return '表单已暂停'
      }
      if(this.disableEditFill){
        return '表单已到期'
      }
      return true;
    },
    btnE1ShowPay: function(){
      return this.actionExists("E1") && this.formDetail.pay;
    },
    // 批量支付
    btnE2PayBatch: function () {
      return this.actionExists("E2") && this.formDetail.pay ;
    },
    // 修改支付金额
    btnE3EditPay: function () {
      return this.actionExists("E3") && this.formDetail.pay;
    },
    // 修改支付状态
    btnE4EditPayStatus: function () {
      return this.actionExists("E4") && this.formDetail.pay;
    },
    // 发送通知
    btnF1SendNotice: function () {
      return this.actionExists("F1");
    },
    // 数据分享
    btnF2Share: function () {
      return this.actionExists("F2");
    },
    dropdownShow: function () {
      return this.btnC1ExportExcel() ||
        // this.btnC2ExportPicExcel() ||
        this.btnC3ExportExcelCombine() ||
        this.btnC4ExportAttr() ||
        this.btnC5ExportWord() ||
        this.btnD1Import() ||
        this.btnA4BatchEdit() ||
        this.btnA6BatchUpdate() ||
        this.btnF1SendNotice() ||
        this.btnE3EditPay() ||
        this.btnE4EditPayStatus();
    },



    // #region 附加函数
    showStatusDataImagePreview (item) {
      const viewer = new picViewer();
      viewer.show({ src: item.thumb, url: item.url, alt: item.name }, 0, {
        toolbar: {
          zoomIn: { show: true, size: "large" },
          zoomOut: { show: true, size: "large" },
          rotateLeft: { show: true, size: "large" },
          rotateRight: { show: true, size: "large" },
          flipHorizontal: { show: true, size: "large" },
          flipVertical: { show: true, size: "large" },
          reset: { show: false, size: "large" },
          prev: { show: false, size: "large" },
          play: { show: false, size: "large" },
          next: { show: false, size: "large" },
        },
        navbar: false,
      });
    },
    //填报窗口 数据选择组件弹出选择数据
    showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.show(fillSelectOption, callback);
    },
    showDataAutoSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect (fillSelectOption, callback, search, searchObject) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFillMulti(fillSelectOption, callback, search, searchObject);
    },
    async showDataView (fillSelectOption, fillData, callback) {
      // 加载表单配置数据
      fillSelectOption.formId = fillSelectOption.formId || this.formId;
      const res = await httpGetFormFieldV2(fillSelectOption.formId);
      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });
      Promise.all([loadField, loadData]).then((values) => {
        this.$refs.dlgvFormViewer.showView({
          fillId: fillData.fillId,
          formId: fillSelectOption.formId,
          formJson: values[0],
          formData: values[1],
          showHiddenWidgets: this.cur != 3,
          showField: fillSelectOption.fields,
          agencyId: this.formDetail.agencyId,
          // fillInfo: listType == "MANAGE"?{
          //     fillUserName: row.fillUserName,
          //     fillUserAvatarUrl: row.fillUserAvatarUrl,
          //     fillUserId: response.data.userId,
          //     createTime: row.createTime,
          //     updateTime: row.updateTime,
          //   }: null,
        });
      });
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    formAgencyId () {
      return this.formDetail.agencyId;
    },
    handleFormStopUpdate (isStop) {
      this.formDetail.isStop = isStop;
      this.$forceUpdate();
    },
    returnProjectHome () {
      this.$emit("multipleFormDelete");
    },
    fillUserViewer () {
      this.$refs.fillUserViewer.showFilledUserView();
    },
    unfillUserViewer () {
      this.$refs.fillUserViewer.showUnfilledUserView();
    },
    showUserSelect (selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },
    //#endregion
  },
  watch: {
    displayViewColumns: {
      deep: true,
      handler: function (val) {
        if (val&&val.length === 0) return;
        this.renderTableColumns(this.fillGridOptions, deepClone(val));
      },
    },
  },
};
