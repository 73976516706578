<template>
  <el-select size="mini" v-model="selectedVisibility" default-first-option placeholder="请选择可见范围" @change="changeVisibility">
    <el-option v-for="(option, index) in visibilityOptions" :key="index" :label="option.label" :value="option.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "viewScope",
  props: {
    scope: {
      type: Number,
      default: 6,
    },
  },
  data () {
    return {
      selectedVisibility: null,
      visibilityOptions: [
        { value: 0, label: '所有人可见' },
        { value: 1, label: '登录用户可见' },
        { value: 2, label: '机构用户可见' },
        { value: 3, label: '填报人' },
        { value: 4, label: '审核人' },
        { value: 5, label: '数据管理员' },
        { value: 6, label: '表单管理员'  },
      ],
    };
  },
  watch: {
    scope: {
      immediate: true,
      handler: function (value) {
        this.selectedVisibility = value;
      }
    }
  },
  methods: {
    changeVisibility (value) {
      this.$emit('update:scope', value);
    }
  }
};

</script>