var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.standalone
        ? _c("div", { staticClass: "detail-nav" }, [
            _c(
              "div",
              { staticClass: "detail-container app-inner-container" },
              [
                _c(
                  "div",
                  { staticClass: "detail-left" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-logo",
                        on: { click: _vm.naviHome },
                      },
                      [_c("svg-icon", { attrs: { "icon-class": "home" } })],
                      1
                    ),
                    _c("div", { staticClass: "separator" }),
                    _c(
                      "span",
                      {
                        staticClass: "headerNow",
                        on: { click: _vm.onBackDetail },
                      },
                      [_vm._v(_vm._s(_vm.formDetail.name))]
                    ),
                    _vm.cur !== 1 && _vm.cur !== 2
                      ? [
                          _c("div", { staticClass: "separator" }),
                          _c(
                            "span",
                            {
                              staticClass: "headerNow",
                              staticStyle: { cursor: "inherit" },
                            },
                            [_vm._v(_vm._s(_vm.breadcrumbName))]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("title-user-menu", {
                  attrs: {
                    "name-color": "#2B344199",
                    "icon-color": "#2B344199",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "app-container form-detail-container",
          style: [
            {
              width: _vm.cur == 5 || !_vm.standalone ? "100%" : "",
              top: !_vm.standalone ? "0px" : "56px",
              marginTop: _vm.standalone ? "16px" : "0px",
              maxWidth: "100%",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "forms-content",
              style: [
                {
                  width:
                    _vm.cur == 5 || _vm.cur == 3 || _vm.cur == 4
                      ? "100%"
                      : "100%",
                },
                { background: _vm.cur == 1 ? "none" : "#ffffff" },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 1,
                      expression: "cur == 1",
                    },
                  ],
                  staticClass: "form-cur1-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-detail-top-part",
                      class: [_vm.viewName],
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "el-row",
                          staticStyle: { margin: "0px 0px 10px" },
                          attrs: { gutter: 20, type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0",
                                width: "200px",
                                height: "120px",
                              },
                              attrs: { span: 7, xs: 24 },
                            },
                            [
                              _c("div", { staticClass: "form-img" }, [
                                _vm.formDetail && _vm.formDetail.cover
                                  ? _c("div", {
                                      staticClass: "img",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          _vm.formDetail.cover +
                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300)",
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0",
                                "margin-left": "20px",
                                position: "relative",
                                width: "100%",
                              },
                              attrs: { span: 19, xs: 24 },
                            },
                            [
                              _c("div", { staticClass: "form-info" }, [
                                _c(
                                  "h1",
                                  { staticClass: "clearfix form-title" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-title-left" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.formDetail.name))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "60px",
                                              "margin-left": "20px",
                                            },
                                          },
                                          [
                                            !_vm.formDetail.isStop
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    staticClass:
                                                      "form-state-tag stop",
                                                    attrs: {
                                                      size: "mini",
                                                      type: "info",
                                                    },
                                                  },
                                                  [_vm._v("已暂停")]
                                                )
                                              : _c(
                                                  "el-tag",
                                                  {
                                                    staticClass:
                                                      "form-state-tag",
                                                    class: _vm.getTagClass(
                                                      _vm.formDetail
                                                        .formStateTxt
                                                    ),
                                                    attrs: { size: "mini" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .formStateTxt
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-share-warp" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "btn-wrapper",
                                            on: { click: _vm.onCollectClick },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "collect-btn btn",
                                                class: {
                                                  active:
                                                    _vm.formDetail.favorite,
                                                },
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "btn-icon",
                                                  attrs: {
                                                    "icon-class": _vm.formDetail
                                                      .favorite
                                                      ? "formListCollect"
                                                      : "formListUnCollect",
                                                  },
                                                }),
                                                _c("span", [_vm._v("收藏")]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "btn-wrapper",
                                            on: { click: _vm.onShareClick },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "btn",
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "btn-icon",
                                                  attrs: {
                                                    "icon-class":
                                                      "formListShare",
                                                  },
                                                }),
                                                _c("span", [_vm._v("分享")]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-detail-top-bottom" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "clearfix form-date",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _c("p", { staticClass: "fill-date" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "75px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v("开始时间：")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formDetail.fillStartTime
                                                  ? _vm.formDetail.fillStartTime
                                                  : "——"
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "0px",
                                              top: "10px",
                                            },
                                          },
                                          [
                                            _c("formTagEditor", {
                                              attrs: { readonly: true },
                                              model: {
                                                value: _vm.formDetail.tagList,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDetail,
                                                    "tagList",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDetail.tagList",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "clearfix form-date" },
                                      [
                                        _c("p", { staticClass: "fill-date" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "75px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v("截止时间：")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formDetail.fillEndTime
                                                  ? _vm.formDetail.fillEndTime
                                                  : "——"
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "clearfix form-date" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "fill-date" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  width: "75px",
                                                  display: "inline-block",
                                                },
                                              },
                                              [_vm._v("填报人数：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formDetail.fillNum != null
                                                    ? _vm.formDetail.fillNum +
                                                        "人可填"
                                                    : "填报人数无限制"
                                                )
                                              ),
                                            ]),
                                            _vm.formDetail.fillNumPublic
                                              ? _c("span", [_vm._v(",")])
                                              : _vm._e(),
                                            _vm.formDetail.fillNumPublic
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .completedFillNum !=
                                                          null
                                                          ? _vm.formDetail
                                                              .completedFillNum +
                                                              "人已填"
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "clearfix form-date" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "fill-date" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  width: "75px",
                                                  display: "inline-block",
                                                },
                                              },
                                              [_vm._v("创建机构：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formDetail.founder
                                                    ? _vm.formDetail.founder
                                                    : _vm.formDetail.createName
                                                )
                                              ),
                                            ]),
                                            _vm.formDetail.agencyId
                                              ? _c("img", {
                                                  staticClass: "is-agency-icon",
                                                  attrs: {
                                                    src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-fill-btn" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { height: "41px" },
                                            attrs: {
                                              type: "default",
                                              icon: "el-icon-box",
                                              size: "medium",
                                            },
                                            on: { click: _vm.openDraftBox },
                                          },
                                          [_vm._v("草稿箱")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "primary",
                                              size: "medium",
                                              disabled:
                                                _vm.disableFill ||
                                                _vm.formLoading,
                                            },
                                            on: { click: _vm.onFillClickCheck },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formDetail.startFillText ||
                                                  "开始填报"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "el-row",
                          staticStyle: {
                            "margin-left": "0",
                            "margin-right": "0",
                            height: "30px",
                            padding: "0",
                            display: "-webkit-flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                          },
                          attrs: { gutter: 20, type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-num-wrap",
                              staticStyle: { padding: "0", width: "200px" },
                              attrs: { span: 8, xs: 24, type: "flex" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-total-num" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "font-size": "14px",
                                    },
                                    attrs: { "icon-class": "formDetailSeeNum" },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.formDetail.viewNum || 0)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-total-num" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "font-size": "14px",
                                    },
                                    attrs: {
                                      "icon-class": "formDetailShareNum",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formDetail.shareNum || 0)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-total-num" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "font-size": "14px",
                                    },
                                    attrs: {
                                      "icon-class": "formDetailCollectNum",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formDetail.favoriteNum || 0)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0px 0px 0px 20px",
                                position: "relative",
                                "box-sizing": "border-box",
                                "flex-grow": "1",
                                width: "auto",
                              },
                              attrs: { span: 9, xs: 16 },
                            },
                            [
                              _vm.formDetail.name && !_vm.formDetail.enableView
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formAuth
                                                .currentQueryFillListAuth,
                                            expression:
                                              "formAuth.currentQueryFillListAuth",
                                          },
                                        ],
                                        staticClass: "operate-btn",
                                        attrs: {
                                          size: "small",
                                          disabled: _vm.formLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onTabClick(3)
                                          },
                                        },
                                      },
                                      [_vm._v("我的填报")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formAuth
                                                .currentQueryReviewListAuth,
                                            expression:
                                              "formAuth.currentQueryReviewListAuth",
                                          },
                                        ],
                                        staticClass: "operate-btn",
                                        attrs: {
                                          size: "small",
                                          disabled: _vm.formLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onTabClick(4)
                                          },
                                        },
                                      },
                                      [_vm._v("审核列表")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formAuth
                                                .currentQueryDataListAuth,
                                            expression:
                                              "formAuth.currentQueryDataListAuth",
                                          },
                                        ],
                                        staticClass: "operate-btn",
                                        attrs: {
                                          size: "small",
                                          disabled: _vm.formLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onTabClick(5)
                                          },
                                        },
                                      },
                                      [_vm._v("管理列表")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formAuth
                                                .currentStatisticsAuth,
                                            expression:
                                              "formAuth.currentStatisticsAuth",
                                          },
                                        ],
                                        staticClass: "operate-btn",
                                        attrs: {
                                          size: "small",
                                          disabled: _vm.formLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onTabClick(8)
                                          },
                                        },
                                      },
                                      [_vm._v("统计报表")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0px 0px 0px 20px",
                                position: "relative",
                                "box-sizing": "border-box",
                                "flex-grow": "0",
                                "flex-shrink": "0",
                                "text-align": "right",
                              },
                              attrs: { span: 9, xs: 8 },
                            },
                            [
                              _c("editLinks", {
                                attrs: {
                                  formDetail: _vm.formDetail,
                                  loginUserId: _vm.loginUserId,
                                  loginAgencyUserId: _vm.loginAgencyUserId,
                                  formType: "form",
                                  returnType: _vm.standalone
                                    ? "back"
                                    : "refresh",
                                },
                                on: {
                                  "update:formDetail": function ($event) {
                                    _vm.formDetail = $event
                                  },
                                  "update:form-detail": function ($event) {
                                    _vm.formDetail = $event
                                  },
                                  refreshList: _vm.refreshList,
                                  formStopUpdate: _vm.handleFormStopUpdate,
                                  home: _vm.returnProjectHome,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-info-tab-bottom" },
                    [
                      _vm.viewName === "baseView"
                        ? [
                            _vm.formDetail.details != "" ||
                            _vm.formCustomFileList.length != 0
                              ? _c("div", { staticClass: "tab-content-nav" }, [
                                  _vm.formDetail && _vm.formDetail.details != ""
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "content-nav-title",
                                          class: {
                                            active: _vm.formInfoCur === 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFormInfoTabClick(1)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "formDetailInfo",
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.formCustomDetails) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.formCustomFileList &&
                                  _vm.formCustomFileList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "content-nav-title",
                                          class: {
                                            active: _vm.formInfoCur === 2,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFormInfoTabClick(2)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "formDetailFile",
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.formCustomFile) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.formInfoCur === 1
                              ? _c("div", { staticClass: "info-tab-content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ql-editor details-intro",
                                      attrs: { vhtml: "formDetail.details" },
                                    },
                                    [
                                      _c("pre", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.formDetail.details
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.formInfoCur === 2
                              ? _c(
                                  "div",
                                  { staticClass: "info-tab-content" },
                                  [
                                    _c("Download", {
                                      attrs: {
                                        fileList: _vm.formCustomFileList,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formDetail.details == "" &&
                            _vm.formCustomFileList.length == 0
                              ? _c(
                                  "div",
                                  [
                                    _c("el-empty", {
                                      attrs: {
                                        "image-size": 200,
                                        description: "暂无填报内容",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm.viewName === "webView"
                        ? [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("WebViewIntroduce", {
                                  attrs: {
                                    formDesign: _vm.formDesign,
                                    fileList: _vm.formCustomFileList,
                                    projectDetail: _vm.formDetail,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.cur == 2,
                    expression: "cur == 2",
                  },
                ],
                staticStyle: { padding: "20px" },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 9,
                      expression: "cur == 9",
                    },
                  ],
                  staticClass: "form-info-tab-content",
                  staticStyle: { padding: "20px" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "el-row",
                      staticStyle: { padding: "0 20px" },
                      attrs: { gutter: 20, type: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-fill-detail" },
                        [
                          _c("v-form-render", {
                            ref: "vformConfirmRender",
                            attrs: {
                              formJson: _vm.formJson,
                              formData: _vm.formData,
                              optionData: _vm.optionData,
                              previewState: false,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "myVxeTable",
                                fn: function (ref) {
                                  var fields = ref.fields
                                  var datas = ref.datas
                                  var isAdmin = ref.isAdmin
                                  var viewItem = ref.viewItem
                                  var removeItem = ref.removeItem
                                  return [
                                    _c("fill-select-table-view", {
                                      attrs: {
                                        fields: fields,
                                        datas: datas,
                                        isAdmin: isAdmin,
                                        viewItem: viewItem,
                                        removeItem: removeItem,
                                        onHandlePreviewImg:
                                          _vm.onHandlePreviewImg,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          !_vm.isEditFill
                            ? _c(
                                "div",
                                {
                                  staticClass: "fill-detail-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "12px 30px" },
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        disabled: _vm.fillBtnClick,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.onFillConfirmClick($event)
                                        },
                                      },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 3,
                      expression: "cur == 3",
                    },
                  ],
                  staticStyle: {
                    height: "100%",
                    display: "flex",
                    "flex-direction": "column",
                    "padding-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100%",
                        display: "inline-block",
                        "flex-grow": "1",
                        "flex-shrink": "1",
                        "overflow-y": "auto",
                        overflow: "hidden",
                      },
                      style:
                        "height:" +
                        (_vm.viewName == "webView" ? "500px" : "100%") +
                        ";",
                    },
                    [
                      _vm.cur == 3
                        ? _c("MyVxeTable", {
                            ref: "fillGridTable",
                            attrs: {
                              tableName: _vm.formId + "_myFill",
                              columns: _vm.fillGridOptions.columns,
                              loading: _vm.fillGridOptions.loading,
                              data: _vm.fillGridOptions.data,
                              params: _vm.fillGridOptions.params,
                              agencyId: _vm.formDetail.agencyId,
                              formJson: _vm.formJson,
                              formDetail: _vm.formDetail,
                              rowEditable: _vm.fillTableRowEditable,
                            },
                            on: {
                              "page-change": _vm.handleDataPageChange,
                              "view-picture": _vm.onHandlePreviewImg,
                              "filter-change": _vm.fillFilterChanged,
                              "sort-change": _vm.fillSortChanged,
                              setEditRowIndex: _vm.setEditRowIndex,
                              "clear-global-search": _vm.clearGlobalSearch,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "toolbar_search",
                                  fn: function () {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          "prefix-icon": "el-icon-search",
                                          placeholder: "全局搜索...",
                                          clearable: "",
                                        },
                                        on: {
                                          clear: _vm.onHandleResetFillClick,
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onHandleQueryFillClick(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.fillQueryParams.fillValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fillQueryParams,
                                              "fillValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "fillQueryParams.fillValue",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "toolbar_button",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "default",
                                            icon: "el-icon-box",
                                            size: "mini",
                                          },
                                          on: { click: _vm.openDraftBox },
                                        },
                                        [_vm._v("草稿箱")]
                                      ),
                                      _vm.formPay
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: { height: "29px" },
                                              attrs: {
                                                type: "success",
                                                size: "mini",
                                                disabled: _vm.disableFill,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onBatchPayHandler(
                                                    _vm.fillGridOptions.params
                                                      .selectedRowKeys
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("批量支付")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { height: "29px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            disabled: _vm.disableFill,
                                          },
                                          on: { click: _vm.onFillClickCheck },
                                        },
                                        [_vm._v("继续填报")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "status_text",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "当前状态",
                                          width: "150",
                                          field: "status",
                                          "header-class-name": "sysfield",
                                          filters: [{ data: {} }],
                                          "filter-render": {
                                            name: "filter-status",
                                          },
                                          params: _vm.filters.status,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c("StatusCode", {
                                                    attrs: {
                                                      statusCode: row.status,
                                                      row: row,
                                                      fillStatusCode:
                                                        row.fillStatus,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          700667606
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "operate",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: _vm.statusTxt,
                                          "min-width": "150",
                                          "slots:default": "operate",
                                          fixed: "right",
                                          "header-class-name": "sysfield",
                                          align: "center",
                                          type: "operate",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c("span", [_vm._v("操作")]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                var rowIndex = ref.rowIndex
                                                return [
                                                  _vm.editRowIndex !== rowIndex
                                                    ? [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "primary",
                                                              content: "查看",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onViewFillClick(
                                                                  row["fillId"],
                                                                  "FILL",
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看")]
                                                        ),
                                                        _vm.formDetail.auditAuth
                                                          ? [
                                                              row["status"] ==
                                                                1 ||
                                                              row["status"] ==
                                                                2 ||
                                                              row["status"] ==
                                                                5 ||
                                                              row["status"] ==
                                                                9 ||
                                                              row["status"] ==
                                                                13
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "mini",
                                                                        status:
                                                                          "primary",
                                                                        content:
                                                                          "修改",
                                                                        disabled:
                                                                          _vm.disableEditFill &&
                                                                          _vm.disableUserFill(
                                                                            row
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onEditFillClick(
                                                                              row[
                                                                                "fillId"
                                                                              ],
                                                                              "FILL",
                                                                              row
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "修改"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                  size: "mini",
                                                                  status:
                                                                    "primary",
                                                                  content:
                                                                    "修改",
                                                                  disabled:
                                                                    _vm.disableEditFill &&
                                                                    _vm.disableUserFill(
                                                                      row
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEditFillClick(
                                                                        row[
                                                                          "fillId"
                                                                        ],
                                                                        "FILL",
                                                                        row
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("修改")]
                                                            ),
                                                        row["fillStatus"] == 2
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                  size: "mini",
                                                                  status:
                                                                    "primary",
                                                                  content:
                                                                    "确认",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onConfirmFillBtnClick(
                                                                        row[
                                                                          "fillId"
                                                                        ]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("确认")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.formDetail.pay &&
                                                        _vm.disableFill
                                                          ? [
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "dark",
                                                                    content:
                                                                      "表单已暂停，无法支付",
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    [
                                                                      row[
                                                                        "payStatus"
                                                                      ] == 2
                                                                        ? _c(
                                                                            "el-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  size: "mini",
                                                                                  status:
                                                                                    "primary",
                                                                                  content:
                                                                                    "支付",
                                                                                  disabled:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "支付"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        _vm.formDetail.pay &&
                                                        !_vm.disableFill
                                                          ? [
                                                              row[
                                                                "payStatus"
                                                              ] == 2
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "mini",
                                                                        status:
                                                                          "primary",
                                                                        content:
                                                                          "支付",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onPayFillClick(
                                                                              row[
                                                                                "fillId"
                                                                              ],
                                                                              2
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "支付"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                        row.payStatus <= 2
                                                          ? [
                                                              row["status"] ==
                                                                1 ||
                                                              row["status"] == 2
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "mini",
                                                                        status:
                                                                          "primary",
                                                                        content:
                                                                          "删除",
                                                                        disabled:
                                                                          _vm.disableFill,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onDeleteFillClick(
                                                                              row[
                                                                                "fillId"
                                                                              ]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "删除"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                        row.payStatus > 2
                                                          ? [
                                                              row["status"] ==
                                                                1 ||
                                                              row["status"] == 2
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "mini",
                                                                        status:
                                                                          "primary",
                                                                        content:
                                                                          "删除",
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onDeleteFillClick(
                                                                              row[
                                                                                "fillId"
                                                                              ]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "删除"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                        _vm.isShowFillListMoreButton &&
                                                        (!_vm.formDetail
                                                          .exportAfterAudit ||
                                                          (_vm.formDetail
                                                            .exportAfterAudit &&
                                                            row["status"] ==
                                                              14))
                                                          ? _c(
                                                              "el-dropdown",
                                                              {
                                                                staticStyle: {
                                                                  float:
                                                                    "initial",
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "dropdown",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "el-dropdown-menu",
                                                                              [
                                                                                !_vm
                                                                                  .formDetail
                                                                                  .exportAfterAudit ||
                                                                                (_vm
                                                                                  .formDetail
                                                                                  .exportAfterAudit &&
                                                                                  row[
                                                                                    "status"
                                                                                  ] ==
                                                                                    14)
                                                                                  ? [
                                                                                      _c(
                                                                                        "el-dropdown-item",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .formDetail
                                                                                                    .exportIsPerson &&
                                                                                                  _vm
                                                                                                    .formDetail
                                                                                                    .exportTemplate !=
                                                                                                    null &&
                                                                                                  !_vm
                                                                                                    .formDetail
                                                                                                    .exportIsPdf,
                                                                                                expression:
                                                                                                  "formDetail.exportIsPerson &&\n                            formDetail.exportTemplate != null &&\n                            !formDetail.exportIsPdf\n                            ",
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "el-button",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  status:
                                                                                                    "primary",
                                                                                                  content:
                                                                                                    "WORD导出",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.onExportMyFill(
                                                                                                      row[
                                                                                                        "fillId"
                                                                                                      ],
                                                                                                      "Word",
                                                                                                      row
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "WORD导出"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "el-dropdown-item",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .formDetail
                                                                                                    .exportIsPerson &&
                                                                                                  _vm
                                                                                                    .formDetail
                                                                                                    .exportTemplate !=
                                                                                                    null &&
                                                                                                  _vm
                                                                                                    .formDetail
                                                                                                    .exportIsPdf,
                                                                                                expression:
                                                                                                  "formDetail.exportIsPerson &&\n                            formDetail.exportTemplate != null &&\n                            formDetail.exportIsPdf\n                            ",
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "el-button",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  status:
                                                                                                    "primary",
                                                                                                  content:
                                                                                                    "PDF导出",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.onExportMyFill(
                                                                                                      row[
                                                                                                        "fillId"
                                                                                                      ],
                                                                                                      "Pdf",
                                                                                                      row
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "PDF导出"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  : _vm._e(),
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "el-dropdown-link",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          size: "mini",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "更多... "
                                                                        ),
                                                                        _c(
                                                                          "el-icon",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon--right",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "arrow-down"
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.editRowIndex === rowIndex
                                                    ? [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "danger",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$refs.gridTable.cancelEditFillClick(
                                                                    {
                                                                      row: row,
                                                                      rowIndex:
                                                                        rowIndex,
                                                                    },
                                                                    true
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$refs.gridTable.saveEditFillClick(
                                                                    {
                                                                      row: row,
                                                                      rowIndex:
                                                                        rowIndex,
                                                                    },
                                                                    true
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("提交")]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1993971094
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              999087299
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 4,
                      expression: "cur == 4",
                    },
                  ],
                  staticStyle: {
                    height: "100%",
                    display: "flex",
                    "flex-direction": "column",
                    "padding-top": "10px",
                  },
                  style:
                    "height:" +
                    (_vm.viewName == "webView" ? "500px" : "100%") +
                    ";",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100%",
                        display: "inline-block",
                        "flex-grow": "1",
                        "flex-shrink": "1",
                        "overflow-y": "auto",
                        overflow: "hidden",
                      },
                    },
                    [
                      _vm.cur == 4
                        ? _c("MyVxeTable", {
                            ref: "auditTable",
                            attrs: {
                              tableName: _vm.formId + "_audit",
                              columns: _vm.reviewGridOptions.columns,
                              loading: _vm.reviewGridOptions.loading,
                              data: _vm.reviewGridOptions.data,
                              params: _vm.reviewGridOptions.params,
                              pageConfig: _vm.reviewGridOptions.pagerConfig,
                              selectAllRowsHandler_: _vm.GetAllDataRows,
                              allowCheckAllPagesData: false,
                              agencyId: _vm.formDetail.agencyId,
                              formJson: _vm.formJson,
                              formDetail: _vm.formDetail,
                              rowEditable: _vm.reviewTableRowEditable,
                            },
                            on: {
                              "page-change": _vm.handleReviewPageChange,
                              "view-picture": _vm.onHandlePreviewImg,
                              "filter-change": _vm.reviewFilterChanged,
                              "sort-change": _vm.reviewSortChanged,
                              setEditRowIndex: _vm.setEditRowIndex,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "toolbar_search",
                                  fn: function () {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          "prefix-icon": "el-icon-search",
                                          placeholder: "全局搜索...",
                                          clearable: "",
                                        },
                                        on: {
                                          clear: _vm.onHandleResetReviewClick,
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onHandleQueryReviewClick(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.queryParams.fillValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "fillValue",
                                              $$v
                                            )
                                          },
                                          expression: "queryParams.fillValue",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "toolbar_button",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            disabled:
                                              _vm.reviewGridOptions.params
                                                .selectedRowKeys.length == 0,
                                            title: _vm.reviewGridOptions.params
                                              .isAllDataSelected
                                              ? "批量审核时无法审核全部记录"
                                              : "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onBatchReviewClick(
                                                _vm.reviewGridOptions.params
                                                  .selectedRowKeys,
                                                _vm.reviewGridOptions.params
                                                  .isAllDataSelected,
                                                _vm.reviewGridOptions.params
                                                  .excludeIds
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("批量审核")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "status_text",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "当前状态",
                                          width: "150",
                                          field: "status",
                                          "header-class-name": "sysfield",
                                          "filter-type": "status",
                                          isSysField: true,
                                          filters: [{ data: {} }],
                                          "filter-render": {
                                            name: "filter-status",
                                          },
                                          params: _vm.filters.status,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c("StatusCode", {
                                                    attrs: {
                                                      statusCode: row.status,
                                                      row: row,
                                                      fillStatusCode:
                                                        row.fillStatus,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          700667606
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "operate",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          "min-width": "150",
                                          "slots:default": "operate",
                                          fixed: "right",
                                          "header-class-name": "sysfield",
                                          align: "center",
                                          type: "operate",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c("span", [_vm._v("操作")]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                var rowIndex = ref.rowIndex
                                                return [
                                                  _vm.editRowIndex !== rowIndex
                                                    ? [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "primary",
                                                              content: "查看",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onViewFillClick(
                                                                  row["fillId"],
                                                                  "AUDIT",
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看")]
                                                        ),
                                                        row["auditButton"] &&
                                                        (row["status"] == "2" ||
                                                          row["status"] ==
                                                            "10" ||
                                                          row["status"] == "6")
                                                          ? [
                                                              row["status"] ==
                                                                "2" ||
                                                              row["status"] ==
                                                                "6" ||
                                                              row["status"] ==
                                                                "10"
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "mini",
                                                                        status:
                                                                          "primary",
                                                                        content:
                                                                          "审核",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onAuditFillClick(
                                                                              row[
                                                                                "fillId"
                                                                              ],
                                                                              row
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "审核"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                        _vm.formDetail
                                                          .auditAuthReUpdate &&
                                                        _vm.isShowAuthReUpdate(
                                                          row
                                                        )
                                                          ? [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    size: "mini",
                                                                    status:
                                                                      "primary",
                                                                    content:
                                                                      "修改审核",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onEditReviewClick(
                                                                          row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "修改审核"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.editRowIndex === rowIndex
                                                    ? [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "danger",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$refs.gridTable.cancelEditFillClick(
                                                                    {
                                                                      row: row,
                                                                      rowIndex:
                                                                        rowIndex,
                                                                    },
                                                                    true
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$refs.gridTable.saveEditFillClick(
                                                                    {
                                                                      row: row,
                                                                      rowIndex:
                                                                        rowIndex,
                                                                    },
                                                                    true
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("提交")]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1327790106
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              894796133
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 5,
                      expression: "cur == 5",
                    },
                  ],
                  staticStyle: { height: "100%", "padding-top": "10px" },
                },
                [
                  _vm.cur == 5
                    ? _c("MyVxeTable", {
                        ref: "gridTable",
                        attrs: {
                          tableName: _vm.formId + "_manage",
                          columns: _vm.gridOptions.columns,
                          loading: _vm.gridOptions.loading,
                          data: _vm.gridOptions.data,
                          params: _vm.gridOptions.params,
                          pageConfig: _vm.gridOptions.pagerConfig,
                          selectAllRowsHandler_: _vm.GetAllDataRows,
                          agencyId: _vm.formDetail.agencyId,
                          formJson: _vm.formJson,
                          formDetail: _vm.formDetail,
                          rowEditable: _vm.gridTableRowEditable,
                        },
                        on: {
                          "page-change": _vm.handleDataPageChange,
                          "view-picture": _vm.onHandlePreviewImg,
                          "filter-change": _vm.gridFilterChanged,
                          "sort-change": _vm.gridSortChanged,
                          setEditRowIndex: _vm.setEditRowIndex,
                          "clear-global-search": _vm.clearGlobalSearch,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "toolbar_search",
                              fn: function () {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      "prefix-icon": "el-icon-search",
                                      placeholder: "全局搜索...",
                                      clearable: "",
                                    },
                                    on: { clear: _vm.onHandleResetDataClick },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.onHandleQueryDataClick(
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dataQueryParams.fillValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataQueryParams,
                                          "fillValue",
                                          $$v
                                        )
                                      },
                                      expression: "dataQueryParams.fillValue",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "toolbar_share",
                              fn: function () {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "分享",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-link", {
                                        attrs: {
                                          icon: "el-icon-share",
                                          underline: false,
                                        },
                                        on: { click: _vm.onHandleShareClick },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "toolbar_function",
                              fn: function () {
                                return [
                                  _vm._l(
                                    _vm.dataRefreshActions,
                                    function (item, index) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: index,
                                          attrs: {
                                            type: item.backGround,
                                            icon: item.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doDataRefreshHandler(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }
                                  ),
                                  _vm.showStatisticRefreshButton(_vm.formDetail)
                                    ? _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.refreshStatisticHandler(
                                                _vm.formDetail
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("数据刷新")]
                                      )
                                    : _vm._e(),
                                  _vm.showStatusButtonShangHaiShiJue(
                                    _vm.formDetail
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.shangHaiShiJueChouJian(
                                                _vm.formDetail
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("论文抽取")]
                                      )
                                    : _vm._e(),
                                  _vm.showStatusButtonShangHaiShiJue(
                                    _vm.formDetail
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.shangHaiShiJuePiPei(
                                                _vm.formDetail
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("专家自动匹配")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-dropdown",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            background: "#f2f5f7",
                                            border: "none",
                                          },
                                          style:
                                            _vm.gridOptions.params
                                              .selectedRowKeys.length > 0
                                              ? "border: 1px solid #3476f0; "
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            " 批量操作 " +
                                              _vm._s(
                                                _vm.gridOptions.params
                                                  .selectedRowKeys.length > 0
                                                  ? _vm.gridOptions.params
                                                      .selectedRowKeys.length +
                                                      " 条"
                                                  : _vm.gridOptions.params
                                                      .isAllDataSelected &&
                                                    _vm.gridOptions.params
                                                      .allDataTotal
                                                  ? _vm.gridOptions.params
                                                      .allDataTotal + " 条"
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { "data-tag": "excel" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onExportForGridClick(
                                                    "excel",
                                                    null
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("导出Excel")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { "data-tag": "file" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onExportForGridClick(
                                                    "file",
                                                    null
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("导出附件")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { "data-tag": "word" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onExportForGridClick(
                                                    "word",
                                                    null
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v("导出word/pdf文档 "),
                                              _vm.formDetail.exportAfterAudit
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "仅能导出审核完成的数据",
                                                        placement: "top-start",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-info",
                                                      }),
                                                      _vm._v("> "),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("el-divider"),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onHandleImportNotSelfClick()
                                                },
                                              },
                                            },
                                            [_vm._v("批量导入")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.batchUpdateDataForExcel = true
                                                },
                                              },
                                            },
                                            [_vm._v("批量更新数据")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.gridOptions.params
                                                    .selectedRowKeys.length ==
                                                    0 &&
                                                  !_vm.gridOptions.params
                                                    .isAllDataSelected,
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.showMultiEdit = true
                                                },
                                              },
                                            },
                                            [_vm._v(" 批量编辑 ")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.gridOptions.params
                                                    .selectedRowKeys.length ==
                                                    0 &&
                                                  !_vm.gridOptions.params
                                                    .isAllDataSelected,
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.showMultiDelete = true
                                                },
                                              },
                                            },
                                            [_vm._v(" 批量删除 ")]
                                          ),
                                          _c("el-divider"),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onHandleNoticeClick(
                                                    _vm.formDetail
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("发布通知")]
                                          ),
                                          _vm.formDetail.pay
                                            ? _c("el-divider")
                                            : _vm._e(),
                                          _vm.formDetail.pay
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.gridOptions.params
                                                        .selectedRowKeys
                                                        .length == 0,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.onHandleEditPayPriceClick()
                                                    },
                                                  },
                                                },
                                                [_vm._v("修改订单金额")]
                                              )
                                            : _vm._e(),
                                          _vm.formDetail.pay
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.gridOptions.params
                                                        .selectedRowKeys
                                                        .length == 0,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.onHandleConfirmPayClick()
                                                    },
                                                  },
                                                },
                                                [_vm._v("调整支付状态")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("multiEdit", {
                                    attrs: {
                                      showMultiEdit: _vm.showMultiEdit,
                                      formJson: _vm.formJson,
                                      formId: _vm.formId,
                                      refresh: _vm.onHandleQueryDataClick,
                                      formOptionData: _vm.optionData,
                                      fillIds:
                                        _vm.gridOptions.params.selectedRowKeys,
                                      fillCacheKey:
                                        _vm.gridOptions.fillCacheKey,
                                      excludeIds:
                                        _vm.gridOptions.params.excludeIds,
                                      selectAll:
                                        _vm.gridOptions.params
                                          .isAllDataSelected,
                                    },
                                    on: {
                                      "update:showMultiEdit": function (
                                        $event
                                      ) {
                                        _vm.showMultiEdit = $event
                                      },
                                      "update:show-multi-edit": function (
                                        $event
                                      ) {
                                        _vm.showMultiEdit = $event
                                      },
                                    },
                                  }),
                                  _c("multiDelete", {
                                    attrs: {
                                      showMultiDelete: _vm.showMultiDelete,
                                      formJson: _vm.formJson,
                                      formId: _vm.formId,
                                      refresh: _vm.onHandleQueryDataClick,
                                      formOptionData: _vm.optionData,
                                      fillIds:
                                        _vm.gridOptions.params.selectedRowKeys,
                                      fillCacheKey:
                                        _vm.gridOptions.fillCacheKey,
                                      excludeIds:
                                        _vm.gridOptions.params.excludeIds,
                                      formName: _vm.formDetail.name,
                                      selectAll:
                                        _vm.gridOptions.params
                                          .isAllDataSelected,
                                    },
                                    on: {
                                      "update:showMultiDelete": function (
                                        $event
                                      ) {
                                        _vm.showMultiDelete = $event
                                      },
                                      "update:show-multi-delete": function (
                                        $event
                                      ) {
                                        _vm.showMultiDelete = $event
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "toolbar_button",
                              fn: function () {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.disableFill,
                                      },
                                      on: { click: _vm.onFillClickCheck },
                                    },
                                    [_vm._v("开始填报")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "status_text",
                              fn: function () {
                                return [
                                  _c("vxe-column", {
                                    attrs: {
                                      title: "当前状态",
                                      width: "150",
                                      field: "status",
                                      "header-class-name": "sysfield",
                                      "filter-type": "status",
                                      isSysField: true,
                                      filters: [{ data: {} }],
                                      "filter-render": {
                                        name: "filter-status",
                                      },
                                      params: _vm.filters.status,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c("StatusCode", {
                                                attrs: {
                                                  statusCode: row.status,
                                                  row: row,
                                                  fillStatusCode:
                                                    row.fillStatus,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      700667606
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "operate",
                              fn: function () {
                                return [
                                  _c("vxe-column", {
                                    attrs: {
                                      "min-width": "150",
                                      "slots:default": "operate",
                                      fixed: "right",
                                      align: "center",
                                      type: "operate",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _c("span", [_vm._v("操作")]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            var rowIndex = ref.rowIndex
                                            return [
                                              _vm.editRowIndex !== rowIndex
                                                ? [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          status: "primary",
                                                          size: "mini",
                                                          content: "查看",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onViewFillClick(
                                                              row["fillId"],
                                                              "MANAGE",
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("查看")]
                                                    ),
                                                    _vm.formDetail.auditAuth
                                                      ? [
                                                          true ||
                                                          row["status"] == 1 ||
                                                          row["status"] == 2 ||
                                                          row["status"] == 5 ||
                                                          row["status"] == 9
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    size: "mini",
                                                                    status:
                                                                      "primary",
                                                                    content:
                                                                      "修改",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onEditFillClick(
                                                                          row[
                                                                            "fillId"
                                                                          ],
                                                                          "MANAGE",
                                                                          row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("修改")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                              size: "mini",
                                                              status: "primary",
                                                              content: "修改",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onEditFillClick(
                                                                  row["fillId"],
                                                                  "MANAGE",
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("修改")]
                                                        ),
                                                    !_vm.formDetail.pay ||
                                                    (_vm.formDetail.pay &&
                                                      _vm.formDetail.userId ==
                                                        _vm.loginUserId)
                                                      ? [
                                                          true ||
                                                          row["status"] == 1 ||
                                                          row["status"] == 2
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    size: "mini",
                                                                    status:
                                                                      "warning",
                                                                    content:
                                                                      "删除",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDeleteManageFillClick(
                                                                          row[
                                                                            "fillId"
                                                                          ],
                                                                          row[
                                                                            "formId"
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("删除")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                    _vm.isShowAdminListMoreButton &&
                                                    (!_vm.formDetail
                                                      .exportAfterAudit ||
                                                      (_vm.formDetail
                                                        .exportAfterAudit &&
                                                        row["status"] == 14))
                                                      ? _c(
                                                          "el-dropdown",
                                                          {
                                                            staticStyle: {
                                                              float: "initial",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "dropdown",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "el-dropdown-menu",
                                                                        [
                                                                          !_vm
                                                                            .formDetail
                                                                            .exportAfterAudit ||
                                                                          (_vm
                                                                            .formDetail
                                                                            .exportAfterAudit &&
                                                                            row[
                                                                              "status"
                                                                            ] ==
                                                                              14)
                                                                            ? [
                                                                                _c(
                                                                                  "el-dropdown-item",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm
                                                                                              .formDetail
                                                                                              .exportTemplate &&
                                                                                            _vm
                                                                                              .formDetail
                                                                                              .exportTemplate !=
                                                                                              null &&
                                                                                            !_vm
                                                                                              .formDetail
                                                                                              .exportIsPdf,
                                                                                          expression:
                                                                                            "formDetail.exportTemplate &&\n                          formDetail.exportTemplate != null &&\n                          !formDetail.exportIsPdf\n                          ",
                                                                                        },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "text",
                                                                                            icon: "el-icon-download",
                                                                                            status:
                                                                                              "primary",
                                                                                            content:
                                                                                              "WORD导出",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onExportMyFill(
                                                                                                row[
                                                                                                  "fillId"
                                                                                                ],
                                                                                                "Word"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "WORD导出"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "el-dropdown-item",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm
                                                                                              .formDetail
                                                                                              .exportTemplate &&
                                                                                            _vm
                                                                                              .formDetail
                                                                                              .exportTemplate !=
                                                                                              null &&
                                                                                            _vm
                                                                                              .formDetail
                                                                                              .exportIsPdf,
                                                                                          expression:
                                                                                            "formDetail.exportTemplate &&\n                          formDetail.exportTemplate != null &&\n                          formDetail.exportIsPdf\n                          ",
                                                                                        },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "text",
                                                                                            icon: "el-icon-download",
                                                                                            status:
                                                                                              "primary",
                                                                                            content:
                                                                                              "PDF导出",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onExportMyFill(
                                                                                                row[
                                                                                                  "fillId"
                                                                                                ],
                                                                                                "Pdf"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "PDF导出"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "el-dropdown-link",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      size: "mini",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "更多... "
                                                                    ),
                                                                    _c(
                                                                      "el-icon",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon--right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "arrow-down"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                              _vm.editRowIndex === rowIndex
                                                ? [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                          status: "danger",
                                                        },
                                                        on: {
                                                          click: function () {
                                                            _vm.$refs.gridTable.cancelEditFillClick(
                                                              {
                                                                row: row,
                                                                rowIndex:
                                                                  rowIndex,
                                                              },
                                                              true
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                          status: "primary",
                                                        },
                                                        on: {
                                                          click: function () {
                                                            _vm.$refs.gridTable.saveEditFillClick(
                                                              {
                                                                row: row,
                                                                rowIndex:
                                                                  rowIndex,
                                                              },
                                                              true
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("提交")]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4053976459
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3280505428
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.cur == 6
                ? _c("div", {
                    staticStyle: { height: "100%", "padding-top": "10px" },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 8,
                      expression: "cur == 8",
                    },
                  ],
                  staticStyle: {
                    padding: "20px",
                    height: "100%",
                    "overflow-y": "auto",
                  },
                },
                [
                  _c("div", { staticClass: "chart-desc-container" }, [
                    _vm.largeFormList.length > 0
                      ? _c("div", { staticClass: "statics-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "static-title-tabs" },
                            _vm._l(_vm.largeFormList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "static-tab click-tab",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showLargeScreen(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "statics-wrapper" }, [
                      _c("div", { staticClass: "statics-title" }, [
                        _vm._v("发布概况"),
                      ]),
                      _c("div", { staticClass: "count-chart-container" }, [
                        _c("div", { staticClass: "count-chart-item" }, [
                          _c("span", { staticClass: "current-amount" }, [
                            _vm._v(_vm._s(_vm.formStatics.viewNum)),
                          ]),
                          _c("span", { staticClass: "sum" }, [
                            _vm._v("总浏览量"),
                          ]),
                        ]),
                        _c("div", { staticClass: "count-chart-item" }, [
                          _c("span", { staticClass: "current-amount" }, [
                            _vm._v(_vm._s(_vm.formStatics.favoriteNum)),
                          ]),
                          _c("span", { staticClass: "sum" }, [
                            _vm._v("收藏量"),
                          ]),
                        ]),
                        _c("div", { staticClass: "count-chart-item" }, [
                          _c("span", { staticClass: "current-amount" }, [
                            _vm._v(_vm._s(_vm.formStatics.shareNum)),
                          ]),
                          _c("span", { staticClass: "sum" }, [
                            _vm._v("分享量"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "statics-wrapper" }, [
                      _c("div", { staticClass: "statics-title" }, [
                        _vm._v("数据概况"),
                      ]),
                      _c("div", { staticClass: "count-chart-container" }, [
                        _c("div", { staticClass: "count-chart-item" }, [
                          _c("span", { staticClass: "current-amount" }, [
                            _vm._v(_vm._s(_vm.formStatics.totalFillNum)),
                          ]),
                          _c("span", { staticClass: "sum" }, [
                            _vm._v("提交数据总量"),
                          ]),
                        ]),
                        _c("div", { staticClass: "count-chart-item" }, [
                          _c("span", { staticClass: "current-amount" }, [
                            _vm._v(_vm._s(_vm.formStatics.todayFillNum)),
                          ]),
                          _c("span", { staticClass: "sum" }, [
                            _vm._v("今日新增"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "count-chart-item",
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.fillUserViewer },
                          },
                          [
                            _c("span", { staticClass: "current-amount" }, [
                              _vm._v(_vm._s(_vm.formStatics.fillUserNum)),
                            ]),
                            _c("span", { staticClass: "sum" }, [
                              _vm._v("已填报人数"),
                            ]),
                          ]
                        ),
                        _vm.formStatics.notFillUserNum != null
                          ? _c(
                              "div",
                              {
                                staticClass: "count-chart-item",
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.unfillUserViewer },
                              },
                              [
                                _c("span", { staticClass: "current-amount" }, [
                                  _vm._v(
                                    _vm._s(_vm.formStatics.notFillUserNum)
                                  ),
                                ]),
                                _c("span", { staticClass: "sum" }, [
                                  _vm._v("未填报人数"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.formStatics.totalUserNum != null
                          ? _c("div", { staticClass: "count-chart-item" }, [
                              _c("span", { staticClass: "current-amount" }, [
                                _vm._v(_vm._s(_vm.formStatics.totalUserNum)),
                              ]),
                              _c("span", { staticClass: "sum" }, [
                                _vm._v("总人数"),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.formStatics.completeProportion != null
                          ? _c("div", { staticClass: "count-chart-item" }, [
                              _c("span", { staticClass: "current-amount" }, [
                                _vm._v(
                                  _vm._s(_vm.formStatics.completeProportion)
                                ),
                              ]),
                              _c("span", { staticClass: "sum" }, [
                                _vm._v("完成率"),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.staticFieldList && _vm.staticFieldList.length > 0
                      ? _c("div", { staticClass: "statics-wrapper" }, [
                          _c("div", { staticClass: "statics-title" }, [
                            _vm._v("数据统计"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-data-list" },
                            _vm._l(_vm.staticFieldList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "file_" + index,
                                  staticClass: "data-list-item",
                                },
                                [
                                  item.fieldType === "radio" ||
                                  item.fieldType === "select" ||
                                  item.fieldType === "checkbox"
                                    ? [
                                        _c("h3", [
                                          _c("span", { staticClass: "num" }, [
                                            _vm._v(
                                              _vm._s(_vm.padLeft(index + 1))
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(
                                                    item.fieldType === "radio"
                                                      ? "单选"
                                                      : item.fieldType ===
                                                        "checkbox"
                                                      ? "多选"
                                                      : "下拉选项"
                                                  ) +
                                                  "】"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "el-tabs",
                                          { attrs: { value: "dataTable" } },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "数据表格",
                                                  name: "dataTable",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "650px",
                                                      "min-height": "300px",
                                                      "margin-bottom": "50px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        attrs: {
                                                          data: item.fieldOptionList,
                                                          border: "",
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "name",
                                                            label: "选项",
                                                            "min-width": "200",
                                                          },
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "value",
                                                            label: "小计",
                                                            width: "100",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "ratio",
                                                            label: "占比",
                                                            width: "300",
                                                            align: "left",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    !scope.row
                                                                      .summary
                                                                      ? _c(
                                                                          "el-progress",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                percentage:
                                                                                  Number(
                                                                                    (
                                                                                      (scope
                                                                                        .row
                                                                                        .value *
                                                                                        100) /
                                                                                      (item.validNum ||
                                                                                        1)
                                                                                    ).toFixed(
                                                                                      2
                                                                                    )
                                                                                  ),
                                                                                "text-inside": true,
                                                                                "stroke-width": 15,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "统计图",
                                                  name: "statChart",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "statics-echarts-container",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "650px",
                                                          "min-height": "300px",
                                                          "margin-bottom":
                                                            "50px",
                                                        },
                                                      },
                                                      [
                                                        _c("myEcharts", {
                                                          attrs: {
                                                            echartsData:
                                                              _vm.getEchartsData(
                                                                item.fieldOptionList,
                                                                item.fieldOptionLimit
                                                              ),
                                                            type: item.echartsType,
                                                            width: "500px",
                                                            height:
                                                              item
                                                                .fieldOptionList
                                                                .length *
                                                                10 +
                                                              200 +
                                                              "px",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "图表",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      "value-key":
                                                                        "echartsType",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.echartsTypeSelect,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.echartsType,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "echartsType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.echartsType",
                                                                    },
                                                                  },
                                                                  [
                                                                    item.fieldType !==
                                                                    "7"
                                                                      ? _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "饼状图",
                                                                                value:
                                                                                  "pie",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "柱状图",
                                                                          value:
                                                                            "bar",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "折线图",
                                                                          value:
                                                                            "line",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            item.fieldOptionList
                                                              .length > 10
                                                              ? _c(
                                                                  "el-form-item",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "显示数量",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        attrs: {
                                                                          placeholder:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.fieldOptionLimit,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "fieldOptionLimit",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.fieldOptionLimit",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "不限制",
                                                                                value: 0,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "5",
                                                                                value: 5,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "10",
                                                                                value: 10,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "20",
                                                                                value: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "50",
                                                                                value: 50,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : item.fieldType === "图片选择"
                                    ? [
                                        _c("h3", [
                                          _c("span", { staticClass: "num" }, [
                                            _vm._v(
                                              _vm._s(_vm.padLeft(index + 1))
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [_vm._v("【图片选择】")]
                                          ),
                                        ]),
                                        _c(
                                          "el-tabs",
                                          { attrs: { value: "dataTable" } },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "数据表格",
                                                  name: "dataTable",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "650px",
                                                      "min-height": "300px",
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        attrs: {
                                                          data: item.fieldOptionList,
                                                          border: "",
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "name",
                                                            label: "名称",
                                                            "min-width": "200",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "vertical-align":
                                                                              "middle",
                                                                          },
                                                                      },
                                                                      [
                                                                        scope
                                                                          .row
                                                                          .thumb
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "divPreview",
                                                                                style:
                                                                                  {
                                                                                    backgroundImage:
                                                                                      "url('" +
                                                                                      scope
                                                                                        .row
                                                                                        .thumb +
                                                                                      "')",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.showStatusDataImagePreview(
                                                                                        scope.row
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "divPreviewName",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .name
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "value",
                                                            label: "小计",
                                                            width: "100",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "ratio",
                                                            label: "占比",
                                                            width: "300",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    !scope.row
                                                                      .summary
                                                                      ? _c(
                                                                          "el-progress",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                percentage:
                                                                                  Number(
                                                                                    (
                                                                                      (scope
                                                                                        .row
                                                                                        .value *
                                                                                        100) /
                                                                                      (scope
                                                                                        .row
                                                                                        .totalSelect ||
                                                                                        1)
                                                                                    ).toFixed(
                                                                                      2
                                                                                    )
                                                                                  ),
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "统计图",
                                                  name: "statChart",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "statics-echarts-container",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "650px",
                                                          "min-height": "300px",
                                                          "margin-bottom":
                                                            "20px",
                                                        },
                                                      },
                                                      [
                                                        _c("myEcharts", {
                                                          attrs: {
                                                            echartsData:
                                                              _vm.getEchartsData(
                                                                item.fieldOptionList
                                                              ),
                                                            type: item.echartsType,
                                                            width: "500px",
                                                            height:
                                                              item
                                                                .fieldOptionList
                                                                .length *
                                                                30 +
                                                              120 +
                                                              "px",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c("h3", [
                                          _c("span", { staticClass: "num" }, [
                                            _vm._v(
                                              _vm._s(_vm.padLeft(index + 1))
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [_vm._v(_vm._s(item.displayName))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "fieldTitle" },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(
                                                    item.type === "input"
                                                      ? "单行输入"
                                                      : "多行输入"
                                                  ) +
                                                  "】"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "40px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.wordcloudDialog.showDialog(
                                                        item,
                                                        _vm.formId
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("词云统计")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("div", {
                                          staticStyle: {
                                            "margin-bottom": "30px",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核结果",
            visible: _vm.fillReviewOpen,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fillReviewOpen = $event
            },
            close: _vm.onCloseFillReviewDialog,
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.fillReviewList },
            },
            [
              _c("el-table-column", {
                attrs: { label: "审核时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.reviewTime ? scope.row.reviewTime : "--"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核人", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c("span", [
                              _vm._v(
                                "待" +
                                  _vm._s(scope.row.waitReviewAgencyUserName) +
                                  "审核"
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.reviewAgencyUserName
                                    ? scope.row.reviewAgencyUserName
                                    : "--"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("审核中")]
                            )
                          : _vm._e(),
                        scope.row.status == "2"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("已通过")]
                            )
                          : _vm._e(),
                        scope.row.status == "3"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("已驳回")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核意见", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.reviewRemark
                                ? scope.row.reviewRemark
                                : "--"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核详情",
            visible: _vm.reviewDialog.open,
            width: "800px",
            "destroy-on-close": "",
            "append-to-body": "",
            "custom-class": "audit-process-dialog",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.reviewDialog, "open", $event)
            },
            opened: _vm.onShowReviewDialog,
            close: _vm.onHandleReviewClose,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isAuditLoading,
                  expression: "isAuditLoading",
                },
              ],
              staticClass: "review-contain",
            },
            [
              _c("AuditForm", {
                ref: "reviewFormDialog",
                attrs: {
                  loginUserId: _vm.loginUserId,
                  reviewForm: _vm.reviewForm,
                  fillId: _vm.fillId,
                  formDetail: _vm.formDetail,
                  isAllDataSelected:
                    _vm.reviewGridOptions.params.isAllDataSelected,
                  fillCacheKey: _vm.reviewGridOptions.fillCacheKey,
                  selectedRowKeys: _vm.selectedRowKeys,
                  excludeIds: _vm.reviewGridOptions.params.excludeIds,
                },
              }),
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { width: "100%", "text-align": "right" } },
              [
                _vm.isMultipleSelectionFill
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSubmitMultipleReviewClick },
                      },
                      [_vm._v("提交")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSubmitReviewClick },
                      },
                      [_vm._v("提交")]
                    ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.onCancelReviewClick },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填报地址",
            visible: _vm.shareOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareOpen = $event
            },
            close: _vm.handleFillDiaClose,
          },
        },
        [
          _c("div", { staticClass: "share-container" }, [
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v(" 链接分享 ")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v(" 将填报地址粘贴到浏览器访问，或者直接发给填报者 ")]
            ),
            _c(
              "div",
              { staticStyle: { width: "100%", height: "auto" } },
              [
                _c("el-input", {
                  staticClass: "fl",
                  staticStyle: { width: "60%", "margin-right": "40px" },
                  attrs: {
                    type: "text",
                    value: _vm.shareFillUrl,
                    readonly: "",
                  },
                }),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onCopyFillClick },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.onToClick },
                      },
                      [_vm._v("直接打开")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v(" 小程序码分享 ")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v(" 选择小程序码样式，保存小程序码直接发给填报者 ")]
            ),
            _c("img", {
              staticClass: "list-share-image",
              attrs: { src: _vm.shareImageUrl },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  width: "398px",
                  margin: "auto",
                  "text-align": "center",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "20px auto 0" },
                    attrs: { type: "primary" },
                    on: { click: _vm.onSaveClick },
                  },
                  [_vm._v("保存小程序码")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dataDownloadOpen,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dataDownloadOpen = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "data-download-content",
              style: {
                justifyContent:
                  _vm.formDetail.userId == _vm.loginUserId
                    ? "space-around"
                    : "center",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "type-content-default",
                  on: { click: _vm.onDownLoadExcel },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/default_icon.png",
                    },
                  }),
                  _c("p", [_vm._v("Excel文件")]),
                ]
              ),
              _vm.formDetail.userId == _vm.loginUserId
                ? _c(
                    "div",
                    {
                      staticClass: "type-content-page",
                      on: { click: _vm.onDownLoadAll },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/page_icon.png",
                        },
                      }),
                      _c("p", [_vm._v("Excel文件（含附件）")]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出详情",
            visible: _vm.dataDownloadAllOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dataDownloadAllOpen = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.onCreateDownloadClick },
            },
            [_vm._v("数据下载")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.fillDataZipDownList, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "下载创建时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "downStartTime",
                  label: "数据打包开始时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "downEndTime",
                  label: "链接生成时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expireTime",
                  label: "链接过期时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "任务状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("排队下载中")]
                            )
                          : scope.row.status == "2"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("数据打包中")]
                            )
                          : scope.row.status == "3"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("数据打包完成")]
                            )
                          : scope.row.status == "4"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                scope.row.errorMsg
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.errorMsg)),
                                    ])
                                  : _c("span", [_vm._v("数据打包失败")]),
                              ]
                            )
                          : scope.row.status == "5"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v("下载链接过期")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              disabled: scope.row.status == "3" ? false : true,
                            },
                          },
                          [
                            scope.row.status == "3"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.apiPathUrl +
                                        "/dataDownload/downloadZip/" +
                                        scope.row.zipDownId +
                                        "?token=" +
                                        _vm.Authorization,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("下载")]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      cursor: "not-allowed",
                                      color: "#C0C4CC",
                                    },
                                    attrs: { href: "javascript:void(0);" },
                                  },
                                  [_vm._v("下载")]
                                ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入填报",
            visible: _vm.fillOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fillOpen = $event
            },
            close: _vm.onCloseUploadFillClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "formFillUpload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                action: "",
                "http-request": _vm.handleFillFileRequest,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                      on: { click: _vm.onDownloadExcelTemplate },
                    },
                    [_vm._v("下载模板")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 提示：仅允许导入“xls”或“xlsx”格式文件！ ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmUploadFillClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelUploadFillClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("vxe-modal", {
        attrs: {
          title: "查看图片",
          width: "500",
          height: "600",
          mask: false,
          "lock-view": false,
          resize: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "preview-img-bar" }, [
                  _c(
                    "span",
                    { on: { click: _vm.onMagnifyImg } },
                    [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "18px",
                          height: "18px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "imgMagnify" },
                      }),
                      _vm._v("放大 "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { on: { click: _vm.onShrinkImg } },
                    [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "18px",
                          height: "18px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "imgShrink" },
                      }),
                      _vm._v("缩小 "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { on: { click: _vm.onRotateImg } },
                    [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          "vertical-align": "-4px",
                          "margin-right": "5px",
                        },
                        attrs: { "icon-class": "imgScale" },
                      }),
                      _vm._v("旋转 "),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "show-image" }, [
                  _c("img", {
                    style: _vm.styleObj,
                    attrs: { src: _vm.showDetailsImg },
                    on: {
                      mousewheel: function ($event) {
                        $event.preventDefault()
                        return _vm.onGunlunImg($event)
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showDetails,
          callback: function ($$v) {
            _vm.showDetails = $$v
          },
          expression: "showDetails",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入他人填报",
            visible: _vm.notSelfFillOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notSelfFillOpen = $event
            },
            close: _vm.onCloseNotSelfUploadFillClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "notSelfFillUpload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                action: "",
                "http-request": _vm.handleNotSelfFillFileRequest,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                      on: { click: _vm.onDownloadNotSelfExcelTemplate },
                    },
                    [_vm._v("下载模板")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 提示：仅允许导入“xls”或“xlsx”格式文件！ ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmNotSelfUploadFillClick },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.onCancelNotSelfUploadFillClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量更新数据",
            visible: _vm.batchUpdateDataForExcel,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchUpdateDataForExcel = $event
            },
            close: _vm.onCloseNotSelfUploadFillClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "notSelfFillUpload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                action: "",
                "http-request": _vm.handleNotSelfFillFileRequest,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 提示：仅允许导入“xls”或“xlsx”格式文件！ ")]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onBatchUpdateDataForExcel },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.onCancelNotSelfUploadFillClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.notSelfFillFailOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notSelfFillFailOpen = $event
            },
            close: _vm.onCloseNotSelfUploadFillClick,
          },
        },
        [
          _c("div", { staticStyle: { "line-height": "30px" } }, [
            _vm._v(
              " 系统检查出您导入的数据有部分存在问题，已将问题数据整理成为Excel，请点击下载修改 "
            ),
          ]),
          _c(
            "div",
            { staticStyle: { height: "30px", "line-height": "30px" } },
            [
              _c(
                "a",
                {
                  staticStyle: { color: "#1890FF" },
                  attrs: { href: _vm.notSelfFillFailUrl },
                },
                [_vm._v("点击此处下载导入失败的Excel")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.notSelfFillFailOpen = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fillSuccessShow,
            width: "430px",
            "custom-class": "el-dialog-hide-header",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fillSuccessShow = $event
            },
            close: _vm.onCloseFillSuccessPop,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("提交成功！ "),
                ]
              ),
              _vm.customTip.commitTipShow == 0
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报” "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 例如可实时接收审核状态变更提醒、管理员定向提醒通知等。 "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: {
                        "max-height": "450px",
                        "overflow-y": "auto",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.commitTipContent),
                      },
                    }),
                  ],
              _vm.formDetail.pay
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "10px auto 20px",
                          "line-height": "20px",
                        },
                      },
                      [
                        _vm._v(
                          " 系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。 "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pay-footer",
                        staticStyle: {
                          display: "-webkit-flex",
                          "justify-content": "space-around",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { on: { click: _vm.onCancelPayFillClick } },
                          [_vm._v("暂不支付")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmPayFillClick },
                          },
                          [_vm._v("立即支付")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.paySuccessShow,
            width: "430px",
            "custom-class": "el-dialog-hide-header",
            "append-to-body": "",
            close: "onClosePaySuccessPop",
          },
          on: {
            "update:visible": function ($event) {
              _vm.paySuccessShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("支付成功！ "),
                ]
              ),
              _vm.customTip.payTipShow == 0
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报” "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 例如可实时接收审核状态变更提醒、管理员定向提醒通知等。 "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: {
                        "max-height": "450px",
                        "overflow-y": "auto",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.payTipContent),
                      },
                    }),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据填报支付",
            visible: _vm.formPayOpen,
            "custom-class": "el-dialog-hide-header",
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formPayOpen = $event
            },
            close: _vm.onClosePay,
          },
        },
        [
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v(" 本次填报支付" + _vm._s(_vm.orderPrice / 100) + "元 "),
          ]),
          _c("div", {
            ref: "qrCodeUrl",
            staticClass: "qrCodeCon",
            staticStyle: {
              "text-align": "center",
              margin: "auto",
              width: "200px",
            },
            attrs: { id: "qrCode" },
          }),
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v("微信扫一扫付款"),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "通知",
            visible: _vm.dataNoticeShow,
            width: "640px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dataNoticeShow = $event
            },
            opened: _vm.onDataNoticeOpen,
            close: _vm.onDataNoticeClose,
          },
        },
        [
          _c("h5", { staticClass: "noticeHTitle" }, [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "14px",
                  color: "#999",
                  "font-weight": "normal",
                },
              },
              [_vm._v("注：关注微信公众“鲸鱼填报”的用户才能收到此通知")]
            ),
            _c("img", {
              staticClass: "officialQrImg",
              staticStyle: { width: "25px", height: "25px", cursor: "pointer" },
              attrs: { src: _vm.qrImg },
            }),
            _c("img", {
              staticClass: "officialChatImg",
              attrs: { src: _vm.chatImg },
            }),
          ]),
          _c(
            "el-form",
            {
              ref: "noticeForm",
              attrs: {
                model: _vm.noticeModel,
                rules: _vm.noticeRules,
                "label-width": "90px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "noticeOverview", label: "通知概览" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入通知概览",
                        },
                        model: {
                          value: _vm.noticeModel.noticeOverview,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeModel, "noticeOverview", $$v)
                          },
                          expression: "noticeModel.noticeOverview",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "noticeDetails", label: "通知详情" } },
                [
                  _c("richEditor", {
                    attrs: {
                      formId: _vm.formId,
                      agencyId: _vm.agencyId,
                      uploadPath: "fillFile/{agencyId}/{formId}/formNotice/",
                      Settings: { placeholder: "请输入通知详情" },
                    },
                    model: {
                      value: _vm.noticeModel.noticeDetails,
                      callback: function ($$v) {
                        _vm.$set(_vm.noticeModel, "noticeDetails", $$v)
                      },
                      expression: "noticeModel.noticeDetails",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmNoticeClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onDataNoticeClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dataShareShow && _vm.loginUserId
        ? _c("dataShare", {
            ref: "dataShare",
            attrs: { formId: _vm.formId, formJson: this.formJson },
            model: {
              value: _vm.dataShareShow,
              callback: function ($$v) {
                _vm.dataShareShow = $$v
              },
              expression: "dataShareShow",
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "表单填报支付",
            visible: _vm.myFillListPayOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.myFillListPayOpen = $event
            },
            close: _vm.onCloseMyFillListPay,
          },
        },
        [
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v(
              " 本次填报支付" + _vm._s(_vm.myFillListPayPrice / 100) + "元 "
            ),
          ]),
          _c("div", {
            ref: "myFillListQrCodeUrl",
            staticClass: "qrCodeCon",
            staticStyle: {
              "text-align": "center",
              margin: "auto",
              width: "200px",
            },
            attrs: { id: "myFillListQrCode" },
          }),
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v("微信扫一扫付款"),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改订单金额",
            visible: _vm.editPayPriceOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPayPriceOpen = $event
            },
            close: _vm.onCloseEditPayPriceClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPayPriceForm",
              staticClass: "clearfix",
              attrs: { "label-width": "80px", "label-position": "left" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单金额", prop: "pay" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px", display: "inline-block" },
                    attrs: { type: "text", placeholder: "请输入支付金额" },
                    model: {
                      value: _vm.editPayPrice,
                      callback: function ($$v) {
                        _vm.editPayPrice = $$v
                      },
                      expression: "editPayPrice",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("元"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmEditPayPriceClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCloseEditPayPriceClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认支付",
            visible: _vm.confirmPayStatusOpen,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.confirmPayStatusOpen = $event
            },
            close: _vm.onCloseEditPayStatusClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "confirmPriceForm",
              staticClass: "clearfix",
              attrs: { "label-width": "80px", "label-position": "left" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "支付状态", prop: "payStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择支付状态" },
                      model: {
                        value: _vm.confirmPayStatus,
                        callback: function ($$v) {
                          _vm.confirmPayStatus = $$v
                        },
                        expression: "confirmPayStatus",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "未支付", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "确认到款", value: "4" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmPayStatusClick },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.onCloseEditPayStatusClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择需要导出的列信息",
            visible: _vm.exportFieldSelect,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportFieldSelect = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "500px", "overflow-y": "auto" } },
            [
              _c("el-tree", {
                ref: "channelTree",
                attrs: {
                  "default-checked-keys": _vm.defaultSelectAll,
                  props: _vm.defaultFieldProps,
                  "check-on-click-node": true,
                  data: _vm.exportFieldList,
                  "show-checkbox": "",
                  "node-key": "field",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "span",
                        {},
                        [
                          data.type === "sysField"
                            ? _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  staticStyle: { "font-size": "10px" },
                                  attrs: { value: "系", type: "primary" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v(_vm._s(node.label))]
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(node.label))]),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onExportExcelClick(_vm.exportExcelType)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.exportFieldSelect = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("VFormViewer", {
        ref: "dlgvFormViewer",
        attrs: {
          formAuth: _vm.formAuth,
          onHandleImportClick: _vm.onHandleImportClick,
        },
        on: { "on-save": _vm.onHandleEditRefreshData },
      }),
      _c("fillSelector", {
        ref: "fillSelector",
        attrs: { optionData: _vm.optionData },
      }),
      _c("fillUserViewer", {
        ref: "fillUserViewer",
        attrs: { formId: _vm.formId },
      }),
      _c("fillAgreement", {
        ref: "fillAgreement",
        attrs: { formAgreement: _vm.agreement },
        on: { agree: _vm.onFillClick },
      }),
      _c("userSelect", {
        ref: "userSelect",
        attrs: { callback: _vm.userSelectCallback, formDetail: _vm.formDetail },
      }),
      _c("wordcloudDialog", { ref: "wordcloudDialog" }),
      _vm.loginUserId ? _c("bindUserMobile") : _vm._e(),
      _c("draft", {
        ref: "draft",
        attrs: {
          formAuth: _vm.formAuth,
          formId: _vm.formId,
          formJson: _vm.formJson,
          formDetail: _vm.formDetail,
        },
        on: { "on-draft-submit": _vm.onHandleResetFillClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }