<template>
  <div style="position:relative">
    <div id="mywordcloud" ref="mywordcloud" :style="{width: '100%', height: '300px'}" v-if="worddata && worddata.length>0"></div>
    <div v-else class="empty">
      {{tipText}}
    </div>
    <div ref="delRef" class="delete" :style="delStyle">
      删除
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";

export default {
  name: "commonWordCloud",
  props: {
    formId: {
      type: String,
      default: ''
    },
    fieldId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      worddata: [],
      tipText: '词云正则生成请稍候...',
      delStyle: {
        display: 'none',
        top: '0px',
        left: '0px'
      }
    }
  },
  methods: {
    initChart (wordCloudData) {
      let self = this;
      this.worddata = wordCloudData;
      if (!wordCloudData || wordCloudData.length == 0) {

        this.tipText = "暂无数据";
        return
      }
      this.$nextTick(() => {
        this.chart = echarts.init(this.$refs.mywordcloud);

        const option = {
          title: {
            text: "",
            x: "center"
          },
          backgroundColor: "#fff",
          // tooltip: {
          //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
          // },
          series: [
            {
              type: "wordCloud",
              //用来调整词之间的距离
              gridSize: 10,
              //位置相关设置
              // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
              // Default to be put in the center and has 75% x 80% size.
              left: "center",
              top: "center",
              right: null,
              bottom: null,
              width: "200%",
              height: "200%",
              sizeRange: [12, 60],
              rotationRange: [-90, 90],
              rotationStep: 45,
              gridSize: 8,
              drawOutOfBound: false,
              layoutAnimation: true,
              textStyle: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                color: function () {
                  return 'rgb(' + [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                  ].join(',') + ')';
                }
              },
              emphasis: {
                // focus: 'self',
                textStyle: {
                  textShadowBlur: 3,
                  textShadowColor: '#333'
                }
              },
              //数据
              data: this.worddata
            }
          ]
        };
        this.chart.setOption(option);
        // 点击某个字
        this.chart.on('click', function (params) {
          // 弹出提示，是否删除

          self.$confirm("需要移除\"" + params.data.name + "\"吗", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let wordIndex = self.worddata.findIndex(x => x.name === params.data.name);
            if (wordIndex > -1) {
              self.worddata.splice(wordIndex, 1);
              option.series[0].data = self.worddata;

              self.chart.setOption(option);
            }

          })
        })


        // this.chart.on('mouseover', function (params) {
        //   console.log('myChart----mouseover---:', params, '------', params.data)
        //   params.borderColor ="#333333";
        // if (params.data) {
        //   self.delStyle.display="inline-block";
        //   self.delStyle.top = (params.event.offsetY-20) + 'px';
        //   self.delStyle.left = params.event.offsetX + 'px';
        // }
        // })
        // this.chart.on('mouseout',function(params){
        //   console.log('myChart----mouseout---:', params, '------', params.data)
        //   self.delStyle.display="none";

        // })
      })
    },

    // 字符串除去前后空格
    trim (str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }
  }
}
</script>

<style scoped>
.empty {
  text-align: center;
  color: #999;
  font-size: 16px;
  width: 100%;
  padding: 20px 0;
}
.delete {
  display: inline-block;
  width: 40px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  background-color: #ff6d70;
  left: 0px;
  height: 25px;
  cursor: pointer;
  user-select: none;
  line-height: 25px;
  border: solid #ff2428 1px;
  border-radius: 4px;
}
</style>