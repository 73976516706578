var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _vm.worddata && _vm.worddata.length > 0
      ? _c("div", {
          ref: "mywordcloud",
          style: { width: "100%", height: "300px" },
          attrs: { id: "mywordcloud" },
        })
      : _c("div", { staticClass: "empty" }, [
          _vm._v(" " + _vm._s(_vm.tipText) + " "),
        ]),
    _c("div", { ref: "delRef", staticClass: "delete", style: _vm.delStyle }, [
      _vm._v(" 删除 "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }