<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'MyEcharts',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    type: {
      type: String,
      default: 'pie'
    },
    echartsData: {
      type: Array,
      default: [
        {
          value: 0,
          name: '暂无数据1'
        },
        {
          value: 0,
          name: '暂无数据2'
        },
        {
          value: 0,
          name: '暂无数据3'
        }
      ]
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    console.log('this height', this.height)
    this.$nextTick(() => {
      this.initChart()
    })

  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el)
      let option;
      switch (this.type) {
        case 'bar':
          option = this.getBarOption(this.echartsData);
          break;
        case 'line':
          option = this.getLineOption(this.echartsData);
          break;
        case 'pic-bar':
          option = this.getPicBarOption(this.echartsData);
          break;
        default:
          option = this.getPieOption(this.echartsData);
      }
      this.chart.setOption(option);
    },
    getPieOption (data) {
      return {
        legend: {
          top: 'bottom'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            center: ['50%', '40%'],
            label: {
              formatter: '{name|{b}}   {time|{c}}',
              rich: {
                time: {
                  color: '#999'
                }
              }
            },
            data: data
          }
        ]
      }
    },
    getLineOption (data) {
      const xdata = []
      const ydata = []
      data.forEach(item => {
        xdata.push(item.name)
        ydata.push(item.value)
      })
      return {
        xAxis: {
          data: xdata
        },
        yAxis: {
        },
        series: [
          {
            data: ydata,
            type: 'line',
            label: {
              show: true,
              position: 'top',
              // textStyle: {
              //     fontSize: 20
              // }
            }
          }
        ]
      };
    },
    getBarOption (data) {
      const xdata = []
      const ydata = []
      data.forEach(item => {
        xdata.push(item.name)
        ydata.push(item.value)
      })
      return {
        xAxis: {
          data: xdata
        },
        yAxis: {
        },
        series: [
          {
            type: 'bar',
            data: ydata,
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
    },
    getPicBarOption (data) {
      const xdata = []
      const ydata = []
      data.forEach(item => {
        xdata.push(item.name)
        ydata.push(item.value)
      })
      return {
        xAxis: {},
        yAxis: {
          data: xdata,
        },
        grid: {
          left: 150,
        },
        series: [
          {
            type: 'bar',
            data: ydata,
            // barWidth: 15,//固定柱子宽度
            // barWidth:20,
            //    barGap:'80%',/*多个并排柱子设置柱子之间的间距*/
            barCategoryGap: '50%',/*多个并排柱子设置柱子之间的间距*/
            label: {
              show: true,
              position: 'right'
            }
          }
        ]
      };
    },
  },
  watch: {
    type: {
      handler (n, o) {
        if (this.chart) {
          this.chart.dispose()
          this.chart = null
        }
        this.initChart();
      },
      deep: true // 深度监听父组件传过来对象变化
    },
    echartsData: function () {
      this.initChart()
    }
  }
}
</script>

<style scoped>
</style>
