var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.showdrawer,
        "destroy-on-close": true,
        "append-to-body": true,
        direction: "rtl",
        size: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showdrawer = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "span",
          { staticStyle: { "font-size": "18px", "font-weight": "bold" } },
          [_vm._v(_vm._s(_vm.title))]
        ),
      ]),
      _c("div", { staticClass: "view-designer-drawer" }, [
        _c(
          "div",
          { staticClass: "view-designer-body" },
          [
            _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { title: "名称", name: "8" } },
                  [
                    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "4px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("名称 "),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "设置视图名称" },
                      model: {
                        value: _vm.viewConfig.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.viewConfig, "name", $$v)
                        },
                        expression: "viewConfig.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "可见范围", name: "6" } },
                  [
                    _c("div", { staticClass: "view-item" }, [
                      _c("div", { staticClass: "view-item-subtitle" }, [
                        _vm._v(" 设置哪些用户可以看到此视图 "),
                      ]),
                    ]),
                    _c("ViewScope", {
                      attrs: {
                        formJson: _vm.formJson,
                        scope: _vm.viewConfig.viewRange,
                      },
                      on: {
                        "update:scope": function ($event) {
                          return _vm.$set(_vm.viewConfig, "viewRange", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "筛选内容", name: "4" } },
                  [
                    _c("ViewFilter", {
                      attrs: {
                        formJson: _vm.formJson,
                        filters: _vm.viewConfig.filters,
                      },
                      on: {
                        "update:filters": function ($event) {
                          return _vm.$set(_vm.viewConfig, "filters", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "显示字段", name: "2" } },
                  [
                    _c("div", { staticClass: "view-item" }, [
                      _c("div", { staticClass: "view-item-title" }, [
                        _vm._v(" 字段 "),
                      ]),
                    ]),
                    _c("ColumnTable", {
                      attrs: {
                        formWidgetList: _vm.formJson.fieldList,
                        showFields: _vm.viewConfig.showFields,
                        showColumns: _vm.viewConfig.showColumns,
                      },
                      on: {
                        "update:showFields": function ($event) {
                          return _vm.$set(_vm.viewConfig, "showFields", $event)
                        },
                        "update:show-fields": function ($event) {
                          return _vm.$set(_vm.viewConfig, "showFields", $event)
                        },
                        "update:showColumns": function ($event) {
                          return _vm.$set(_vm.viewConfig, "showColumns", $event)
                        },
                        "update:show-columns": function ($event) {
                          return _vm.$set(_vm.viewConfig, "showColumns", $event)
                        },
                        change: _vm.onShowFieldChange,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "默认排序", name: "5" } },
                  [
                    _c("ViewSort", {
                      attrs: {
                        formJson: _vm.formJson,
                        sort: _vm.viewConfig.sortList,
                      },
                      on: {
                        "update:sort": function ($event) {
                          return _vm.$set(_vm.viewConfig, "sortList", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "操作按钮", name: "7" } },
                  [
                    _c("ViewActions", {
                      attrs: {
                        formJson: _vm.formJson,
                        formId: _vm.formId,
                        actions: _vm.viewConfig.actions,
                      },
                      on: {
                        "update:actions": function ($event) {
                          return _vm.$set(_vm.viewConfig, "actions", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-collapse-item",
                  { attrs: { title: "其他设置", name: "1" } },
                  [
                    _c("div", { staticClass: "view-item" }, [
                      _c("div", { staticClass: "view-item-title" }, [
                        _vm._v(" 行高 "),
                      ]),
                      _c("div", { staticClass: "view-item-subtitle" }),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.viewConfig.style.lineHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewConfig.style, "lineHeight", $$v)
                          },
                          expression: "viewConfig.style.lineHeight",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "tight" } }, [
                          _vm._v("紧凑"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "medium" } }, [
                          _vm._v("中等"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "high" } }, [
                          _vm._v("高"),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "view-item" }, [
                      _c("div", { staticClass: "view-item-title" }, [
                        _vm._v(" 排序号 "),
                      ]),
                      _c("div", { staticClass: "view-item-subtitle" }, [
                        _vm._v("越小排序越靠前"),
                      ]),
                    ]),
                    _c("el-input-number", {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.viewConfig.sort,
                        callback: function ($$v) {
                          _vm.$set(_vm.viewConfig, "sort", $$v)
                        },
                        expression: "viewConfig.sort",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "view-designer-footer" },
          [
            _c(
              "el-button",
              { attrs: { type: "default" }, on: { click: _vm.cancelSaveView } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.saveView } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }