<template>
  <div class="details-file">
    <a :href="item.url" target="_blank" class="file-wrapper" v-for="(item,index) in fileList" :key="index">
      <svg-icon v-if="item.suffixType == 'image'" icon-class="annex_image" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'word'" icon-class="annex_word" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'excel'" icon-class="annex_excel" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'pdf'" icon-class="annex_pdf" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'audio'" icon-class="annex_audio" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'video'" icon-class="annex_video" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'zip'" icon-class="annex_zip" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'ppt'" icon-class="annex_ppt" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon v-if="item.suffixType == 'other'" icon-class="annex_other" style="width: 22px; height: 22px;vertical-align: middle;" />
      <svg-icon class="down-icon" icon-class="download2" />
      <span>{{item.name}}</span>
    </a>
    <div class="empty" v-if="fileList.length==0">
      暂无内容
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fileList: {
      type: Array,
      default: () => { }
    }
  }

}
</script>
<style scoped lang="scss">
.details-file {
  width: 100%;
  padding: 0 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .empty{
    width:100%;
    text-align: center;
    color:#7c7c7c;
    font-size:14px;
    height:80px;
    line-height:80px;
  }
}

.details-file .file-wrapper:hover {
  background: rgba(0, 92, 238, 0.07);
}

.details-file .file-wrapper {
  position: relative;
  width: 752px;
  height: 40px;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  padding-left: 8px;
  color: #646d7a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &:hover .down-icon {
    display: block;
  }
}

.down-icon {
  font-size: 16px;
  color: #3476f0;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
  display: none;
}
</style>