var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "草稿箱",
        visible: _vm.draftBoxVisible,
        width: "85%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "custom-class": "el-dialog-hide-header",
      },
      on: {
        "update:visible": function ($event) {
          _vm.draftBoxVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "500px" } },
        [
          _c("MyVxeTable", {
            ref: "fillGridTable",
            attrs: {
              columns: _vm.fillGridOptions.columns,
              loading: _vm.fillGridOptions.loading,
              data: _vm.fillGridOptions.data,
              params: _vm.fillGridOptions.params,
              agencyId: _vm.formDetail.agencyId,
              formJson: _vm.formJson,
              formDetail: _vm.formDetail,
              extendTableConfig: _vm.customTableConfig,
            },
            scopedSlots: _vm._u([
              {
                key: "operate",
                fn: function () {
                  return [
                    _c("vxe-column", {
                      attrs: {
                        width: "140px",
                        "slots:default": "operate",
                        fixed: "right",
                        "header-class-name": "sysfield",
                        align: "center",
                        type: "operate",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [_c("span", [_vm._v("操作")])]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    status: "primary",
                                    disabled: _vm.disableFill,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row["fillId"], row)
                                    },
                                  },
                                },
                                [_vm._v("继续填报")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    status: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        row["fillId"],
                                        row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("VFormViewer", {
        ref: "dlgvFormViewer",
        attrs: { formAuth: _vm.formAuth },
        on: { "on-save": _vm.handleDraftSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }