var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.agreement.agreementTitle,
        top: "5vh",
        visible: _vm.showFormAgreementDialog,
        "custom-class": "agreement-dialog",
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showFormAgreementDialog = $event
        },
        close: _vm.onCloseEditPayStatusClick,
      },
    },
    [
      _c("div", {
        staticClass: "agreement-context",
        domProps: { innerHTML: _vm._s(_vm.agreement.agreementContent) },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "-30px", "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onDisableAgreementClick } }, [
            _vm._v("不同意"),
          ]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.isAgreementButtonDisabled,
              },
              on: { click: _vm.onAgreementClick },
            },
            [
              _vm._v("同意并继续"),
              _vm.agreementCountdown > 0
                ? _c("span", [
                    _vm._v("(" + _vm._s(_vm.agreementCountdown) + ")"),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }