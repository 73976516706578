<template>
  <div class="footer" :style="`background: linear-gradient(to bottom, ${formDesign.themeColor}60, ${formDesign.themeColor});`">
    {{formDesign.pageFoot}}
  </div>
</template>
<script>
export default { props:{
    formDesign:{
      type:Object,
      default: ()=>{}
    }
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  background-size: 100% 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
</style>
