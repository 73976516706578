var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "750px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "user-item header" }, [
        _c("div", { staticClass: "user-item-name" }, [_vm._v("编号")]),
        _c("div", { staticClass: "user-item-name" }, [_vm._v("姓名")]),
        _c("div", { staticClass: "user-item-agency" }, [_vm._v("部门")]),
        _c("div", { staticClass: "user-item-agency" }, [_vm._v("角色")]),
      ]),
      _c(
        "div",
        { staticClass: "user-list" },
        [
          _vm._l(_vm.userList, function (item, index) {
            return _c("div", { key: index, staticClass: "user-item" }, [
              _c("div", { staticClass: "user-item-name" }, [
                _vm._v(_vm._s(item.number)),
              ]),
              _c("div", { staticClass: "user-item-name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("div", { staticClass: "user-item-agency" }, [
                _vm._v(_vm._s(item.deptName)),
              ]),
              _c("div", { staticClass: "user-item-agency" }, [
                _vm._v(_vm._s(item.roleName)),
              ]),
            ])
          }),
          _vm.userList.length == 0
            ? _c("div", { staticClass: "user-list" }, [
                !_vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "60px",
                          "line-height": "60px",
                          "text-align": "center",
                          color: "#888888",
                        },
                      },
                      [_vm._v("暂无数据")]
                    )
                  : _vm._e(),
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "60px",
                          "line-height": "60px",
                          "text-align": "center",
                          color: "#888888",
                        },
                      },
                      [_vm._v("数据加载中，请稍候")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "-30px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticStyle: { height: "30px", "text-aign": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.userList)
                    },
                  },
                },
                [_vm._v("复制数据")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeDialog } },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }