<template>
    <div class="detail-nav">        
      <div class="detail-container app-inner-container">
        <div class="detail-left">
          <div class="detail-left-logo" @click="naviHome">
            <svg-icon icon-class="home" />
          </div>

          <div class="separator"></div>
          <span class="headerNow" @click="naviTo(1)">{{layerName(1)}}</span>
          <div class="separator" v-if="layer(2)"></div>
          <span class="headerNow" v-if="layer(2)" @click="naviTo(2)">{{ layerName(2) }}</span>

          <div class="separator" v-if="layer(3)"></div>
          <span class="headerNow" v-if="layer(3)" style="cursor: inherit;" @click="naviTo(3)">{{ layerName(3) }}</span>
        </div>
        <title-user-menu name-color="#2B344199" icon-color="#2B344199" v-if="needLogin"></title-user-menu>
        <template v-else>
            <div class="detail-right-login" @click="toLogin">
                <span class="right-login-text">您暂未登录，请微信扫码或账号登录</span>
                <el-button type="primary" size="mini">登录</el-button>
            </div>
        </template>
      </div>

      <el-dialog :visible.sync="loginDialog.open" width="340px" append-to-body custom-class="el-dialog-hide-header login-dialog">
        <div slot="title"></div>
        <LoginDialog @login-success="handleLoginSuccess"></LoginDialog>
      </el-dialog>
    </div>
</template>
<script>
import TitleUserMenu from "@/components/TitleUserMenu/index.vue";
import LoginDialog from '@/components/Login/index.vue'
export default {
    name: "DetailNav",
    components:{TitleUserMenu, LoginDialog},
    props:{
        breadcrumb :{
            type: Array,
            default: ()=>[]
        },
        loginSuccess:{
          type:Function
        }
    },
    data(){
        return {
            // 新版---登录弹框
            loginDialog: {
                open: false,
            },
        }
    },
    computed:{
        router(){
            return this.$route.name;
        },
        needLogin(){
            return this.router === 'Detail' || this.router==='multipleFormDetail'
        },
        title:function(){
            return this.breadcrumb.filter(x=>!!x)
        }
    },
    methods:{
        layer:function(layer){
            return this.title && Array.isArray(this.breadcrumb) && this.title.length>=layer;
        },
        layerName: function(layer){
            if(this.breadcrumb && Array.isArray(this.breadcrumb) && this.title.length>=layer){
                return this.title[layer-1]
            }
        },
        // 返回事件
        naviHome () {
            if (this.$router.options.from.path === "/login") {
                this.$router.push({
                path: "/index",
                });
            } else {
                this.$router.go(-1);
            }
        },
        naviTo(layer){
            this.$emit("naviTo"+layer, this.title[layer-1])
        },        
        toLogin() {
            this.loginDialog.open = true;
        },
        handleLoginSuccess(){
            this.$emit("login-success")
        }
    }
}
</script>
<style lang="scss" scoped>
.detail-nav {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 56px;
  -webkit-transform: translateZ(0);
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);

  .detail-container {
    display: inline-flex;
    justify-content: space-between;
  }

  .detail-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 56px;
    font-size: 14px;
    color: #333333;
    .detail-left-logo {
      cursor: pointer;
      font-size: 20px;
    }
    .separator {
      width: 1px;
      height: 20px;
      border-right: 1px solid #dbdde7;
      margin: 0 16px;
    }
    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .detail-center {
    max-width: 600px;
    margin: auto;
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #404040;
  }
  .form-fill-btn {
    line-height: 60px;
  }
}

.detail-right-login {
    display: flex;
    display: -webkit-flex;
    height: 60px;
    line-height: 60px;
    align-items: center;
    float: right;
    margin-right: 20px;

    .right-login-text {
        margin-right: 20px;
    }
}

</style>
<style lang="scss">
.el-dialog.login-dialog{
  .el-dialog__header{
    height:0px;
    padding: 0px;
  }
  .el-dialog__headerbtn{
    z-index: 100;
  }
  .el-dialog__body{
    padding: 0px;
  }
}

</style>