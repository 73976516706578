export const viewActions = [
  { value: 'A1', label: '新增' },
  { value: 'A2', label: '详情/编辑' },
  { value: 'A3', label: '删除' },
  { value: 'A8', label: '管理员删除' },
  { value: 'A4', label: '批量编辑' },
  // { value: 'A5', label: '草稿' },
  { value: 'A6', label: '批量更新' },
  { value: 'A7', label: '日志' },
  { value: 'B1', label: '审核/修改审核' },
  { value: 'B2', label: '批量审核' },
  { value: 'C1', label: '导出Excel' },
  // { value: 'C2', label: '导出带图片的Excel' },
  // { value: 'C3', label: 'EXCEL的合并导出' },
  { value: 'C4', label: '导出附件' },
  { value: 'C5', label: '导出word/pdf文档' },
  { value: 'D1', label: '批量导入他人数据' },
  { value: 'E1', label: '支付' },
  { value: 'E2', label: '批量支付' },
  { value: 'E3', label: '修改支付金额' },
  { value: 'E4', label: '修改支付状态' },
  { value: 'F1', label: '发送通知' },
  { value: 'F2', label: '数据分享' },
  { value: 'F3', label: '数据刷新' }
];
