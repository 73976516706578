import { fillDetail, httpGetFormFieldV2 } from "@/api/system/form";
export default {
  computed: {
    fillSelectorRef: function () {
      return this.$refs.fillSelector;
    },
  },
  methods: {
    //填报窗口 数据选择组件弹出选择数据
    showDataSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.fillSelectorRef.show(fillSelectOption, callback);
    },
    
    showDataAutoSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.fillSelectorRef.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect(fillSelectOption, callback, search, searchObject) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.fillSelectorRef.autoFillMulti(
        fillSelectOption,
        callback,
        search,
        searchObject
      );
    },
    async showDataView(fillSelectOption, fillData, callback) {
      // 加载表单配置数据
      fillSelectOption.formId = fillSelectOption.formId || this.formId;
      const res = await httpGetFormFieldV2(fillSelectOption.formId);

      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });
      Promise.all([loadField, loadData]).then((values) => {
        this.$refs.dlgvFormViewer.showView({
          fillId: fillData.fillId,
          formId: fillSelectOption.formId,
          formJson: values[0],
          formData: values[1],
          showHiddenWidgets: this.cur != 3,
          showField: fillSelectOption.fields,
          agencyId: this.formDetail.agencyId,
          // fillInfo: listType == "MANAGE"?{
          //     fillUserName: row.fillUserName,
          //     fillUserAvatarUrl: row.fillUserAvatarUrl,
          //     fillUserId: response.data.userId,
          //     createTime: row.createTime,
          //     updateTime: row.updateTime,
          //   }: null,
        });
      });
    },
    // 引用table的render，显示dataCard内容
    dataCardRender() {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
  },
};
