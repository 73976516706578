<template>
    <el-dropdown size="mini" split-button type="" @click="command['excel']" style="height:30px;margin-right:10px">
        <i class="el-icon-download"></i>导出
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="command['excel']" data-tag="excel">
                导出为Excel
                <el-tooltip content="若包含“图片/附件”字段，则导出为下载链接" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item @click.native="command['picexcel']" data-tag="picexcel">
                导出为带图片的Excel
                <el-tooltip content="图片会以缩略图形式导出到excel中，由于excel自身问题，图片会被拉伸。" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item @click.native="command['excel2']" data-tag="excel2" :disabled="disableExportExcel2">
                Excel的合并导出
                <el-tooltip content="若包含“图片/附件”字段，则导出为下载链接" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item>
            
            <el-dropdown-item @click.native="command['file']" data-tag="file">
                附件
                <el-tooltip content="命名规则：“编号-姓名-上传时间-字符串”" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item @click.native="command['word']" data-tag="word">
                Word文档
                <el-tooltip content="功能开发中" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item>
            <!-- <el-dropdown-item @click.native="command['word']" data-tag="word">
                PDF文档
                <el-tooltip content="功能开发中" placement="top" effect="light">
                    <span class="tip-icon"><i class="el-icon-info"></i></span>
                </el-tooltip>
            </el-dropdown-item> -->
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
  name: 'Export',
  props: {
    command: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fieldList:{
        type:Array,
        default:()=>{
            return []
        }
    }
  },
  computed:{
      disableExportExcel2:function(){
          return !!!this.fieldList.find(x=>x.type=='fill-select')
      }
  },
  data(){
      return{
      }
  },
  created(){
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.tip-icon {
    float: right;
    margin-left: 5px;
    i {
        color: #46a6ff;
    }
}

.iconfont-jyd {
    color: #1890ff;
    font-size: 12px;
}
</style>